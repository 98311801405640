import { FC } from 'react';
import CepTargetListGroup from 'modules/maya/groups/CepFormTargetListGroup';

const TargetListStep: FC = () => {
  return (
    <>
      <CepTargetListGroup />
    </>
  );
};

export default TargetListStep;
