import { FC } from 'react';
import DialogFormContent from 'components/DialogForm/DialogFormContent';
import { CreateEventModalConfig } from 'modules/events/components/CreateEvent/CreateEvent.types';
import Box from 'yoda-ui/Box';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

type CreateEventDialogContentProps = {
  createEventConfig: CreateEventModalConfig;
};

const CreateEventDialogContent: FC<CreateEventDialogContentProps> = ({
  createEventConfig,
}) => (
  <DialogFormContent>
    <Box fontSize={ YodaFontSize.medium }>
      <Box paddingTop={ YodaSpacing.large }>
        <TextYoda { ...createEventConfig.title } />
      </Box>
      <Box paddingTop={ YodaSpacing.large }>
        <SelectYoda { ...createEventConfig.market } />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box paddingTop={ YodaSpacing.large } width="48%">
          <SelectYoda { ...createEventConfig.presenceType } />
        </Box>
        <Box paddingTop={ YodaSpacing.large } width="48%">
          <SelectYoda { ...createEventConfig.eventFormat } />
        </Box>
      </Box>
    </Box>
  </DialogFormContent>
);

export default CreateEventDialogContent;
