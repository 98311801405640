import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mayaCepListRoute } from 'basics/constants/routes.constants';
import { StepButtonsConfig } from 'components/ButtonsBar/ButtonsBar';
import { UseStepIndexer } from 'hooks/useStepIndexer';
import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { CepViewStepsIndex } from 'modules/maya/routes/CepView/CepView.types';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepViewButtons = (
  stepIndexer: UseStepIndexer,
  nextPage: MouseEventHandler,
  previousPage: MouseEventHandler,
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const firstStepButtons: StepButtonsConfig = {
    thirdButton: {
      buttonProps: {
        onClick: nextPage,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
    },
  };

  const middleStepsButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        onClick: previousPage,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
      hidden: false,
    },
    thirdButton: {
      buttonProps: {
        onClick: nextPage,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
    },
  };

  const lastStepButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        onClick: previousPage,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
    },
    thirdButton: {
      buttonProps: {
        onClick: () => navigate(mayaCepListRoute),
        buttonType: ButtonType.primary,
      },
      label: t('maya_cep_view_home'),
    },
  };

  switch (CepViewStepsIndex[stepIndexer.stepIndex]) {
    case CepSteps.configurationStep:
      return firstStepButtons;
    case CepSteps.targetListStep:
      return middleStepsButtons;
    case CepSteps.tacticsStep:
      return lastStepButtons;
    default:
      return null;
  }
};

export default useCepViewButtons;
