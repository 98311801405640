import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_TARGETS_FACETS = gql`
query getTargetsFacets($filters: TargetFilterInput!) {
  getTargetsFacets(filters: $filters) {
    types
    country
    businessUnits
    specialties
    smaSegments
    biosSegments
    imsSegments
  }
}
`;

type ResultType = {
  getTargetsFacets: {
    types: string[];
    country: string[];
    businessUnits: string[];
    specialties: string[];
    smaSegments: string[];
    biosSegments: string[];
    imsSegments: string[];
  };
};

type UseGetTargetsFacetsLazy = () => {
  queryGetTargetsFacets: Function;
  loading: boolean;
  error?: ApolloError;
  data: unknown;
};

const useGetTargetsFacetsLazy: UseGetTargetsFacetsLazy = () => {
  const [queryGetTargetsFacets, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_TARGETS_FACETS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getTargetsFacets.ts - useGetTargetsFacetsLazy',
    });
  }

  return {
    queryGetTargetsFacets,
    data: data?.getTargetsFacets || {},
    loading,
    error,
    called,
  };
};

export default useGetTargetsFacetsLazy;
