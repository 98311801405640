import { ApolloError, gql, useQuery } from '@apollo/client';
import { Market } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_FULL_PRIVATE_EVENTS = gql`
  query getFullPrivateEvents($source: Source, $market: String, $isPast: Boolean) {
    getFullPrivateEvents(source: $source, market: $market, isPast: $isPast) {
      ${eventFragment}
      data {
        ...on OnlineEvent {
          __typename
          session {
            startDate
            endDate
          }
          provider {
            host
            participantUrl
            organizerUrl
            licenseId
            providedId
          }
          biogenLincConfig {
            showSendInvitations
            slug
            isVisibleOnBiogenLinc
          }
          invitationSent
        }
      }
    }
  }
`;

type ResultType = {
  getFullPrivateEvents: Event[];
};

type UseGetEvents = (market: Market | null, isPast: boolean) => {
  loading: boolean;
  error?: ApolloError;
  data?: Event[];
};

const useGetFullPrivateEventsQuery: UseGetEvents = (market, isPast) => {
  const { data, loading, error } = useQuery<ResultType>(GET_FULL_PRIVATE_EVENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      source: 'backoffice',
      market,
      isPast,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEvents.ts - useGetFullPrivateEventsQuery',
    });
  }

  return {
    data: data?.getFullPrivateEvents || [],
    loading,
    error,
  };
};

export default useGetFullPrivateEventsQuery;
