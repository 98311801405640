import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useInviteesGroupSchema from './InviteesGroup.schema';
import { FileExtension } from 'basics/types/files.types';
import { UseFormDropZoneStateSource } from 'components/QuickForm/FormsBucket/FormDropZone/FormDropZone.enums';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import { FILTER_NO_STATUS, filterParticipants } from 'modules/events/basics/utils/participants.utils';
import { UploadInviteesProps } from 'modules/events/components/UploadInvitees/UploadInvitees.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/Icons/Icon';

type UploadInviteesConfig = Omit<UploadInviteesProps, 'handleFilesExternal' | 'handleRemoveItemAsync'>;

const useInviteesGroupConfig = () => {
  const { t } = useTranslation();
  const inviteesGroupSchema = useInviteesGroupSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const isPublished = !isDraft(snap.createEvent.status);

  const cardSwitchConfig = {
    name: commonFields.showSendInvitations,
    title: t('inviteesGroup_cardSwitch_title'),
    defaultValue: snap.createEvent.data?.showSendInvitations || false,
    disabled: isPublished,
  };

  const uploadInviteesConfig: UploadInviteesConfig = useMemo(
    () => ({
      name: commonFields.participantsWishList,
      formConfig: {
        btnLabel: t('inviteesGroup_tabUpload_btnLabel'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 0,
        extensionsAllowed: [FileExtension.csv],
        message: t('inviteesGroup_tabUpload_message'),
        label: t('inviteesGroup_tabUpload_label'),
        name: commonFields.participantsWishList,
        assetTarget: AssetTarget.participantCsv,
        assetType: AssetType.document,
        assetsArePublic: true,
        stateSource: UseFormDropZoneStateSource.EXTERNAL,
      },
      listConfig: {
        noItemLabel: t('inviteesGroup_tabUpload_list_noItemLabel'),
        title: t('inviteesGroup_tabUpload_list_title'),
      },
      defaultValue: filterParticipants(snap.createEvent.data?.participants || [], FILTER_NO_STATUS, false),
      validation: inviteesGroupSchema.participantsWishList,
      disabled: isPublished,
    }),
    [t, snap.createEvent.data?.participants, inviteesGroupSchema.participantsWishList, isPublished],
  );

  return {
    cardSwitchConfig,
    uploadInviteesConfig,
  };
};

export default useInviteesGroupConfig;
