import Autocomplete from '@mui/material/Autocomplete';
import { FC, useCallback } from 'react';
import { MultiSelectIdsType } from 'basics/options/options.types';
import { findMultipleOptionsByIds } from 'basics/options/options.utils';
import { useYodaField } from 'services/yodaForm';
import FormHelperText from 'yoda-ui/Form/FormHelperText';
import { MultiSelectYodaInputProps } from 'yoda-ui/Form/SelectYoda/MultiSelectYoda/MultiSelectYoda.types';
import { selectStyle, textFieldStyle } from 'yoda-ui/Form/SelectYoda/SelectYoda.styles';
import TextField from 'yoda-ui/Form/TextField';
import Paper from 'yoda-ui/Paper';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const MultiSelectYoda: FC<MultiSelectYodaInputProps> = ({
  defaultValue,
  disableClearable,
  disabled,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  validation,
}) => {
  const {
    onChangeFieldSynthetic,
    fieldValue,
    fieldDefaultValue,
    fieldShowError,
    fieldErrorMessage,
  } = useYodaField({ name, defaultValue, onChange, validation });

  // eslint-disable-next-line max-len
  const findCurrentMultiOptionsByIds = useCallback((idsList: MultiSelectIdsType) => findMultipleOptionsByIds(options, idsList), [options]);

  return (
    <>
      <Autocomplete
        data-testid="multi-select"
        multiple
        PaperComponent={
          ({ children }) => (
            <Paper margin={ YodaSpacing.small }>{ children }</Paper>
          )
        }
        sx={ selectStyle }
        disabled={ disabled }
        options={ options }
        getOptionLabel={ (option) => option.label }
        defaultValue={ findCurrentMultiOptionsByIds(fieldDefaultValue) }
        disableClearable={ disableClearable }
        value={ findCurrentMultiOptionsByIds(fieldValue) }
        onChange={ onChangeFieldSynthetic }
        renderInput={
          (params) => (
            <TextField
              { ...params }
              sx={ textFieldStyle }
              label={ label }
              placeholder={ placeholder }
              required={ required }
              error={ fieldShowError }
            />
          )
        }
      />
      { fieldShowError && <FormHelperText error>{ fieldErrorMessage }</FormHelperText> }
    </>
  );
};

export default MultiSelectYoda;
