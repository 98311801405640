import { FC } from 'react';
import { Event } from 'modules/events/basics/types/events.types';
import useEventSummary from 'modules/events/components/EventsList/EventSummary/EventSummary.hook';

import Box from 'yoda-ui/Box';
import Icon, { Icons } from 'yoda-ui/Icons/Icon';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

export type EventDialogTitleProps = {
  event: Event;
  titleSize?: YodaFontSize;

};

const EventSummary: FC<EventDialogTitleProps> = ({ event, titleSize = YodaFontSize.large }) => {
  const { eventType, eventDateAndTime } = useEventSummary({ event });

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={ YodaFontSize.small }
        marginBottom={ YodaSpacing.xxxSmall }
      >
        <Box
          color={ YodaColors.primaryBlue }
          marginRight={ YodaSpacing.medium }
          fontWeight={ YodaFontWeight.medium }
        >
          { eventType }
        </Box>
        <Box
          fontWeight={ YodaFontWeight.regular }
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Icon
            name={ Icons.calendarOutline }
            fontSize="inherit"
            style={ { fill: YodaColors.gray4 } }
          />
          <Box marginLeft={ YodaSpacing.xxSmall } color={ YodaColors.gray4 }>
            { eventDateAndTime }
          </Box>
        </Box>
      </Box>
      <Box
        fontSize={ titleSize }
        fontWeight={ YodaFontWeight.medium }
      >{ event.title }</Box>

    </>

  );
};

export default EventSummary;
