import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { customerInitiativeFormFullHeight, width50, width33, customerInitiativeFormWrapper } from './CustomerInitiativeForm.style';
import { CustomerInitiativeFormPropsType } from './CustomerInitiativeForm.types';
import { FormLayoutContainer } from 'components/FormLayout';
import useCustomerInitiativeCreate from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.hook';
import FormContainer from 'modules/maya/components/FormContainer/FormContainer';
import Box from 'yoda-ui/Box';
import DateRangePicker from 'yoda-ui/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import Loader from 'yoda-ui/Loader';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const CustomerInitiativeForm: FC<CustomerInitiativeFormPropsType> = ({
  parentStrategicImperative,
  customerInitiative,
  submitCallback,
  cancelCallback,
}) => {
  const flags = useFlags();

  const {
    cancelButtonConfig,
    customerInitiativeFormConfig,
    handleChangeProduct,
    handleChangeStrategicImperative,
    handleChangeTA,
    providerFields,
    siLoading = false,
    submitButtonConfig,
  } = useCustomerInitiativeCreate(parentStrategicImperative, customerInitiative, submitCallback, cancelCallback);

  return (
    <FormContainer providerFields={ providerFields } cancelButtonConfig={ cancelButtonConfig } submitButtonConfig={ submitButtonConfig }>
      <Box sx={ customerInitiativeFormWrapper }>
        <Box paddingTop={ YodaSpacing.large } width={ width50 }>
          <TextYoda { ...customerInitiativeFormConfig.title } />
        </Box>
        <Box paddingTop={ YodaSpacing.large } width={ width50 }>
          <SelectYoda { ...customerInitiativeFormConfig.country } />
        </Box>
      </Box>
      <Box paddingTop={ YodaSpacing.small }>
        <DateRangePicker { ...customerInitiativeFormConfig.date } />
      </Box>
      <Box sx={ customerInitiativeFormWrapper }>
        <Box sx={ customerInitiativeFormFullHeight }>
          <TextYoda { ...customerInitiativeFormConfig.description } />
        </Box>
        <Box paddingTop={ YodaSpacing.large } width={ width50 }>
          <TextYoda { ...customerInitiativeFormConfig.solutionCoreStory } />
        </Box>
      </Box>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween }>
        <Box width={ width33 } >
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda { ...customerInitiativeFormConfig.strategicImperative } onChange={ handleChangeStrategicImperative } />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda { ...customerInitiativeFormConfig.functionalOwner } />
          </Box>
        </Box>
        <Box width={ width33 } >
          <Box paddingTop={ YodaSpacing.large }>
            {
              siLoading
                ? (
                  <Box
                    display="flex"
                    justifyContent={ YodaJustifyContent.center }
                    position="absolute"
                    paddingTop={ YodaSpacing.small }
                    paddingBottom={ YodaSpacing.small }
                  >
                    <Loader center size={ 1 } />
                  </Box>
                )
                : null
            }
            <SelectYoda
              { ...customerInitiativeFormConfig.therapeuticArea }
              disabled={ siLoading || customerInitiativeFormConfig.therapeuticArea.disabled }
              onChange={ handleChangeTA }
            />
          </Box>
          <Box paddingTop={ YodaSpacing.large }>
            <MultiSelectYoda { ...customerInitiativeFormConfig.functionsInvolved } />
          </Box>
        </Box>
        <Box width={ width33 } >
          <Box paddingTop={ YodaSpacing.large }>
            <SelectYoda { ...customerInitiativeFormConfig.product } onChange={ handleChangeProduct } />
          </Box>
          {
            flags.mayaFinanceBiogenlinc866 && (
              <Box paddingTop={ YodaSpacing.large }>
                <TextYoda { ...customerInitiativeFormConfig.totalBudget } />
              </Box>
            )
          }
        </Box>
      </FormLayoutContainer>
    </FormContainer>
  );
};

export default CustomerInitiativeForm;
