import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { FormAgendaConfig } from 'components/QuickForm/QuickForm.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useAgendaGroupSchema from 'modules/events/groups/AgendaGroup/AgendaGroup.schema';
import { AgendaGroupConfig } from 'modules/events/groups/AgendaGroup/AgendaGroup.types';
import eventState from 'modules/events/states/events.states';

const useAgendaGroupConfig = ({ agendaFormConfig }: { agendaFormConfig: FormAgendaConfig }) => {
  const { t } = useTranslation();
  const { createEvent: { data: eventSnapData } } = useSnapshot(eventState) as EventStateType;
  const agendaGroupSchema = useAgendaGroupSchema();

  const formConfig: AgendaGroupConfig = {
    agenda: {
      defaultValue: eventSnapData?.agenda ? [...eventSnapData.agenda] : [],
      formConfig: agendaFormConfig,
      listConfig: {
        noItemLabel: t('events.agendaGroupEmpty'),
        title: '',
      },
      name: commonFields.agenda,
      validation: agendaGroupSchema.agenda,
      disabled: false,
    },
  };

  return ({ formConfig });
};

export default useAgendaGroupConfig;
