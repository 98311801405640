import { cloneDeep, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCepTemplateListConfig from './CepTemplateList.config';
import { mayaCepDuplicateRoute, mayaCepTemplateCreateRoute, mayaCepTemplateEditRoute } from 'basics/constants/routes.constants';
import { errorToast, succesToast } from 'basics/utils/toast';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { useSetCepStatusToDeletedMutation } from 'modules/maya/graphql/mutations/setCepStatusToDeleted';
import useGetAllCepQuery from 'modules/maya/graphql/queries/getAllCeps';

const useCepTemplateList = () => {
  const [cepTitleFilter, setCepTitleFilter] = useState<(string)>();
  const [customerInitiativeFilter] = useState<(string | number)[]>([]);
  const [statusFilter] = useState<(string | number)[]>([]);
  const [ceps, setCeps] = useState<CustomerEngagementPlanning[]>([]);

  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  const navigate = useNavigate();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      title: cepTitleFilter,
      isTemplate: true,
    }),
    [cepTitleFilter],
  );

  const { data: cepData, loading: cepLoading, count } = useGetAllCepQuery(queryFilters, queryOptions);
  const [setCepStatusToDeleted, { loading: removeCepLoading }] = useSetCepStatusToDeletedMutation();

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!cepLoading) {
      setCeps(cepData);
    }
  }, [cepData, cepLoading]);

  const editCepCallback = async (cepId: string) => {
    navigate(`${mayaCepTemplateEditRoute}?id=${cepId}`);
  };

  const duplicateCepCallback = async (cepId: string) => {
    navigate(`${mayaCepDuplicateRoute}?sourceId=${cepId}`);
  };

  const deleteCepCallback = async (cepId: string) => {
    const isCepDeleted = await setCepStatusToDeleted(cepId, true);

    if (isCepDeleted.data) {
      succesToast(t('maya_action_deleting_cep_success'));
      const clonedCeps = cloneDeep(ceps);
      const targetCepIndex = clonedCeps.findIndex((cep) => { return cep._id === cepId; });
      if (targetCepIndex !== -1) {
        clonedCeps.splice(targetCepIndex, 1);
        setCeps(clonedCeps);
      }
    } else if (isCepDeleted.error) {
      errorToast(isCepDeleted.error);
    }
    setOpen(false);
  };

  const openModalCallback = (cepId: string) => {
    setOpen(true);
    setRowId(cepId);
  };

  const {
    cepListColumnConfig,
    cepTitleFieldConfig,
  } = useCepTemplateListConfig(
    editCepCallback,
    duplicateCepCallback,
    openModalCallback,
  );

  const newCepClick = () => {
    navigate(mayaCepTemplateCreateRoute);
  };

  const handleChangeCepTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCepTitleFilter(event.target.value);
  };
  const debouncedHandleChangeCepTitle = useMemo(
    () => debounce(handleChangeCepTitle, 750),
    [],
  );

  const getFilteredRows = useCallback(() => {
    let filteredRows = ceps;

    if (customerInitiativeFilter.length > 0) {
      filteredRows = cepData.filter((value) => {
        if (typeof value.customerInitiative === 'string') {
          return value.customerInitiative;
        }

        return customerInitiativeFilter.indexOf(value.customerInitiative._id) !== -1;
      });
    }

    if (statusFilter.length > 0) {
      filteredRows = filteredRows.filter((value) => {
        return statusFilter.indexOf(value.status) !== -1;
      });
    }

    return filteredRows;
  }, [ceps, customerInitiativeFilter, statusFilter, cepData]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const menuCustomerEngagementPlanningLabel = t('maya_main_menu_cep_tpl_label');
  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: 'Template' }),
    newElementClick: newCepClick,
  };

  return {
    cepData,
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    customerInitiativeFilter,
    debouncedHandleChangeCepTitle,
    deleteCepCallback,
    getFilteredRows,
    menuCustomerEngagementPlanningLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCepLoading,
    rowCountState,
    rowId,
    setOpen,
    statusFilter,
  };
};

export default useCepTemplateList;
