import { CepSteps, CepGroups } from 'modules/maya/basics/enums/cep.enums';

export const configurationStepFields = {
  aop: `${CepSteps.configurationStep}.${CepGroups.configuration}.aop`,
  biogenFunction: `${CepSteps.configurationStep}.${CepGroups.configuration}.biogenFunction`,
  budgetPlanned: `${CepSteps.configurationStep}.${CepGroups.configuration}.budgetPlanned`,
  channels: `${CepSteps.configurationStep}.${CepGroups.configuration}.channels`,
  ciDateEnd: `${CepSteps.configurationStep}.${CepGroups.configuration}.ciDateEnd`,
  ciDateStart: `${CepSteps.configurationStep}.${CepGroups.configuration}.ciDateStart`,
  customerInitiative: `${CepSteps.configurationStep}.${CepGroups.configuration}.customerInitiative`,
  customerType: `${CepSteps.configurationStep}.${CepGroups.configuration}.customerType`,
  date: `${CepSteps.configurationStep}.${CepGroups.configuration}.date`,
  description: `${CepSteps.configurationStep}.${CepGroups.configuration}.description`,
  digital: `${CepSteps.configurationStep}.${CepGroups.configuration}.digital`,
  keyMessages: `${CepSteps.configurationStep}.${CepGroups.configuration}.keyMessages`,
  kpi: `${CepSteps.configurationStep}.${CepGroups.configuration}.kpi`,
  localCurrency: `${CepSteps.configurationStep}.${CepGroups.configuration}.localCurrency`,
  multiyearPlan: `${CepSteps.configurationStep}.${CepGroups.configuration}.multiyearPlan`,
  product: `${CepSteps.configurationStep}.${CepGroups.configuration}.product`,
  specialty: `${CepSteps.configurationStep}.${CepGroups.configuration}.specialty`,
  spendCategory: `${CepSteps.configurationStep}.${CepGroups.configuration}.spendCategory`,
  therapeuticArea: `${CepSteps.configurationStep}.${CepGroups.configuration}.therapeuticArea`,
  title: `${CepSteps.configurationStep}.${CepGroups.configuration}.title`,
};

