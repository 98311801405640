import { CommonGroups, CommonSteps } from 'modules/events/basics/enums/commonForms.enums';

export const commonFields = {
  collectParticipantPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.collectParticipantPhoneNumber`,
  coorganizers: `${CommonSteps.detailsStep}.${CommonGroups.coorganizers}.coOrganizers`,
  hostProvider: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.hostProvider`,
  hostProviderLink: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.hostProviderLink`,
  eventFormat: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.eventFormat`,
  locale: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.locale`,
  session: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.session`,
  enableZoomStreaming: `${CommonSteps.detailsStep}.${CommonGroups.timeSlot}.enableZoomStreaming`,
  title: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.title`,
  description: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.description`,
  cardImage: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.cardImage`,
  product: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.product`,
  program: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.program`,
  programLogoUrl: `${CommonSteps.detailsStep}.${CommonGroups.primaryInformations}.programLogoUrl`,
  speakerSupportPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.speakers}.speakerSupportPhoneNumber`,
  speakers: `${CommonSteps.detailsStep}.${CommonGroups.speakers}.speakers`,
  teaser: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.teaser`,
  testimonials: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.testimonials`,
  disclaimerText: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.disclaimerText`,
  productInformationButtonLabel: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.productInformationButtonLabel`,
  productInformationButtonLink: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.productInformationButtonLink`,
  recordingOptinLabel: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.recordingOptinLabel`,
  participantsSupportPhoneNumber: `${CommonSteps.detailsStep}.${CommonGroups.optionalInformations}.participantsSupportPhoneNumber`,
  agenda: `${CommonSteps.detailsStep}.${CommonGroups.agenda}.agenda`,
  isVisibleOnBiogenLinc: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.isVisibleOnBiogenLinc`,
  isPublic: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.isPublic`,
  enableGuestRegistration: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.enableGuestRegistration`,
  prcCodeEventDetails: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.prcCodeEventDetails`,
  showSendInvitations: `${CommonSteps.communicationStep}.${CommonGroups.biogenLincConfig}.showSendInvitations`,
  participantsWishList: `${CommonSteps.communicationStep}.${CommonGroups.invitees}.participantsWishList`,
  emailHeaderNote: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailHeaderNote`,
  emailFooterNote: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailFooterNote`,
  emailContentfulUrl: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.emailContentfulUrl`,
  biogenLogo: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.biogenLogo`,
  buttonColor: `${CommonSteps.emailConfigurationStep}.${CommonGroups.emailNotes}.buttonColor`,
};
