import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import useStrategicImperativeList from './StrategicImperativeList.hook';
import GridList from 'modules/maya/components/GridList/GridList';
import MayaLayout from 'modules/maya/components/MayaLayout';
import { CustomerInitiativeListFilters } from 'modules/maya/routes/CustomerInitiativeList/CustomerInitiativeList.style';
import { useYodaCreateForm, YodaFormProvider } from 'services/yodaForm';
import TextYoda from 'yoda-ui/Form/TextYoda';

const StrategicImperativeList: FC = () => {
  const { providerFields } = useYodaCreateForm();
  const flags = useFlags();
  const {
    siTitleFieldConfig,
    strategicImperativeListColumnConfig,
    newElementButton,
    menuStrategicImperativeLabel,
    onPageChange,
    onPageSizeChange,
    strategicImperatives,
    loading,
    rowCountState,
    debouncedHandleChangeSiTitle,
  } = useStrategicImperativeList();

  return (
    <MayaLayout>
      <GridList
        getRowId={ (row) => row._id }
        loading={ loading }
        title={ menuStrategicImperativeLabel }
        columnConfig={ strategicImperativeListColumnConfig }
        rows={ strategicImperatives }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        newElementButton={
          flags.mayaBiogenlinc4493EnableStrategicImperativeManagement
            ? newElementButton
            : undefined
        }
        rowCountState={ rowCountState }
        disableColumnMenu={ true }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box sx={ CustomerInitiativeListFilters } >
                <TextYoda
                  onChange={ debouncedHandleChangeSiTitle }
                  { ...siTitleFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
      />
    </MayaLayout>
  );
};

export default StrategicImperativeList;
