import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useTacticFormConfig from './TacticForm.config';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CustomerEngagementPlanning, CustomerInitiative, Tactic } from 'generated/maya.types';
import { KEY_MESSAGES_STATUS, KEY_MESSAGE_MS, PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { KeyMessageType, TacticMode } from 'modules/maya/basics/enums/maya.enums';
import useKeyMessagesOptions from 'modules/maya/basics/options/keyMessages.options';
import { tacticToBE } from 'modules/maya/basics/transformers/Tactic.transformer';
import { CepStateType, TacticStateType } from 'modules/maya/basics/types/maya.types';
import { useCreateTacticMutation } from 'modules/maya/graphql/mutations/createTactic';
import { useUpdateTacticMutation } from 'modules/maya/graphql/mutations/updateTactic';
import useGetCepByIdLazyQuery from 'modules/maya/graphql/queries/getCepByIdLazy';
import useGetKeyMessagesQuery from 'modules/maya/graphql/queries/getKeyMessages';
import useGetNextTacticsQuery from 'modules/maya/graphql/queries/getNextTactics';
import cepState from 'modules/maya/states/cep.states';
import tacticState from 'modules/maya/states/tactic.states';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useTacticCreate = (isTemplate: boolean) => {
  const flags = useFlags();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const customerInitiative = cepStateValue.cep?.customerInitiative as CustomerInitiative;

  const productKeys = Object.keys(BiogenProduct);
  const productValues = Object.values(BiogenProduct);
  const productIndex = productValues.indexOf(cepStateValue.cep?.product as BiogenProduct);
  const product = productKeys[productIndex];

  const keyMessagesFilters = {
    Country: customerInitiative?.country,
    Product: cepStateValue.cep?.product === BiogenProduct.MS_Portfolio ? KEY_MESSAGE_MS : product,
    ActiveStatusFlag: KEY_MESSAGES_STATUS,
  };

  const skip = (!keyMessagesFilters.Country || !keyMessagesFilters.Product);
  const { data: keyMessages, loading: keyMessagesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.keyMessage },
    skip,
  );
  const { data: medicalStrategies, loading: medicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: internationalMedicalStrategies, loading: internationalMedicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Country: 'INT', Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: discussionTopics, loading: discussionTopicsLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.discussionTopic },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );

  const keyMessagesOptions = useKeyMessagesOptions(
    [...keyMessages, ...medicalStrategies, ...internationalMedicalStrategies, ...discussionTopics],
  );

  const { tacticFormConfig } = useTacticFormConfig(
    tacticStateValue,
    cepStateValue,
    selectedChannel,
    keyMessagesOptions,
  );
  const { t } = useTranslation();
  const {
    getValues,
    providerFields,
    resetForm,
    resetField,
    useWatchForm,
    useWatchField,
    setFieldShowError,
    setDefaultValue,
  } = useYodaCreateForm();
  const [createTactic] = useCreateTacticMutation();
  const [updateTactic] = useUpdateTacticMutation();
  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const sendToVeeva = !isTemplate;

  const { isValid } = useWatchForm();

  if (tacticState.tactic) {
    const fieldsName = Object.keys(tacticFormConfig);
    setFieldShowError(true, fieldsName);
  }

  const cancelTacticForm = () => {
    resetForm();
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  };

  const handleSaveTacticForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedTactic = tacticToBE(formValues, tacticStateValue.cepId);

    const tacticData = await createTactic(formattedTactic, tacticStateValue.cepId, sendToVeeva);

    const { data: { getCustomerEngagementPlanningById: customerEngagementPlanning } } = await queryGetCepById(
      { variables: { id: cepStateValue.cep?._id } },
    );
    if (cepState.cep) {
      cepState.cep.globalCepSyncStatus = (
        customerEngagementPlanning as CustomerEngagementPlanning
      ).globalCepSyncStatus;
    }

    setIsSubmitting(false);
    if (!tacticData.error) {
      succesToast(`Tactic ${formValues.title} created`);
      resetForm();
    } else {
      warningToast(tacticData.error);
    }

    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  };

  const handleUpdateTacticForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedTactic = tacticToBE(formValues, cepStateValue.cep?._id);

    if (tacticStateValue.tactic) {
      const tacticData = await updateTactic(
        tacticStateValue.tactic._id,
        formattedTactic,
        tacticStateValue.cepId,
        sendToVeeva,
      );

      const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepStateValue.cep?._id } });
      if (cepState.cep) {
        cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
      }

      setIsSubmitting(false);
      if (tacticData) {
        succesToast(`Tactic ${formValues.title} updated`);
      } else {
        warningToast(t('errors_standard'));
      }

      tacticState.tactic = null;
      tacticState.mode = TacticMode.list;
      cepState.tactics = [];
    }
  };

  const channelKey = useWatchField(tacticFormConfig.channel.name)?.value;
  useEffect(() => {
    if (channelKey) {
      const formValues = getValues();
      Object.keys(formValues).forEach((element: string) => {
        if (element !== tacticFormConfig.channel.name) {
          setDefaultValue(element, undefined);
          resetField(element);
        }
      });
      setSelectedChannel(channelKey);
    }
  }, [channelKey, getValues, resetField, setDefaultValue, tacticFormConfig.channel.name, tacticStateValue.tactic]);

  const cancelButtonConfig = {
    buttonProps: {
      onClick: cancelTacticForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: tacticStateValue.tactic?._id ? handleUpdateTacticForm : handleSaveTacticForm,
      buttonType: ButtonType.primary,
      disabled: !isValid || isSubmitting,
    },
    label: t('maya_tactic_form_submit_label'),
    loading: isSubmitting,
  };

  const [nextTactics, setNextTactics] = useState<Tactic[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const { data, loading, count } = useGetNextTacticsQuery(tacticStateValue.tactic?._id || null, queryOptions);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setNextTactics(data);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return {
    cancelButtonConfig,
    keyMessagesLoading: keyMessagesLoading || medicalStrategiesLoading || internationalMedicalStrategiesLoading || discussionTopicsLoading,
    keyMessagesOptions,
    loading,
    nextTactics,
    onPageChange,
    onPageSizeChange,
    providerFields,
    rowCountState,
    selectedChannel,
    submitButtonConfig,
    t,
    tacticFormConfig,
  };
};

export default useTacticCreate;
