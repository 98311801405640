import { proxy } from 'valtio';
import { Channel } from 'modules/maya/basics/enums/maya.enums';
import { TacticStateType } from 'modules/maya/basics/types/maya.types';

const tacticState = proxy<TacticStateType>({
  tactic: null,
  tacticType: Channel.approvedEmail,
  mode: null,
  cepId: '',
});

export default tacticState;
