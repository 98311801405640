import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { errorToast, succesToast } from 'basics/utils/toast';
import { FileDataInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import { participantFragment } from 'modules/events/graphql/fragments/commonData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UploadParticipantsResponse = {
  createParticipantsFromCSV: Partial<Participant[]> | [];
};

export type UseCreateParticipantsFromCSVMutation = [
  (files: File, filesDataInput: FileDataInput, eventId: string,) => Promise<{ participants?: Partial<Participant[]> | [] } | null>,
  MutationResult<UploadParticipantsResponse>,
];

export const createParticipantsFromCSVMutationCall = gql`
mutation createParticipantsFromCSV($file: Upload!, $fileDataInput: FileDataInput!, $eventId: String!) {
  createParticipantsFromCSV(file: $file, fileDataInput: $fileDataInput, eventId: $eventId) {
    ${participantFragment}
  }
}`;

export const useCreateParticipantsFromCSVMutation = (): UseCreateParticipantsFromCSVMutation => {
  const [createParticipantsFromCSVMutation, state] = useMutation<UploadParticipantsResponse>(createParticipantsFromCSVMutationCall);

  const executeMutation = useCallback(async (file, fileDataInput, eventId) => {
    try {
      const { data } = await createParticipantsFromCSVMutation({
        variables: { file, fileDataInput, eventId },
      });
      succesToast('Your file has been successfully uploaded');
      return {
        participants: data?.createParticipantsFromCSV || [],
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createParticipantsFromCSV.ts - useCreateParticipantsFromCSVMutation',
        });
        errorToast(customContext.description as string);
      }
      return null;
    }
  }, [createParticipantsFromCSVMutation]);

  return [executeMutation, state];
};
