import { FC } from 'react';
import CardHeader from 'components/CardHeader';
import Box from 'yoda-ui/Box';
import Paper from 'yoda-ui/Paper';
import { YodaBorderRadius, YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardFormProps = {
  title: string;
};

const CardForm: FC<CardFormProps> = ({ children, title }) => (
  <Paper margin={ 0 } borderRadius={ YodaBorderRadius.medium }>
    <Box>
      <CardHeader title={ title } />
      <Box
        style={ { borderTop: `1px solid ${YodaColors.gray1}` } }
        paddingX={ YodaSpacing.large }
        paddingY={ YodaSpacing.medium }
      >
        { children }
      </Box>
    </Box>
  </Paper>
);

export default CardForm;
