import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { boolean, mixed, string } from 'yup';
import { mayaCepTemplateCreateRoute, mayaCepTemplateEditRoute } from 'basics/constants/routes.constants';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { TestContextType } from 'basics/types/common.types';
import {
  getUTCDate,
  testDateRangeInValidationRange,
  testEndDayAfterStartDay,
  testEndDayNotBeforeStartDay,
  testStartDateIsSet,
} from 'basics/utils/dates/dates.utils';
import { CtaType, CustomerEngagementPlanning } from 'generated/maya.types';
import { BiogenFunction, Channel, EngagementMetric, ReachMetric } from 'modules/maya/basics/enums/maya.enums';
import { TacticStateType } from 'modules/maya/basics/types/maya.types';
import tacticState from 'modules/maya/states/tactic.states';
import { YodaFieldsState } from 'services/yodaForm/yodaForm.types';
import { DateRange } from 'yoda-ui/Form/DateRangePicker';

const maxTitleLength = 80;

const useTacticFormSchema = (cep: Partial<CustomerEngagementPlanning> | null | undefined) => {
  const { t } = useTranslation();
  const maxLengthTitleMessage = `${t('maya_tactic_step_configuration_title_error_max_char', { count: maxTitleLength })}`;
  const requiredLabel = t('form_errors_required');
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const location = useLocation();
  const currentURL = location.pathname;
  const templateRoute = [mayaCepTemplateCreateRoute, mayaCepTemplateEditRoute];
  const isKeyMessageMandatory = (currentChannel: Channel) => {
    return ([
      Channel.f2fMeeting,
      Channel.oneToOne,
      Channel.telephone,
      Channel.virtualMeeting,
    ].includes(currentChannel))
  && (!templateRoute.includes(currentURL));
  };

  const parentCepStartDate = cep?.dateStart ? new Date(cep?.dateStart) : null;
  const parentCepEndDate = cep?.dateEnd ? new Date(cep?.dateEnd) : null;

  return {
    channel: mixed<Channel | string>()
      .oneOf([...Object.values(Channel)])
      .required(),
    title: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    date: mixed()
      .test({
        name: 'startDate is set',
        message: requiredLabel,
        test: testStartDateIsSet,
      })
      .test({
        name: 'endDate must be after startDate',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayAfterStartDay,
      })
      .test({
        name: 'start date before end date',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayNotBeforeStartDay,
      })
      .test({
        name: 'date in CEP date range',
        message: 'tactic date range must be in Parent cep date range',
        test: (dateRange, context) => {
          const [tacticDateStart, tacticDateEnd] = dateRange;
          const tacticDateStartUTC = tacticDateStart
            ? getUTCDate(tacticDateStart, `${(tacticDateStart as Date).getHours()}:${(tacticDateStart as Date).getMinutes()}`, TimeZone.UTC)
            : null;
          const tacticDateEndUTC = tacticDateEnd
            ? getUTCDate(tacticDateEnd, `${(tacticDateEnd as Date).getHours()}:${(tacticDateEnd as Date).getMinutes()}`, TimeZone.UTC)
            : null;

          const dateRangeUTC = [
            tacticDateStartUTC,
            tacticDateEndUTC,
          ] as DateRange;

          if (!testDateRangeInValidationRange(dateRangeUTC, [parentCepStartDate, parentCepEndDate])) {
            return context.createError(
              {
                message: t(
                  'maya_tactic_date_range_validation',
                  { startDate: parentCepStartDate?.toLocaleDateString(), endDate: parentCepEndDate?.toLocaleDateString() },
                ),
              },
            );
          }

          return true;
        },
      }),
    productionOwner: mixed<BiogenFunction | string>()
      .oneOf([...Object.values(BiogenFunction)], requiredLabel),
    executingTeam: mixed<BiogenFunction | string>()
      .oneOf([...Object.values(BiogenFunction)], requiredLabel)
      .required(),
    callToAction: mixed<CtaType | string>()
      .oneOf([...Object.keys(CtaType)])
      .when(tacticStateValue.tacticType, {
        is: Channel.approvedEmail
        || Channel.broadcastEmail
        || Channel.oneToOne,
        then: mixed<CtaType | string>()
          .oneOf([...Object.keys(CtaType)], requiredLabel)
          .required(),
      }),
    reachMetric: mixed<ReachMetric | string>()
      .oneOf([...Object.values(ReachMetric), '']),
    engagementMetric: mixed<EngagementMetric | string>()
      .oneOf([...Object.values(EngagementMetric), '']),
    mandatory: boolean()
      .when(tacticStateValue.tacticType, {
        is: Channel.approvedEmail
        || Channel.broadcastEmail
        || Channel.oneToOne
        || Channel.virtualEvent
        || Channel.f2fEvent
        || Channel.hybridEvent
        || Channel.telephone
        || Channel.f2fMeeting
        || Channel.virtualMeeting,
        then: boolean()
          .required(requiredLabel),
      }),
    includeConsent: boolean(),
    keyMessage: string()
      .test({
        name: 'date in CEP date range',
        test: (value, context) => {
          const fieldsState: YodaFieldsState = (context.options as TestContextType)?.fieldsState;
          const channel = fieldsState.channel.value;

          if (isKeyMessageMandatory(channel) && !value) {
            return context.createError(
              {
                message: requiredLabel,
              },
            );
          }

          return true;
        },
      }),
    parentEvent: boolean()
      .when(tacticStateValue.tacticType, {
        is: Channel.virtualEvent
        || Channel.f2fEvent
        || Channel.hybridEvent,
        then: boolean()
          .required(requiredLabel),
      }),
    approvedDocumentId: string()
      .test({
        name: 'approved document id in tactic approved email',
        message: t('maya_tactics_document_id_error'),
        test: (value) => {
          return !value || value.match(/^[0-9]{6}$/) !== null;
        },
      })
      .notRequired(),
    eventId: string(),
    actionOrder: string()
      .matches(/^$|^([1-9][0-9]?)$/, t('maya_tactics_action_order_error'))
      .required(requiredLabel),
    destinationUrl: string()
      .optional()
      .url(t('maya_tactics_destination_url_error'))
      .test({
        name: 'validDestinationUrl',
        message: t('maya_tactics_destination_url_error'),
        test: (value) => {
          if (!value) {
            return true;
          }

          const destinationUrl = new URL(value);

          return destinationUrl.pathname !== '/';
        },
      })
      .optional(),
  };
};

export default useTacticFormSchema;
