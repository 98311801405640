import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { widgetItem } from 'modules/maya/basics/styles/widget.style';
import useCountCepWidget from 'modules/maya/components/CountWidgets/CountCepWidget/CountCepWidget.hook';
import { CountWidgetPropsType } from 'modules/maya/components/CountWidgets/CountWidget.types';
import Loader from 'yoda-ui/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

const CountCepWidget: FC<CountWidgetPropsType> = ({
  filters,
}) => {
  const { value, loading } = useCountCepWidget(filters);
  const { t } = useTranslation();

  return (
    <Box sx={ widgetItem } >
      <Box color={ YodaColors.primaryBlue } >{ t('maya_count_cep_widget_label') }</Box>
      <Box visibility={ loading ? 'hidden' : 'inherit' } >{ value !== undefined ? value as number : '-' }</Box>
      { loading ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : null }
    </Box>
  );
};

export default CountCepWidget;
