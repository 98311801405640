import { customerInitiativeFragment } from './customerInitiative.fragments';

export const cepFragment = `
  _id
  title
  formattedCepId
  description
  customerInitiative {
    ${customerInitiativeFragment}
  }
  multiyearPlan
  therapeuticArea
  product
  biogenFunction
  dateStart
  dateEnd
  kpi
  customerType
  specialty
  budgetPlanned
  aop
  digital
  totalEstimatedCost
  channels {
    name
    estimatedNumber
    estimatedCost
  }
  spendCategory
  status
  updated
  published
  targetFilter {
    countries
    types
    businessUnits
    specialties
    biosSegments
    smaSegments
    imsSegments
  }
  veevaSyncStatus
  lastVeevaSync
  lastVeevaSyncError
  globalCepSyncStatus {
    syncStatus,
    errors
  }
`;
