import { FC } from 'react';
import FieldCard from 'components/FieldCard';
import { HandleRemoveItem } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/Box';
import Icon, { Icons } from 'yoda-ui/Icons/Icon/Icon';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardRowProps = {
  item: { id: string; property1: string; property2: string; property3?: string };
  handleRemoveItem: HandleRemoveItem;
  disabled: boolean;
};

const CardRow: FC<CardRowProps> = ({ item, handleRemoveItem, disabled }) => (
  <Box
    marginBottom={ YodaSpacing.medium }
    data-testid="user-container"
  >
    <FieldCard>
      <Box>
        <Box display="flex" fontWeight={ YodaFontWeight.light } color={ YodaColors.gray5 }>
          <Box marginRight={ YodaSpacing.xSmall }>{ `${item.property1} ${item.property2}` }</Box>
          { item.property3 && <Box>{ item.property3 }</Box> }
        </Box>
      </Box>

      {
        disabled ? null : (<Box display="flex" justifyContent="center">
          <Icon
            data-testid="close-icon"
            name={ Icons.close }
            style={ { fill: YodaColors.gray3, cursor: 'pointer' } }
            onClick={ () => handleRemoveItem(item.id) }
          />
        </Box>)
      }
    </FieldCard>
  </Box>
);

export default CardRow;
