import { gql, MutationResult, useMutation } from '@apollo/client';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { cepFragment } from 'modules/maya/graphql/fragments/cep.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateCepMutationCall = gql`
mutation updateCustomerEngagementPlanning(
    $id: String!, 
    $updateCustomerEngagementPlanning: UpdateCustomerEngagementPlanningInput!, 
    $sendToVeeva: Boolean!
  ) {
  updateCustomerEngagementPlanning(
    id: $id, 
    updateCustomerEngagementPlanningInput: $updateCustomerEngagementPlanning, 
    sendToVeeva: $sendToVeeva
  ) {
    ${cepFragment}
  }
}
`;

type ResultType = {
  updateCustomerEngagementPlanning: CustomerEngagementPlanning;
};

type UseUpdateCepMutation = [
  (
    id: string | null,
    customerEngagementPlanning: Partial<CustomerEngagementPlanning>,
    sendToVeeva?: boolean
  ) => Promise<CustomerEngagementPlanning | undefined | null>,
  MutationResult<ResultType>,
];

export const useUpdateCepMutation = (): UseUpdateCepMutation => {
  const [updateCep, state] = useMutation<ResultType>(updateCepMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (id, customerEngagementPlanning, sendToVeeva = true) => {
    try {
      const { data } = await updateCep({
        variables: {
          id,
          updateCustomerEngagementPlanning: customerEngagementPlanning,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });
      return data?.updateCustomerEngagementPlanning;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, customerEngagementPlanning);
        Logger.logError({
          error,
          customContext,
          transactionName: 'updateCustomerEngagementPlanning.ts - useUpdateCustomerEngagementPlanningMutation',
        });
      }
      return null;
    }
  }, [flags.mayaEnableVeevaBiogenlinc1816, updateCep]);

  return [executeMutation, state];
};
