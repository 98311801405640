import { FC, useMemo } from 'react';
import { ParticipantsTabs } from '../ParticipantsList.enums';
import { ParticipantsItemProps } from './ParticipantItem.types';
import useParticipantsItem from './ParticipantsItem.hook';
import * as styles from './ParticipantsItem.styles';
import ParticipantsItemCollapse from './ParticipantsItemCollapse';
import Box from 'yoda-ui/Box';
import CollapseIcon from 'yoda-ui/Icons/CollapseIcon';
import Icon, { Icons } from 'yoda-ui/Icons/Icon';
import IconButton from 'yoda-ui/Icons/IconButton';
import ListItem from 'yoda-ui/List/ListItem';
import Tag from 'yoda-ui/Tags/Tag';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const ParticipantsItem: FC<ParticipantsItemProps> = (props) => {
  const { INVITED, REGISTERED } = ParticipantsTabs;
  const {
    email = '',
    firstName = '',
    lastName = '',
    registrationStatusHistory,
    variant = REGISTERED,
    ...restParticipant
  } = props;

  const { opened, tagConfig, toggleOpened } = useParticipantsItem(registrationStatusHistory);

  const icons = useMemo(
    () => ({
      [INVITED]: (
        <IconButton>
          <Icon name={ Icons.moreVertical }/>
        </IconButton>
      ),
      [REGISTERED]: (<CollapseIcon expand={ false } onClick={ toggleOpened }/>),
    }),
    [INVITED, REGISTERED, toggleOpened],
  );

  return (
    <ListItem sx={ styles.ListItem } disablePadding>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={ 1 }
        paddingBottom="6px"
        paddingTop="6px"
        paddingRight="12px"
        paddingLeft="24px"
        width="calc(100% - 36px)"
      >
        <Box fontWeight={ YodaFontWeight.medium } minWidth="150px">
          { `${lastName} ${firstName}` }
        </Box>
        <Box
          fontWeight={ YodaFontWeight.regular }
          flexGrow="1"
          marginRight={ YodaSpacing.medium }
          marginLeft={ YodaSpacing.medium }
        >
          { email }
        </Box>
        {
          tagConfig && (
            <Tag
              bgcolor={ tagConfig.bgColor }
              color={ tagConfig.color }
              fontWeight={ YodaFontWeight.medium }
              paddingX={ YodaSpacing.xxSmall }
              paddingY={ YodaSpacing.xxxxSmall }
            >
              { tagConfig.label }
            </Tag>
          )
        }
        <Box marginLeft={ YodaSpacing.medium }>
          { icons[variant] }
        </Box>
      </Box>
      {
        variant === REGISTERED && (
          <ParticipantsItemCollapse
            email={ email }
            firstName={ firstName }
            lastName={ lastName }
            opened={ opened }
            registrationStatusHistory={ registrationStatusHistory }
            { ...restParticipant }
          />
        )
      }
    </ListItem>
  );
};

export default ParticipantsItem;
