import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { CustomerEngagementPlanning, CustomerInitiative } from 'generated/maya.types';
import { Channel } from 'modules/maya/basics/enums/maya.enums';
import { isCustomerInitiative } from 'modules/maya/basics/types/customerInitiative.types';
import { configurationStepFields } from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.constants';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';
import { DateRange } from 'yoda-ui/Form/DateRangePicker/DateRangePicker.types';

const transformInputChannels = (data: YodaFieldValue) => {
  if (!data[configurationStepFields.channels]) {
    return [];
  }

  return data[configurationStepFields.channels].map((channel: string, index: number) => {
    return {
      name: channel,
      estimatedNumber: parseInt(data[`estimatedNumber_${index}`], 10) || null,
      estimatedCost: parseInt(data[`estimatedCost_${index}`], 10) || null,
    };
  });
};

const calculateTotalEstimatedCost = (data: YodaFieldValue) => {
  if (data.channels) {
    return data.channels.reduce((previousValue: number, _currentValue: Channel, currentIndex: number) => {
      return previousValue + parseInt(data[`estimatedCost_${currentIndex}`], 10) || 0;
    }, 0);
  }

  return 0;
};

export const cepToBE = (data: YodaFieldValue) => {
  return {
    title: data[configurationStepFields.title],
    description: data[configurationStepFields.description],
    customerInitiative: data[configurationStepFields.customerInitiative],
    multiyearPlan: data[configurationStepFields.multiyearPlan] === '1',
    therapeuticArea: data[configurationStepFields.therapeuticArea],
    product: data[configurationStepFields.product],
    biogenFunction: data[configurationStepFields.biogenFunction],
    keyMessages: data[configurationStepFields.keyMessages],
    ...transformInputDateUTC(data[configurationStepFields.date]),
    kpi: data[configurationStepFields.kpi],
    customerType: data[configurationStepFields.customerType],
    specialty: data[configurationStepFields.specialty],
    budgetPlanned: parseInt(data[configurationStepFields.budgetPlanned], 10),
    aop: data[configurationStepFields.aop] === '1',
    digital: data[configurationStepFields.digital] === '1',
    spendCategory: data[configurationStepFields.spendCategory],
    channels: transformInputChannels(data),
    totalEstimatedCost: calculateTotalEstimatedCost(data),
  };
};

export const cepToForm = (
  cep: Partial<CustomerEngagementPlanning> | undefined | null,
  customerInitiative?: CustomerInitiative | undefined,
) => {
  let customerInitiativeDefaultValue = isCustomerInitiative(cep?.customerInitiative) ? cep?.customerInitiative._id : '';
  if (!customerInitiativeDefaultValue) {
    customerInitiativeDefaultValue = customerInitiative?._id || '';
  }

  const therapeuticAreaDefaultValue = cep?.therapeuticArea || customerInitiative?.therapeuticArea || '';
  const productDefaultValue = cep?.product || customerInitiative?.product[0] || '';
  const biogenFunctionDefaultValue = cep?.biogenFunction || customerInitiative?.functionalOwner || '';

  const dateStart = new Date(cep?.dateStart);
  const dateStartUTC = new Date(
    dateStart.getUTCFullYear(),
    dateStart.getUTCMonth(),
    dateStart.getUTCDate(),
    dateStart.getUTCHours(),
    dateStart.getUTCMinutes(),
    dateStart.getUTCSeconds(),
  );

  const dateEnd = new Date(cep?.dateEnd);
  const dateEndUTC = new Date(
    dateEnd.getUTCFullYear(),
    dateEnd.getUTCMonth(),
    dateEnd.getUTCDate(),
    dateEnd.getUTCHours(),
    dateEnd.getUTCMinutes(),
    dateEnd.getUTCSeconds(),
  );

  return {
    title: cep?.title || '',
    description: cep?.description || '',
    customerInitiative: customerInitiativeDefaultValue,
    multiyearPlan: cep?.multiyearPlan !== undefined ? (cep.multiyearPlan ? '1' : '0') : null,
    therapeuticArea: therapeuticAreaDefaultValue,
    product: productDefaultValue,
    biogenFunction: biogenFunctionDefaultValue,
    keyMessages: cep?.keyMessages || '',
    date: (cep?.dateStart && cep?.dateEnd) ? [dateStartUTC, dateEndUTC] as DateRange : undefined,
    kpi: cep?.kpi || '',
    customerType: cep?.customerType || [],
    specialty: cep?.specialty || [],
    budgetPlanned: cep?.budgetPlanned?.toString() || '',
    aop: cep?.aop !== undefined ? (cep.aop ? '1' : '0') : null,
    digital: cep?.digital !== undefined ? (cep.digital ? '1' : '0') : null,
    spendCategory: cep?.spendCategory || [],
    channels: cep?.channels?.map((channel) => channel.name) || [],
    totalEstimatedCost: cep?.totalEstimatedCost,
    estimatedCosts: cep?.channels?.map((channel) => channel.estimatedCost?.toString() || '') || [],
    estimatedNumbers: cep?.channels?.map((channel) => channel.estimatedNumber?.toString() || '') || [],
  };
};

