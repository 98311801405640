import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { OktaUser } from 'basics/types/user.types';

const useCoorganizers = () => {
  const { t } = useTranslation();

  const transformToState = (coorganizer: OktaUser) => pick(coorganizer, ['id', 'name', 'lastName', 'email']);

  const transformCoorganizerToCard = (coorganizer: OktaUser) => ({
    id: coorganizer.id,
    property1: coorganizer.name,
    property2: coorganizer.lastName,
    property3: coorganizer.email,
  });

  return {
    transformCoorganizerToCard,
    transformToState,
    t,
  };
};

export default useCoorganizers;
