export enum PresenceFormat {
  hybrid_congress = 'hybrid_congress',
  online_congress = 'online_congress',
  online_meeting = 'online_meeting',
  physical_congress = 'physical_congress',
}

export enum EventPromotionalityEnum {
  gated = 'gated',
  public = 'public',
}

export enum EventsSaveRequestStatus {
  error = 'Error',
  last = 'Last',
  loading = 'Loading',
  success = 'Success',
}

export enum ParticipantStatus {
  accepted = 'accepted',
  invited = 'invited',
  requested = 'requested',
  rejected = 'rejected',
}
