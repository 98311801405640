import { FC } from 'react';
import QuickForm from 'components/QuickForm';
import { transformMediaToCard } from 'components/QuickForm/CardsBucket/Cards.utils';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import { FormDropZoneConfig, HandleFiles, ListConfig } from 'components/QuickForm/QuickForm.types';
import { Asset } from 'modules/events/basics/types/eventData.types';
import { YodaFieldValidation } from 'services/yodaForm/yodaForm.types';

export type CardImageProps = {
  name: string;
  cardImageFormConfig: FormDropZoneConfig;
  listConfig: ListConfig;
  validation?: YodaFieldValidation;
  defaultValue?: Asset[];
  handleFiles: HandleFiles;
};

const CardImage: FC<CardImageProps> = ({
  name,
  cardImageFormConfig,
  listConfig,
  validation,
  handleFiles,
  defaultValue = [],
}) => (
  <QuickForm
    name={ name }
    form={ { shape: FormShape.formAsset, formConfig: { ...cardImageFormConfig, handleFiles } } }
    list={ { shape: ListShape.listAsset, listConfig } }
    transformToCard={ transformMediaToCard }
    defaultValue={ defaultValue }
    validation={ validation }
  />
);

export default CardImage;
