import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import { StrategicImperative } from 'generated/maya.types';
import {
  SiFormCanceltCallbackType,
  SiFormSubmitCallbackType,
} from 'modules/maya/components/StrategicImperativeForm/StrategicImperativeForm.types';
import { useCreateStrategicImperativeMutation } from 'modules/maya/graphql/mutations/createStrategicImperatives';
import useGetStrategicImperativeByIdQuery from 'modules/maya/graphql/queries/getStrategicImperativeById';

const useStrategicImperativeDuplicate = () => {
  const navigate = useNavigate();

  const [createStrategicImperative] = useCreateStrategicImperativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [loading, setLoading] = useState(true);

  const strategicImperative = useGetStrategicImperativeByIdQuery(urlParams.get('sourceId')).data;
  const duplicatedStrategicImperative = {
    ...strategicImperative,
    title: `Copy - ${strategicImperative?.title}`,
  } as StrategicImperative;

  useEffect(() => {
    if (strategicImperative && loading) {
      setLoading(false);
    }
  }, [loading, setLoading, strategicImperative]);

  const handleCancelStrategicImperativeForm: SiFormCanceltCallbackType = () => {
    navigate(mayaStrategicImperativeListRoute);
  };

  const handleSaveStrategicImperativeForm: SiFormSubmitCallbackType = async (si) => {
    const strategicImperativeData = await createStrategicImperative(si);

    if (strategicImperativeData) {
      succesToast(`Strategic Imperative ${si.title} created`);
      navigate(mayaStrategicImperativeListRoute);
    } else {
      navigate(mayaStrategicImperativeListRoute);
      warningToast(`Cannot duplicate to strategic imperative ${si.title}`);
    }
  };

  return {
    loading,
    strategicImperative: duplicatedStrategicImperative,
    handleCancelStrategicImperativeForm,
    handleSaveStrategicImperativeForm,
  };
};

export default useStrategicImperativeDuplicate;
