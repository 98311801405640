import { FC } from 'react';
import useFieldNumberClick from './FieldNumberClicker.hook';
import { fieldNumberClickerStyle } from './FieldNumberClicker.styles';
import { FieldNumberClickerTypes } from './FieldNumberClicker.types';
import { ButtonSize } from 'yoda-ui/BiogenLincDS/Buttons/Buttons.types';
import IconButton from 'yoda-ui/BiogenLincDS/Buttons/IconButton';
import Box from 'yoda-ui/Box';
import TextYoda from 'yoda-ui/Form/TextYoda/TextYoda';
import { Icons } from 'yoda-ui/Icons/Icon';
import { fromUnitsToRem, YodaSpacing } from 'yoda-ui/yodaTheme';

const FieldNumberClicker: FC<FieldNumberClickerTypes> = ({
  defaultValue = '0',
  name,
  placeholder,
  disabled,
  required,
  onChange,
  validation,
}) => {
  const { fieldValue, changeValue } = useFieldNumberClick(name, defaultValue, onChange);

  return (
    <Box position='relative' sx={ fieldNumberClickerStyle }>
      <Box className='FieldNumberClicker-btn' left={ fromUnitsToRem(YodaSpacing.xSmall) }>
        <IconButton icon={ Icons.remove } size={ ButtonSize.xSmall } onClick={ () => changeValue(fieldValue - 1) } />
      </Box>
      <Box className='FieldNumberClicker-btn' right={ fromUnitsToRem(YodaSpacing.xSmall) }>
        <IconButton icon={ Icons.add } size={ ButtonSize.xSmall } onClick={ () => changeValue(fieldValue + 1) } />
      </Box>
      <TextYoda
        label=""
        defaultValue={ `${defaultValue}` }
        disabled={ disabled }
        placeholder={ placeholder }
        name={ name }
        required={ required }
        onChange={ (event) => changeValue(parseInt(event.target.value, 10)) }
        type='number'
        hiddeControls={ true }
        validation={ validation }
      />
    </Box>
  );
};

export default FieldNumberClicker;
