import { TableFooter } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCepFormEstimationCostGroupGroup from './CepFormEstimationCostGroup.hook';
import {
  CepFormEstimationCostGroupBlueCellStyle,
  CepFormEstimationCostGroupStyle,
} from './CepFormEstimationCostGroup.style';
import { FormattedChannelType } from './CepFormEstimationCostGroup.types';
import FieldNumberClicker from 'components/Field/FieldNumberClicker';
import TextYoda from 'yoda-ui/Form/TextYoda';
import Table from 'yoda-ui/Table';
import TableBody from 'yoda-ui/Table/TableBody';
import TableCell from 'yoda-ui/Table/TableCell';
import TableContainer from 'yoda-ui/Table/TableContainer';
import TableHead from 'yoda-ui/Table/TableHead';
import TableRow from 'yoda-ui/Table/TableRow';

const CepFormEstimationCostGroup: FC = () => {
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0);
  const [standardCosts, setStandardCosts] = useState<{ [key: string]: number }>({});
  const { t } = useTranslation();
  const {
    formatChannels,
    calculateTotalStandardCost,
    updateOneStandardCost,
    calculateStandardCostFromForm,
    calculatedEstimatedCost,
  } = useCepFormEstimationCostGroupGroup();
  const formattedChannels = formatChannels();

  return (
    <TableContainer sx={ CepFormEstimationCostGroupStyle }>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{ t('maya_cep_costStep_channel') }</TableCell>
            <TableCell>{ t('maya_cep_costStep_details') }</TableCell>
            <TableCell>{ t('maya_cep_costStep_nbItems') }</TableCell>
            <TableCell>{ t('maya_cep_costStep_standardCost') }</TableCell>
            <TableCell>{ t('maya_cep_costStep_estimatedCost') }</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            formattedChannels.map((channel: FormattedChannelType) => {
              return (
                <TableRow key={ `key_${channel.name}` }>
                  <TableCell>
                    { channel.name }
                  </TableCell>
                  <TableCell>
                    { channel.detail }
                  </TableCell>
                  <TableCell>
                    <FieldNumberClicker
                      { ...channel.formConfig.number }
                      onChange={ (value: number) => updateOneStandardCost(channel, value, standardCosts, setStandardCosts) }
                    />
                  </TableCell>
                  <TableCell>
                    {
                      standardCosts[
                        channel.formConfig.number.name
                      ] || calculateStandardCostFromForm(channel)
                    } { t('common_devise_symbol') }
                  </TableCell>
                  <TableCell>
                    <TextYoda onChange={ () => setTotalEstimatedCost(calculatedEstimatedCost()) } { ...channel.formConfig.estimatedCost } />
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={ 2 } />
            <TableCell sx={ CepFormEstimationCostGroupBlueCellStyle }>
              { t('maya_cep_costStep_totalEstimated') }
            </TableCell>
            <TableCell sx={ CepFormEstimationCostGroupBlueCellStyle }>
              { calculateTotalStandardCost(standardCosts, formattedChannels) || '---' } { t('common_devise_symbol') }
            </TableCell>
            <TableCell sx={ CepFormEstimationCostGroupBlueCellStyle }>
              { totalEstimatedCost || calculatedEstimatedCost() || '---' } { t('common_devise_symbol') }
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CepFormEstimationCostGroup;
