import { FC } from 'react';
import { OktaUser } from 'basics/types/user.types';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import { ListConfig } from 'components/QuickForm/QuickForm.types';
import useCoorganizers from 'modules/events/components/Coorganizers/Coorganizers.hook';
import { YodaFieldValidation } from 'services/yodaForm/yodaForm.types';

type CoorganizersFormProps = {
  name: string;
  coorganizersFormConfig: {
    lazyQueryHook: Function;
    label: string;
    placeholder: string;
  };
  listConfig: ListConfig;
  defaultValue: OktaUser[];
  validation: YodaFieldValidation;
  disabled: boolean;
};

const Coorganizers: FC<CoorganizersFormProps> = ({
  name,
  coorganizersFormConfig: formConfig,
  listConfig,
  validation,
  defaultValue,
  disabled,
}) => {
  const { transformCoorganizerToCard, transformToState } = useCoorganizers();

  return (
    <>
      <QuickForm
        name={ name }
        form={ { shape: FormShape.formAsyncSelect, formConfig } }
        list={ { shape: ListShape.listRow, listConfig } }
        defaultValue={ defaultValue }
        transformToCard={ transformCoorganizerToCard }
        transformToState={ transformToState }
        validation={ validation }
        disabled={ disabled }
      />
    </>
  );
};

export default Coorganizers;
