import { gql, MutationResult, useMutation } from '@apollo/client';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { FileDataInput, Target } from 'generated/maya.types';
import { targetListFragment } from 'modules/maya/graphql/fragments/targetList.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UploadTargetListResponse = {
  createTargetListFromCSV: Target[];
};

export type UseCreateTargetListFromCSVMutation = [
  (
    files: File,
    filesDataInput: FileDataInput,
    cepId: string,
    formattedCepId: string,
  ) => Promise<{ targetList: Target[] } | null>,
  MutationResult<UploadTargetListResponse>,
];

export const createTargetListFromCSVMutationCall = gql`
mutation createTargetListFromCSV(
  $file: Upload!, 
  $fileDataInput: FileDataInput!, 
  $cepId: String!, 
  $formattedCepId: String!, 
  $sendToVeeva: Boolean!
  ) {
  createTargetListFromCSV(
    file: $file, 
    fileDataInput: $fileDataInput, 
    cepId: $cepId, 
    formattedCepId: $formattedCepId, 
    sendToVeeva: $sendToVeeva) {
    ${targetListFragment}
  }
}`;

export const useCreateTargetListFromCSVMutation = (): UseCreateTargetListFromCSVMutation => {
  const [createTargetListFromCSVMutation, state] = useMutation<UploadTargetListResponse>(createTargetListFromCSVMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (file, fileDataInput, cepId, formattedCepId) => {
    try {
      const { data } = await createTargetListFromCSVMutation({
        variables: {
          file,
          fileDataInput,
          cepId,
          formattedCepId,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816,
        },
      });
      return {
        targetList: data?.createTargetListFromCSV || [],
      };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createTargetListFromCSV.ts - useCreateTargetListFromCSVMutation',
        });
      }
      return null;
    }
  }, [createTargetListFromCSVMutation, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
