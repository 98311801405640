import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { AgendaEntry } from '../../../../generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { onlineEventDataFragment } from 'modules/events/graphql/fragments/onlineEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_LAZY_FULL_PRIVATE_EVENT_BY_ID = gql`
  query getFullPrivateEventById($eventId: ObjectId!) {
    getFullPrivateEventById(eventId: $eventId) {
      ${eventFragment}
      data {
        ...on OnlineEvent {
          ${onlineEventDataFragment}
        }
      }
    }
  }
`;

type UseLazyGetFullPrivateEventById = () => {
  queryGetFullPrivateEventById: Function;
  loading: boolean;
  data?: Event;
  error?: ApolloError;
  called: boolean;
};
const transformEvent = (event: Event | undefined): Event | undefined => {
  if (event === undefined
      || !event?.data
      || !('agenda' in event.data)) {
    return event;
  }

  const agenda = !Array.isArray(event?.data?.agenda) ? undefined : event.data.agenda.map((agendaItem) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const castAgenda = agendaItem as unknown as AgendaEntry;
    const session = castAgenda?.session || {};

    const id = `${session?.startDate || '-'}${session?.endDate || '-'}${castAgenda.title}${session?.timeZone || '-'}`;

    return {
      ...agendaItem,
      id,
    };
  });
  return {
    ...event,
    data: {
      ...event.data,
      agenda,
    },
  };
};

const useLazyGetFullPrivateEventById: UseLazyGetFullPrivateEventById = () => {
  const [queryGetFullPrivateEventById, { loading, data, error, called }] = useLazyQuery(GET_LAZY_FULL_PRIVATE_EVENT_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getLazyFullPrivateEventById.ts - useLazyGetFullPrivateEventById',
    });
  }

  return {
    queryGetFullPrivateEventById,
    loading,
    data: transformEvent(data?.getFullPrivateEventById),
    error,
    called,
  };
};

export default useLazyGetFullPrivateEventById;
