import { isEmpty } from 'lodash';
import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { DashboardFilter } from 'generated/maya.types';
import { CepStatus, CiStatus, TacticStatus } from 'modules/maya/basics/enums/maya.enums';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

export const formatDashboardFilter = (data: YodaFieldValue): DashboardFilter => {
  return {
    countries: !isEmpty(data.countries) ? data.countries : null,
    status: [CiStatus.ACTIVE, CiStatus.DRAFT],
    therapeuticArea: !isEmpty(data.therapeuticArea) ? data.therapeuticArea : null,
    product: !isEmpty(data.product) ? data.product : null,
    cepFunction: !isEmpty(data.cepFunction) ? data.cepFunction : null,
    tacticStatus: [TacticStatus.DRAFT, TacticStatus.APPROVED],
    cepStatus: !isEmpty(data.cepStatus) ? data.cepStatus : [CepStatus.DRAFT, CepStatus.FEEDBACK, CepStatus.APPROVED],
    ...transformInputDateUTC(data.date),
  };
};
