import { FC } from 'react';
import Box from 'yoda-ui/Box';

const FormLayoutMidPanelContent: FC = ({ children }) => {
  return (
    <Box
      width="75%"
      display="flex"
      flexDirection="column"
    >
      { children }
    </Box>
  );
};

export default FormLayoutMidPanelContent;
