export const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
} as React.CSSProperties;

export const toolbarStyle = {
  border: '1px solid rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
} as React.CSSProperties;

export const editorStyle = {
  flex: 1,
  height: 'initial',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  padding: '10px',
} as React.CSSProperties;

export const fieldStyle = {
  label: { display: 'none' },
  div: { display: 'none' },
};

export const labelStyle = {
  marginTop: '0',
  marginBottom: '10px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '0.9rem',
  fontWeight: 300,
  lineHeight: '1.2rem',
};
