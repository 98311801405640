import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseRemoveParticipantMutationResponse = {
  removeParticipant: boolean;
};

export type UseRemoveParticipantMutation = [
  (eventId: string, mdmId: string,) => Promise<boolean>,
  MutationResult<UseRemoveParticipantMutationResponse>,
];

export const removeParticipantMutationCall = gql`
mutation removeParticipant($mdmId: String!, $eventId: String!) {
  removeParticipant(mdmId: $mdmId, eventId: $eventId)
}`;

export const useRemoveParticipantMutation = (): UseRemoveParticipantMutation => {
  const { t } = useTranslation();
  const [deleteInviteeMutation, state] = useMutation<UseRemoveParticipantMutationResponse>(removeParticipantMutationCall);

  const executeMutation = useCallback(async (mdmId, eventId) => {
    try {
      const { data } = await deleteInviteeMutation({
        variables: { mdmId, eventId },
      });
      if (data?.removeParticipant) {
        succesToast('Invitee has been removed');
      } else {
        errorToast(t('errors_standard'));
      }
      return !!data?.removeParticipant;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'deleteInvitee.ts - useRemoveParticipantMutation',
        });
        errorToast(t('errors_standard'));
      }
      return false;
    }
  }, [deleteInviteeMutation, t]);

  return [executeMutation, state];
};
