import { FC } from 'react';
import { formLayoutPaddingTop, formLayoutStickyStyles } from './FormLayout.styles';
import Box from 'yoda-ui/Box';

const FormLayoutRightPanel: FC = ({ children }) => {
  return (
    <Box
      minWidth="15%"
      paddingTop={ formLayoutPaddingTop }
      display="flex"
      sx={ formLayoutStickyStyles }
    >
      { children }
    </Box>
  );
};

export default FormLayoutRightPanel;
