import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export type UseSendOnlineEventInvitationMutationResponse = {
  sendOnlineEventInvitation: boolean;
};

export type UseSendOnlineEventInvitationMutation = [
  (onlineEventId: string,) => Promise<boolean>,
  MutationResult<UseSendOnlineEventInvitationMutationResponse>,
];

export const sendOnlineEventInvitationMutationCall = gql`
mutation sendOnlineEventInvitation($onlineEventId: String!) {
  sendOnlineEventInvitation(onlineEventId: $onlineEventId)
}`;

export const useSendOnlineEventInvitationtMutation = (): UseSendOnlineEventInvitationMutation => {
  const { t } = useTranslation();
  const [
    sendOnlineEventInvitationMutation,
    state,
  ] = useMutation<UseSendOnlineEventInvitationMutationResponse>(sendOnlineEventInvitationMutationCall);

  const executeMutation = useCallback(async (onlineEventId) => {
    try {
      const { data } = await sendOnlineEventInvitationMutation({
        variables: { onlineEventId },
      });
      if (data?.sendOnlineEventInvitation) {
        succesToast(t('event_invitations_sent_label'));
      } else {
        errorToast(t('event_invitations_already_sent_label'));
      }
      return !!data?.sendOnlineEventInvitation;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'sendInvitation.ts - useSendOnlineEventInvitationtMutation',
        });
        errorToast(t('errors_standard'));
      }
      return false;
    }
  }, [sendOnlineEventInvitationMutation, t]);

  return [executeMutation, state];
};
