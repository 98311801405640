export enum CommonSteps {
  communicationStep = 'communicationStep',
  detailsStep = 'detailsStep',
  emailConfigurationStep = 'emailConfigurationStep',
  sessionStep = 'sessionStep',
}

export enum CommonGroups {
  agenda = 'agenda',
  biogenLincConfig = 'biogenLincConfig',
  coorganizers = 'coorganizers',
  optionalInformations = 'optionalInformations',
  invitees = 'invitees',
  primaryInformations = 'primaryInformations',
  speakers = 'speakers',
  timeSlot = 'timeSlot',
  registerParticipant = 'registerParticipant',
  emailNotes = 'emailNotes',
}
