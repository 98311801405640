import { FC } from 'react';
import { ExportFormPropsType } from 'modules/maya/components/ExportForm/ExportForm.types';
import FormContainer from 'modules/maya/components/FormContainer/FormContainer';
import Box from 'yoda-ui/Box';
import MultiSelectYoda from 'yoda-ui/Form/SelectYoda/MultiSelectYoda';

const ExportForm: FC<ExportFormPropsType> = ({
  exportFormConfig,
  providerFields,
  handleChangeCountrySelected,
}) => {
  return (
    <FormContainer providerFields={ providerFields } >
      <Box>
        <MultiSelectYoda { ...exportFormConfig.countries } onChange={ handleChangeCountrySelected } />
      </Box>
    </FormContainer>
  );
};

export default ExportForm;
