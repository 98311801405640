/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, gql, LazyQueryResult, QueryLazyOptions, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { downloadBase64AsPdf } from 'basics/utils/files/files.utils';
import { errorToast } from 'basics/utils/toast';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_SCREENSHOTS = gql`
  query getScreenshots($eventId: ObjectId!) {
    getScreenshots(eventId: $eventId)
  }
`;

type GetScreenshotsResponse = {
  getScreenshots: string | null;
};

export type QueryGetScreenshots = (
  options?: QueryLazyOptions<{ eventId: string | undefined }> | undefined
) => Promise<LazyQueryResult<any, {
  eventId: string | undefined;
}>>;

type UseLazyGetScreenshots = (args: { eventId?: string; title?: string }) => {
  queryGetScreenshots: QueryGetScreenshots;
  loading: boolean;
  data?: GetScreenshotsResponse;
  error?: ApolloError;
  called: boolean;
};

const useLazyGetScreenshots: UseLazyGetScreenshots = ({ eventId, title }) => {
  const { t } = useTranslation();
  const [queryGetScreenshots, { loading, data, error, called }] = useLazyQuery(GET_SCREENSHOTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId,
    },
    onCompleted: (response) => downloadBase64AsPdf(response.getScreenshots, title),
    onError: (err) => {
      errorToast(t('errors_standard'));
      const customContext = extractApolloErrorDetails(err);
      Logger.logError({
        error: err,
        customContext,
        transactionName: 'getScreenshotsLazy.ts - useLazyGetScreenshots',
      });
    },
  });

  // if (error) {
  //   errorToast(t('errors_standard'));
  //   const customContext = extractApolloErrorDetails(error);
  //   Logger.logError({
  //     error,
  //     customContext,
  //     transactionName: 'getScreenshotsLazy.ts - useLazyGetScreenshots',
  //   });
  // }

  return {
    queryGetScreenshots,
    loading,
    data,
    error,
    called,
  };
};

export default useLazyGetScreenshots;
