import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const GET_POSSIBLE_ORGANIZERS = gql`
    query getPossibleOrganizers($name: String!) {
        getPossibleOrganizers(name: $name) {
            id
            name
            lastName
            email
        }
    }
`;

type ApolloCoorganizer = {
  id: string;
  name: string;
  lastName: string;
  email: string;
};

export type PossibleCoorganizer = {
  label: string;
} & ApolloCoorganizer;

type ApolloCoorganizersResponse = {
  getPossibleOrganizers: ApolloCoorganizer[];
};

type UseLazyGetPossibleCoorganizers = () => {
  lazyQuery: Function;
  loading: boolean;
  data: PossibleCoorganizer[] | null;
};

const cleanApolloData = (data: ApolloCoorganizersResponse): null | PossibleCoorganizer[] => {
  if (data && data.getPossibleOrganizers) {
    return data.getPossibleOrganizers.map((apolloCoorganizer) => {
      const { id, name, lastName, email } = apolloCoorganizer;
      const label = `${name} ${lastName} (${email})`;
      return { id, name, lastName, email, label };
    });
  }
  return null;
};

export const useLazyGetPossibleCoorganizers: UseLazyGetPossibleCoorganizers = () => {
  const [queryPossibleOrganizers, { loading, data, error }] = useLazyQuery(GET_POSSIBLE_ORGANIZERS, {
    fetchPolicy: 'cache-and-network',
  });

  const lazyQuery = useCallback(async (query: string) => {
    await queryPossibleOrganizers(
      {
        variables: { name: query },
      },
    );
  }, [queryPossibleOrganizers]);

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getPossibleOrganizer.ts - useLazyGetPossibleCoorganizers',
    });
  }

  return {
    lazyQuery,
    loading,
    data: cleanApolloData(data),
  };
};
