import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useInviteesGroupConfig from './InviteesGroup.config';
import { InviteesGroupEnums } from './InviteesGroup.enums';
import { upsertParticipants } from './InviteesGroup.utils';
import { HandleFilesExternal, HandleRemoveItemAsync } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { Participant } from 'modules/events/basics/types/events.types';
import { useCreateParticipantsFromCSVMutation } from 'modules/events/graphql/mutations/createParticipantsFromCSV';
import { useRemoveParticipantMutation } from 'modules/events/graphql/mutations/removeParticipant';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'services/yodaForm';

const useInviteesGroup = () => {
  const { UPLOAD_LIST } = InviteesGroupEnums;
  const { t } = useTranslation();
  const { useWatchField } = useYodaForm();
  const [runCreateMutation] = useCreateParticipantsFromCSVMutation();
  const [runDeleteMutation] = useRemoveParticipantMutation();
  const { cardSwitchConfig, uploadInviteesConfig } = useInviteesGroupConfig();
  const { createEvent } = useSnapshot(eventState);

  const showSendInvitationsFieldState = useWatchField(commonFields.showSendInvitations);
  const [selectedTab, setSelectedTab] = useState<InviteesGroupEnums>(UPLOAD_LIST);

  const handleFilesExternal: HandleFilesExternal = async (files, setErrorMessage) => {
    const errorMessage = t('errors_standard');
    if (!createEvent?._id) {
      setErrorMessage(errorMessage);
      return;
    }
    const { size, name } = files[0];
    const response = await runCreateMutation(
      files[0],
      {
        size,
        name,
        assetType: AssetType.document,
        assetTarget: AssetTarget.participantCsv,
      },
      createEvent._id,
    );
    if (response?.participants?.length && eventState.createEvent.data) {
      const { participants: prevParticipants } = eventState.createEvent.data;
      eventState.createEvent.data.participants = upsertParticipants(prevParticipants || [], response.participants as Participant[]);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleRemoveItemAsync: HandleRemoveItemAsync = async (id: string) => {
    if (createEvent?._id) {
      const isRemoved = await runDeleteMutation(id, createEvent._id);
      if (isRemoved && eventState.createEvent.data) {
        const { participants } = eventState.createEvent.data;
        eventState.createEvent.data.participants = participants?.filter((participant) => participant.id !== id) || [];
      }
    }
  };

  const tabsConfig = [
    {
      label: t('inviteesGroup_tabsConfig_uploadList_label'),
      value: UPLOAD_LIST,
    },
  ];

  return {
    cardSwitchConfig,
    uploadInviteesConfig: {
      ...uploadInviteesConfig,
      handleFilesExternal,
      handleRemoveItemAsync,
    },
    openedPanel: showSendInvitationsFieldState?.value || false,
    selectedTab,
    setSelectedTab,
    t,
    tabsConfig,
  };
};

export default useInviteesGroup;
