import { array, object, string, date } from 'yup';

const useInviteesGroupSchema = () => ({
  participantsWishList: array()
    .nullable(true)
    .of(object().shape({
      id: string().required(),
      firstName: string().required(),
      lastName: string().required(),
      email: string(),
      registrationStatusHistory: object().shape({
        accepted: date().nullable(true),
        invited: date().nullable(true),
        rejected: date().nullable(true),
        requested: date().nullable(true),
        waitingList: date().nullable(true),
      }),
    })),
});

export default useInviteesGroupSchema;
