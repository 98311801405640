import { MultiSelectIdsType, SelectOption } from './options.types';

export const findOptionById = (options: SelectOption[], id: string | number) => {
  if (!id) return null;
  return options.find((option) => option.id === id) || null;
};

export const findMultipleOptionsByIds = (
  options: SelectOption[],
  idsList?: MultiSelectIdsType,
): SelectOption[] => {
  const allOptions: SelectOption[] = [];
  if (idsList && idsList.length > 0) {
    idsList.forEach((id) => {
      const optionFound = findOptionById(options, id);
      if (optionFound) {
        allOptions.push(optionFound);
      }
    });
  }
  return allOptions;
};

export const mapToSelectOptions = (data: string[]) => data.map((option) => ({ id: option, label: option }));
