import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStrategicImperativeFormConfig from './StrategicImperativeForm.config';
import { StrategicImperative } from 'generated/maya.types';
import { strategicImperativeToBE } from 'modules/maya/basics/transformers/StrategicImperative.transformer';
import {
  SiFormCanceltCallbackType,
  SiFormSubmitCallbackType,
} from 'modules/maya/components/StrategicImperativeForm/StrategicImperativeForm.types';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useStrategicImperativeCreate = (
  strategicImperative: StrategicImperative | undefined,
  submitCallback: SiFormSubmitCallbackType,
  cancelCallback: SiFormCanceltCallbackType,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { strategicImperativeFormConfig } = useStrategicImperativeFormConfig(strategicImperative);
  const { t } = useTranslation();
  const { getValues, providerFields, resetForm, useWatchForm, setFieldShowError } = useYodaCreateForm();
  const { isValid } = useWatchForm();

  useEffect(() => {
    if (strategicImperative) {
      const fieldsName = Object.keys(strategicImperativeFormConfig);
      setFieldShowError(true, fieldsName);
    }
  }, [setFieldShowError, strategicImperative, strategicImperativeFormConfig]);

  const handleCancelStrategicImperativeForm = () => {
    resetForm();
    cancelCallback();
  };

  const handleSaveStrategicImperativeForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedSI = strategicImperativeToBE(formValues);

    await submitCallback(formattedSI);

    setIsSubmitting(false);
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: handleCancelStrategicImperativeForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSaveStrategicImperativeForm,
      buttonType: ButtonType.primary,
      disabled: !isValid || isSubmitting,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    strategicImperativeFormConfig,
    submitButtonConfig,
    providerFields,
    t,
  };
};

export default useStrategicImperativeCreate;
