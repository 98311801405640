import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAddSpeakerProfileConfig from '../AddSpeakerProfile.config';
import { transformSpeakerProfile } from './AddSpeakerProfileModal.transformers';
import { useCreateSpeakerProfileMutation } from 'modules/events/graphql/mutations/createSpeakerProfile';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useAddSpeakerProfileModal = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createSpeakerProfile] = useCreateSpeakerProfileMutation();

  const { t } = useTranslation();
  const {
    getValues,
    providerFields,
    resetForm,
    useWatchForm,
  } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const { addSpeakerProfileConfig } = useAddSpeakerProfileConfig();

  const getTransformSpeakerImage = useCallback(() => {
    const formValues = getValues();
    return transformSpeakerProfile(formValues);
  }, [getValues]);

  const cancelAddSpeakerForm = () => {
    resetForm();
    setOpen(false);
  };

  const handleSaveSpeakerProfileForm = useCallback(async () => {
    setIsSubmitting(true);
    const formValues = getTransformSpeakerImage();
    const speakerProfileData = await createSpeakerProfile(formValues);
    setIsSubmitting(false);

    if (speakerProfileData) {
      resetForm();
      setOpen(false);
    }
  }, [createSpeakerProfile, getTransformSpeakerImage, resetForm, setIsSubmitting, setOpen]);

  const cancelButtonConfig = {
    buttonProps: {
      buttonType: ButtonType.secondary,
      onClick: cancelAddSpeakerForm,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      buttonType: ButtonType.primary,
      disabled: !isValid,
      onClick: handleSaveSpeakerProfileForm,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    addSpeakerProfileConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  };
};

export default useAddSpeakerProfileModal;
