import { array, object, string } from 'yup';

const useCoorganizersSchema = () => ({
  coorganizers: array()
    .nullable(true)
    .of(object().shape({
      id: string().required(),
      name: string().required(),
      lastName: string().required(),
      email: string().required(),
    })),
});

export default useCoorganizersSchema;
