import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { KeyMessage, KeyMessagesFilter } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_KEY_MESSAGES = gql`
query GetKeyMessages($filters: KeyMessagesFilter) {
  getKeyMessages(filters: $filters) {
    ID
    BusinessUnit,
    Category,
    Country,
    Description,
    DetailGroupName,
    EndDate,
    Function,
    KeyName,
    MarketAccess,
    Medical,
    ProductName,
    Sales,
    StartDate,
    Status,
    Type,
    VeevaExternalID,
  }
}
`;

type ResultType = {
  getKeyMessages: KeyMessage[];
};

type UseGetKeyMessages = (filters: KeyMessagesFilter, skip?: boolean) => {
  loading: boolean;
  error?: ApolloError;
  data: KeyMessage[];
};

const useGetKeyMessagesQuery: UseGetKeyMessages = (filters: KeyMessagesFilter, skip = false) => {
  const { data, loading, error } = useQuery<ResultType>(GET_KEY_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    skip,
    variables: {
      filters,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getKeyMessages.ts - useGetKeyMessagesQuery',
    });
  }

  return {
    data: data?.getKeyMessages || [],
    loading,
    error,
  };
};

export default useGetKeyMessagesQuery;
