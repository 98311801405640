import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { Program } from 'modules/events/basics/types/program.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const getPrograms = gql`
  query getPrograms {
    getPrograms {
      id: _id
      programName
      programLogoUrl
    }
  }
`;

type GetProgramsResponse = {
  getPrograms: Program[];
};

type UseLazyGetPrograms = () => {
  queryGetPrograms: Function;
  loading: boolean;
  data?: GetProgramsResponse;
  error?: ApolloError;
  called: boolean;
};

const useLazyGetPrograms: UseLazyGetPrograms = () => {
  const [queryGetPrograms, { loading, data, error, called }] = useLazyQuery(getPrograms, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getProgramsLazy.ts - useLazyGetPrograms',
    });
  }

  return {
    queryGetPrograms,
    loading,
    data,
    error,
    called,
  };
};

export default useLazyGetPrograms;
