import { getUTCDate } from 'basics/utils/dates/dates.utils';
import { AgendaEntry, AssetTarget } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import { AssetsSentToDB } from 'modules/events/basics/types/eventData.types';
import { Speaker } from 'modules/events/basics/types/speaker.types';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

export const transformInputAssets = (data: YodaFieldValue) => {
  const assetsFields = [
    commonFields.cardImage,
    commonFields.teaser,
  ];

  const initialAcc: AssetsSentToDB = {};

  const assets = assetsFields.reduce((acc, current) => {
    const target = current.split('.').at(-1) as AssetTarget;
    if (data[current]) {
      acc[target] = data[current]?.map((item: { id: string }) => item.id);
    }
    return acc;
  }, initialAcc);
  return Object.keys(assets).length ? { assets } : {};
};

export const transformInputProvider = (data: YodaFieldValue) => {
  const hostProvider = data[commonFields.hostProvider];
  const hostProviderLink = data[commonFields.hostProviderLink];

  return {
    provider: {
      host: hostProvider,
      participantUrl: hostProviderLink,
      organizerUrl: hostProviderLink,
    },
  };
};

export const transformInputSession = (data: YodaFieldValue) => {
  const sessionData = data[commonFields.session];
  if (sessionData) {
    const { dateRange: [startDate, endDate], startTime, endTime, timeZone } = sessionData;
    return ({
      startDate: getUTCDate(startDate || undefined, startTime, timeZone) || new Date(),
      endDate: getUTCDate(endDate || undefined, endTime, timeZone) || new Date(),
      timeZone,
    });
  }
  return undefined;
};

export const transformInputBiogenLincConfig = (dataToSave: YodaFieldValue, formValues: YodaFieldValue) => {
  const { isVisibleOnBiogenLinc, isPublic, prcCodeEventDetails, showSendInvitations, enableGuestRegistration } = commonFields;
  const { public: publicEnum } = EventPromotionalityEnum;
  const isVisibleOnBiogenLincCurrent = formValues[isVisibleOnBiogenLinc];
  const showSendInvitationsCurrent = formValues[showSendInvitations];

  const isVisibleOnBiogenLincHasChanged = dataToSave[isVisibleOnBiogenLinc] !== undefined;
  const isPublisHasChanged = dataToSave[isPublic] !== undefined;
  const prcCodeEventDetailsHasChanged = dataToSave[prcCodeEventDetails] !== undefined;
  const showSendInvitationsHasChanged = dataToSave[showSendInvitations] !== undefined;
  const enableGuestRegistrationHasChanged = dataToSave[enableGuestRegistration] !== undefined;
  const biogenLincConfigHasChanged = isVisibleOnBiogenLincHasChanged
    || isPublisHasChanged
    || prcCodeEventDetailsHasChanged
    || enableGuestRegistrationHasChanged
    || showSendInvitationsHasChanged;

  const enableGuestRegistrationToSave = dataToSave[enableGuestRegistration] || false;

  const prcCodeEventDetailsToSave = prcCodeEventDetailsHasChanged
    ? dataToSave[prcCodeEventDetails]
    : formValues[prcCodeEventDetails];

  if (!biogenLincConfigHasChanged) {
    return {};
  }

  if (!isVisibleOnBiogenLincCurrent) {
    return {
      biogenLincConfig: {
        isVisibleOnBiogenLinc: false,
        isPublic: false,
        enableGuestRegistration: false,
        prcCodeEventDetails: prcCodeEventDetailsToSave,
        showSendInvitations: showSendInvitationsCurrent,
      },
    };
  }

  return {
    biogenLincConfig: {
      isVisibleOnBiogenLinc: true,
      isPublic: isPublisHasChanged ? dataToSave[isPublic] === publicEnum : formValues[isPublic] === publicEnum,
      enableGuestRegistration: enableGuestRegistrationToSave,
      prcCodeEventDetails: prcCodeEventDetailsToSave,
      showSendInvitations: showSendInvitationsCurrent,
    },
  };
};

export const transformSpeakers = (data: YodaFieldValue) => {
  const speakers = data[commonFields.speakers];
  if (speakers) {
    const transformation = speakers.map((speaker: Speaker) => speaker.id);
    return {
      speakers: transformation,
    };
  }
  return {};
};

export const transformAgenda = (data: YodaFieldValue) => {
  const agenda = data[commonFields.agenda];
  return (agenda && agenda.length > 0)
    ? data[commonFields.agenda].map((agendaEntry: AgendaEntry) => {
      const { title, description, session } = agendaEntry;
      return {
        title,
        description,
        session,
      };
    }) : null;
};
