import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const syncCiToVeevaMutationCall = gql`
  mutation syncCiToVeeva($id: String!) {
    syncCiToVeeva(id: $id)
  }
`;

type ResultType = {
  syncCiToVeeva: boolean;
};

type UseSyncCiToVeevaMutation = [
  (id: string) => Promise<boolean>,
  MutationResult<ResultType>,
];

export const useSyncCiToVeevaMutation = (): UseSyncCiToVeevaMutation => {
  const [syncCiToVeeva, state] = useMutation<ResultType>(syncCiToVeevaMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const executeMutation = useCallback(async (id) => {
    try {
      const { data } = await syncCiToVeeva({
        variables: {
          id,
        },
      });
      return data?.syncCiToVeeva || false;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'syncCiToVeeva.ts - useSyncCiToVeevaMutation',
        });
      }
      return false;
    }
  }, [syncCiToVeeva]);

  return [executeMutation, state];
};
