import { FC } from 'react';
import { FormShape } from '../QuickForm.enums';
import { FormMapperProps } from '../QuickForm.types';
import Form2FieldsColumn from './Form2Fields/Form2FieldsColumn';
import Form2FieldsRow from './Form2Fields/Form2FieldsRow';
import FormAgenda from './FormAgenda';
import FormAsyncSelect from './FormAsyncSelect';
import FormDropZone from './FormDropZone';

const FormMapper: FC<FormMapperProps> = ({
  form,
  fieldState,
  setFieldState,
  setListLoading,
  transformToState,
  disabled,
}) => {
  switch (form.shape) {
    case FormShape.form2FieldsColumn:
      return (
        <Form2FieldsColumn
          fieldState={ fieldState }
          setFieldState={ setFieldState }
          transformToState={ transformToState }
          { ...form.formConfig }
        />
      );
    case FormShape.form2FieldsRow:
      return (
        <Form2FieldsRow
          fieldState={ fieldState }
          setFieldState={ setFieldState }
          transformToState={ transformToState }
          { ...form.formConfig }
        />
      );
    case FormShape.formAsset:
      return (
        <FormDropZone
          fieldState={ fieldState }
          setFieldState={ setFieldState }
          setListLoading={ setListLoading }
          transformToState={ transformToState }
          disabled={ disabled }
          { ...form.formConfig }
        />
      );
    case FormShape.formAsyncSelect:
      return (
        <FormAsyncSelect
          fieldState={ fieldState }
          setFieldState={ setFieldState }
          transformToState={ transformToState }
          disabled={ disabled }
          { ...form.formConfig }
        />
      );
    case FormShape.formAgenda:
      return (
        <FormAgenda
          fieldState={ fieldState }
          setFieldState={ setFieldState }
          transformToState={ transformToState }
          disabled={ disabled }
          { ...form.formConfig }
        />
      );
    default:
      return null;
  }
};

export default FormMapper;
