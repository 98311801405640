import { FC } from 'react';
import Box from 'yoda-ui/Box';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type CardHeaderProps = {
  title: string;
};

const CardHeader: FC<CardHeaderProps> = ({ children, title }) => (
  <Box
    display="flex"
    flexDirection="row"
    paddingBottom={ YodaSpacing.xxSmall }
    paddingX={ YodaSpacing.large }
    paddingY={ YodaSpacing.medium }
    fontSize={ YodaFontSize.xLarge }
    fontWeight={ YodaFontWeight.medium }
    justifyContent="space-between"
  >
    { title }
    { children }
  </Box>
);

export default CardHeader;
