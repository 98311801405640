import { FC } from 'react';
import useTacticCreate from './TacticForm.hook';
import ActionButton from 'components/ActionButton';
import ContentCard from 'components/ContentCard';
import { FormLayoutContainer } from 'components/FormLayout';
import { Channel } from 'modules/maya/basics/enums/maya.enums';
import { formContainerActionsStyle } from 'modules/maya/components/FormContainer/FormContainer.style';
import { TacticFormPropsTypes } from 'modules/maya/components/TacticForm/TacticForm.types';
import { ApprovedEmail } from 'modules/maya/components/TacticForm/TacticsChannel/ApprovedEmail';
import { BroadcastEmail } from 'modules/maya/components/TacticForm/TacticsChannel/BroadcastEmail';
import { F2fEvent } from 'modules/maya/components/TacticForm/TacticsChannel/F2fEvent';
import { F2fMeeting } from 'modules/maya/components/TacticForm/TacticsChannel/F2fMeeting';
import { HybridEvent } from 'modules/maya/components/TacticForm/TacticsChannel/HybridEvent';
import { OneToOne } from 'modules/maya/components/TacticForm/TacticsChannel/OneToOne';
import { Telephone } from 'modules/maya/components/TacticForm/TacticsChannel/Telephone';
import { VirtualEvent } from 'modules/maya/components/TacticForm/TacticsChannel/VirtualEvent';
import { VirtualMeeting } from 'modules/maya/components/TacticForm/TacticsChannel/VirtualMeeting';
import { WebLogIn } from 'modules/maya/components/TacticForm/TacticsChannel/WebLogIn';
import { WebNoLogIn } from 'modules/maya/components/TacticForm/TacticsChannel/WebNoLogIn';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticForm: FC<TacticFormPropsTypes> = ({ isTemplate }) => {
  const {
    cancelButtonConfig,
    keyMessagesLoading,
    providerFields,
    selectedChannel,
    submitButtonConfig,
    tacticFormConfig,
  } = useTacticCreate(isTemplate);

  const FormDisplayByChannel = (channel: Channel) => {
    switch (channel) {
      case Channel.approvedEmail:
        return (
          < ApprovedEmail tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.broadcastEmail:
        return (
          < BroadcastEmail tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.f2fEvent:
        return (
          < F2fEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.f2fMeeting:
        return (
          < F2fMeeting tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.hybridEvent:
        return (
          < HybridEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.oneToOne:
        return (
          < OneToOne tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.telephone:
        return (
          < Telephone tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.virtualEvent:
        return (
          < VirtualEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.virtualMeeting:
        return (
          < VirtualMeeting tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.webLogIn:
        return (
          < WebLogIn tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.webNoLogIn:
        return (
          < WebNoLogIn tacticFormConfig={ tacticFormConfig } />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <YodaFormProvider { ...providerFields }>

        <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
          <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } >
            <FormLayoutContainer
              flexDirection="column"
              justifyContent={ YodaJustifyContent.spaceBetween }
            >

              <Box marginBottom={ YodaSpacing.large }>
                <SelectYoda { ...tacticFormConfig.channel } maxHeight={ 250 } overflow={ 'auto' }/>
              </Box>

              { FormDisplayByChannel(selectedChannel as Channel) }

            </FormLayoutContainer>
          </FormLayoutContainer>
        </ContentCard>

        <Box sx={ formContainerActionsStyle }>
          { cancelButtonConfig ? <ActionButton { ...cancelButtonConfig } /> : <Box></Box> }
          { submitButtonConfig && <ActionButton { ...submitButtonConfig } /> }
        </Box>
      </YodaFormProvider>
    </>
  );
};

export default TacticForm;

