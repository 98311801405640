import { FC } from 'react';
import { TacticFormTypes } from 'modules/maya/components/TacticForm/TacticForm.types';
import Box from 'yoda-ui/Box';
import CheckBoxYoda from 'yoda-ui/Form/CheckBoxYoda';
import DateRangePicker from 'yoda-ui/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

export const WebNoLogIn: FC<TacticFormTypes> = ({ tacticFormConfig }) => {
  return (
    <>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.title } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <DateRangePicker { ...tacticFormConfig.date } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...tacticFormConfig.mandatory } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.actionOrder } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.previousTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.executingTeam } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <SelectYoda { ...tacticFormConfig.parentTactic } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.description } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...tacticFormConfig.destinationUrl } />
      </Box>
    </>
  );
};
