import { FC } from 'react';

import { SpeakersFormProps } from './Speakers.types';
import QuickForm from 'components/QuickForm';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';

import AddSpeakerProfile from 'modules/events/components/AddSpeakerProfile';
import useSpeakers from 'modules/events/components/Speakers/Speakers.hook';

const Speakers: FC<SpeakersFormProps> = ({
  defaultValue,
  listConfig,
  name,
  speakersFormConfig: formConfig,
  validation,
  disabled,
}) => {
  const { transformSpeakerToCard, transformToState } = useSpeakers();

  return (
    <>
      <QuickForm
        defaultValue={ defaultValue }
        form={ { shape: FormShape.formAsyncSelect, formConfig } }
        list={ { dragAndDrop: true, shape: ListShape.listRow, listConfig } }
        name={ name }
        transformToCard={ transformSpeakerToCard }
        transformToState={ transformToState }
        validation={ validation }
        disabled={ disabled }
      />
      { disabled ? null : <AddSpeakerProfile /> }
    </>
  );
};

export default Speakers;
