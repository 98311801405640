import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import { useLazyGetPossibleCoorganizers } from 'modules/events/graphql/queries/getPossibleOrganizer';
import useCoorganizersSchema from 'modules/events/groups/CoorganizersGroup/CoorganizersGroup.schema';
import { CoorganizersType } from 'modules/events/groups/CoorganizersGroup/CoorganizersGroup.types';
import eventState from 'modules/events/states/events.states';

const useCoorganizersConfig = () => {
  const { t } = useTranslation();
  const coorganizersSchema = useCoorganizersSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const isPublished = !isDraft(snap.createEvent.status);

  const coorganizersConfig: CoorganizersType = {
    coorganizers: {
      name: commonFields.coorganizers,
      coorganizersFormConfig: {
        lazyQueryHook: useLazyGetPossibleCoorganizers,
        label: t('meeting_field_coorganizer_add'),
        placeholder: t('user_search_email'),
      },
      listConfig: {
        noItemLabel: t('coorganizers_list_empty'),
        title: t('coorganizers_form_list_title'),
      },
      defaultValue: snap.createEvent.data?.coOrganizers || [],
      validation: coorganizersSchema.coorganizers,
    },
  };

  return { coorganizersConfig, isPublished };
};

export default useCoorganizersConfig;
