import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayoutContainer } from 'components/FormLayout';
import useDynamicTargetList from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.hook';
import { DynamicTargetListFormPropsType } from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.types';
import FormContainer from 'modules/maya/components/FormContainer/FormContainer';
import Box from 'yoda-ui/Box';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/Form/SelectYoda/MultiSelectYoda';
import Loader from 'yoda-ui/Loader';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const DynamicTargetListForm: FC<DynamicTargetListFormPropsType> = (
  {
    updateTargetList,
    loading,
    setLoading,
  },
) => {
  const {
    dynamicTargetListConfig,
    providerFields,
    submitButtonConfig,
    targetsFacetsLoading,
    filteredTargetsFacetsLoading,
  } = useDynamicTargetList(updateTargetList, loading, setLoading);

  const { t } = useTranslation();

  const width50Style = 'calc(50% - 20px)';

  return (
    <FormContainer providerFields={ providerFields } submitButtonConfig={ submitButtonConfig } >
      <Box marginBottom={ YodaSpacing.medium }>{ t('maya_dynamic_target_list_form_title_scope') }</Box>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
        <Box width={ width50Style }>
          {
            targetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <SelectYoda
            { ...dynamicTargetListConfig.country }
            disabled={ targetsFacetsLoading }
          />
        </Box>
        <Box width={ width50Style }>
          {
            targetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <SelectYoda
            { ...dynamicTargetListConfig.businessUnit }
            disabled={ targetsFacetsLoading }
          />
        </Box>
      </FormLayoutContainer>

      <Box marginBottom={ YodaSpacing.medium }>{ t('maya_dynamic_target_list_form_title_segment') }</Box>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
        <Box width={ width50Style }>
          {
            filteredTargetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dynamicTargetListConfig.smaSegments }
            disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.smaSegments.disabled }
          />
        </Box>
        <Box width={ width50Style }>
          {
            filteredTargetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dynamicTargetListConfig.biosSegments }
            disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.biosSegments.disabled }
          />
        </Box>
      </FormLayoutContainer>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ fromUnitsToRem(YodaSpacing.large) } >
        <Box width={ width50Style }>
          {
            filteredTargetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dynamicTargetListConfig.imsSegments }
            disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.imsSegments.disabled }
          />
        </Box>
      </FormLayoutContainer>
      <Box marginBottom={ YodaSpacing.medium }>{ t('maya_dynamic_target_list_form_title_customer_attribute') }</Box>
      <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } >
        <Box width={ width50Style }>
          {
            filteredTargetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dynamicTargetListConfig.type }
            disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.type.disabled }
          />
        </Box>
        <Box width={ width50Style }>
          {
            filteredTargetsFacetsLoading
              ? (
                <Box
                  display="flex"
                  justifyContent={ YodaJustifyContent.center }
                  position="absolute"
                  paddingTop={ YodaSpacing.small }
                  paddingBottom={ YodaSpacing.small }
                >
                  <Loader center size={ 1 } />
                </Box>
              )
              : null
          }
          <MultiSelectYoda
            { ...dynamicTargetListConfig.specialty }
            disabled={ filteredTargetsFacetsLoading || dynamicTargetListConfig.specialty.disabled }
          />
        </Box>
      </FormLayoutContainer>
    </FormContainer>
  );
};

export default DynamicTargetListForm;
