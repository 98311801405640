import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { hydrateParticipants } from 'modules/events/basics/mocks/participants.mock';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import { useYodaCreateForm } from 'services/yodaForm';

const useEditOnlineWebinar = () => {
  const { id } = useParams();

  const { queryGetFullPrivateEventById, data: event, error, loading } = useLazyGetFullPrivateEventById();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  useEffect(() => {
    if (id) {
      queryGetFullPrivateEventById({ variables: { eventId: id } });
    }
  }, [id, queryGetFullPrivateEventById]);

  const mockedEvent = useMemo(
    () => hydrateParticipants(event),
    [event],
  );

  return {
    error,
    event: mockedEvent,
    loading,
    yodaFormMethods,
  };
};

export default useEditOnlineWebinar;
