import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';

export const programFragment = `
  id: _id
  programName
  programLogo {
    ${assetFragment}
  }
  programLogoUrl
`;
