import { formatISO } from 'date-fns';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ParticipantsItemCollapseFields } from './ParticipantsItemCollapse.enums';
import { FieldProps } from './ParticipantsItemCollapse.types';
import {
  getShortFormatDateTimeFromIsoString,
  LONG_DAY_NUM_MONTH_LONG_YEAR_SLASHES,
} from 'basics/utils/dates/dates.utils';
import { Participant } from 'modules/events/basics/types/events.types';

// Fields ordered by display
const FIELDS = (t: TFunction<'translation'>): FieldProps[] => ([
  {
    key: ParticipantsItemCollapseFields.mdmId,
    label: t('person_field_mdmId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.accountId,
    label: t('person_field_accountId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.phone,
    label: t('person_field_phone_label'),
  },
  {
    key: ParticipantsItemCollapseFields.collectedPhoneNumber,
    label: t('person_field_collectedPhoneNumber_label'),
  },
  {
    key: ParticipantsItemCollapseFields.institution,
    label: t('person_field_institution_label'),
  },
  {
    key: ParticipantsItemCollapseFields.specialty,
    label: t('person_field_specialty_label'),
  },
  {
    key: ParticipantsItemCollapseFields.doctorId,
    label: t('person_field_doctorId_label'),
  },
  {
    key: ParticipantsItemCollapseFields.zoomCallLink,
    label: t('person_field_eventLink_label'),
  },
  {
    key: ParticipantsItemCollapseFields.invitationDate,
    label: t('person_field_invitationDate_label'),
  },
  {
    key: ParticipantsItemCollapseFields.registrationEmailSent,
    label: t('person_field_registrationEmailSent_label'),
  },

  {
    key: ParticipantsItemCollapseFields.reminder48h,
    label: t('person_field_reminder1EmailSent_label'),
  },

  {
    key: ParticipantsItemCollapseFields.reminder24h,
    label: t('person_field_reminder2EmailSent_label'),
  },

  {
    key: ParticipantsItemCollapseFields.reminder12h,
    label: t('person_field_reminder3EmailSent_label'),
  },
  {
    key: ParticipantsItemCollapseFields.reminder1h,
    label: t('person_field_reminder4EmailSent_label'),
  },
  {
    key: ParticipantsItemCollapseFields.cxEmailSent,
    label: t('person_field_cxEmailSent_label'),
  },

]);

const formatStatusInvitedDate = (date: Date | null | undefined) => {
  if (!date) {
    return null;
  }
  const formatted = formatISO(new Date(date));
  return getShortFormatDateTimeFromIsoString(formatted, LONG_DAY_NUM_MONTH_LONG_YEAR_SLASHES);
};

const formatValue = (field: FieldProps, participant: Participant, t: TFunction<'translation'>) => {
  const { key } = field;
  const {
    invitationDate,
    registrationEmailSent,
    reminder48h,
    reminder24h,
    reminder12h,
    reminder1h,
    cxEmailSent,
  } = ParticipantsItemCollapseFields;

  switch (key) {
    case invitationDate:
      return participant.registrationStatusHistory.invited ? formatStatusInvitedDate(participant.registrationStatusHistory.invited) : null;
    case registrationEmailSent:
    case reminder48h:
    case reminder24h:
    case reminder12h:
    case reminder1h:
    case cxEmailSent:
      return participant[key] ? t('common_yes') : t('common_no');
    default:
      return participant[key];
  }
};

const useParticipantsItemCollapse = (participant: Participant) => {
  const { t } = useTranslation();

  const fieldsConfig = useMemo(
    () => FIELDS(t).map((field) => ({
      ...field,
      value: formatValue(field, participant, t),
    })),
    [participant, t],
  );

  return {
    fieldsConfig,
  };
};

export default useParticipantsItemCollapse;
