import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useUploadTargetListSchema from './UploadTargetListForm.schema';
import { FileExtension } from 'basics/types/files.types';
import { UseFormDropZoneStateSource } from 'components/QuickForm/FormsBucket/FormDropZone/FormDropZone.enums';
import { AssetTarget, AssetType } from 'generated/maya.types';
import { UploadTargetListProps } from 'modules/maya/basics/types/targetList.types';
import { Icons } from 'yoda-ui/Icons/Icon';

type UploadTargetListConfig = Omit<UploadTargetListProps, 'handleFilesExternal' | 'handleRemoveItemAsync'>;

const useTargetListConfig = () => {
  const { t } = useTranslation();
  const targetListSchema = useUploadTargetListSchema();

  const uploadTargetListConfig: UploadTargetListConfig = useMemo(
    () => ({
      name: 'targetList',
      formConfig: {
        btnLabel: t('maya_targetlist_upload_btnlabel'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 0,
        extensionsAllowed: [FileExtension.csv],
        message: t('maya_targetList_upload_message'),
        label: t('maya_targetlist_label'),
        name: 'targetList',
        assetTarget: AssetTarget.targetListCsv,
        assetType: AssetType.csv,
        assetsArePublic: true,
        stateSource: UseFormDropZoneStateSource.EXTERNAL,
      },
      listConfig: {
        noItemLabel: t('maya_targetlist_noitemlabel'),
        title: t('maya_targetlist_upload_title'),
      },
      defaultValue: [],
      validation: targetListSchema.targetList,
    }), [t, targetListSchema.targetList],
  );

  return {
    uploadTargetListConfig,
  };
};

export default useTargetListConfig;
