import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormGroup from 'components/FormGroup';

import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';

import Speakers from 'modules/events/components/Speakers';
import useSpeakersConfig from 'modules/events/groups/SpeakersGroup/SpeakersGroup.config';
import Box from 'yoda-ui/Box/Box';
import TextYoda from 'yoda-ui/Form/TextYoda/TextYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const SpeakersGroup: FC = () => {
  const { t } = useTranslation();
  const { speakersConfig } = useSpeakersConfig();

  return (
    <FormGroup title={ t('speakers_form_list_title') } groupName={ CommonGroups.speakers }>

      <Box marginBottom={ YodaSpacing.large } width="50%">
        <TextYoda { ...speakersConfig.speakerSupportPhoneNumber } />
      </Box>

      <Speakers { ...speakersConfig.speakers } />
    </FormGroup>
  );
};

export default SpeakersGroup;
