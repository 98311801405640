import { useTranslation } from 'react-i18next';
import { CommonSteps } from 'modules/events/basics/enums/commonForms.enums';
import { StepItem } from 'yoda-ui/Stepper/Stepper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreateOnlineWebinarStepper = (useWatchStep: any) => {
  const { t } = useTranslation();
  const watchDetailsStep = useWatchStep(CommonSteps.detailsStep);
  const watchSessionStep = useWatchStep(CommonSteps.sessionStep);
  const watchCommunicationStep = useWatchStep(CommonSteps.communicationStep);

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('event_step_details')}`,
      isValid: watchDetailsStep?.isValid || false,
      disabled: false,
    },
    {
      title: `2. ${t('event_step_communication')}`,
      isValid: watchCommunicationStep?.isValid || false,
      disabled: false,
    },
    {
      title: `3. ${t('event_step_session')}`,
      isValid: watchSessionStep?.isValid || false,
      disabled: false,
    },
  ];
  return stepsList;
};

export default useCreateOnlineWebinarStepper;
