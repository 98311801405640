import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import AddProgram from 'modules/events/components/AddProgram';
import CardImage from 'modules/events/components/CardImage';
import usePrimaryInformations from 'modules/events/groups/PrimaryInformationsGroup/PrimaryInformationsGroup.hook';
import Box from 'yoda-ui/Box';
import Divider from 'yoda-ui/Divider';
import RichtextYoda from 'yoda-ui/Form/RichtextYoda';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda/TextYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const PrimaryInformationsGroup: FC = () => {
  const { t } = useTranslation();
  const { handleDropImage, primaryInformationsConfig } = usePrimaryInformations();

  return (
    <FormGroup title={ t('event_group_primary_information') } groupName={ CommonGroups.primaryInformations }>
      <Box paddingBottom={ YodaSpacing.large }>
        <TextYoda { ...primaryInformationsConfig.title } />
      </Box>
      <Box paddingBottom={ YodaSpacing.large }>
        <RichtextYoda { ...primaryInformationsConfig.description } />
      </Box>
      <Box paddingBottom={ YodaSpacing.large }>
        <SelectYoda { ...primaryInformationsConfig.product } />
      </Box>
      <Box paddingBottom={ YodaSpacing.large }>
        <SelectYoda { ...primaryInformationsConfig.program } />
        <AddProgram />
      </Box>
      <Divider />
      <Box paddingTop={ YodaSpacing.large }>
        <CardImage
          handleFiles={ handleDropImage }
          { ...primaryInformationsConfig.cardImage }
        />
      </Box>
    </FormGroup>
  );
};

export default PrimaryInformationsGroup;
