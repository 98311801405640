import { ApolloError } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, warningToast } from 'basics/utils/toast';
import { RegisterParticipantInput } from 'generated/event.types';
import { Participant } from 'modules/events/basics/types/events.types';
import { useAddParticipantMutation } from 'modules/events/graphql/mutations/addParticipant';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import useLazyGetParticipantByMdmId from 'modules/events/graphql/queries/getLazyParticipantByMdmId';
import useRegisterParticipantGroupConfig from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.config';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useRegisterParticipantGroup = (
  eventId: string,
  participants: Participant[],
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [newParticipant, setNewParticipant] = useState<Participant | null>(null);

  const { queryGetParticipantByMdmId } = useLazyGetParticipantByMdmId();
  const { queryGetFullPrivateEventById, data: event } = useLazyGetFullPrivateEventById();
  const [runAddMutation] = useAddParticipantMutation();

  const { t } = useTranslation();

  const {
    registerParticipantGroup,
  } = useRegisterParticipantGroupConfig();

  const {
    getValues,
    providerFields,
  } = useYodaCreateForm();

  const handleSubmit = async () => {
    const formValues = getValues();

    try {
      const { data: { getParticipantByMdmId: newParticipantData } } = await queryGetParticipantByMdmId({ variables: {
        mdmId: formValues.participantMdmid,
      } });

      if (participants.filter((participant) => participant.mdmId === formValues.participantMdmid).length) {
        warningToast(t('event_register_participant_error_already_exist'));

        return;
      }

      setNewParticipant(newParticipantData);
      setOpen(true);
    } catch (error) {
      warningToast((error as ApolloError).graphQLErrors[0]?.extensions.description as string || t('errors_standard'));
    }
  };

  const registerParticipantCallback = async () => {
    setIsSubmitting(true);

    if (newParticipant) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { id, phone, zoomCallLink, registrationStatusHistory, cxEmailSent, registrationEmailSent,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        reminder1h, reminder12h, reminder24h, reminder48h, ...registerParticipantInput } = newParticipant;
      const success = await runAddMutation({ ...registerParticipantInput, eventId } as RegisterParticipantInput);
      await queryGetFullPrivateEventById({ variables: { eventId } });

      if (success && event?.data.participants) {
        setParticipants(event.data.participants);

        succesToast('event_register_participant_sucess');
      }
    }

    setIsSubmitting(false);
    setOpen(false);
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSubmit,
      buttonType: ButtonType.primary,
    },
    label: t('actions_add_label'),
    loading: isSubmitting,
  };

  return {
    registerParticipantGroup,
    submitButtonConfig,
    open,
    setOpen,
    registerParticipantCallback,
    isSubmitting,
    providerFields,
    newParticipant,
  };
};

export default useRegisterParticipantGroup;
