import { useTranslation } from 'react-i18next';
import { StepItem } from 'yoda-ui/Stepper/Stepper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepViewStepper = () => {
  const { t } = useTranslation();

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('maya_cep_step_configuration')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `2. ${t('maya_cep_step_target_list')}`,
      isValid: true,
      disabled: false,
    },
    {
      title: `3. ${t('maya_cep_step_tactics')}`,
      isValid: true,
      disabled: false,
    },
  ];
  return stepsList;
};

export default useCepViewStepper;
