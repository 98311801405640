import { useTranslation } from 'react-i18next';
import { AddSpeakerProfileModalConfig } from './AddSpeakerProfile.types';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import useAddSpeakerProfileSchema from 'modules/events/components/AddSpeakerProfile/AddSpeakerProfile.schema';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

const useAddSpeakerProfileConfig = () => {
  const { t } = useTranslation();
  const addSpeakerProfileSchema = useAddSpeakerProfileSchema();
  const addSpeakerProfileConfig: AddSpeakerProfileModalConfig = {
    title: {
      defaultValue: '',
      label: t('person_field_title_label'),
      name: 'title',
      placeholder: t('person_field_title_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.title,
    },
    firstName: {
      defaultValue: '',
      label: t('person_field_firstName_label'),
      name: 'firstName',
      placeholder: t('person_field_firstName_placeholder'),
      required: true,
      validation: addSpeakerProfileSchema.firstName,
    },
    lastName: {
      defaultValue: '',
      label: t('person_field_lastName_label'),
      name: 'lastName',
      placeholder: t('person_field_lastName_placeholder'),
      required: true,
      validation: addSpeakerProfileSchema.lastName,
    },
    email: {
      defaultValue: '',
      label: t('person_field_email_label'),
      name: 'email',
      placeholder: t('person_field_email_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.email,
    },
    affiliation: {
      defaultValue: '',
      label: t('speaker_profile_affiliation_label'),
      name: 'affiliation',
      placeholder: t('speaker_profile_affiliation_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.affiliation,
    },
    specialty: {
      defaultValue: '',
      label: t('speaker_profile_specialty_label'),
      name: 'specialty',
      placeholder: t('speaker_profile_specialty_placeholder'),
      required: false,
      validation: addSpeakerProfileSchema.specialty,
    },
    image: {
      cardImageFormConfig: {
        btnLabel: t('speaker_profile_image_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.jpg, FileExtension.jpeg, FileExtension.png],
        message: t('speaker_profile_image_dropzone_message'),
        label: t('speaker_profile_image_dropzone_action_label'),
        name: 'image',
        assetTarget: AssetTarget.speakerImage,
        assetType: AssetType.image,
        assetsArePublic: true,
      },
      defaultValue: [],
      dimensions: {
        maxHeight: 80,
        maxWidth: 80,
        minHeight: 80,
        minWidth: 80,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_image_title'),
      },
      name: 'image',
      validation: addSpeakerProfileSchema.image,
    },
  };

  return { addSpeakerProfileConfig };
};

export default useAddSpeakerProfileConfig;
