import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, warningToast } from 'basics/utils/toast';
import { Speaker, SpeakerInput } from 'modules/events/basics/types/speaker.types';
import { speakerFragment } from 'modules/events/graphql/fragments/speaker.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createSpeakerProfileMutationCall = gql`
mutation createSpeakerProfileMutation($createSpeaker: SpeakerInput!) {
    createSpeaker(speaker: $createSpeaker) {
        ${speakerFragment}
    }
}
`;

type ResultType = {
  createSpeaker: Speaker;
};

type UseCreateSpeakerProfileMutation = [
  (speakerProfile: SpeakerInput) => Promise<Speaker | undefined | null>,
  MutationResult<ResultType>,
];

export const useCreateSpeakerProfileMutation = (): UseCreateSpeakerProfileMutation => {
  const { t } = useTranslation();
  const [createSpeaker, state] = useMutation<ResultType>(createSpeakerProfileMutationCall);

  const executeMutation = useCallback(async (speakerProfile) => {
    try {
      const data = await createSpeaker({
        variables: { createSpeaker: speakerProfile },
      });
      succesToast(t('createSpeaker_toast_success'));
      return data.data?.createSpeaker;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, speakerProfile);
        Logger.logError({
          error,
          customContext,
          transactionName: 'createSpeakerProfile.ts - useCreateSpeakerProfileMutation',
        });
        if (customContext.description) warningToast(String(customContext.description));
      }
      return null;
    }
  }, [createSpeaker, t]);

  return [executeMutation, state];
};
