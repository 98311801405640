type AutologinLinkMapper = { [key: string]: string };
type StaticBiogenLincLinkMapper = { [key: string]: string };

/* eslint-disable max-len */
export const marketStaticAutologinLinks: AutologinLinkMapper = {
  AU: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testprodauv3&email=250223testesv13prt@mailinator.com&biib_asset_code=other-au-biogenlinc&ui_locales=en-AU&utm_campaign=au-standard-vip&country_code=AU&redirect_uri=',
  BR: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testprbrv4prod&email=050323testbrv10prod@mailinator.com&biib_asset_code=other-br-biogenlinc&country_code=BR&ui_locales=pt-BR&utm_campaign=br-standard-vip&redirect_uri=',
  DE: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=sourc&utm_content=280323Testproddev9&email=020323testdev6prod@mailinator.com&biib_asset_code=other-de-biogenlinc&country_code=DE&ui_locales=de-DE&utm_campaign=de-standard-vip&redirect_uri=',
  FR: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testprfrv4prod&email=050323testfrv10prod@mailinator.com&custgroup=FR-HCP-PHY&biib_asset_code=other-fr-biogenlinc&country_code=FR&ui_locales=fr-FR&utm_campaign=fr-standard-vip&redirect_uri=',
  COM: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=300323Testprodbiv4&email=060223testbiv4prodt@mailinator.com&biib_asset_code=other-com-biogenlinc&ui_locales=en-US&utm_campaign=us-standard-vip&country_code=US&redirect_uri=',
  MX: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testprjpv4prod&email=050323testmxv10prod@mailinator.com&biib_asset_code=other-mx-biogenlinc&country_code=MX&ui_locales=es-MX&utm_campaign=mx-standard-vip&redirect_uri=',
  JP: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testprjpv4prod&email=050323testjpv10prod@mailinator.com&biib_asset_code=other-jp-biogenlinc&country_code=JP&ui_locales=ja-JP&utm_campaign=jp-standard-vip&redirect_uri=',
  ES: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=280323Testeprodsv5&email=130323testesv15prod@mailinator.com&biib_asset_code=other-es-biogenlinc&ui_locales=es-ES&utm_campaign=es-standard-vip&country_code=ES&redirect_uri=',
  GB: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=230323Testprodukv4&email=250223testukv18prt@mailinator.com&biib_asset_code=other-uk-biogenlinc&ui_locales=en-GB&utm_campaign=gb-standard-vip&country_code=GB&redirect_uri=',
  IE: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=230323Testprodukv4&email=250223testukv18prt@mailinator.com&biib_asset_code=other-uk-biogenlinc&ui_locales=en-GB&utm_campaign=gb-standard-vip&country_code=GB&redirect_uri=',
  CA: 'https://app.biogenlinc.eu/?mid=932023625766993951&biib_asset_code=other-ca-biogenlinc&utm_medium=email&utm_source=source&utm_content=270323Testpodcav5&utm_campaign=ca-en-standard-vip&email=020223testcav35prodt@mailinator.com&ui_locales=en-CA&custgroup=CA-HCP-PHY&country_code=CA&op_type=consent&redirect_uri=',
  PT: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=Test&email=050323testptv21prod@mailinator.com&biib_asset_code=other-comm-pt-mybiogen&ui_locales=pt-PT&utm_campaign=pt-standard-vip&country_code=PT&redirect_uri=',
  AT: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=Test&email=050323testatv20prod@mailinator.com&biib_asset_code=other-at-biogenlinc&ui_locales=de-AT&utm_campaign=at-standard-vip&country_code=AT&redirect_uri=',
  BE: 'https://app.biogenlinc.eu/?mid=932023625766993951&utm_medium=email&utm_source=source&utm_content=200423beTestpr5&email=200423Testbefrv2prod@mailinator.com&biib_asset_code=other-be-biogenlinc&ui_locales=nl-BE&utm_campaign=be-nl-standard-vip&country_code=BE&redirect_uri=',
  // CH: '',
  // DK: '',
  // NL: '',
  // PL: '',
};

export const marketStaticDomainLinks: StaticBiogenLincLinkMapper = {
  AU: 'https://biogenlinc.com.au/events-list/',
  BR: 'https://biogenlinc.com.br/events-list/',
  DE: 'https://biogenlinc.de/events-list/',
  FR: 'https://biogenlinc.fr/events-list/',
  COM: 'https://biogenlinc.com/events-list/',
  MX: 'https://biogenlinc.mx/events-list/',
  JP: 'https://biogenlinc.jp/events-list/',
  ES: 'https://biogenlinc.es/events-list/',
  GB: 'https://biogenlinc.co.uk/events-list/',
  IE: 'https://biogenlinc.co.uk/events-list/',
  CA: 'https://biogenlinc.ca/events-list/',
  PT: 'https://biogenlinc.pt/events-list/',
  AT: 'https://biogenlinc.at/events-list/',
  BE: 'https://biogenlinc.be/events-list/',
  // CH: 'https://biogenlinc.ch/events-list/',
  // DK: 'https://biogenlinc.dk/events-list/',
  // NL: 'https://biogenlinc.nl/events-list/',
  // PL: 'https://biogenlinc.pl/events-list/',
};
