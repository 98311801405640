import { useCurrentUserState } from 'contexts/user';
import { Market } from 'generated/event.types';
import { marketStaticAutologinLinks, marketStaticDomainLinks } from 'modules/events/basics/constants/PrcBlock.constants';
import useCommunicationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/CommunicationStep/CommunicationStep.hook';
import eventState from 'modules/events/states/events.states';

const useAutologinLink = () => {
  const { isVisibleOnBiogenLinc, isPublic } = useCommunicationStep();
  const { user } = useCurrentUserState();
  const market = user?.market ? user.market as Market : null; // Should give us market

  if (!market) {
    return {
      isVisibleOnBiogenLinc,
      isPublic,
    };
  }

  const eventId = eventState.createEvent._id;
  const { title } = eventState.createEvent;

  const staticPublicBiogenLink = marketStaticDomainLinks[market.toUpperCase()];
  const staticGatedBiogenLink = marketStaticAutologinLinks[market.toUpperCase()];

  let formattedTitle = title?.toLowerCase(); // Change all characters to lowercase
  formattedTitle = formattedTitle?.replace(/[^a-zA-Z0-9 ]/g, ''); // Delete all special characters that are not going to be in URL
  formattedTitle = formattedTitle?.replace(/\s/g, '-'); // Change every empty char into -

  const finalPublicBiogenLincLink = `${staticPublicBiogenLink}${formattedTitle}_${eventId}`;
  const finalGatedBiogenLincLink = `${staticGatedBiogenLink}${finalPublicBiogenLincLink}`;

  return {
    isVisibleOnBiogenLinc,
    finalPublicBiogenLincLink,
    finalGatedBiogenLincLink,
    isPublic,
  };
};

export default useAutologinLink;
