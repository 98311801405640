import { Dispatch, SyntheticEvent, useCallback, useState } from 'react';
import { InitiativeCountry } from 'basics/enums/country.enums';
import { SelectOption } from 'basics/options/options.types';
import useExportFormConfig from 'modules/maya/components/ExportForm/ExportForm.config';
import { useYodaCreateForm } from 'services/yodaForm';

const useExportForm = (setStatus: Dispatch<string>) => {
  const { providerFields } = useYodaCreateForm();
  const { exportFormConfig } = useExportFormConfig();
  const [countrySelected, setCountrySelected] = useState<[InitiativeCountry]>();

  const handleChangeCountrySelected = useCallback((event: SyntheticEvent<Element, Event>, selectedCountry: SelectOption[]) => {
    const countries = selectedCountry.map((country) => country.id) as [InitiativeCountry];
    setCountrySelected(countries);
    setStatus('pending');
  }, [setStatus, setCountrySelected]);

  return {
    countrySelected,
    providerFields,
    exportFormConfig,
    handleChangeCountrySelected,
  };
};

export default useExportForm;
