import { FC } from 'react';
import GridList from 'modules/maya/components/GridList/GridList';
import MayaLayout from 'modules/maya/components/MayaLayout';
import { CepListFilters } from 'modules/maya/routes/CepList/CepList.style';
import useCepTrashedList from 'modules/maya/routes/CepTrashedList/CepTrashedList.hook';
import { useYodaCreateForm, YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import TextYoda from 'yoda-ui/Form/TextYoda';

const CepTrashedList: FC = () => {
  const { providerFields } = useYodaCreateForm();

  const {
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    debouncedHandleChangeCepTitle,
    getFilteredRows,
    menuCustomerEngagementPlanningLabel,
    onPageChange,
    onPageSizeChange,
    rowCountState,
  } = useCepTrashedList();

  return (
    <MayaLayout>
      <GridList
        loading={ cepLoading }
        getRowId={ (row) => row._id }
        title={ menuCustomerEngagementPlanningLabel }
        columnConfig={ cepListColumnConfig }
        rows={ getFilteredRows() }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box sx={ CepListFilters }>
                <TextYoda
                  onChange={ debouncedHandleChangeCepTitle }
                  { ...cepTitleFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
        disableColumnMenu={ true }
      />
    </MayaLayout>
  );
};

export default CepTrashedList;
