import { array, object, string } from 'yup';

export const maxTestimonyLength = 200;
const useAgendaGroupSchema = () => {
  return {
    agenda: array()
      .nullable(true)
      .of(object().shape({
        dateRange: string().required(),
        description: string(),
        endTime: string().required(),
        startTime: string().required(),
        title: string().required(),
      })),
  };
};

export default useAgendaGroupSchema;
