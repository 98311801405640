export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  ObjectId: any;
  Upload: any;
};

export type Accommodation = {
  __typename?: 'Accommodation';
  endDate: Scalars['DateTime'];
  hotel: Hotel;
  startDate: Scalars['DateTime'];
  status: RequestStatus;
  transfer: Transfer;
};

export type AccommodationInput = {
  endDate: Scalars['DateTime'];
  hotel: HotelInput;
  startDate: Scalars['DateTime'];
  status: RequestStatus;
  transfer: TransferInput;
};

export type AgendaEntry = {
  __typename?: 'AgendaEntry';
  description?: Maybe<Scalars['String']>;
  session: Session;
  title: Scalars['String'];
};

export type AgendaEntryInput = {
  description?: InputMaybe<Scalars['String']>;
  session: SessionInput;
  title: Scalars['String'];
};

export type AgentEvent = {
  __typename?: 'AgentEvent';
  agentId: Scalars['String'];
  agentType: AgentType;
  events: Array<Event>;
};

export enum AgentType {
  guest = 'guest',
  janrainGroup = 'janrainGroup',
  janrainUser = 'janrainUser',
  oktaUser = 'oktaUser'
}

export enum AssetTarget {
  cardImage = 'cardImage',
  detailsImage = 'detailsImage',
  hotelBooking = 'hotelBooking',
  logoImage = 'logoImage',
  participantCsv = 'participantCsv',
  programLogo = 'programLogo',
  speakerImage = 'speakerImage',
  teaser = 'teaser',
  transportTicket = 'transportTicket'
}

export enum AssetType {
  csv = 'csv',
  document = 'document',
  image = 'image',
  video = 'video'
}

export type AssetsByTarget = {
  __typename?: 'AssetsByTarget';
  cardImage?: Maybe<Array<AssetsEvent>>;
  teaser?: Maybe<Array<AssetsEvent>>;
};

export type AssetsByTargetInput = {
  cardImage?: InputMaybe<Array<Scalars['String']>>;
  teaser?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetsEvent = {
  __typename?: 'AssetsEvent';
  _id: Scalars['String'];
  assetTarget?: Maybe<AssetTarget>;
  assetType?: Maybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type AssetsEventInput = {
  _id: Scalars['String'];
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  bucket: Scalars['String'];
  eTag: Scalars['String'];
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  size: Scalars['Float'];
  url: Scalars['String'];
};

export type BiogenLincConfig = {
  __typename?: 'BiogenLincConfig';
  enableGuestRegistration?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isVisibleOnBiogenLinc?: Maybe<Scalars['Boolean']>;
  prcCodeEventDetails?: Maybe<Scalars['String']>;
  prcCodeEventsListCard?: Maybe<Scalars['String']>;
  registration?: Maybe<BiogenLincConfigRegistration>;
  restrictedTo?: Maybe<Array<Scalars['String']>>;
  showSendInvitations?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
};

export type BiogenLincConfigInput = {
  enableGuestRegistration?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isVisibleOnBiogenLinc?: InputMaybe<Scalars['Boolean']>;
  prcCodeEventDetails?: InputMaybe<Scalars['String']>;
  prcCodeEventsListCard?: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<BiogenLincConfigRegistrationInput>;
  restrictedTo?: InputMaybe<Array<Scalars['String']>>;
  showSendInvitations?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type BiogenLincConfigRegistration = {
  __typename?: 'BiogenLincConfigRegistration';
  limitSeats: Scalars['Boolean'];
  registerBeforeDays: Scalars['Float'];
  validate: Scalars['Boolean'];
};

export type BiogenLincConfigRegistrationInput = {
  limitSeats: Scalars['Boolean'];
  registerBeforeDays: Scalars['Float'];
  validate: Scalars['Boolean'];
};

export type Booking = {
  __typename?: 'Booking';
  _id: Scalars['String'];
  end: Scalars['DateTime'];
  event: Event;
  format: Format;
  hostProvider: HostProvider;
  license: Scalars['String'];
  start: Scalars['DateTime'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  topic: Scalars['String'];
};

export type ContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  topic: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  _id?: Maybe<Scalars['String']>;
  contentfulUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<EventPresence>;
  description?: Maybe<Scalars['String']>;
  externalFormat: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  externalPresence: Scalars['String'];
  externalStatus: Scalars['String'];
  format?: Maybe<Format>;
  locale?: Maybe<Scalars['String']>;
  market: Market;
  owner?: Maybe<OktaPublicUserInfo>;
  presence: Presence;
  source?: Maybe<Source>;
  status: EventStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventInput = {
  description?: InputMaybe<Scalars['String']>;
  externalFormat: Scalars['String'];
  externalPresence: Scalars['String'];
  externalStatus: Scalars['String'];
  format: Format;
  market: Market;
  owner: OktaPublicUserInfoInput;
  presence: Presence;
  title: Scalars['String'];
};

export type EventPresence = HybridEvent | OnlineEvent | PhysicalEvent;

export enum EventStatus {
  draft = 'draft',
  published = 'published',
  ready = 'ready'
}

export type EventsFilterInput = {
  format?: InputMaybe<Format>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  isPast?: InputMaybe<Array<Scalars['Boolean']>>;
  market?: InputMaybe<Market>;
  presence?: InputMaybe<Presence>;
  sessionFilter?: InputMaybe<SessionFilterInput>;
  source?: InputMaybe<Source>;
  status?: InputMaybe<Array<EventStatus>>;
};

export type FileDataInput = {
  assetTarget?: InputMaybe<AssetTarget>;
  assetType?: InputMaybe<AssetType>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export enum Format {
  advisoryBoard = 'advisoryBoard',
  biogenMeeting = 'biogenMeeting',
  congress = 'congress',
  liveStreaming = 'liveStreaming',
  meeting = 'meeting',
  onDemand = 'onDemand',
  peerToPeer = 'peerToPeer',
  standalone = 'standalone',
  symposium = 'symposium',
  webinar = 'webinar'
}

export enum HostProvider {
  other = 'other',
  zoom = 'zoom'
}

export type Hotel = {
  __typename?: 'Hotel';
  assetId: Scalars['String'];
  contactPhone: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  website: Scalars['String'];
};

export type HotelInput = {
  assetId: Scalars['String'];
  contactPhone: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  website: Scalars['String'];
};

export type HybridEvent = {
  __typename?: 'HybridEvent';
  eventPresence: Presence;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  session?: Maybe<Session>;
};

export type Journey = {
  __typename?: 'Journey';
  arrivalDate: Scalars['DateTime'];
  arrivalPlace: Scalars['String'];
  assets: Array<Scalars['String']>;
  departureDate: Scalars['DateTime'];
  departurePlace: Scalars['String'];
  order: Scalars['Float'];
  transportType: TransportType;
};

export type JourneyInput = {
  arrivalDate: Scalars['DateTime'];
  arrivalPlace: Scalars['String'];
  assets: Array<Scalars['String']>;
  departureDate: Scalars['DateTime'];
  departurePlace: Scalars['String'];
  order: Scalars['Float'];
  transportType: TransportType;
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String'];
  country: Scalars['String'];
  number: Scalars['String'];
  placeName: Scalars['String'];
  street: Scalars['String'];
};

export type LocationInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  number: Scalars['String'];
  placeName: Scalars['String'];
  street: Scalars['String'];
};

export enum Market {
  at = 'at',
  au = 'au',
  be = 'be',
  br = 'br',
  ca = 'ca',
  com = 'com',
  de = 'de',
  es = 'es',
  fr = 'fr',
  gb = 'gb',
  ie = 'ie',
  jp = 'jp',
  mx = 'mx',
  pt = 'pt',
  uk = 'uk'
}

export type Mutation = {
  __typename?: 'Mutation';
  bookSlot?: Maybe<Scalars['Boolean']>;
  cancelParticipantRegistration: Scalars['Boolean'];
  createEvent: Event;
  createOrUpdateUserData: UserData;
  createParticipantsFromCSV: Array<Participant>;
  createProgram: Program;
  createSpeaker: Speaker;
  publishOnlineEvent: Event;
  publishPhysicalEvent: Event;
  registerParticipant: Scalars['Boolean'];
  removeParticipant: Scalars['Boolean'];
  sendOnlineEventInvitation: Scalars['Boolean'];
  sendPhysicalEventInvitation: Scalars['Boolean'];
  updateOnlineEvent: Event;
  updatePhysicalEvent: Event;
  updateSpeaker: Speaker;
  uploadFiles: Array<AssetsEvent>;
};


export type MutationBookSlotArgs = {
  eventId: Scalars['String'];
  session: SessionInput;
};


export type MutationCancelParticipantRegistrationArgs = {
  participant: RegisterParticipantInput;
};


export type MutationCreateEventArgs = {
  event: EventInput;
};


export type MutationCreateOrUpdateUserDataArgs = {
  userData: UserDataInput;
};


export type MutationCreateParticipantsFromCsvArgs = {
  eventId: Scalars['String'];
  file: Scalars['Upload'];
  fileDataInput: FileDataInput;
};


export type MutationCreateProgramArgs = {
  program: ProgramInput;
};


export type MutationCreateSpeakerArgs = {
  speaker: SpeakerInput;
};


export type MutationPublishOnlineEventArgs = {
  onlineEventInput: OnlineEventInput;
};


export type MutationPublishPhysicalEventArgs = {
  physicalEventInput: PhysicalEventInput;
};


export type MutationRegisterParticipantArgs = {
  participant: RegisterParticipantInput;
};


export type MutationRemoveParticipantArgs = {
  eventId: Scalars['String'];
  mdmId: Scalars['String'];
};


export type MutationSendOnlineEventInvitationArgs = {
  onlineEventId: Scalars['String'];
};


export type MutationSendPhysicalEventInvitationArgs = {
  physicalEventId: Scalars['String'];
};


export type MutationUpdateOnlineEventArgs = {
  onlineEventFormat?: InputMaybe<Format>;
  onlineEventInput: OnlineEventInput;
};


export type MutationUpdatePhysicalEventArgs = {
  physicalEventFormat?: InputMaybe<Format>;
  physicalEventInput: PhysicalEventInput;
};


export type MutationUpdateSpeakerArgs = {
  speakerInput: UpdatedSpeakerInput;
};


export type MutationUploadFilesArgs = {
  assetsArePublic?: InputMaybe<Scalars['Boolean']>;
  files: Array<Scalars['Upload']>;
  filesDataInput: Array<FileDataInput>;
  prefix: Scalars['String'];
};

export type OktaPublicUserInfo = {
  __typename?: 'OktaPublicUserInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OktaPublicUserInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type OktaUserInfo = {
  __typename?: 'OktaUserInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type OktaUserInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type OnlineEvent = {
  __typename?: 'OnlineEvent';
  agenda?: Maybe<Array<AgendaEntry>>;
  assets?: Maybe<AssetsByTarget>;
  biogenLincConfig?: Maybe<BiogenLincConfig>;
  biogenLogo?: Maybe<Scalars['Boolean']>;
  buttonColor?: Maybe<Scalars['String']>;
  coOrganizers?: Maybe<Array<OktaPublicUserInfo>>;
  collectParticipantPhoneNumber?: Maybe<Scalars['Boolean']>;
  disclaimerText?: Maybe<Scalars['String']>;
  emailFooterNote?: Maybe<Scalars['String']>;
  emailHeaderNote?: Maybe<Scalars['String']>;
  enableZoomStreaming?: Maybe<Scalars['Boolean']>;
  eventPresence: Presence;
  invitationSent?: Maybe<Scalars['Boolean']>;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  participantsSupportPhoneNumber?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  programLogoUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  recordingOptinLabel?: Maybe<Scalars['String']>;
  session?: Maybe<Session>;
  speakerSupportPhoneNumber?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Speaker>>;
  testimonials?: Maybe<Array<Testimonial>>;
};

export type OnlineEventInput = {
  agenda?: InputMaybe<Array<AgendaEntryInput>>;
  assets?: InputMaybe<AssetsByTargetInput>;
  biogenLincConfig?: InputMaybe<BiogenLincConfigInput>;
  biogenLogo?: InputMaybe<Scalars['Boolean']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  coOrganizers?: InputMaybe<Array<OktaPublicUserInfoInput>>;
  collectParticipantPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  contentfulUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterNote?: InputMaybe<Scalars['String']>;
  emailHeaderNote?: InputMaybe<Scalars['String']>;
  enableZoomStreaming?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  participantsSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  productInformationButtonLabel?: InputMaybe<Scalars['String']>;
  productInformationButtonLink?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  programLogoUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<ProviderInput>;
  recordingOptinLabel?: InputMaybe<Scalars['String']>;
  speakerSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<Scalars['String']>>;
  testimonials?: InputMaybe<Array<TestimonialInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type Participant = {
  __typename?: 'Participant';
  accommodation?: Maybe<Accommodation>;
  accountId?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Scalars['String']>>;
  collectedPhoneNumber?: Maybe<Scalars['String']>;
  cxEmailSent?: Maybe<Scalars['Boolean']>;
  doctorId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  externalEventParticipantStatus?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  institution?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  mdmId?: Maybe<Scalars['String']>;
  registrationEmailSent?: Maybe<Scalars['Boolean']>;
  registrationStatusHistory: RegistrationStatusHistory;
  reminder1h?: Maybe<Scalars['Boolean']>;
  reminder12h?: Maybe<Scalars['Boolean']>;
  reminder24h?: Maybe<Scalars['Boolean']>;
  reminder48h?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  surveyLink?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  transportationRequests?: Maybe<Array<TransportationRequest>>;
  uniqueLink?: Maybe<Scalars['String']>;
};

export type PhysicalEvent = {
  __typename?: 'PhysicalEvent';
  agenda?: Maybe<Array<AgendaEntry>>;
  assets?: Maybe<AssetsByTarget>;
  biogenLincConfig?: Maybe<BiogenLincConfig>;
  biogenLogo?: Maybe<Scalars['Boolean']>;
  buttonColor?: Maybe<Scalars['String']>;
  coOrganizers?: Maybe<Array<OktaPublicUserInfo>>;
  collectParticipantPhoneNumber?: Maybe<Scalars['Boolean']>;
  disclaimerText?: Maybe<Scalars['String']>;
  emailFooterNote?: Maybe<Scalars['String']>;
  emailHeaderNote?: Maybe<Scalars['String']>;
  eventLocation?: Maybe<Scalars['String']>;
  eventPresence: Presence;
  invitationSent?: Maybe<Scalars['Boolean']>;
  participant?: Maybe<Participant>;
  participants?: Maybe<Array<Participant>>;
  participantsSupportPhoneNumber?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  productInformationButtonLabel?: Maybe<Scalars['String']>;
  productInformationButtonLink?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  programLogoUrl?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Float']>;
  session?: Maybe<Session>;
  speakerSupportPhoneNumber?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<Speaker>>;
  testimonials?: Maybe<Array<Testimonial>>;
};

export type PhysicalEventInput = {
  agenda?: InputMaybe<Array<AgendaEntryInput>>;
  assets?: InputMaybe<AssetsByTargetInput>;
  biogenLincConfig?: InputMaybe<BiogenLincConfigInput>;
  biogenLogo?: InputMaybe<Scalars['Boolean']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  coOrganizers?: InputMaybe<Array<OktaPublicUserInfoInput>>;
  collectParticipantPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  contentfulUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  emailFooterNote?: InputMaybe<Scalars['String']>;
  emailHeaderNote?: InputMaybe<Scalars['String']>;
  eventLocation?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  participantsSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  productInformationButtonLabel?: InputMaybe<Scalars['String']>;
  productInformationButtonLink?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  programLogoUrl?: InputMaybe<Scalars['String']>;
  seats?: InputMaybe<Scalars['Float']>;
  session?: InputMaybe<SessionInput>;
  speakerSupportPhoneNumber?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<Scalars['String']>>;
  testimonials?: InputMaybe<Array<TestimonialInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export enum Presence {
  hybrid = 'hybrid',
  online = 'online',
  physical = 'physical'
}

export type Profile = {
  __typename?: 'Profile';
  default?: Maybe<ProfileByMarket>;
};

export type ProfileByMarket = {
  __typename?: 'ProfileByMarket';
  affiliation?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  _id: Scalars['String'];
  programLogo?: Maybe<AssetsEvent>;
  programLogoUrl?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
};

export type ProgramInput = {
  programLogo?: InputMaybe<Scalars['String']>;
  programLogoUrl?: InputMaybe<Scalars['String']>;
  programName?: InputMaybe<Scalars['String']>;
};

export type Provider = {
  __typename?: 'Provider';
  endWithGap?: Maybe<Scalars['DateTime']>;
  host: HostProvider;
  licenseId?: Maybe<Scalars['String']>;
  organizerUrl?: Maybe<Scalars['String']>;
  participantUrl?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  providedId?: Maybe<Scalars['String']>;
  startWithGap?: Maybe<Scalars['DateTime']>;
};

export type ProviderInput = {
  endWithGap?: InputMaybe<Scalars['DateTime']>;
  host: HostProvider;
  licenseId?: InputMaybe<Scalars['String']>;
  organizerUrl?: InputMaybe<Scalars['String']>;
  participantUrl?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  providedId?: InputMaybe<Scalars['String']>;
  startWithGap?: InputMaybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  exportEventParticipantData: StreamableBuffer;
  findSpeakers: Array<Speaker>;
  getEventById?: Maybe<Event>;
  getEvents: Array<Event>;
  getFullPrivateEventById?: Maybe<Event>;
  getFullPrivateEvents: Array<Event>;
  getParticipantByMdmId: Participant;
  getPossibleOrganizers: Array<OktaPublicUserInfo>;
  getPrograms: Array<Program>;
  getScreenshots: Scalars['String'];
  getSurveyLink?: Maybe<Scalars['String']>;
  getUserData?: Maybe<UserData>;
  getZoomSignature: Scalars['String'];
  importEventsFromVeeva?: Maybe<Array<Event>>;
};


export type QueryExportEventParticipantDataArgs = {
  eventId: Scalars['String'];
};


export type QueryFindSpeakersArgs = {
  lookup: Scalars['String'];
};


export type QueryGetEventByIdArgs = {
  eventId: Scalars['ObjectId'];
  filter?: InputMaybe<EventsFilterInput>;
};


export type QueryGetEventsArgs = {
  filter?: InputMaybe<EventsFilterInput>;
};


export type QueryGetFullPrivateEventByIdArgs = {
  eventId: Scalars['ObjectId'];
};


export type QueryGetFullPrivateEventsArgs = {
  isPast?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Source>;
};


export type QueryGetParticipantByMdmIdArgs = {
  mdmId: Scalars['String'];
};


export type QueryGetPossibleOrganizersArgs = {
  name: Scalars['String'];
};


export type QueryGetScreenshotsArgs = {
  eventId: Scalars['ObjectId'];
};


export type QueryGetSurveyLinkArgs = {
  eventId: Scalars['String'];
};


export type QueryGetZoomSignatureArgs = {
  meetingNumber: Scalars['String'];
};


export type QueryImportEventsFromVeevaArgs = {
  filter?: InputMaybe<EventsFilterInput>;
  source?: InputMaybe<Source>;
};

export type RegisterParticipantInput = {
  collectedPhoneNumber?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  eventId: Scalars['String'];
  firstName: Scalars['String'];
  institution?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mdmId?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

export type RegistrationStatusHistory = {
  __typename?: 'RegistrationStatusHistory';
  accepted?: Maybe<Scalars['DateTime']>;
  invited?: Maybe<Scalars['DateTime']>;
  rejected?: Maybe<Scalars['DateTime']>;
  requested?: Maybe<Scalars['DateTime']>;
  waitingList?: Maybe<Scalars['DateTime']>;
};

export type RegistrationStatusHistoryInput = {
  accepted?: InputMaybe<Scalars['DateTime']>;
  invited?: InputMaybe<Scalars['DateTime']>;
  rejected?: InputMaybe<Scalars['DateTime']>;
  requested?: InputMaybe<Scalars['DateTime']>;
  waitingList?: InputMaybe<Scalars['DateTime']>;
};

export enum RequestStatus {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected'
}

export type Session = {
  __typename?: 'Session';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  timeZone: Scalars['String'];
};

export type SessionFilterInput = {
  /** Filter events which end date is after/equal to provided date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which end date is before/equal to provided date. */
  endsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which start date is after/equal to provided date. */
  startsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Filter events which start date is before/equal to provided date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
};

export type SessionInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  timeZone: Scalars['String'];
};

export enum Source {
  backoffice = 'backoffice',
  veeva = 'veeva'
}

export type Speaker = {
  __typename?: 'Speaker';
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  image?: Maybe<Array<AssetsEvent>>;
  lastName: Scalars['String'];
  profile?: Maybe<Profile>;
  title?: Maybe<Scalars['String']>;
};

export type SpeakerInput = {
  affiliation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type StreamableBuffer = {
  __typename?: 'StreamableBuffer';
  buffer?: Maybe<Scalars['String']>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  author: Scalars['String'];
  id: Scalars['String'];
  testimony: Scalars['String'];
};

export type TestimonialInput = {
  author: Scalars['String'];
  id: Scalars['String'];
  testimony: Scalars['String'];
};

export type Transfer = {
  __typename?: 'Transfer';
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  departurePlace: Scalars['String'];
};

export type TransferInput = {
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  departurePlace: Scalars['String'];
};

export enum TransportType {
  boat = 'boat',
  bus = 'bus',
  cab = 'cab',
  car = 'car',
  plane = 'plane',
  train = 'train'
}

export type TransportationRequest = {
  __typename?: 'TransportationRequest';
  departureJourney: Journey;
  returnJourney: Journey;
  status: RequestStatus;
};

export type TransportationRequestInput = {
  departureJourney: JourneyInput;
  returnJourney: JourneyInput;
  status: RequestStatus;
};

export type UpdateEventInput = {
  id: Scalars['String'];
};

export type UpdatedSpeakerInput = {
  _id: Scalars['String'];
  affiliation?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserData = {
  __typename?: 'UserData';
  market?: Maybe<Market>;
  userId: Scalars['String'];
};

export type UserDataInput = {
  market?: InputMaybe<Market>;
};
