import { Dispatch, FC, SetStateAction } from 'react';
import useAddProgramModal from './AddProgramModal.hook';
import DialogForm from 'components/DialogForm';
import CardImage from 'modules/events/components/CardImage';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { YodaFontSize, YodaSpacing, YodaFontWeight } from 'yoda-ui/yodaTheme';

type AddProgramModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AddProgramModal: FC<AddProgramModalProps> = ({ open, setOpen }) => {
  const {
    addProgramConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    handleDropImage,
    t,
  } = useAddProgramModal(setOpen);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('events_add_program_form_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
      >
        <Box fontSize={ YodaFontSize.xLarge }>
          <TextYoda
            { ...addProgramConfig.programName }
          />
        </Box>
        <Box fontWeight={ YodaFontWeight.light } paddingTop={ YodaSpacing.large }>
          <CardImage
            handleFiles={ handleDropImage }
            { ...addProgramConfig.programLogo }
          />
        </Box>
      </DialogForm>
    </YodaFormProvider>
  );
};

export default AddProgramModal;
