import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Agenda from 'modules/events/components/Agenda';
import useAgenda from 'modules/events/groups/AgendaGroup/AgendaGroup.hook';

const AgendaGroup: FC = () => {
  const { t } = useTranslation();
  const { formConfig } = useAgenda();

  return (
    <FormGroup title={ t('events.agendaGroupTitle') } groupName={ CommonGroups.agenda }>
      <Agenda { ...formConfig.agenda } />
    </FormGroup>
  );
};

export default AgendaGroup;
