export enum ParticipantsItemCollapseFields {
  accountId = 'accountId',
  collectedPhoneNumber = 'collectedPhoneNumber',
  doctorId = 'doctorId',
  institution = 'institution',
  invitationDate = 'invitationDate',
  mdmId = 'mdmId',
  phone = 'phone',
  specialty = 'specialty',
  zoomCallLink = 'zoomCallLink',
  registrationEmailSent = 'registrationEmailSent',
  reminder48h = 'reminder48h',
  reminder24h = 'reminder24h',
  reminder12h = 'reminder12h',
  reminder1h = 'reminder1h',
  cxEmailSent = 'cxEmailSent',
}
