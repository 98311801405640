import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { warningToast } from 'basics/utils/toast';
import { TargetsFacets } from 'generated/maya.types';
import { targetsFilterToBE } from 'modules/maya/basics/transformers/Target.transformer';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useDynamicTargetListConfig from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.config';
import { useCreateVeevaTargetsMutation } from 'modules/maya/graphql/mutations/createVeevaTargets';
import useGetTargetsFacets from 'modules/maya/graphql/queries/getTargetsFacets';
import useGetTargetsFacetsLazy from 'modules/maya/graphql/queries/getTargetsFacetsLazy';
import cepState from 'modules/maya/states/cep.states';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useDynamicTargetList = (
  updateTargetList: () => Promise<void>,
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,

) => {
  const { t } = useTranslation();
  const { getValues, providerFields, useWatchForm } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const defaultCountries = cepStateValue.cep?.targetFilter?.countries
  || (cepStateValue.cep?.customerInitiative?.country ? [cepStateValue.cep?.customerInitiative?.country] : []);

  const [country, setCountry] = useState<string | null>(defaultCountries[0] || null);
  const [businessUnit, setBusinessUnit] = useState<string | null>(
    cepStateValue.cep?.targetFilter?.businessUnits ? cepStateValue.cep?.targetFilter?.businessUnits[0] : null,
  );

  const { targetsFacets, loading: targetsFacetsLoading } = useGetTargetsFacets({});
  const { queryGetTargetsFacets, data: filteredTargetsFacets, loading: filteredTargetsFacetsLoading } = useGetTargetsFacetsLazy();

  useEffect(() => {
    if (country && businessUnit) {
      queryGetTargetsFacets({ variables: {
        filters: { countries: [country], businessUnits: [businessUnit] },
      } });
    }
  }, [businessUnit, country, queryGetTargetsFacets]);

  const {
    dynamicTargetListConfig,
  } = useDynamicTargetListConfig(
    targetsFacets as TargetsFacets,
    filteredTargetsFacets as TargetsFacets,
    { ...cepStateValue.cep?.targetFilter, countries: defaultCountries } || {},
    setCountry,
    setBusinessUnit,
  );

  const [createVeevaTargetsMutation] = useCreateVeevaTargetsMutation();

  const handleSubmit = async () => {
    setLoading(true);

    const formattedFilters = targetsFilterToBE(getValues());

    const { error } = await createVeevaTargetsMutation(cepStateValue.cep?._id as string, formattedFilters);

    if (!error) {
      updateTargetList();
    } else {
      warningToast(error);
    }

    setLoading(false);
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSubmit,
      buttonType: ButtonType.primary,
      disabled: loading || !isValid,
    },
    label: t('actions_search_label'),
    loading,
  };

  return {
    dynamicTargetListConfig,
    providerFields,
    submitButtonConfig,
    targetsFacetsLoading,
    filteredTargetsFacetsLoading,
  };
};

export default useDynamicTargetList;
