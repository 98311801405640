import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { Tactic, TacticInput } from 'generated/maya.types';
import { Channel } from 'modules/maya/basics/enums/maya.enums';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';
import { DateRange } from 'yoda-ui/Form/DateRangePicker/DateRangePicker.types';

export const tacticToBE = (data: YodaFieldValue, cepId: string | undefined): Partial<TacticInput> => {
  return {
    title: data.title,
    channel: data.channel,
    productionOwner: data.productionOwner,
    ...transformInputDateUTC(data.date),
    cep: cepId,
    executingTeam: data.executingTeam,
    description: data.description,
    mandatory: data.mandatory,
    includeConsent: data.includeConsent,
    previousTactic: data.previousTactic || null,
    parentTactic: data.parentTactic || null,
    assetId: data.assetId,
    assetName: data.assetName,
    callToAction: data.callToAction,
    clickUrl: data.clickUrl,
    reachMetric: data.reachMetric,
    reachValue: parseInt(data?.reachValue, 10) || undefined,
    engagementMetric: data.engagementMetric,
    engagementValue: parseInt(data?.engagementValue, 10) || undefined,
    keyMessage: data.keyMessage,
    parentEvent: data.parentEvent,
    approvedDocumentId: data.approvedDocumentId,
    eventId: data.eventId,
    actionOrder: !Number.isNaN(parseInt(data?.actionOrder, 10)) ? parseInt(data?.actionOrder, 10) : undefined,
    destinationUrl: data.destinationUrl,
  };
};

export const tacticToForm = (
  tactic: Tactic | undefined | null,
) => {
  const dateStart = new Date(tactic?.dateStart as string);
  const dateStartUTC = new Date(
    dateStart.getUTCFullYear(),
    dateStart.getUTCMonth(),
    dateStart.getUTCDate(),
    dateStart.getUTCHours(),
    dateStart.getUTCMinutes(),
    dateStart.getUTCSeconds(),
  );

  const dateEnd = new Date(tactic?.dateEnd as string);
  const dateEndUTC = new Date(
    dateEnd.getUTCFullYear(),
    dateEnd.getUTCMonth(),
    dateEnd.getUTCDate(),
    dateEnd.getUTCHours(),
    dateEnd.getUTCMinutes(),
    dateEnd.getUTCSeconds(),
  );

  const previousTactic = tactic?.previousTactic as Tactic;
  const parentTactic = tactic?.parentTactic as Tactic;
  return {
    title: tactic?.title || '',
    channel: tactic?.channel ? Channel[tactic.channel as unknown as keyof typeof Channel] : undefined,
    productionOwner: tactic?.productionOwner || '',
    date: (tactic?.dateStart && tactic?.dateEnd) ? [dateStartUTC, dateEndUTC] as DateRange : undefined,
    description: tactic?.description,
    cep: tactic?.cep || {},
    executingTeam: tactic?.executingTeam || '',
    mandatory: tactic?.mandatory || false,
    includeConsent: tactic?.includeConsent !== undefined ? tactic?.includeConsent : true,
    previousTactic: previousTactic?._id || null,
    parentTactic: parentTactic?._id || null,
    assetId: tactic?.assetId,
    assetName: tactic?.assetName,
    callToAction: tactic?.callToAction,
    clickUrl: tactic?.clickUrl,
    reachMetric: tactic?.reachMetric,
    reachValue: tactic?.reachValue?.toString(),
    engagementMetric: tactic?.engagementMetric,
    engagementValue: tactic?.engagementValue?.toString(),
    keyMessage: tactic?.keyMessage || '',
    parentEvent: tactic?.parentEvent || false,
    approvedDocumentId: tactic?.approvedDocumentId || '',
    eventId: tactic?.eventId || '',
    actionOrder: tactic?.actionOrder?.toString(),
    destinationUrl: tactic?.destinationUrl || '',
  };
};

