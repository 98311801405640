import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useEmailNotesSchema from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.schema';
import { ColorOption, EmailNotesType } from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.types';
import eventState from 'modules/events/states/events.states';

const useEmailNotesConfig = () => {
  const { t } = useTranslation();
  const emailNotesSchema = useEmailNotesSchema();
  const snap = useSnapshot(eventState) as EventStateType;

  const colorOptions: ColorOption[] = [
    {
      colorHex: '#122035',
      colorLabel: 'BiogenLinc BG',
    },
    {
      colorHex: '#1F49E0',
      colorLabel: 'BiogenLinc 1',
    },
    {
      colorHex: '#F5F2EC',
      colorLabel: 'BiogenLinc 2',
    },
    {
      colorHex: '#223867',
      colorLabel: 'Spinraza BG',
    },
    {
      colorHex: '#028CA6',
      colorLabel: 'Spinraza 1',
    },
    {
      colorHex: '#EF3D33',
      colorLabel: 'Spinraza 2',
    },
    {
      colorHex: '#007EB5',
      colorLabel: 'Tysabri BG',
    },
    {
      colorHex: '#009CDE',
      colorLabel: 'Tysabri 1',
    },
    {
      colorHex: '#FF671F',
      colorLabel: 'Tysabri 2',
    },
    {
      colorHex: '#80276C',
      colorLabel: 'Tecfidera BG',
    },
    {
      colorHex: '#FF671F',
      colorLabel: 'Tecfidera 2',
    },
    {
      colorHex: '#003865',
      colorLabel: 'Vumerity BG',
    },
    {
      colorHex: '#007FA3',
      colorLabel: 'Vumerity 1',
    },
    {
      colorHex: '#CE0058',
      colorLabel: 'Vumerity 2',
    },
    {
      colorHex: '#522398',
      colorLabel: 'Avonex BG',
    },
    {
      colorHex: '#C60C30',
      colorLabel: 'Avonex 2',
    },
    {
      colorHex: '#0076D6',
      colorLabel: 'Plegridy BG',
    },
    {
      colorHex: '#F28420',
      colorLabel: 'Plegridy 1',
    },
    {
      colorHex: '#007257',
      colorLabel: 'Plegridy 2',
    },
    {
      colorHex: '#387C2B',
      colorLabel: 'Fampyra BG',
    },
    {
      colorHex: '#E36F1E',
      colorLabel: 'Fampyra 2',
    },
    {
      colorHex: '#4A4C9A',
      colorLabel: 'Benepali BG',
    },
    {
      colorHex: '#00C4B4',
      colorLabel: 'Benepali 2',
    },
    {
      colorHex: '#00873A',
      colorLabel: 'Imraldi BG',
    },
    {
      colorHex: '#009D4E',
      colorLabel: 'Imraldi 1',
    },
    {
      colorHex: '#0032A0',
      colorLabel: 'Imraldi 2',
    },
    {
      colorHex: '#D53F1F',
      colorLabel: 'Flixabi BG',
    },
    {
      colorHex: '#E65400',
      colorLabel: 'Flixabi 1',
    },
    {
      colorHex: '#00205C',
      colorLabel: 'Flixabi 2',
    },
    {
      colorHex: '#2573BA',
      colorLabel: 'Byooviz BG',
    },
    {
      colorHex: '#5AB4E4',
      colorLabel: 'Byooviz 1',
    },
    {
      colorHex: '#FAA807',
      colorLabel: 'Byooviz 2',
    },
  ];

  const emailNotesConfig: EmailNotesType = {
    emailHeaderNote: {
      label: t('events_email_header_note_label'),
      name: commonFields.emailHeaderNote,
      placeholder: t('events_email_header_note_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.emailHeaderNote,
      validation: emailNotesSchema.emailHeaderNote,
    },
    emailFooterNote: {
      label: t('events_email_footer_note_label'),
      name: commonFields.emailFooterNote,
      placeholder: t('events_email_footer_note_placeholder'),
      required: false,
      defaultValue: snap.createEvent.data?.emailFooterNote,
      validation: emailNotesSchema.emailFooterNote,
    },
    emailContentfulUrl: {
      label: t('events_email_contentful_url_label'),
      name: commonFields.emailContentfulUrl,
      placeholder: t('events_email_contentful_url_placeholder'),
      required: false,
      defaultValue: snap.createEvent?.contentfulUrl,
      validation: emailNotesSchema?.emailContentfulUrl,
    },
    biogenLogo: {
      label: t('events_email_biogen_logo_label'),
      name: commonFields.biogenLogo,
      checked: snap.createEvent.data?.biogenLogo,
    },
    buttonColor: {
      name: commonFields.buttonColor,
      defaultValue: snap.createEvent.data?.buttonColor || '#1F49E0',
      options: colorOptions,
    },
  };

  return {
    emailNotesConfig,
  };
};

export default useEmailNotesConfig;
