import { useCallback, useMemo } from 'react';
import useStepIndexer from 'hooks/useStepIndexer';
import useGetCepByIdQuery from 'modules/maya/graphql/queries/getCepById';
import { cepViewStepIndexer } from 'modules/maya/routes/CepView/CepView.states';
import useCepViewButtons from 'modules/maya/routes/CepView/CepViewButtons.hook';
import useCepViewStepper from 'modules/maya/routes/CepView/CepViewStepper.hook';

const useCepView = () => {
  const stepIndexer = useStepIndexer(cepViewStepIndexer);
  const { moveNextStep, movePreviousStep } = stepIndexer;

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const cep = useGetCepByIdQuery(urlParams.get('id')).data;

  const handleChangeStep = useCallback(
    (stepIndexVal: number) => {
      stepIndexer.moveToStep(stepIndexVal);
    },
    [stepIndexer],
  );

  const nextPage = useCallback(async () => {
    moveNextStep();
  }, [moveNextStep]);

  const previousPage = useCallback(() => {
    movePreviousStep();
  }, [movePreviousStep]);

  const buttonsConfig = useCepViewButtons(
    stepIndexer,
    nextPage,
    previousPage,
  );
  const stepsList = useCepViewStepper();

  return {
    buttonsConfig,
    cep,
    handleChangeStep,
    stepIndexer,
    stepsList,
  };
};

export default useCepView;
