import { TargetFilter } from 'generated/maya.types';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

export const targetsFilterToBE = (data: YodaFieldValue): Partial<TargetFilter> => {
  return {
    biosSegments: data.biosSegments.length ? data.biosSegments : null,
    businessUnits: data.businessUnit ? [data.businessUnit] : null,
    countries: data.country ? [data.country] : null,
    imsSegments: data.imsSegments.length ? data.imsSegments : null,
    smaSegments: data.smaSegments.length ? data.smaSegments : null,
    specialties: data.specialty.length ? data.specialty : null,
    types: data.type.length ? data.type : null,
  };
};

