import { ApolloError, gql, useQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_FACET_STRATEGIC_IMPERATIVES_YEARS = gql`
query GetFacetStrategicImperativesYears {
  getFacetStrategicImperativesYears {
    years
  }
}
`;

type ResultType = {
  getFacetStrategicImperativesYears: {
    years: number[];
  };
};

type UseGetFacetStrategicImperativesYears = () => {
  loading: boolean;
  error?: ApolloError;
  data: number[];
};

const useGetFacetStrategicImperativeYearsQuery: UseGetFacetStrategicImperativesYears = () => {
  const { data, loading, error } = useQuery<ResultType>(GET_FACET_STRATEGIC_IMPERATIVES_YEARS, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getFacetStrategicImperativesYears.ts - useGetFacetStrategicImperativeYearsQuery',
    });
  }

  return {
    data: data?.getFacetStrategicImperativesYears.years || [],
    loading,
  };
};

export default useGetFacetStrategicImperativeYearsQuery;
