import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddSpeakerProfileModal from './AddSpeakerProfileModal';
import { ActionButton } from 'yoda-ui/BiogenLincDS/Buttons';
import { ButtonVariant } from 'yoda-ui/BiogenLincDS/Buttons/Buttons.types';
import Box from 'yoda-ui/Box';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

const AddSpeakerProfile: FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      <ActionButton
        label={ t('events_modal_speakerprofile_open_btn_label') }
        icon={ Icons.add }
        onClick={ () => { setOpen(true); } }
        variant={ ButtonVariant.primary }
      />
      <AddSpeakerProfileModal open={ open } setOpen={ setOpen } />
    </Box>
  );
};

export default AddSpeakerProfile;
