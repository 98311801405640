import { FC } from 'react';
import BiogenLincConfigGroup from 'modules/events/groups/BiogenLincConfigGroup';
import InviteesGroup from 'modules/events/groups/InviteesGroup';
import Box from 'yoda-ui/Box';

const CommunicationStep: FC = () => (
  <Box>
    <BiogenLincConfigGroup />
    <InviteesGroup />
  </Box>
);

export default CommunicationStep;
