import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import { Event } from 'modules/events/basics/types/events.types';
import { OnlineWebinarInput } from 'modules/events/basics/types/onlineWebinar.types';
import { eventFragment } from 'modules/events/graphql/fragments/event.fragments';
import { onlineEventDataFragment } from 'modules/events/graphql/fragments/onlineEventData.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const publishOnlineWebinarMutationCall = gql`
  mutation publishOnlineWebinarMutation($onlineEventInput: OnlineEventInput!) {
    publishOnlineEvent(onlineEventInput: $onlineEventInput) {
      ${eventFragment}
      source
      data {
        ...on OnlineEvent {
          ${onlineEventDataFragment}
        }
      }
    }
  }
`;

type ResultType = {
  publishOnlineEvent: Event;
};

type UsePublishOnlineWebinarMutation = [
  (onlineEventInput: OnlineWebinarInput) => Promise<Event | undefined | null>,
  MutationResult<ResultType>,
];

export const usePublishOnlineWebinarMutation = (): UsePublishOnlineWebinarMutation => {
  const { t } = useTranslation();
  const [publishOnlineWebinar, state] = useMutation<ResultType>(publishOnlineWebinarMutationCall);
  const executeMutation = useCallback(async (onlineEventInput) => {
    try {
      const data = await publishOnlineWebinar({
        variables: { onlineEventInput },
      });
      succesToast(t('toast_publish_event_success'));
      return data.data?.publishOnlineEvent;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, onlineEventInput);
        Logger.logError({
          error,
          customContext,
          transactionName: 'publishOnlineWebinar.ts - usePublishOnlineWebinarMutation',
        });
      }
      errorToast(t('toast_publish_event_fail'));
      return null;
    }
  }, [publishOnlineWebinar, t]);

  return [executeMutation, state];
};
