import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { validateImagesDimensions } from 'basics/utils/files/files.validations';
import { HandleFiles } from 'components/QuickForm/QuickForm.types';
import useLazyGetPrograms from 'modules/events/graphql/queries/getProgramsLazy';
import usePrimaryInformationsConfig from 'modules/events/groups/PrimaryInformationsGroup/PrimaryInformationsGroup.config';
import eventState from 'modules/events/states/events.states';

const usePrimaryInformations = () => {
  const { t } = useTranslation();
  const { primaryInformationsConfig } = usePrimaryInformationsConfig();
  const { queryGetPrograms, data } = useLazyGetPrograms();

  useEffect(() => {
    queryGetPrograms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      eventState.programs.data = data.getPrograms;
    }
  }, [data]);

  const handleDropImage: HandleFiles = async (files) => {
    const { minWidth, minHeight } = primaryInformationsConfig.cardImage.dimensions;
    const dimensionsAreValid = await validateImagesDimensions({
      files,
      dimensions: primaryInformationsConfig.cardImage.dimensions,
    });
    if (dimensionsAreValid) {
      return { ok: true };
    }
    return {
      ok: false,
      errorMessage: `${t('cardImage_dimensions_validation_error_message', { imageWidth: minWidth, imageHeight: minHeight })}`,
    };
  };
  return {
    handleDropImage,
    primaryInformationsConfig,
  };
};

export default usePrimaryInformations;
