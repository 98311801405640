import { FC } from 'react';
import useEstimationCostStep from './EstimationCostStep.hook';
import CepEstimationCostGroup from 'modules/maya/groups/CepFormEstimationCostGroup';
import Box from 'yoda-ui/Box';

const EstimationCostStep: FC = () => {
  useEstimationCostStep();
  return (
    <Box>
      <CepEstimationCostGroup />
    </Box>
  );
};

export default EstimationCostStep;
