import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSlotBookingField, UseTimeSlotBooking } from './TimeSlotBooking.types';
import { buildDateRangePickerEndTimeOptions, buildDateRangePickerStartTimeOptions } from 'basics/options/time.options';
import { TimeSlot } from 'modules/events/basics/types/events.types';
import { useYodaCreateForm, useYodaForm } from 'services/yodaForm';

const useTimeSlotBooking = ({ onSave }: UseTimeSlotBooking) => {
  const { t } = useTranslation();
  const { setFormIsDirty: setParentFormIsDirty } = useYodaForm();

  const { formState, fieldsState, useWatchForm, getValues, useWatchField, setValue, providerFields, setFormIsDirty } = useYodaCreateForm();
  const dateRange = useWatchField(TimeSlotBookingField.dateRange)?.value;
  const startTime = useWatchField(TimeSlotBookingField.startTime)?.value;
  const { isValid: isFormValid, isDirty: isFormDirty } = useWatchForm();

  const startTimeOptions = useMemo(() => (
    buildDateRangePickerStartTimeOptions({ dateRange, startTime, setValue, fieldsState })
  ), [dateRange, setValue, startTime, fieldsState]);

  const endTimeOptions = useMemo(() => (
    buildDateRangePickerEndTimeOptions({ dateRange, startTime, setValue, fieldsState })
  ),
  [dateRange, startTime, setValue, fieldsState]);

  const handleSave = useCallback(() => {
    if (onSave) {
      const { dateRange: [startDate, endDate], ...values } = getValues() as TimeSlot;
      onSave({
        dateRange: [startDate, endDate || startDate],
        ...values,
      });
      setFormIsDirty(false);
      setParentFormIsDirty(true);
    }
  }, [getValues, onSave, setFormIsDirty, setParentFormIsDirty]);

  return {
    t,
    formState,
    fieldsState,
    isFormValid,
    handleSave,
    startTimeOptions,
    endTimeOptions,
    providerFields,
    isFormDirty,
  };
};

export default useTimeSlotBooking;
