import { useTranslation } from 'react-i18next';
import useEmailNotesConfig from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.config';

const useEmailNotes = () => {
  const { t } = useTranslation();
  const { emailNotesConfig } = useEmailNotesConfig();

  return {
    emailNotesConfig,
    t,
  };
};

export default useEmailNotes;
