import { getOffsetTime } from 'basics/options/timezone.options';
import { getShortFormatDateTimeFromIsoString, HOURS_MINUTES } from 'basics/utils/dates/dates.utils';
import { Provider, Session } from 'modules/events/basics/types/eventData.types';
import { TimeSlot } from 'modules/events/basics/types/events.types';

export const transformOutputProvider = (provider?: Provider) => ({
  provider: provider?.host,
  providerLink: provider?.participantUrl,
});

export const transformOutputSession = (sessionStored?: Session) => {
  if (!sessionStored) {
    return undefined;
  }
  const sessionState: TimeSlot = {
    dateRange: [new Date(sessionStored.startDate), new Date(sessionStored.endDate)],
    timeZone: sessionStored.timeZone,
    startTime: getShortFormatDateTimeFromIsoString(getOffsetTime(sessionStored.startDate), HOURS_MINUTES),
    endTime: getShortFormatDateTimeFromIsoString(getOffsetTime(sessionStored.endDate), HOURS_MINUTES),
  };
  return sessionState;
};
