import { cepFragment } from './cep.fragments';

export const tacticFragment = `
  _id
  tacticId
  formattedTacticId
  title
  channel
  cep {
    ${cepFragment}
  }
  dateStart
  dateEnd
  description
  executingTeam
  mandatory
  previousTactic{
    _id
    title
  }
  parentTactic{
    _id
    title
  }
  assetId
  assetName
  callToAction
  clickUrl
  keyMessage
  parentEvent
  approvedDocumentId
  status
  eventId
  actionOrder
  destinationUrl
  veevaSyncStatus
`;
