import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mayaCustomerInitiativeListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CustomerInitiative } from 'generated/maya.types';
import { CiStatus } from 'modules/maya/basics/enums/maya.enums';
import {
  CiFormCanceltCallbackType,
  CiFormSubmitCallbackType,
} from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useCreateCustomerInitiativeMutation } from 'modules/maya/graphql/mutations/createCustomerInitiative';
import useGetCustomerInitiativeByIdQuery from 'modules/maya/graphql/queries/getCustomerInitiativeById';

const useCustomerInitiativeDuplicate = () => {
  const navigate = useNavigate();

  const [createCustomerInitiative] = useCreateCustomerInitiativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [loading, setLoading] = useState(true);

  const customerInitiative = useGetCustomerInitiativeByIdQuery(urlParams.get('sourceId')).data;
  const prefix = customerInitiative?.isTemplate ? 'From' : 'Copy';
  const duplicatedCustomerInitiative = {
    ...customerInitiative,
    status: CiStatus.DRAFT,
    title: `${prefix} - ${customerInitiative?.title}`,
  } as CustomerInitiative;

  useEffect(() => {
    if (customerInitiative && loading) {
      setLoading(false);
    }
  }, [customerInitiative, loading, setLoading]);

  const handleCancelCustomerInitiativeForm: CiFormCanceltCallbackType = () => {
    navigate(mayaCustomerInitiativeListRoute);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (ci) => {
    const customerInitiativeData = await createCustomerInitiative({ ...ci, isTemplate: false });

    if (customerInitiativeData) {
      succesToast(`Customer initiative ${ci.title} created`);
      navigate(mayaCustomerInitiativeListRoute);
    } else {
      navigate(mayaCustomerInitiativeListRoute);
      warningToast(`Cannot duplicate to customer initiative ${ci.title}`);
    }
  };

  return {
    loading,
    customerInitiative: duplicatedCustomerInitiative,
    handleCancelCustomerInitiativeForm,
    handleSaveCustomerInitiativeForm,
  };
};

export default useCustomerInitiativeDuplicate;
