import { FC } from 'react';
import Box from 'yoda-ui/Box';
import Tag from 'yoda-ui/Tags/Tag';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type InfoTagProps = {
  title: string;
  field?: string;
};

const InfoTag: FC<InfoTagProps> = ({ title, field }) => (
  <Box marginBottom={ YodaSpacing.medium } fontWeight={ YodaFontWeight.medium }>
    { title }
    <Tag bgcolor={ YodaColors.blue2 } color={ YodaColors.gray5 } fontWeight={ YodaFontWeight.light }>
      { field }
    </Tag>
  </Box>
);

export default InfoTag;
