import faker from '@faker-js/faker';
import { Event } from 'modules/events/basics/types/events.types';

const { date, datatype, internet, name, phone } = faker;
const generateParticipant = () => ({
  // Base (head)
  email: internet.email(),
  firstName: name.firstName(),
  lastName: name.lastName(),
  // Extra (collapse)
  accountId: datatype.number().toString(),
  doctorId: datatype.number().toString(),
  institution: null,
  mdmId: datatype.number().toString(),
  phone: phone.phoneNumber('+33 ## ## ## ##'),
  specialty: name.jobTitle(),
  zoomCallLink: internet.url(),
  // Common
  registrationStatusHistory: {
    accepted: date.past(),
    invited: date.past(),
    requested: date.past(),
  },
});

export const MOCK_PARTICIPANTS = new Array(24).fill(null).map(generateParticipant);

export const hydrateParticipants = (event?: Event) => ({
  ...event,
  data: {
    ...event?.data,
    participants: MOCK_PARTICIPANTS,
  },
});
