import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { VeevaSyncStatusBadgeMap } from 'modules/maya/basics/constants/common.constant';
import { CepStatus, TargetListMode, VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { CepFormTargetListGroupConfigType } from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.types';
import cepState from 'modules/maya/states/cep.states';
import Tag from 'yoda-ui/Tags/Tag';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const useCepFormTargetListGroupConfig = (
  targetListMode: TargetListMode,
  selectTargetListOnChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const targetSelectRadioConfigs = [
    {
      label: t('maya_targetlist_select_dynamic'),
      key: 'dynamic_list',
      value: TargetListMode.dynamic,
      checked: targetListMode === TargetListMode.dynamic,
      disabled: isCepApproved || !flags.mayaBiogenlinc4307EnableDynamicTargeting,
    },
    {
      label: t('maya_targetlist_select_csv'),
      key: 'csv_list',
      value: TargetListMode.upload,
      checked: targetListMode === TargetListMode.upload,
      disabled: isCepApproved,
    },
  ];

  const cepFormTargetListGroupConfig: CepFormTargetListGroupConfigType = {
    selectTargetList: {
      name: 'selectTargetList',
      radioConfigs: targetSelectRadioConfigs,
      row: true,
      defaultValue: targetListMode,
      onChange: selectTargetListOnChange,
    },
  };

  const targetListConfig: GridColDef[] = [
    {
      field: 'veevaSyncStatus',
      headerName: t('maya_veeva_sync_status_header_label'),
      flex: 0.2,
      renderCell: (cellValues: GridRenderCellParams) => {
        const badge = VeevaSyncStatusBadgeMap[cellValues.row.veevaSyncStatus || VeevaSyncStatus.PENDING];
        return badge && (
          <Tag
            fitContent
            bgcolor={ badge.bgColor }
            color={ badge.color }
            fontWeight={ YodaFontWeight.medium }
            paddingY={ YodaSpacing.xxxxSmall }
            paddingX={ YodaSpacing.xSmall }
            width={ 'auto' }
            height={ '20px' }
            borderRadius={ '1rem' }
          >
            { badge.label }
          </Tag>
        );
      },
      sortable: false,
    },
    {
      field: 'mdmId',
      headerName: t('maya_targetlist_mdmid_label'),
      flex: 0.2,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'email',
      headerName: t('maya_targetlist_email_label'),
      flex: 0.3,
      sortable: false,
    },
    {
      field: 'firstName',
      headerName: t('maya_targetlist_firstname_label'),
      flex: 0.3,
      sortable: false,
    },
    {
      field: 'lastName',
      headerName: t('maya_targetlist_lastname_label'),
      flex: 0.3,
      sortable: false,
    },
  ];

  return { cepFormTargetListGroupConfig, targetListConfig };
};

export default useCepFormTargetListGroupConfig;
