import { useTranslation } from 'react-i18next';
import useAddProgramSchema from './AddProgram.schema';
import { AddProgramModalConfig } from './AddProgram.types';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

const useAddProgramConfig = () => {
  const { t } = useTranslation();
  const addProgramSchema = useAddProgramSchema();
  const addProgramConfig: AddProgramModalConfig = {
    programName: {
      label: t('events_modal_program_input_label'),
      name: 'programName',
      placeholder: t('events_modal_program_input_placeholder'),
      required: true,
      defaultValue: '',
      validation: addProgramSchema.programName,
    },
    programLogo: {
      name: 'programLogo',
      cardImageFormConfig: {
        btnLabel: t('events_modal_program_image_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 2048000,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.jpg, FileExtension.jpeg, FileExtension.png],
        message: t('events_modal_program_image_dropzone_message'),
        label: t('events_modal_program_image_dropzone_action_label'),
        name: 'image',
        assetTarget: AssetTarget.programLogo,
        assetType: AssetType.image,
        assetsArePublic: true,
      },
      defaultValue: [],
      dimensions: {
        maxHeight: 80,
        maxWidth: 80,
        minHeight: 80,
        minWidth: 80,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_image_title'),
      },
      validation: addProgramSchema.programLogo,
    },
  };

  return { addProgramConfig };
};

export default useAddProgramConfig;
