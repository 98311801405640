import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const setTacticStatusToDeletedMutationCall = gql`
  mutation setTacticStatusToDeleted($id: String!, $sendToVeeva: Boolean!) {
    setTacticStatusToDeleted(id: $id, sendToVeeva: $sendToVeeva)
  }
`;

export type MutationReturnType = {
  data: boolean;
  error: string | null;
};

type ApiResultType = {
  setTacticStatusToDeleted: boolean;
};

type UseSetTacticStatusToDeletedMutation = [
  (
    id: string,
    sendToVeeva?: boolean,
  ) => Promise<MutationReturnType>,
  MutationResult<ApiResultType>,
];

export const useSetTacticStatusToDeletedMutation = (): UseSetTacticStatusToDeletedMutation => {
  const [setTacticStatusToDeleted, state] = useMutation<ApiResultType>(setTacticStatusToDeletedMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });
  const flags = useFlags();

  const executeMutation = useCallback(async (id, sendToVeeva = true) => {
    try {
      const { data } = await setTacticStatusToDeleted({
        variables: {
          id,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816 && sendToVeeva,
        },
      });
      return { data: data?.setTacticStatusToDeleted || false, error: null };
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'setTacticStatusToDeleted.ts - useSetTacticStatusToDeletedMutation',
        });
        return { data: false, error: customContext.message as string };
      }
      return { data: false, error: null };
    }
  }, [setTacticStatusToDeleted, flags.mayaEnableVeevaBiogenlinc1816]);

  return [executeMutation, state];
};
