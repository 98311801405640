import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  YodaBorderRadius,
  YodaColors,
  YodaFontSize,
  YodaFontWeight,
  YodaJustifyContent,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

export const targetListSelectStyle = {
  '& .MuiFormControlLabel-root': {
    flex: 1,
    borderRadius: YodaBorderRadius.small,
    border: `2px ${YodaColors.gray3} solid`,
    margin: 0,
    paddingLeft: YodaSpacing.small,
    height: 90,

    '&:hover': {
      borderColor: YodaColors.primaryBlue,
    },

    '&:not(:last-of-type)': {
      marginRight: YodaSpacing.xxxLarge,
    },

    '&.Mui-disabled': {
      borderColor: YodaColors.gray1,
    },
  },
  '& .MuiFormControlLabel-label': {
    flex: 1,
    fontWeight: YodaFontWeight.medium,
    fontSize: YodaFontSize.medium,
  },
};

export const deleteTargetsButtonStyle: SxProps<Theme> = {
  marginTop: '-20px',
  display: 'flex',
  gap: '20px',
  justifyContent: YodaJustifyContent.flexEnd,
};

export const cepApprovedDeleteTargetsButtonStyle: SxProps<Theme> = {
  marginTop: '-20px',
  display: 'flex',
  justifyContent: YodaJustifyContent.flexEnd,
  gap: '20px',
};
