import { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mayaCiTemplateListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import {
  CiFormCanceltCallbackType,
  CiFormSubmitCallbackType,
} from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useUpdateCustomerInitiativeMutation } from 'modules/maya/graphql/mutations/updateCustomerInitiative';
import useGetCustomerInitiativeByIdQuery from 'modules/maya/graphql/queries/getCustomerInitiativeById';

const useCiTemplateEdit = () => {
  const navigate = useNavigate();

  const [updateCustomerInitiative] = useUpdateCustomerInitiativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const location = useLocation();
  const currentURL = location.pathname;

  const [loading, setLoading] = useState(true);

  const customerInitiative = useGetCustomerInitiativeByIdQuery(urlParams.get('id')).data;

  useEffect(() => {
    if (customerInitiative) {
      setLoading(false);
    }
  }, [customerInitiative, setLoading]);

  const handleCancelCustomerInitiativeForm: CiFormCanceltCallbackType = () => {
    navigate(mayaCiTemplateListRoute);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (ci) => {
    const customerInitiativeData = await updateCustomerInitiative(customerInitiative?._id || null, { ...ci, isTemplate: true }, false);

    if (customerInitiativeData) {
      succesToast(`Customer initiative ${ci.title} updated`);
      navigate(mayaCiTemplateListRoute);
    } else {
      warningToast(`Cannot update customer initiative ${ci.title}`);
    }
  };

  return {
    loading,
    customerInitiative,
    currentURL,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  };
};

export default useCiTemplateEdit;
