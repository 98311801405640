import { useTranslation } from 'react-i18next';
import { Channel, ReachMetric } from 'modules/maya/basics/enums/maya.enums';

const useReachMetricOptions = (tacticChannelType: string) => {
  const { t } = useTranslation();
  const reachMetricKeys = Object.keys(ReachMetric);
  const reachMetricValues = Object.values(ReachMetric);
  switch (tacticChannelType) {
    case Channel.approvedEmail:
    case Channel.broadcastEmail:
    case Channel.oneToOne:
      return [
        { id: ReachMetric.ctaClickRate, label: t('maya_reachmetric_ctaclickrate') },
        { id: ReachMetric.openRate, label: t('maya_reachmetric_openrate') },
      ];
    case Channel.virtualEvent:
    case Channel.f2fEvent:
    case Channel.hybridEvent:
      return [
        { id: ReachMetric.registrationRate, label: t('maya_reachmetric_registrationrate') },
      ];
    case Channel.webLogIn:
    case Channel.webNoLogIn:
      return [
        { id: ReachMetric.uniqueVisits, label: t('maya_reachmetric_uniquevisits') },
      ];
    default:
      return reachMetricValues.map((value, index) => { return { id: reachMetricKeys[index], label: value }; });
  }
};

export default useReachMetricOptions;

