import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useSelectTacticDesignConfig = () => {
  const { t } = useTranslation();
  const [newDesign, setNewDesign] = useState(true);

  const cardSwitchConfig = {
    name: 'selectTacticDesign',
    title: t('maya_tactic_design'),
    defaultValue: newDesign,
    onclick: () => {
      setNewDesign(!newDesign);
    },
  };

  return { cardSwitchConfig, newDesign };
};

export default useSelectTacticDesignConfig;
