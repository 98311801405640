import { FC } from 'react';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import InfoTag from 'modules/maya/components/SideFormDetails/InfoTag/InfoTag';
import useSideFormDetails from 'modules/maya/components/SideFormDetails/SideFormDetails.hook';
import { sideContainerStyle } from 'modules/maya/components/SideFormDetails/SideFormDetails.styles';
import UpdateBanner from 'modules/maya/components/SideFormDetails/UpdateBanner/UpdateBanner';
import Box from 'yoda-ui/Box';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const SideContainer: FC = () => {
  const {
    date,
    cepStateValue,
    t,
  } = useSideFormDetails();

  return (
    <Box sx={ sideContainerStyle.root }>
      {
        cepStateValue.cep?.title
          ? <Box
            sx={ sideContainerStyle.firstBox }
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            padding={ YodaSpacing.medium }
          >
            <Box marginBottom={ YodaSpacing.medium } marginTop={ YodaSpacing.small }>
              <Box fontSize={ YodaFontSize.small } fontWeight={ YodaFontWeight.light }>
                { t('maya_side_details_title') }
              </Box>
              <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium } marginBottom={ YodaSpacing.large }>
                { cepStateValue.cep?.title }
              </Box>
              <Box marginBottom={ YodaSpacing.xxxLarge }>
                <InfoTag
                  title={ t('maya_side_details_date') }
                  field={ date }
                />
                <InfoTag
                  title={ t('maya_side_details_therapeutic_area') }
                  field={ cepStateValue.cep?.therapeuticArea }
                />
                <InfoTag
                  title={ t('maya_side_details_product') }
                  field={
                    (Object.keys(BiogenProduct) as Array<keyof typeof BiogenProduct>)
                      .find((key) => BiogenProduct[key] === cepStateValue.cep?.product)
                  }
                />
              </Box>
            </Box>
          </Box>
          : null
      }
      {
        cepStateValue.cep?.updated
          ? <UpdateBanner />
          : null
      }
    </Box>
  );
};

export default SideContainer;
