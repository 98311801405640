import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCard from 'components/ContentCard';
import CountCepWidget from 'modules/maya/components/CountWidgets/CountCepWidget/CountCepWidget';
import CountCiWidget from 'modules/maya/components/CountWidgets/CountCiWidget/CountCiWidget';
import CountTacticsWidget from 'modules/maya/components/CountWidgets/CountTacticsWidget/CountTacticsWidget';
import CountTargetsWidget from 'modules/maya/components/CountWidgets/CountTargetsWidget/CountTargetsWidget';
import CepDashBoardList from 'modules/maya/components/Dashboard/CepDashboardGridlist/CepDashboardList';
import CiDashboardList from 'modules/maya/components/Dashboard/CiDashboardGridlist/CiDashboardList';
import { dashboardSummaryStats, gridListDashboard } from 'modules/maya/components/Dashboard/Dashboard.style';
import DashboardFilters from 'modules/maya/components/DashboardFilters/DashboardFilters';
import useDashboardFilters from 'modules/maya/components/DashboardFilters/DashboardFilters.hook';
import Heading from 'modules/maya/components/Heading';
import Box from 'yoda-ui/Box';

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const {
    providerFields,
    dashboardFiltersConfig,
    handleChangeTA,
    handleChangeProduct,
    submitButtonConfig,
    resetButtonConfig,
    filters,
  } = useDashboardFilters();

  return (
    <Box flexDirection="column">
      <Heading>
        { t('maya_dashboard_title') }
      </Heading>

      <DashboardFilters
        providerFields={ providerFields }
        dashboardFiltersConfig={ dashboardFiltersConfig }
        handleChangeTA={ handleChangeTA }
        handleChangeProduct={ handleChangeProduct }
        submitButtonConfig={ submitButtonConfig }
        resetButtonConfig={ resetButtonConfig }
      />

      <Box>
        <ContentCard>
          <Heading>
            { t('maya_dashboard_title_summary_stats_title') }
          </Heading>

          <Box sx={ dashboardSummaryStats }>
            <CountCiWidget filters={ filters } />
            <CountCepWidget filters={ filters } />
            <CountTacticsWidget filters={ filters } />
            <CountTargetsWidget filters={ filters } />
          </Box>
        </ContentCard>

        <Box sx={ gridListDashboard }>
          <CiDashboardList filters={ filters }/>
          <CepDashBoardList filters={ filters }/>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
