import { getHyphenedEventStartAndEndDate } from 'basics/utils/dates/dates.utils';
import { Event } from 'modules/events/basics/types/events.types';
import { getEventType } from 'modules/events/basics/utils/events.utils';

type UseEventSummaryParams = {
  event: Event;
};

const useEventSummary = ({ event }: UseEventSummaryParams) => {
  const eventType = getEventType(event);
  const startDate = event.data?.session?.startDate;
  const endDate = event.data?.session?.endDate;
  const eventDateAndTime = startDate ? getHyphenedEventStartAndEndDate(startDate, endDate) : 'Day 00 Month \u00B7 00:00 - 00:00';

  return {
    eventType,
    eventDateAndTime,
  };
};

export default useEventSummary;
