import { FC } from 'react';
import useStatusBanner from './StatusBanner.hook';
import Alert from 'yoda-ui/Alert';
import Box from 'yoda-ui/Box';
import Icon from 'yoda-ui/Icons/Icon/Icon';
import { YodaFontSize, YodaFontWeight } from 'yoda-ui/yodaTheme';

const StatusBanner: FC = () => {
  const { customStatus } = useStatusBanner();
  const { severity, icon, label, lastSaved } = customStatus;

  return (
    <Alert severity={ severity } hideDefaultIcon>
      <Box
        display="flex"
        flexDirection="row"
        fontSize={ YodaFontSize.small }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row">
          <Box fontWeight={ YodaFontWeight.medium }>
            { label }
          </Box>
          {
            lastSaved && (
              <Box fontWeight={ YodaFontWeight.light }>
                { `${lastSaved.date} ${lastSaved.time}` }
              </Box>
            )
          }
        </Box>
        <Box id="box-icon" display="flex">
          <Icon name={ icon } sx={ { fontSize: '14px' } } />
        </Box>
      </Box>
    </Alert>
  );
};

export default StatusBanner;
