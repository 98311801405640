import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarContent from './NavbarContent';
import { mayaRoute } from 'basics/constants/routes.constants';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import { mayaLogoStyle } from 'components/Layout/NavBar/Navbar.styles';
import MayaMenu from 'modules/maya/components/MayaMenu';
import AppBar from 'yoda-ui/AppBar';
import Box from 'yoda-ui/Box';
import Toolbar from 'yoda-ui/Toolbar';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const NavBar: FC = () => {
  const currentModuleConfig = useGetConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;

  if (currentModuleConfig.noLayoutURL.includes(currentURL)) {
    return null;
  }

  return (
    <AppBar position="fixed">
      <Box paddingX={ YodaSpacing.small }>
        <Toolbar>
          <Box display="flex" flex="column" justifyContent="space-between" width="100%">
            {
              currentModuleConfig.path === '/maya'
                ? <Box sx={ mayaLogoStyle } onClick={ () => navigate(mayaRoute) }>
                  <img src={ currentModuleConfig?.logo } alt="biogen-logo" style={ { height: 'inherit' } } />
                </Box>
                : <Box height="3rem">
                  <img src={ currentModuleConfig?.logo } alt="biogen-logo" style={ { height: 'inherit' } } />
                </Box>
            }
            {
              currentModuleConfig.path === '/maya'
                ? <MayaMenu />
                : null
            }
            <Box display="flex" height="2.5rem">
              <NavbarContent
                displayCountrySelector={ currentModuleConfig.enableCountrySelector }
                currentPath={ currentModuleConfig.path }
              />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
