import { useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { Tactic } from 'generated/maya.types';
import { TacticStatus } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetAllTacticsQuery from 'modules/maya/graphql/queries/getAllTactics';
import useGetCepByIdQuery from 'modules/maya/graphql/queries/getCepById';
import cepState from 'modules/maya/states/cep.states';

const useTacticPrintView = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cepId = urlParams.get('id') as string;

  const cep = useGetCepByIdQuery(cepId || null).data;
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
    }),
    [cepId],
  );

  const { data, loading } = useGetAllTacticsQuery(undefined, queryFilters);

  useEffect(() => {
    if (!loading) {
      cepState.tactics = data;
    }
  }, [data, loading]);

  useEffect(() => {
    if (cep) {
      cepState.cep = cep;
    } else {
      cepState.cep = {};
    }
  }, [cep]);

  type TacticActionOrderGroup = {
    [actionOrder: string]: Tactic[];
  };

  const groupedTactics: TacticActionOrderGroup = {};
  cepStateValue.tactics.forEach((tactic) => {
    const actionOrder = tactic?.actionOrder;

    if (actionOrder) {
      if (groupedTactics[actionOrder] === undefined) {
        groupedTactics[actionOrder] = [tactic];
      } else {
        groupedTactics[actionOrder].push(tactic);
      }
    }
  });

  let date;
  if (cepStateValue.cep?.dateStart && cepStateValue.cep?.dateEnd) {
    date = `
      ${new Date(cepStateValue.cep.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(cepStateValue.cep.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    cepStateValue,
    date,
    groupedTactics,
  };
};

export default useTacticPrintView;
