import { FC } from 'react';
import FormFieldTitle from 'components/FormFieldTitle';
import QuickForm from 'components/QuickForm';
import { transformMediaToCard } from 'components/QuickForm/CardsBucket/Cards.utils';
import { FormShape, ListShape } from 'components/QuickForm/QuickForm.enums';
import { FormDropZoneConfig, ListConfig } from 'components/QuickForm/QuickForm.types';
import { Asset } from 'modules/events/basics/types/eventData.types';
import { YodaFieldValidation } from 'services/yodaForm/yodaForm.types';

type TeaserProps = {
  name: string;
  teaserFormConfig: FormDropZoneConfig;
  listConfig: ListConfig;
  validation?: YodaFieldValidation;
  defaultValue?: Asset[];
  title: string;
};

const Teaser: FC<TeaserProps> = ({
  name,
  teaserFormConfig,
  listConfig,
  validation,
  title,
  defaultValue = [],
}) => (
  <>
    <FormFieldTitle title={ title } />
    <QuickForm
      name={ name }
      form={ { shape: FormShape.formAsset, formConfig: { ...teaserFormConfig } } }
      list={ { shape: ListShape.listAsset, listConfig } }
      transformToCard={ transformMediaToCard }
      defaultValue={ defaultValue }
      validation={ validation }
    />
  </>
);

export default Teaser;
