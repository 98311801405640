import { FC } from 'react';
import ContentCard from 'components/ContentCard';
import QuickForm from 'components/QuickForm';
import { FormShape } from 'components/QuickForm/QuickForm.enums';
import useUploadTargetList from 'modules/maya/components/UploadTargetListForm/UploadTargetListForm.hook';

import { UploadTargetListFormPropsType } from 'modules/maya/components/UploadTargetListForm/UploadTargetListForm.type';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const UploadTargetListForm: FC<UploadTargetListFormPropsType> = (
  {
    updateTargetList,
    loading,
    setLoading,
  },
) => {
  const {
    handleFilesExternal,
    uploadLoading,
    uploadTargetListConfig: {
      defaultValue,
      formConfig,
      name,
      validation,
    },
    isCepApproved,
  } = useUploadTargetList(
    updateTargetList,
    setLoading,
  );

  return (
    <ContentCard sx={ { marginBottom: YodaSpacing.large } }>
      {
        uploadLoading || loading ? (
          <Box
            display='flex'
            justifyContent='center'
            marginTop={ YodaSpacing.xLarge }
          >
            <Loader />
          </Box>) : (
          <>
            {
              !isCepApproved && (
                <QuickForm
                  name={ name }
                  form={ { shape: FormShape.formAsset, formConfig: { handleFiles: handleFilesExternal, ...formConfig } } }
                  defaultValue={ defaultValue }
                  validation={ validation }
                />
              )
            }
          </>)
      }
    </ContentCard>
  );
};

export default UploadTargetListForm;
