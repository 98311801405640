import { FC } from 'react';
import CardsMapper from '../CardsBucket';
import NoItemCard from '../CardsBucket/NoItemCard';
import { ListProps } from './List.types';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const List: FC<ListProps> = (props) => {
  const { fieldState, handleRemoveItem, list, transformToCard, disabled, loading = false } = props;
  const { shape, listConfig: { title, noItemLabel } } = list;

  return (
    <>
      <Box display="flex" marginBottom={ YodaSpacing.small }>
        { title }
        {
          loading && (
            <Box display="flex" alignItems="center" marginLeft={ YodaSpacing.xxSmall }>
              <Loader size={ 1.25 } center />
            </Box>
          )
        }
      </Box>
      <Box paddingBottom={ YodaSpacing.medium } data-testid="items-container">
        {
          fieldState && fieldState.length > 0 ? (
            fieldState.map((item) => {
              const transformedItem = transformToCard(item);
              return (
                <CardsMapper
                  key={ transformedItem.id }
                  card={ { listShape: shape, item: transformedItem } }
                  handleRemoveItem={ handleRemoveItem }
                  disabled={ disabled }
                />
              );
            })
          ) : (
            <NoItemCard noItemLabel={ noItemLabel } />
          )
        }
      </Box>
    </>
  );
};

export default List;
