import { useTranslation } from 'react-i18next';

const useSessionsStep = () => {
  const { t } = useTranslation();
  return {
    t,
  };
};

export default useSessionsStep;
