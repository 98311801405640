export const targetListFragment = `
  _id
  targetId
  mdmId
  email
  firstName
  lastName
  veevaSyncStatus
`;

export const removetargetListFragment = `
  deletedCount
`;

