import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventsRoute } from 'basics/constants/routes.constants';
import useStepIndexer from 'hooks/useStepIndexer';
import { Event } from 'modules/events/basics/types/events.types';
import { usePublishOnlineWebinarMutation } from 'modules/events/graphql/mutations/publishOnlineWebinar';
import { useUpdateOnlineWebinarMutation } from 'modules/events/graphql/mutations/updateOnlineWebinar';
import { createOnlineWebinarStepIndexer } from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.states';
import { onlineWebinarInputTransformer } from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.transformers';
import { CreateOnlineWebinarStepsIndex } from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.types';
import eventState from 'modules/events/states/events.states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreateOnlineWebinarActions = (yodaFormMethods: any) => {
  const navigate = useNavigate();
  const stepIndexer = useStepIndexer(createOnlineWebinarStepIndexer);
  const { stepIndex, moveNextStep, movePreviousStep } = stepIndexer;
  const { getValues, getDirtyValues, formState, setStepShowError, setFormIsDirty } = yodaFormMethods;
  const [publishOnlineWebinar, { loading: publishLoading }] = usePublishOnlineWebinarMutation();
  const [updateOnlineWebinar, { loading: updateLoading }] = useUpdateOnlineWebinarMutation();

  const getOnlineWebinarInput = useCallback(() => {
    const formValues = getValues();
    const dataToSave = getDirtyValues();
    return onlineWebinarInputTransformer(dataToSave, formValues);
  }, [getDirtyValues, getValues]);

  const updateEventState = useCallback((updatedEvent?: Event | null) => {
    if (updatedEvent) {
      eventState.createEvent.updatedAt = updatedEvent.updatedAt;
      eventState.createEvent.status = updatedEvent.status;
    }
  }, []);

  const handleSaveData = useCallback(async () => {
    if (formState.isDirty) {
      const onlineWebinarInput = getOnlineWebinarInput();
      if (onlineWebinarInput) {
        const updatedWebinar = await updateOnlineWebinar(onlineWebinarInput);
        setFormIsDirty(false);
        updateEventState(updatedWebinar);
      }
    }
  }, [formState.isDirty, getOnlineWebinarInput, updateEventState, updateOnlineWebinar, setFormIsDirty]);

  const handlePublishEvent = useCallback(async () => {
    if (formState.isValid) {
      const onlineWebinarInput = getOnlineWebinarInput();
      if (onlineWebinarInput) {
        const publishedWebinar = await publishOnlineWebinar(onlineWebinarInput);
        if (publishedWebinar) {
          updateEventState(publishedWebinar);
          navigate(eventsRoute);
        }
      }
    }
  }, [formState.isValid, getOnlineWebinarInput, navigate, publishOnlineWebinar, updateEventState]);

  const setShowError = useCallback((stepIndexVal: number) => {
    const step = CreateOnlineWebinarStepsIndex[stepIndexVal];
    setStepShowError(true, [step]);
  }, [setStepShowError]);

  const handleChangeStep = useCallback((stepIndexVal: number) => {
    const previousStep = stepIndexer.previousStep || 0;
    stepIndexer.moveToStep(stepIndexVal);
    handleSaveData();
    setShowError(previousStep);
  }, [handleSaveData, setShowError, stepIndexer]);

  const saveAndNext = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    moveNextStep();
  }, [handleSaveData, moveNextStep, setShowError, stepIndex]);

  const saveAndPrevious = useCallback(async () => {
    await handleSaveData();
    setShowError(stepIndex);
    movePreviousStep();
  }, [handleSaveData, movePreviousStep, setShowError, stepIndex]);

  return {
    handleChangeStep,
    handlePublishEvent,
    handleSaveData,
    setShowError,
    publishLoading,
    updateLoading,
    stepIndexer,
    saveAndNext,
    saveAndPrevious,
  };
};

export default useCreateOnlineWebinarActions;
