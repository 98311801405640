import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CepStatus, CepSteps, TargetListMode } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { useRemoveTargetsMutation } from 'modules/maya/graphql/mutations/removeTargets';
import { useSyncTargetsToVeevaMutation } from 'modules/maya/graphql/mutations/syncTargetsToVeeva';
import useGetCepByIdLazyQuery from 'modules/maya/graphql/queries/getCepByIdLazy';
import useGetTargetListByCepIdLazyQuery from 'modules/maya/graphql/queries/getTargetListByCepIdLazy';
import useCepFormTargetListGroupConfig from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.config';
import cepState from 'modules/maya/states/cep.states';
import { useYodaForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useCepFormTargetListGroup = () => {
  const flags = useFlags();

  const [targetListMode, setTargetListMode] = useState(
    flags.mayaBiogenlinc4307EnableDynamicTargeting ? TargetListMode.dynamic : TargetListMode.upload,
  );
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.targetListStep);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const selectTargetListOnChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setTargetListMode(event.target.value as TargetListMode);
  };

  const { cepFormTargetListGroupConfig, targetListConfig } = useCepFormTargetListGroupConfig(targetListMode, selectTargetListOnChange);

  const { t } = useTranslation();
  const [removeTargetList, { loading: removeTargetsLoading }] = useRemoveTargetsMutation();
  const [syncTargetsToVeeva] = useSyncTargetsToVeevaMutation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const { queryGetTargetListByCepId } = useGetTargetListByCepIdLazyQuery();
  const { queryGetCepById } = useGetCepByIdLazyQuery();

  const updateTargetList = useCallback(async () => {
    setLoading(true);
    cepState.targetList = [];

    const cepId = cepStateValue.cep?._id;
    const { limit, offset } = queryOptions;
    const { data: queryData } = await queryGetTargetListByCepId({ variables: {
      cepId, limit, offset,
    } });

    setRowCountState((prevRowCountState) => queryData?.getTargetListByCepId?.count || prevRowCountState);
    setLoading(false);
    cepState.targetList = queryData?.getTargetListByCepId?.targets;
  }, [cepStateValue.cep?._id, queryOptions, queryGetTargetListByCepId]);

  useEffect(() => {
    updateTargetList();
  }, [updateTargetList, queryOptions]);

  const handleRemoveTargets = async (cepId?: string) => {
    setOpen(false);
    setLoading(true);
    await removeTargetList(cepId);

    const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepStateValue.cep?._id } });
    if (cepState.cep) {
      cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
    }

    updateTargetList();
    setRowCountState(0);
    setLoading(false);
  };

  const handleSyncTargets = async (cepId: string) => {
    setLoading(true);
    await syncTargetsToVeeva(cepId);

    const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepStateValue.cep?._id } });
    if (cepState.cep) {
      cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
    }

    updateTargetList();
    setLoading(false);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const syncTargetsButtonConfig = {
    buttonProps: {
      onClick: () => handleSyncTargets(cepStateValue.cep?._id || ''),
      buttonType: ButtonType.primary,
      disabled: isCepApproved,
    },
    label: t('maya_targetlist_sync_label'),
    loading: loading || removeTargetsLoading,
  };

  const openModalButtonConfig = {
    buttonProps: {
      onClick: () => setOpen(true),
      buttonType: ButtonType.neutral,
      disabled: isCepApproved,
    },
    label: t('maya_targetlist_remove_label'),
    loading: loading || removeTargetsLoading,
  };

  return {
    stepIsValid: stepState?.isValid || false,
    cepFormTargetListGroupConfig,
    targetListMode,
    handleRemoveTargets,
    data: Object.values(cepStateValue.targetList),
    loading,
    removeTargetsLoading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    t,
    openModalButtonConfig,
    open,
    setOpen,
    isCepApproved,
    cepStateValue,
    targetListConfig,
    updateTargetList,
    setLoading,
    syncTargetsButtonConfig,
  };
};

export default useCepFormTargetListGroup;
