import { FC } from 'react';
import useTimeSlotBookingConfig from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.config';
import useTimeSlotBooking from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.hook';
import { TimeSlotBookingProps } from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.types';
import { YodaFormProvider } from 'services/yodaForm';
import Alert from 'yoda-ui/Alert';
import { TextButton } from 'yoda-ui/BiogenLincDS/Buttons';
import Box from 'yoda-ui/Box';
import CheckBoxYoda from 'yoda-ui/Form/CheckBoxYoda';
import DateRangePicker from 'yoda-ui/Form/DateRangePicker';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import Grid from 'yoda-ui/Grid';
import { YodaFontSize, YodaFontWeight, YodaGridCellSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const TimeSlotBooking: FC<TimeSlotBookingProps> = ({
  onSave,
  values,
  isInvalid,
  isValid,
  isLoading,
  disabled,
}) => {
  const { t, isFormValid, handleSave, startTimeOptions, endTimeOptions, providerFields, isFormDirty } = useTimeSlotBooking({ onSave });
  const {
    timeSlotBookingConfig,
    showHostProviderLink,
    handleChangeProvider,
  } = useTimeSlotBookingConfig({ startTimeOptions, endTimeOptions, values });

  return (
    <>
      <Grid container spacing={ YodaSpacing.large } marginBottom={ '20px' }>
        <Grid item xs={ YodaGridCellSize.small }>
          <Box>
            <SelectYoda { ...timeSlotBookingConfig.hostProvider } onChange={ handleChangeProvider } disabled={ disabled } />
          </Box>
          <Box paddingTop={ YodaSpacing.large } sx={ { display: showHostProviderLink ? 'block' : 'none' } }>
            <TextYoda { ...timeSlotBookingConfig.hostProviderLink } disabled={ disabled } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } sx={ { display: !showHostProviderLink ? 'block' : 'none' } }>
            <CheckBoxYoda { ...timeSlotBookingConfig.enableZoomStreaming } disabled={ showHostProviderLink } />
          </Box>
        </Grid>

        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <SelectYoda { ...timeSlotBookingConfig.eventFormat } disabled={ disabled } />
          </Box>
        </Grid>

        <Grid item xs={ YodaGridCellSize.small }>
          <Box >
            <SelectYoda { ...timeSlotBookingConfig.locale } disabled={ timeSlotBookingConfig.locale.disabled || disabled } />
          </Box>
        </Grid>

      </Grid>
      <YodaFormProvider { ...providerFields }>
        <Grid container spacing={ YodaSpacing.large }>
          <Grid item xs={ YodaGridCellSize.full }>
            <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium }>{ t('timeSlotBooking_title') }</Box>
          </Grid>
          <Grid item xs={ YodaGridCellSize.full }>
            <DateRangePicker { ...timeSlotBookingConfig.dateRange } disabled={ disabled || timeSlotBookingConfig.dateRange.disabled } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.timeZone } disabled={ true } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.startTime } disabled={ disabled || timeSlotBookingConfig.startTime.disabled } />
          </Grid>
          <Grid item xs={ YodaGridCellSize.small }>
            <SelectYoda { ...timeSlotBookingConfig.endTime } disabled={ disabled || timeSlotBookingConfig.endTime.disabled } />
          </Grid>
        </Grid>

        <Grid item xs={ YodaGridCellSize.full }>
          <Alert severity={ 'success' } active={ isValid && !isFormDirty } >
            { t('timeSlotBooking_booking_succeed') }
          </Alert>
          <Alert severity={ 'error' } active={ isInvalid && !isFormDirty } >
            { t('timeSlotBooking_booking_error') }
          </Alert>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={ YodaSpacing.small }>
            <TextButton
              label={ t('timeSlotBooking_bookButton_label') }
              type="submit"
              disabled={ disabled || !isFormValid || showHostProviderLink }
              onClick={ handleSave }
              loading={ isLoading }
            />
          </Box>
        </Grid>

      </YodaFormProvider>
    </>
  );
};

export default TimeSlotBooking;
