import { FC } from 'react';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import useEmailNotes from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.hook';
import { useYodaField } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import CheckBoxYoda from 'yoda-ui/Form/CheckBoxYoda/CheckboxYoda';
import RichtextYoda from 'yoda-ui/Form/RichtextYoda';
import Select from 'yoda-ui/Form/Select/Select';
import { labelStyle } from 'yoda-ui/Form/Select/Select.styles';
import TextYoda from 'yoda-ui/Form/TextYoda';
import MenuItem from 'yoda-ui/MenuItem';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const EmailNotesGroup: FC = () => {
  const {
    emailNotesConfig,
    t,
  } = useEmailNotes();

  const { defaultValue } = emailNotesConfig.buttonColor;

  const {
    fieldValue,
    setValue,
    setFormIsDirty,
  } = useYodaField({ name: emailNotesConfig.buttonColor.name, defaultValue });

  const changeColorValue = (newValue: string) => {
    setValue(emailNotesConfig.buttonColor.name, newValue);
    setFormIsDirty(true);
  };

  return (
    <FormGroup title={ t('event_group_email_notes') } groupName={ CommonGroups.optionalInformations }>

      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...emailNotesConfig.emailContentfulUrl } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <RichtextYoda { ...emailNotesConfig.emailHeaderNote } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <RichtextYoda { ...emailNotesConfig.emailFooterNote } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <CheckBoxYoda { ...emailNotesConfig.biogenLogo } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <p style={ labelStyle }>{ t('events_email_buttons_color_label') }</p>
        <Select
          name={ emailNotesConfig.buttonColor.name }
          value={ fieldValue }
          onChange={ (event) => changeColorValue(String(event.target.value)) }
        >
          {
            emailNotesConfig.buttonColor.options.map((option) => (
              <MenuItem key={ option.colorHex } value={ option.colorHex }>
                <Box
                  borderRadius={ 8 }
                  bgcolor={ option.colorHex }
                  width={ 40 }
                  height={ 40 }
                  marginRight={ YodaSpacing.medium }
                >
                </Box>
                <Box
                  fontSize={ YodaFontSize.large }
                  fontWeight={ YodaFontWeight.medium }
                >
                  { option.colorLabel }
                </Box>
              </MenuItem>
            ))
          }
        </Select>
      </Box>

    </FormGroup>
  );
};

export default EmailNotesGroup;
