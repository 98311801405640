import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { ApolloSpeakersResponse, PossibleSpeaker } from 'modules/events/groups/SpeakersGroup/SpeakersGroup.types';

import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const GET_POSSIBLE_SPEAKERS = gql`
  query getPossibleSpeakers($name: String!) {
    findSpeakers(lookup: $name) {
      id: _id
      title
      firstName
      lastName
      email
    }
  }
`;

type UseLazyGetPossibleSpeakers = () => {
  called: boolean;
  data: PossibleSpeaker[] | null;
  error?: ApolloError;
  lazyQuery: Function;
  loading: boolean;
};

const cleanApolloData = (data: ApolloSpeakersResponse): null | PossibleSpeaker[] => {
  if (data && data.findSpeakers) {
    return data.findSpeakers.map((apolloSpeaker) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, firstName, lastName, email } = apolloSpeaker;
      const label = `${firstName} ${lastName} (${email})`;
      return { id, firstName, lastName, email, label };
    });
  }
  return null;
};

const useLazyGetPossibleSpeakers: UseLazyGetPossibleSpeakers = () => {
  const [queryPossibleSpeakers, { called, data, error, loading }] = useLazyQuery(GET_POSSIBLE_SPEAKERS, {
    fetchPolicy: 'cache-and-network',
  });

  const lazyQuery = useCallback(async (query: string) => {
    await queryPossibleSpeakers(
      {
        variables: { name: query },
      },
    );
  }, [queryPossibleSpeakers]);

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      customContext,
      error,
      transactionName: 'getPossibleSpeakers.ts - useLazyGetPossibleSpeakers',
    });
  }

  return {
    called,
    data: cleanApolloData(data),
    error,
    lazyQuery,
    loading,
  };
};

export default useLazyGetPossibleSpeakers;
