import { orderBy } from 'lodash';
import {
  createOnlineMeetingRoute,
  editOnlineMeetingRoute,
} from 'basics/constants/routes.constants';
import { Order } from 'basics/enums/general.enums';
import { EventStatus, Format } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';

export const getEventType = (event: Event) => (`${event.presence} ${event.format}`.toUpperCase());

type SortEvents = (eventsList: Event[], property: string, order: Order) => Event[];

export const sortEvents: SortEvents = (eventsList, property, order) => orderBy(eventsList, property, order);

type SortEventsByDate = (eventsList: Event[], order?: Order) => Event[];

export const sortEventsByCreatedAtDate: SortEventsByDate = (eventsList, order = Order.desc) => sortEvents(eventsList, 'createdAt', order);

export const isDraft = (eventStatus: EventStatus | undefined) => {
  if (!eventStatus) {
    return false;
  }
  return [EventStatus.draft, EventStatus.ready].includes(eventStatus);
};

export const getEditRouteFromEvent = (event: Event): string => {
  // TODO: When we can handle physical events we need to be able to distinguish by presence too
  const { format } = event;
  switch (format) {
    case Format.congress:
      return `${createOnlineMeetingRoute}/${event._id}`;
    case Format.liveStreaming:
      return `${createOnlineMeetingRoute}/${event._id}`;
    case Format.meeting:
      return `${createOnlineMeetingRoute}/${event._id}`;
    case Format.onDemand:
      return `${createOnlineMeetingRoute}/${event._id}`;
    case Format.standalone:
      return `${createOnlineMeetingRoute}/${event._id}`;
    case Format.webinar:
      return `${createOnlineMeetingRoute}/${event._id}`;
    default:
      return `${createOnlineMeetingRoute}/${event._id}`;
  }
};

export const getEditRouteFromPublishedEvent = (event: Event): string => {
  // TODO: When we can handle physical events we need to be able to distinguish by presence too
  const { format } = event;
  switch (format) {
    case Format.congress:
      return `${editOnlineMeetingRoute}/${event._id}`;
    case Format.liveStreaming:
      return `${editOnlineMeetingRoute}/${event._id}`;
    case Format.meeting:
      return `${editOnlineMeetingRoute}/${event._id}`;
    case Format.onDemand:
      return `${editOnlineMeetingRoute}/${event._id}`;
    case Format.standalone:
      return `${editOnlineMeetingRoute}/${event._id}`;
    case Format.webinar:
      return `${editOnlineMeetingRoute}/${event._id}`;
    default:
      return `${editOnlineMeetingRoute}/${event._id}`;
  }
};
