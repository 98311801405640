import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { warningToast } from 'basics/utils/toast';
import useCreateEventConfig from 'modules/events/components/CreateEvent/CreateEvent.config';
import { getRouteByFormatAndPresence } from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventModal.utils';
import { useCreateEventMutation } from 'modules/events/graphql/mutations/createEvent';
import { onlineMeetingTransformer } from 'modules/events/routes/CreateOnlineMeeting/CreateOnlineMeeting.transformers';
import eventState from 'modules/events/states/events.states';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useCreateEventModal = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createEventConfig } = useCreateEventConfig();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getValues,
    providerFields,
    resetForm,
    useWatchForm,
  } = useYodaCreateForm();
  const [createEvent] = useCreateEventMutation();
  const { isValid } = useWatchForm();

  const cancelCreateEventForm = () => {
    resetForm();
    setOpen(false);
  };

  const handleSaveCreateEventForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const eventData = await createEvent(formValues);
    setIsSubmitting(false);
    if (eventData) {
      eventState.createEvent = onlineMeetingTransformer(eventData);
      resetForm();
      navigate(getRouteByFormatAndPresence(eventData));
    } else {
      warningToast(t('errors_standard'));
    }
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: cancelCreateEventForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSaveCreateEventForm,
      buttonType: ButtonType.primary,
      disabled: !isValid,
    },
    label: t('event_creation_modal_create_button_label'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    createEventConfig,
    submitButtonConfig,
    providerFields,
    t,
  };
};

export default useCreateEventModal;
