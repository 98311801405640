import { FC, MouseEventHandler } from 'react';
import { MoreActionsMenuItemProps } from '../MoreActionsMenu.types';
import Box from 'yoda-ui/Box';
import Divider from 'yoda-ui/Divider';
import MenuItem from 'yoda-ui/MenuItem';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const MoreActionsMenuItem: FC<MoreActionsMenuItemProps> = ({ closeMenu, menuItemConfig, isLast }) => {
  if (menuItemConfig === null) {
    return null;
  }
  const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
    closeMenu();
    menuItemConfig.onClick(e);
  };

  return (
    <Box
      color={ YodaColors.gray4 }
      minWidth='200px'
    >
      <MenuItem sx={ { padding: YodaSpacing.small } } onClick={ handleClick } >
        <Box
          fontSize={ YodaFontSize.small }
          fontWeight={ YodaFontWeight.light }
          color={ YodaColors.gray4 }
        >
          { menuItemConfig.label }
        </Box>
      </MenuItem>
      {
        !isLast && (
          <Box marginY={ YodaSpacing.none } >
            <Divider />
          </Box>
        )
      }
    </Box>
  );
};

export default MoreActionsMenuItem;
