import { useEffect } from 'react';
import { Order } from 'basics/enums/general.enums';
import { useCurrentUserState } from 'contexts/user';
import { Market } from 'generated/event.types';
import { sortEvents } from 'modules/events/basics/utils/events.utils';
import useGetFullPrivateEventsQuery from 'modules/events/graphql/queries/getFullPrivateEvents';
import eventState from 'modules/events/states/events.states';

const useEvents = (isPast: boolean) => {
  const { user } = useCurrentUserState();
  const market = user?.market ? user.market as Market : null;
  const { data, loading, error } = useGetFullPrivateEventsQuery(market, isPast);

  useEffect(() => {
    eventState.createEvent = {};
  }, []);

  return {
    data: data ? sortEvents(data, 'data.session.endDate', Order.asc) : [],
    loading,
    error,
  };
};

export default useEvents;
