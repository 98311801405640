import { useTranslation } from 'react-i18next';
import useMarketOptions from 'basics/options/markets.options';
import { Format, Presence } from 'generated/event.types';
import eventFormatOptions from 'modules/events/basics/options/eventFormat.options';
import eventPresenceOptions from 'modules/events/basics/options/eventPresence.options';
import useCreateMeetingSchema from 'modules/events/components/CreateEvent/CreateEvent.schema';
import { CreateEventModalConfig } from 'modules/events/components/CreateEvent/CreateEvent.types';

const useCreateEventConfig = () => {
  const { t } = useTranslation();
  const createMeetingSchema = useCreateMeetingSchema();
  const createEventConfig: CreateEventModalConfig = {
    title: {
      label: t('event_creation_modal_title_field_label'),
      name: 'title',
      placeholder: '',
      required: true,
      defaultValue: '',
      validation: createMeetingSchema.title,
    },
    market: {
      label: t('event_creation_modal_market_field_label'),
      name: 'market',
      placeholder: t('event_creation_modal_market_field_placeholder'),
      options: useMarketOptions(),
      required: true,
      defaultValue: '',
      validation: createMeetingSchema.market,
    },
    presenceType: {
      label: t('event_creation_modal_presence_type_field_label'),
      name: 'presence',
      placeholder: t('event_creation_modal_presence_type_field_placeholder'),
      options: eventPresenceOptions,
      required: true,
      defaultValue: Presence.online,
      validation: createMeetingSchema.presenceType,
    },
    eventFormat: {
      label: t('event_creation_modal_event_format_field_label'),
      name: 'format',
      placeholder: t('event_creation_modal_event_format_field_placeholder'),
      options: eventFormatOptions,
      required: true,
      defaultValue: Format.meeting,
      validation: createMeetingSchema.eventFormat,
    },
  };

  return { createEventConfig };
};

export default useCreateEventConfig;
