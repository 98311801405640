import { FC } from 'react';
import { mayaLayoutStyle, mayaLayoutContentStyle } from './MayaLayout.style';
import { MayaLayoutPropsType } from './MayaLayout.types';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/Box';

const MayaLayout: FC<MayaLayoutPropsType> = ({ children }) => {
  return (
    <LargeContainer sx={ mayaLayoutStyle }>
      <Box sx={ mayaLayoutContentStyle }>
        <LargeContainer flexDirection="column" marginX="auto" height="100%">
          { children }
        </LargeContainer>
      </Box>
    </LargeContainer>
  );
};

MayaLayout.displayName = 'MayaLayout';

export default MayaLayout;
