import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardInviteeItem, HandlesRemoveItem } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/Box';
import ConfirmationDialog from 'yoda-ui/Dialogs/ConfirmationDialog';
import Icon, { Icons } from 'yoda-ui/Icons/Icon';
import { YodaBorderRadius, YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export type CardInviteeProps = {
  item: CardInviteeItem;
  handleRemoveItem: HandlesRemoveItem;
  disabled: boolean;
};

const CardInvitee: FC<CardInviteeProps> = ({ item, handleRemoveItem, disabled }) => {
  const { t } = useTranslation();
  const { firstName = '', lastName = '', email = '', id = '' } = item;
  const fullName = `${firstName} ${lastName}`;

  const [dialogOpened, setDialogOpened] = useState(false);

  return (
    <>
      <Box
        bgcolor={ YodaColors.gray1 }
        color={ YodaColors.textPrimary }
        borderRadius={ YodaBorderRadius.small }
        data-testid="invitee-container"
        display="flex"
        marginBottom={ YodaSpacing.xxSmall }
        paddingX={ YodaSpacing.small }
        paddingY={ YodaSpacing.xSmall }
      >
        <Box flexDirection="column" width="40%" maxWidth="250px">
          <Box marginRight={ YodaSpacing.large }>{ fullName }</Box>
          <Box color={ YodaColors.textSecondary }>{ id }</Box>
        </Box>
        <Box flexGrow="1" marginX={ YodaSpacing.medium }>{ email }</Box>
        {
          disabled ? null : (<Box display="flex" alignItems="center">
            <Icon
              data-testid="close-icon"
              name={ Icons.close }
              onClick={ () => setDialogOpened(true) }
              style={ { fill: YodaColors.gray8, cursor: 'pointer' } }
            />
          </Box>)
        }

      </Box>
      {
        dialogOpened && (
          <ConfirmationDialog
            dialogMessage={ fullName }
            dialogTitle={ t('cardInvitee_confirmationDialog_title') }
            onConfirm={ () => handleRemoveItem(id) }
            onConfirmLabel={ t('actions_confirm_label') }
            onDismiss={ () => setDialogOpened(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
            <Box width="100%" textAlign="initial">
              <Box>{ fullName }</Box>
              <Box>{ email }</Box>
              <Box marginTop={ YodaSpacing.xxSmall } color={ YodaColors.textSecondary }>
                { id }
              </Box>
            </Box>
          </ConfirmationDialog>
        )
      }
    </>
  );
};

export default CardInvitee;
