import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import {
  TimeSlotBookingConfig,
  TimeSlotBookingField,
  UseTimeSlotBookingConfigProps,
} from './TimeSlotBooking.types';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { SelectOption } from 'basics/options/options.types';
import { timezoneOptions } from 'basics/options/timezone.options';
import { Format, HostProvider } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import eventFormatOptions from 'modules/events/basics/options/eventFormat.options';
import eventHostProviderOptions from 'modules/events/basics/options/eventHostProvider.options';
import eventLocaleOptions from 'modules/events/basics/options/eventLocale.options';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useTimeSlotBookingSchema from 'modules/events/components/TimeSlotBooking/TimeSlotBooking.schema';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'services/yodaForm';

const useTimeSlotBookingConfig = ({ startTimeOptions, endTimeOptions, values }: UseTimeSlotBookingConfigProps) => {
  const { t } = useTranslation();
  const validations = useTimeSlotBookingSchema();
  const { setValue } = useYodaForm();
  const eventStateValue = useSnapshot(eventState) as EventStateType;

  const [showHostProviderLink, setShowHostProviderLink] = useState(eventStateValue.createEvent?.data?.provider === HostProvider.other);

  useEffect(() => {
    if (eventStateValue.createEvent?.data?.provider) {
      setShowHostProviderLink(eventStateValue.createEvent?.data?.provider === HostProvider.other);
    }
  }, [eventStateValue.createEvent?.data?.provider]);

  const handleChangeProvider = useCallback((event: SyntheticEvent<Element, Event>, selectedProvider: SelectOption) => {
    if (selectedProvider && selectedProvider.id === HostProvider.other) {
      setValue(commonFields.hostProviderLink, eventStateValue.createEvent?.data?.providerLink || 'https://');
      setValue(commonFields.enableZoomStreaming, false);
      setShowHostProviderLink(true);
    } else {
      setValue(commonFields.hostProviderLink, '');
      setShowHostProviderLink(false);
    }
  }, [eventStateValue, setValue]);

  const localeOptions = eventLocaleOptions(eventStateValue.createEvent?.market);

  const timeSlotBookingConfig: TimeSlotBookingConfig = {
    [TimeSlotBookingField.dateRange]: {
      startText: t('timeSlotBooking_startDate_label'),
      endText: t('timeSlotBooking_endDate_label'),
      name: TimeSlotBookingField.dateRange,
      disablePast: true,
      disabled: true,
      validation: validations[TimeSlotBookingField.dateRange],
      startRequired: true,
      endRequired: false,
      defaultValue: values?.dateRange,
    },
    [TimeSlotBookingField.timeZone]: {
      label: t('timeSlotBooking_timeZone_label'),
      name: TimeSlotBookingField.timeZone,
      options: timezoneOptions,
      required: true,
      defaultValue: TimeZone.UTC,
      validation: validations[TimeSlotBookingField.timeZone],
      disabled: true,
    },
    [TimeSlotBookingField.startTime]: {
      label: t('timeSlotBooking_startTime_label'),
      name: TimeSlotBookingField.startTime,
      placeholder: t('actions_select_placeholder'),
      options: startTimeOptions,
      required: true,
      disableClearable: true,
      disabled: true,
      defaultValue: values?.startTime,
      validation: validations[TimeSlotBookingField.startTime],
    },
    [TimeSlotBookingField.endTime]: {
      label: t('timeSlotBooking_endTime_label'),
      name: TimeSlotBookingField.endTime,
      placeholder: t('actions_select_placeholder'),
      options: endTimeOptions,
      required: true,
      disableClearable: true,
      disabled: true,
      defaultValue: values?.endTime,
      validation: validations[TimeSlotBookingField.endTime],
    },
    hostProvider: {
      label: t('events_host_provider_input_label'),
      name: commonFields.hostProvider,
      placeholder: t('events_host_provider_input_placeholder'),
      options: eventHostProviderOptions,
      required: true,
      defaultValue: eventStateValue.createEvent.data?.provider || HostProvider.zoom,
      validation: validations.hostProvider,
      disableClearable: true,
    },
    hostProviderLink: {
      label: t('events_host_provider__link_input_label'),
      name: commonFields.hostProviderLink,
      placeholder: t('events_host_provider_link_input_placeholder'),
      required: true,
      defaultValue: eventStateValue.createEvent.data?.providerLink,
      validation: validations.hostProviderLink,
    },
    eventFormat: {
      label: t('event_creation_modal_event_format_field_label'),
      name: commonFields.eventFormat,
      placeholder: t('event_creation_modal_event_format_field_placeholder'),
      options: eventFormatOptions,
      required: true,
      disabled: true,
      defaultValue: eventStateValue.createEvent?.format || Format.meeting,
      validation: validations.eventFormat,
      disableClearable: true,
    },
    locale: {
      label: t('event_creation_modal_locale_field_label'),
      name: commonFields.locale,
      placeholder: t('event_creation_modal_locale_field_placeholder'),
      options: localeOptions,
      required: true,
      disabled: localeOptions.length === 1,
      defaultValue: eventStateValue.createEvent?.locale || localeOptions[0]?.id,
      validation: validations.locale,
      disableClearable: true,
    },
    enableZoomStreaming: {
      label: t('events_enable_zoom_streaming'),
      name: commonFields.enableZoomStreaming,
      checked: eventStateValue.createEvent.data?.enableZoomStreaming,
    },
  };
  return ({ timeSlotBookingConfig, showHostProviderLink, handleChangeProvider });
};

export default useTimeSlotBookingConfig;
