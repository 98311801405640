import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerInitiativeView from './CustomerInitiativeView.hook';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import {
  FormLayoutContainer,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
} from 'modules/maya/components/FormLayout';
import Heading from 'modules/maya/components/Heading';
import MayaLayout from 'modules/maya/components/MayaLayout';
import VeevaStatusBar from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar';
import { sideContainerStyle } from 'modules/maya/routes/CustomerInitiativeView/CustomerInitiativeView.styles';
import InfoTag from 'modules/maya/routes/CustomerInitiativeView/InfoTag/InfoTag';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CustomerInitiativeView: FC = () => {
  const { t } = useTranslation();
  const {
    customerInitiative,
    date,
    loading,
  } = useCustomerInitiativeView();

  return (
    <MayaLayout>
      <FormLayoutContainer>
        <FormLayoutMidPanel >

          <FormLayoutMidPanelHeader>
          </FormLayoutMidPanelHeader>

          <FormLayoutMidPanelContent>
            <Heading>
              { t('maya_ci_view') }
              <VeevaStatusBar
                lastVeevaSync={ customerInitiative?.lastVeevaSync }
                lastVeevaSyncErrors={ customerInitiative?.lastVeevaSyncError ? [customerInitiative?.lastVeevaSyncError] : undefined }
                veevaSyncStatus={ customerInitiative?.veevaSyncStatus as VeevaSyncStatus }
              />
            </Heading>

            {
              loading
                ? (
                  <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Loader center />
                  </Box>
                )
                : (
                  <Box>
                    <Box
                      sx={ sideContainerStyle.firstBox }
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      padding={ YodaSpacing.medium }
                    >
                      <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
                        <Box>
                          <InfoTag
                            title={ t('maya_ci_view_title') }
                            field={ customerInitiative?.title }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_country') }
                            field={ customerInitiative?.country }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_date') }
                            field={ date }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_description') }
                            field={ customerInitiative?.description }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_solution_core_story') }
                            field={ customerInitiative?.solutionCoreStory }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_si') }
                            field={ customerInitiative?.strategicImperative.title }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_therapeutic_area') }
                            field={ customerInitiative?.therapeuticArea }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_product') }
                            field={
                              (Object.keys(BiogenProduct) as Array<keyof typeof BiogenProduct>)
                                .find((key) => BiogenProduct[key] === customerInitiative?.product)
                            }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_functional_owner') }
                            field={ customerInitiative?.functionalOwner }
                          />
                          <InfoTag
                            title={ t('maya_ci_view_functions_involved') }
                            field={ customerInitiative?.functionsInvolved.toString() }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
            }
          </FormLayoutMidPanelContent>

          <FormLayoutRightPanel>
          </FormLayoutRightPanel>

        </FormLayoutMidPanel>
      </FormLayoutContainer>
    </MayaLayout>
  );
};

export default CustomerInitiativeView;

