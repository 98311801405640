import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CepFormEstimationCostGroupConfigType } from './CepFormEstimationCostGroup.types';
import { cepToForm } from 'modules/maya/basics/transformers/Cep.transformer';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import cepState from 'modules/maya/states/cep.states';

const useCepFormTargetListGroupConfig = () => {
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const defaultFormValue = cepToForm(cepStateValue.cep);

  const cepFormEstimationCostGroupConfig = (
    index: number,
  ): CepFormEstimationCostGroupConfigType => {
    return {
      number: {
        label: t('maya_cep_estimation_cost_estimated_number_label'),
        name: `estimatedNumber_${index}`,
        placeholder: t('maya_cep_estimation_cost_estimated_number_label'),
        required: false,
        defaultValue: defaultFormValue.estimatedNumbers[index] || '1',
        type: 'number',
      },
      estimatedCost: {
        label: t('maya_cep_step_estimation_cost_estimated_cost_label'),
        name: `estimatedCost_${index}`,
        placeholder: t('maya_cep_step_estimation_cost_estimated_cost_label'),
        required: false,
        defaultValue: defaultFormValue.estimatedCosts[index] || '0',
        type: 'number',
      },
    };
  };

  return {
    cepFormEstimationCostGroupConfig,
  };
};

export default useCepFormTargetListGroupConfig;
