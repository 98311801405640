import { FC } from 'react';
import useBiogenLincConfig from './BiogenLincConfigGroup.hook';
import CardForm from 'components/CardForm';
import CardSwitch from 'components/CardSwitch';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import PrcBlock from 'modules/events/components/PrcBlock';
import useCommunicationStep from 'modules/events/routes/CreateOnlineMeeting/Steps/CommunicationStep/CommunicationStep.hook';
import Box from 'yoda-ui/Box';
import CheckBoxYoda from 'yoda-ui/Form/CheckBoxYoda';
import RadioGroup from 'yoda-ui/Form/RadioGroup';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const BiogenLincConfigGroup: FC = () => {
  const { isVisibleOnBiogenLinc, isPublic } = useCommunicationStep();
  const {
    isVisibleOnBiogenLincConfig,
    eventPromotionalityConfig,
    prcValidationsConfig,
    t,
  } = useBiogenLincConfig();

  return (
    <Box>
      <Box paddingBottom={ YodaSpacing.small }>
        <CardSwitch { ...isVisibleOnBiogenLincConfig } />
      </Box>
      {
        isVisibleOnBiogenLinc && (
          <>
            <Box paddingBottom={ YodaSpacing.small }>
              <CardForm title={ eventPromotionalityConfig.formGroupConfig.title }>
                <RadioGroup { ...eventPromotionalityConfig.radioGroupConfig } />
                {
                  isPublic === EventPromotionalityEnum.public
                 && (<CheckBoxYoda { ...eventPromotionalityConfig.enableGuestRegistration } />)
                }
              </CardForm>
            </Box>
          </>
        )
      }
      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('prcValidation_title') }>
          <Box marginBottom={ YodaSpacing.large }>
            <PrcBlock { ...prcValidationsConfig.eventDetails } />
          </Box>
        </CardForm>
      </Box>
    </Box>
  );
};

export default BiogenLincConfigGroup;
