import { useTranslation } from 'react-i18next';
import { mixed, string } from 'yup';
import { Format, Market, Presence } from 'generated/event.types';

const maxTitleLength = 80;

const useCreateMeetingSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const maxLengthTitleMessage = `${t('event_creation_modal_title_max_error_message', { count: maxTitleLength })}`;

  return {
    title: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    market: mixed<Market>()
      .oneOf(Object.values(Market), requiredLabel)
      .required(),
    presenceType: mixed<Presence>()
      .oneOf(Object.values(Presence), requiredLabel)
      .required(),
    eventFormat: mixed<Format>()
      .oneOf(Object.values(Format), requiredLabel)
      .required(),
  };
};

export default useCreateMeetingSchema;
