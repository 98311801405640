import { FC } from 'react';
import MoreActionsMenu from 'components/MoreActionsMenu';
import { Event } from 'modules/events/basics/types/events.types';
import useEventsListItem from 'modules/events/components/EventsList/EventsListItem/EventsListItem.hook';
import EventSummary from 'modules/events/components/EventsList/EventSummary';
import LinkDialog from 'modules/events/components/EventsList/LinkDialog';
import Box from 'yoda-ui/Box';
import Paper from 'yoda-ui/Paper';
import Tag from 'yoda-ui/Tags/Tag';
import { YodaBorderRadius, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type EventsListItemProps = {
  event: Event;
};

const EventsListItem: FC<EventsListItemProps> = ({ event }) => {
  const { badge, handleClickEvent, menuItemConfigs, linkDialogIsOpen, closeLinkDialog } = useEventsListItem({ event });

  return (
    <Box marginBottom={ YodaSpacing.medium }>
      <Paper margin={ YodaSpacing.none } borderRadius={ YodaBorderRadius.medium }>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={ YodaSpacing.medium }
        >
          <Box onClick={ handleClickEvent } style={ { cursor: 'pointer' } }>
            <EventSummary event={ event } />
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tag
              fitContent
              paddingY={ YodaSpacing.xxSmall }
              paddingX={ YodaSpacing.small }
              bgcolor={ badge.bgColor }
              color={ badge.color }
              fontWeight={ YodaFontWeight.medium }
            >
              { badge.label }
            </Tag>
            <Box marginLeft={ YodaSpacing.small }>
              <MoreActionsMenu menuItemConfigs={ menuItemConfigs } />
            </Box>
          </Box>
        </Box>
      </Paper>
      <LinkDialog open={ linkDialogIsOpen } closeDialog={ closeLinkDialog } event={ event }/>
    </Box>
  );
};

export default EventsListItem;
