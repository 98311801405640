import { FC } from 'react';
import { SecondaryNavItemVariants } from '../SecondaryNav.enums';
import { SecondaryNavItemProps } from '../SecondaryNav.types';
import * as styles from './SecondaryNavItem.styles';
import Icon from 'yoda-ui/Icons/Icon';
import LinkButton from 'yoda-ui/Links/LinkButton';
import ListItem from 'yoda-ui/List/ListItem';
import ListItemText from 'yoda-ui/List/ListItemText';

const SecondaryNavItem: FC<SecondaryNavItemProps> = (props) => {
  const { CONTENT, HEAD } = SecondaryNavItemVariants;
  const { icon, text, onClick = () => {}, selected, variant = CONTENT } = props;

  const variantStyle = variant === HEAD ? styles.NavLinkHead : styles.NavLink;
  const activeStyle = variant === CONTENT && selected ? styles.NavLinkActive : {};

  if (!icon && !text) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <LinkButton onClick={ onClick } sx={ { ...variantStyle, ...activeStyle } }>
        { icon && <Icon name={ icon } sx={ styles.ItemIcon } /> }
        { text && <ListItemText disableTypography primary={ text } sx={ styles.ItemText } /> }
      </LinkButton>
    </ListItem>
  );
};

export default SecondaryNavItem;
