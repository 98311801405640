import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAddProgramConfig from '../AddProgram.config';
import { transformProgram } from './AddProgramModal.transformers';
import { validateImagesDimensions } from 'basics/utils/files/files.validations';
import { HandleFiles } from 'components/QuickForm/QuickForm.types';
import { useCreateProgramMutation } from 'modules/events/graphql/mutations/createProgram';
import eventState from 'modules/events/states/events.states';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useAddProgramModal = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addProgramConfig } = useAddProgramConfig();
  const { t } = useTranslation();
  const { providerFields, getValues, resetForm, useWatchForm } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const [createProgram] = useCreateProgramMutation();

  const getTransformProgramLogo = useCallback(() => {
    const formValues = getValues();
    return transformProgram(formValues);
  }, [getValues]);

  const handleAddProgram = async () => {
    setIsSubmitting(true);
    const formValues = getTransformProgramLogo();
    const programData = await createProgram(formValues);
    setIsSubmitting(false);

    if (programData) {
      eventState.programs.data.push({ ...programData });
      resetForm();
      setOpen(false);
    }
  };

  const handleDropImage: HandleFiles = async (files) => {
    const { minWidth, minHeight } = addProgramConfig.programLogo.dimensions;
    const dimensionsAreValid = await validateImagesDimensions({
      files,
      dimensions: addProgramConfig.programLogo.dimensions,
    });

    return dimensionsAreValid ? { ok: true } : {
      ok: false,
      errorMessage: `${t('cardImage_dimensions_validation_error_message', { imageWidth: minWidth, imageHeight: minHeight })}`,
    };
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: () => setOpen(false),
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleAddProgram,
      disabled: !isValid,
      buttonType: ButtonType.primary,
    },
    label: t('events_add_program_form_submit_label'),
    loading: isSubmitting,
  };

  return {
    addProgramConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    handleAddProgram,
    handleDropImage,
    t,
  };
};

export default useAddProgramModal;
