import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { useYodaForm } from 'services/yodaForm';

const useCommunicationStep = () => {
  const { useWatchField } = useYodaForm();
  const isVisibleOnBiogenLincField = useWatchField(commonFields.isVisibleOnBiogenLinc);
  const showSendInvitations = useWatchField(commonFields.showSendInvitations);
  const isPublic = useWatchField(commonFields.isPublic);

  return {
    isVisibleOnBiogenLinc: isVisibleOnBiogenLincField?.value,
    showSendInvitations: showSendInvitations?.value,
    isPublic: isPublic?.value,
  };
};

export default useCommunicationStep;
