import { assetFragment } from 'basics/graphql/fragments/commonData.fragments';
import {
  agendaFragment,
  biogenLincConfigFragment,
  participantFragment,
  providerFragment,
  sessionFragment,
  testimonialFragment,
} from 'modules/events/graphql/fragments/commonData.fragments';
import { userFragment } from 'modules/events/graphql/fragments/event.fragments';
import { speakerFragment } from 'modules/events/graphql/fragments/speaker.fragments';

export const onlineEventDataFragment = `
  __typename
  coOrganizers {
    ${userFragment}
  }
  session {
    ${sessionFragment}
  }
  product
  program
  programLogoUrl
  provider {
    ${providerFragment}
  }
  assets {
    cardImage {
      ${assetFragment}
    }
  }
  speakers {
    ${speakerFragment}
  }
  testimonials {
    ${testimonialFragment}
  }
  agenda {
    ${agendaFragment}
  }
  biogenLincConfig {
    ${biogenLincConfigFragment}
  }
  participants {
    ${participantFragment}
  }
  recordingOptinLabel
  disclaimerText
  productInformationButtonLabel
  productInformationButtonLink
  participantsSupportPhoneNumber
  speakerSupportPhoneNumber
  collectParticipantPhoneNumber
  invitationSent
  enableZoomStreaming
  emailHeaderNote
  emailFooterNote
  biogenLogo
  buttonColor
`;
