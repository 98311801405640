import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCiTemplateEdit from './CiTemplateEdit.hook';
import CustomerInitiativeForm from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm';
import Heading from 'modules/maya/components/Heading';
import MayaLayout from 'modules/maya/components/MayaLayout';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';

const CiTemplateEdit: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    customerInitiative,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  } = useCiTemplateEdit();

  return (
    <MayaLayout>
      <Heading>
        { t('maya_ci_tpl_edit_form_title') }
      </Heading>

      {
        loading
          ? (

            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Loader center />
            </Box>
          )
          : (
            <CustomerInitiativeForm {
              ...{
                customerInitiative,
                submitCallback: handleSaveCustomerInitiativeForm,
                cancelCallback: handleCancelCustomerInitiativeForm,
              }
            } />
          )
      }
    </MayaLayout>
  );
};

export default CiTemplateEdit;
