import { string, boolean } from 'yup';

export const maxNoteLength = 500;
export const maxColorLength = 7;
const useEmailNotesSchema = () => {
  return {
    emailHeaderNote: string()
      .max(maxNoteLength)
      .nullable(true),
    emailFooterNote: string()
      .max(maxNoteLength)
      .nullable(true),
    emailContentfulUrl: string()
      .url('please provide a valid URL')
      .nullable(),
    biogenLogo: boolean()
      .nullable(true),
    buttonColor: string()
      .max(maxColorLength)
      .nullable(true),
  };
};

export default useEmailNotesSchema;
