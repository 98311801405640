import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Speaker } from 'modules/events/basics/types/speaker.types';

const useSpeakers = () => {
  const { t } = useTranslation();

  const transformToState = (speaker: Speaker) => pick(speaker, ['id', 'firstName', 'lastName', 'email']);

  const transformSpeakerToCard = (speaker: Speaker) => ({
    id: speaker.id,
    property1: speaker.firstName,
    property2: speaker.lastName,
    ...(speaker?.email ? { property3: `(${speaker.email})` } : {}),
  });

  return {
    transformSpeakerToCard,
    transformToState,
    t,
  };
};

export default useSpeakers;
