import { FC } from 'react';
import useEditOnlineWebinar from './EditOnlineWebinar.hook';
import InviteesRegistrationPanel from './Panels/InviteesRegistrationPanel';
import LargeContainer from 'components/Layout/LargeContainer';
import EditOnlineSideNav from 'modules/events/components/EditOnlineSideNav/EditOnlineSideNav';
import useEditOnlineSideNavigation from 'modules/events/components/EditOnlineSideNav/EditOnlineSideNav.hook';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';
import { fromUnitsToRem, YodaSpacing } from 'yoda-ui/yodaTheme';

const EditOnlineWebinar: FC = () => {
  const { loading, yodaFormMethods } = useEditOnlineWebinar();
  const { enumActions, selectedPanel } = useEditOnlineSideNavigation();

  if (loading) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return (
    <LargeContainer
      columnGap={ fromUnitsToRem(32) }
      display="grid"
      gridTemplateColumns={ `${fromUnitsToRem(132)} 1fr` }
      margin="0 auto"
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box marginTop={ YodaSpacing.medium }>
        <EditOnlineSideNav/>
      </Box>
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <Box hidden={ selectedPanel !== enumActions.inviteesRegistrations } flexGrow="1">
          <InviteesRegistrationPanel/>
        </Box>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default EditOnlineWebinar;
