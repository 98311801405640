import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { CepFormSubmitCallbackType } from 'modules/maya/components/CepForm/CepForm.types';
import { useUpdateCepMutation } from 'modules/maya/graphql/mutations/updateCep';
import useGetCepByIdQuery from 'modules/maya/graphql/queries/getCepById';
import cepState from 'modules/maya/states/cep.states';

const useCepEdit = () => {
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();

  const urlParams = new URLSearchParams(window.location.search);
  const cepId = urlParams.get('id') as string;

  const customerEngagementPlanning = useGetCepByIdQuery(cepId || null).data;
  const cepStateValue = useSnapshot(cepState) as CepStateType;

  useEffect(() => {
    if (customerEngagementPlanning) {
      cepState.cep = customerEngagementPlanning;
    } else {
      cepState.cep = {};
    }
  }, [customerEngagementPlanning]);

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
      cepId,
      cep,
      true,
    );

    return customerEngagementPlanningData;
  };

  return {
    cepStateValue,
    submitCallback,
  };
};

export default useCepEdit;
