import { FC } from 'react';
import assetsListStyle from './CardAsset.styles';
import FieldCard from 'components/FieldCard';
import { CardAssetItem, HandleRemoveItem } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/Box';
import CardMedia from 'yoda-ui/Card/CardMedia';
import Icon, { Icons } from 'yoda-ui/Icons/Icon/Icon';
import { YodaColors, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type AssetCardProps = {
  item: CardAssetItem;
  handleRemoveItem: HandleRemoveItem;
  disabled: boolean;
};

const AssetCard: FC<AssetCardProps> = ({ item, handleRemoveItem, disabled }) => (
  <Box
    marginBottom={ YodaSpacing.medium }
    data-testid="asset-container"
  >
    <FieldCard sx={ assetsListStyle.assets }>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <CardMedia
          sx={ assetsListStyle.media }
          image={ item.url }
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginLeft={ YodaSpacing.small }
        >
          <Box fontWeight={ YodaFontWeight.medium }>{ item.name }</Box>
          <Box fontWeight={ YodaFontWeight.light }>{ item.size }</Box>
        </Box>
      </Box>
      {
        disabled ? null : (<Box display="flex" justifyContent="center">
          <Icon
            data-testid="close-icon"
            name={ Icons.close }
            style={ { fill: YodaColors.gray3, cursor: 'pointer' } }
            onClick={ () => handleRemoveItem(item.id) }
          />
        </Box>)
      }

    </FieldCard>
  </Box>
);

export default AssetCard;
