import useAutologinLink from './PrcBlock.hook';
import { YodaFieldValidation } from 'services/yodaForm/yodaForm.types';
import CTALink from 'yoda-ui/BiogenLincDS/CTALink';
import Box from 'yoda-ui/Box';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { Icons } from 'yoda-ui/Icons/Icon';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

type PrcBlockProps = {
  headerConfig: {
    buttonLabel: string;
    loading: boolean;
    // disabled: boolean;
    title: string;
    // handleDownload: QueryGetScreenshots; // to be extended with other query types // decomissioned
  };
  fieldConfig: {
    name: string;
    label: string;
    placeholder: string;
    validation: YodaFieldValidation;
  };
};

const PrcBlock = ({ fieldConfig, headerConfig: { buttonLabel, title, loading/* , disabled */ } }: PrcBlockProps) => {
  const {
    isVisibleOnBiogenLinc,
    finalPublicBiogenLincLink,
    finalGatedBiogenLincLink,
    isPublic,
  } = useAutologinLink();
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={ YodaSpacing.medium }
      >
        <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
          { title }
        </Box>
        <Box>
          {
            isVisibleOnBiogenLinc && isPublic === 'public' && (
              <CTALink
                label={ buttonLabel }
                endIconName={ loading ? Icons.donwload : Icons.openInNew }
                onClick={ () => window.open(finalPublicBiogenLincLink, '_blank') }
              />
            )
          }
          {
            isVisibleOnBiogenLinc && isPublic === 'gated' && (
              <CTALink
                label={ buttonLabel }
                endIconName={ loading ? Icons.donwload : Icons.openInNew }
                onClick={ () => window.open(finalGatedBiogenLincLink, '_blank') }
              />
            )
          }
          {
            !isVisibleOnBiogenLinc && (
              <CTALink
                label={ buttonLabel }
                endIconName={ loading ? Icons.donwload : Icons.openInNew }
                disabled
              />
            )
          }
        </Box>
      </Box>
      <TextYoda { ...fieldConfig } />
    </>
  );
};

export default PrcBlock;
