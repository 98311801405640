import { MODULES_LOGO_PATH } from './images.constants';
import { BACKOFFICE_LOGO } from 'basics/constants/images.constants';
import { mayaTacticPrintRoute } from 'basics/constants/routes.constants';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { ConfigModule } from 'basics/enums/general.enums';

const TAListProducts = {
  [BiogenTherapeuticArea.ALZHEIMER]: [
    BiogenProduct.Aducanumab,
  ],
  [BiogenTherapeuticArea.BIOSIMS]: [
    BiogenProduct.Benepali,
    BiogenProduct.Biosimilar,
    BiogenProduct.Byooviz,
    BiogenProduct.Flixabi,
    BiogenProduct.Imraldi,
  ],
  [BiogenTherapeuticArea.MS]: [
    BiogenProduct.Avonex,
    BiogenProduct.Fampyra,
    BiogenProduct.MS_Portfolio,
    BiogenProduct.Plegridy_IM,
    BiogenProduct.Plegridy,
    BiogenProduct.Tecfidera,
    BiogenProduct.Tysabri_IV,
    BiogenProduct.Tysabri,
    BiogenProduct.Vumerity,
  ],
  [BiogenTherapeuticArea.SMA]: [
    BiogenProduct.Spinraza,
  ],
};

export const therapeuticAreaRelativesProducts = Object.seal(TAListProducts);

export const BACKOFFICE_TITLE = 'BiogenLinc Backoffice';
export const MAYA_TITLE = 'BiogenLinc Maya';
export const EVENT_TITLE = 'BiogenLinc Events';
export const APP_TITLE = `Biogen ${BACKOFFICE_TITLE}`;
export const WEBINARS_EMAIL = 'https://reqform.mybiogen.net/#/other-requests/other';
export const SUPPORT_EVENT_EMAIL = 'https://reqform.mybiogen.net/#/other-requests/other';
export const SUPPORT_MAYA_EMAIL = 'https://reqform.mybiogen.net/#/other-requests/other';
export const appBarHeight = '6rem';
export const TIME_20_MINUTES_IN_MS = 20 * 60 * 1000;
export const TIME_ONE_MINUTE_IN_SECONDS = 60;
export const TIME_FIVE_MINUTES_IN_SECONDS = 5 * 60;
export const TIME_ONE_SECOND_IN_MS = 1000;
export const TIME_ONE_SECOND_IN_SECONDS = 1;
export const MAYA_GRAPHQL_ENDPOINT = 'maya_endpoint';
export const EVENT_GRAPHQL_ENDPOINT = 'event_endpoint';

export const MODULES_CONFIG = {
  [ConfigModule.Maya]: {
    logo: `${MODULES_LOGO_PATH}/new_maya_logo.png`,
    module: 'maya',
    path: '/maya',
    support: SUPPORT_MAYA_EMAIL,
    title: MAYA_TITLE,
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
    enableCountrySelector: true,
    noLayoutURL: [mayaTacticPrintRoute],
  },
  [ConfigModule.Events]: {
    logo: `${BACKOFFICE_LOGO}`,
    module: 'events',
    path: '/events',
    support: `${SUPPORT_EVENT_EMAIL}`,
    title: `${EVENT_TITLE}`,
    context: { clientName: EVENT_GRAPHQL_ENDPOINT },
    enableCountrySelector: true,
    noLayoutURL: [],
  },
  [ConfigModule.Default]: {
    logo: `${BACKOFFICE_LOGO}`,
    module: '',
    path: '/',
    support: `${WEBINARS_EMAIL}`,
    title: `${BACKOFFICE_TITLE}`,
    context: { clientName: EVENT_GRAPHQL_ENDPOINT },
    enableCountrySelector: true,
    noLayoutURL: [],
  },
};

