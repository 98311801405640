import { FC } from 'react';
import useFormAsyncSelect from './FormAsyncSelect.hook';
import { FieldState, SetFieldState } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/Box';
import Button from 'yoda-ui/Button';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import SelectAsync from 'yoda-ui/Form/SelectAsync';
import { SelectAsyncProps } from 'yoda-ui/Form/SelectAsync/SelectAsync.types';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type FormAsyncSelectProps = SelectAsyncProps & {
  addButtonLabel?: string;
  fieldState: FieldState;
  setFieldState: SetFieldState;
  transformToState: Function;
};

const FormAsyncSelect: FC<FormAsyncSelectProps> = ({
  fieldState,
  setFieldState,
  transformToState,
  disabled,
  ...rest
}) => {
  const { addButtonLabel, ...selectAsyncProps } = rest;
  const {
    canAddItem,
    setCanAddItem,
    handleAddItem,
    handleOnChange,
    t,
  } = useFormAsyncSelect({ setFieldState, transformToState });

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      marginBottom={ YodaSpacing.medium }
    >
      <Box width="80%">
        <SelectAsync
          onChange={ handleOnChange }
          reset={ !canAddItem }
          setCanAdd={ setCanAddItem }
          excludedOptions={ fieldState }
          disabled={ disabled }
          { ...selectAsyncProps }
        />
      </Box>
      <Button
        data-testid="add-button"
        buttonType={ ButtonType.neutral }
        onClick={ handleAddItem }
        disabled={ disabled || !canAddItem }
      >
        { addButtonLabel || t('actions_add_label') }
      </Button>
    </Box>
  );
};

export default FormAsyncSelect;
