import { Dispatch, SetStateAction } from 'react';
import useCepFormTargetListGroupConfig from './CepFormEstimationCostGroup.config';
import { FormattedChannelType } from './CepFormEstimationCostGroup.types';
import { Channel, ChannelsCost, ChannelsDetail } from 'modules/maya/basics/enums/maya.enums';
import { useYodaForm } from 'services/yodaForm';

const useCepFormEstimationCostGroupGroup = () => {
  const { cepFormEstimationCostGroupConfig } = useCepFormTargetListGroupConfig();

  const yodaFormMethods = useYodaForm();

  const formatChannels = () => {
    const formValues = yodaFormMethods.getValues();

    if (!formValues.channels) {
      return [];
    }

    return formValues.channels.map((channel: Channel, index: number) => {
      const channelIndex = Object.keys(Channel)[Object.values(Channel).indexOf(channel)];
      const channelStandardCost = ChannelsCost[channelIndex as keyof typeof ChannelsCost];
      const channelDetail = ChannelsDetail[channelIndex as keyof typeof ChannelsDetail];

      const formattedChannel: FormattedChannelType = {
        name: channel,
        detail: channelDetail,
        standardCost: channelStandardCost,
        formConfig: cepFormEstimationCostGroupConfig(index),
      };

      return formattedChannel;
    });
  };

  const calculatedEstimatedCost = () => {
    const formValues = yodaFormMethods.getValues();

    let totalEstimatedCost = 0;

    if (formValues.channels) {
      totalEstimatedCost = formValues.channels.reduce((previousValue: number, _currentValue: Channel, currentIndex: number) => {
        return previousValue + parseInt(formValues[`estimatedCost_${currentIndex}`], 10) || 0;
      }, 0);
    }

    return totalEstimatedCost;
  };

  const calculateStandardCostFromForm = (channel: FormattedChannelType) => {
    const formValues = yodaFormMethods.getValues();
    const channelCost = channel.standardCost * parseInt(formValues[channel.formConfig.number.name], 10);
    return channelCost || 0;
  };

  const calculateStandardCost = (channel: FormattedChannelType, value: number) => {
    const channelCost = channel.standardCost * value;
    return channelCost;
  };

  const updateOneStandardCost = (
    channel: FormattedChannelType,
    value: number,
    standardCosts: { [key: string]: number },
    setStandardCosts: Dispatch<SetStateAction<{ [p: string]: number }>>,
  ) => {
    setStandardCosts({ ...standardCosts, [channel.formConfig.number.name]: calculateStandardCost(channel, value) });
  };

  const calculateTotalStandardCost = (
    standardCosts: { [key: string]: number },
    channels: FormattedChannelType[],
  ) => {
    return channels.reduce((total: number, channel: FormattedChannelType) => {
      return total + (standardCosts[channel.formConfig.number.name] ?? calculateStandardCostFromForm(channel));
    }, 0);
  };

  return {
    formatChannels,
    calculateTotalStandardCost,
    calculateStandardCost,
    updateOneStandardCost,
    calculateStandardCostFromForm,
    calculatedEstimatedCost,
  };
};

export default useCepFormEstimationCostGroupGroup;
