import { Box } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/ActionButton';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import useRegisterParticipantGroup from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.hook';
import { YodaFormProvider } from 'services/yodaForm';
import ConfirmationDialog from 'yoda-ui/Dialogs/ConfirmationDialog';
import TextYoda from 'yoda-ui/Form/TextYoda';
import Loader from 'yoda-ui/Loader';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

type RegisterParticipantGroupProps = {
  eventId: string;
  participants: Participant[];
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>;
};

const RegisterParticipantGroup: FC<RegisterParticipantGroupProps> = (props) => {
  const { t } = useTranslation();

  const {
    registerParticipantGroup,
    submitButtonConfig,
    open,
    setOpen,
    registerParticipantCallback,
    providerFields,
    isSubmitting,
    newParticipant,
  } = useRegisterParticipantGroup(props.eventId, props.participants, props.setParticipants);

  return (
    <YodaFormProvider { ...providerFields }>
      <FormGroup
        title={ t('event_register_participant_group') }
        groupName={ CommonGroups.registerParticipant }
        collapsible={ false }
      >
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda { ...registerParticipantGroup.participantMdmid } />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={ YodaSpacing.small }>
          <ActionButton { ...submitButtonConfig } />
        </Box>

        {
          open && newParticipant && (
            <ConfirmationDialog
              dialogTitle={ t('register_partipant_modal_title') }
              onConfirm={
                () => {
                  registerParticipantCallback();
                }
              }
              onConfirmLabel={ isSubmitting ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
              onDismiss={ () => setOpen(false) }
              onDismissLabel={ t('actions_cancel_label') }
            >
              <Box>{ t('register_partipant_email_label') }: { newParticipant.email } </Box>
              <Box>{ t('register_partipant_firstname_label') }: { newParticipant.firstName } </Box>
              <Box>{ t('register_partipant_lastname_label') }: { newParticipant.lastName } </Box>
              <Box>{ t('register_partipant_mdmid_label') }: { newParticipant.mdmId } </Box>
            </ConfirmationDialog>
          )
        }
      </FormGroup>
    </YodaFormProvider>
  );
};

export default RegisterParticipantGroup;
