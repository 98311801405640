import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Participant } from 'modules/events/basics/types/events.types';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import { useYodaCreateForm } from 'services/yodaForm';

const useEditOnlineMeeting = () => {
  const [participants, setParticipants] = useState<Participant[] | null>(null);
  const { id } = useParams();

  const { queryGetFullPrivateEventById, data: event, error, loading } = useLazyGetFullPrivateEventById();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  useEffect(() => {
    if (id) {
      queryGetFullPrivateEventById({ variables: { eventId: id } });
    }
  }, [id, queryGetFullPrivateEventById]);

  useEffect(() => {
    if (event) {
      setParticipants(event.data.participants || null);
    }
  }, [event]);

  return {
    error,
    event,
    loading,
    yodaFormMethods,
    participants,
    setParticipants,
  };
};

export default useEditOnlineMeeting;
