import { Alert, AlertColor } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCard from 'components/ContentCard';
import QuickForm from 'components/QuickForm';
import { FormShape } from 'components/QuickForm/QuickForm.enums';
import { InitiativeCountry } from 'generated/maya.types';
import ExportForm from 'modules/maya/components/ExportForm/ExportForm';
import useExportForm from 'modules/maya/components/ExportForm/ExportForm.hook';
import Heading from 'modules/maya/components/Heading';
import MayaLayout from 'modules/maya/components/MayaLayout';
import useAopImport from 'modules/maya/routes/ImportExport/AopImport/AopImport.hook';
import useExport from 'modules/maya/routes/ImportExport/Export/Export.hook';
import { useYodaCreateForm, YodaFormProvider } from 'services/yodaForm';
import { ActionButton, ButtonVariant } from 'yoda-ui/BiogenLincDS/Buttons';
import Box from 'yoda-ui/Box';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';
import Loader from 'yoda-ui/Loader';
import { YodaSpacing, YodaFontSize, YodaColors, fromUnitsToRem } from 'yoda-ui/yodaTheme';

const AopImportExport: FC = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string>('pending');
  const { countrySelected, providerFields, exportFormConfig, handleChangeCountrySelected } = useExportForm(setStatus);
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const { data, error, exportData, loading: exportLoading, refetch } = useExport();

  const { uploadAopImportConfig:
    {
      handleFilesExternal,
      handleRemoveItemAsync,
      formConfig,
      defaultValue,
      validation,
      name,
    },
  loading: importLoading } = useAopImport();

  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });

  useEffect(() => {
    if (error) {
      setStatus('error');
      setTriggerDownload(false);
    }

    if (!exportLoading && data && triggerDownload) {
      const url = URL.createObjectURL(new Blob(
        [data as Buffer],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      ));
      const event = new MouseEvent('click');
      const el = document.createElement('a');
      el.setAttribute('href', url);
      el.setAttribute('download', `export-maya-${new Date().getTime()}`);
      el.dispatchEvent(event);
      URL.revokeObjectURL(url);

      setStatus('success');
      setTriggerDownload(false);
    }
  }, [exportLoading, data, error, triggerDownload]);

  const initFC = (
    <>
      { t('maya_export_description') }

      <Box
        marginTop={ YodaSpacing.xxxSmall }
      >
        <ActionButton
          icon={ Icons.donwload }
          label={ t('common.actions.downloadButtonLabel') }
          onClick={
            async () => {
              await exportData(
                {
                  variables: {
                    countries: countrySelected as [InitiativeCountry],
                  },
                },
              );
              setTriggerDownload(true);
            }
          }
          variant={ ButtonVariant.primary }
        />
      </Box>
    </>
  );

  const pendingFC = (
    <>
      { t('maya_export_pending') }

      <Box
        marginTop={ YodaSpacing.small }
      >
        <Loader size={ 1.5 } />
      </Box>
    </>
  );

  const errorFC = (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' height='100%' marginBottom={ YodaSpacing.large }>
        <Alert severity={ 'error' as AlertColor }>
          { t('maya_export_error') }
        </Alert>
      </Box>

      <Box
        marginTop={ YodaSpacing.small }
      >
        <ActionButton
          icon={ Icons.loop }
          label={ t('common.actions.reloadButtonLabel') }
          onClick={
            async () => {
              await refetch();
              setTriggerDownload(true);
            }
          }
          variant={ ButtonVariant.primary }
        />
      </Box>
    </>
  );

  const successFC = (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' height='100%' marginBottom={ YodaSpacing.large }>
        <Alert severity={ 'success' as AlertColor }>
          { t('maya_export_success') }
        </Alert>
      </Box>
    </>
  );

  return (
    <MayaLayout>
      <ContentCard>
        <Heading>
          { t('maya_aop_import_title') }
        </Heading>
        <Box
          marginBottom={ fromUnitsToRem(YodaSpacing.xxLarge) }
          marginTop={ fromUnitsToRem(YodaSpacing.xxLarge) }
          marginLeft={ '15%' }
          marginRight={ '15%' }
        >
          {
            importLoading ? (<Box
              display='flex'
              justifyContent='center'
              marginTop={ YodaSpacing.xLarge }
            >
              <Loader />
            </Box>) : (
              <YodaFormProvider { ...yodaFormMethods.providerFields }>
                <QuickForm
                  name={ name }
                  form={ { shape: FormShape.formAsset, formConfig: { handleFiles: handleFilesExternal, ...formConfig } } }
                  handleRemoveItemAsync={ handleRemoveItemAsync }
                  defaultValue={ defaultValue }
                  validation={ validation }
                />
              </YodaFormProvider>)
          }
        </Box>
      </ContentCard>
      <Box marginBottom={ YodaSpacing.xLarge }></Box>
      <ContentCard>
        <Heading>
          { t('maya_export_title') }
        </Heading>
        <ExportForm
          exportFormConfig={ exportFormConfig }
          providerFields={ providerFields }
          handleChangeCountrySelected={ handleChangeCountrySelected }
        />
        <Box
          marginTop={ '-60px' }
          marginBottom={ YodaSpacing.medium }
          fontSize={ YodaFontSize.medium }
          color={ YodaColors.gray4 }
        >
          { exportLoading && pendingFC }
          { !exportLoading && data && status === 'success' && successFC }
          { !exportLoading && error && status === 'error' && errorFC }
          { !exportLoading && countrySelected && countrySelected.length > 0 && status !== 'error' && initFC }
        </Box>
      </ContentCard>
    </MayaLayout>
  );
};

export default AopImportExport;
