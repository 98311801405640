import { FC, useMemo } from 'react';
import ParticipantsItem from './ParticipantsItem';
import { ParticipantsTabs } from './ParticipantsList.enums';
import useParticipantsList from './ParticipantsList.hook';
import * as styles from './ParticipantsList.styles';
import { ParticipantsListProps } from './ParticipantsList.types';
import Box from 'yoda-ui/Box';
import List from 'yoda-ui/List';
import Tabs from 'yoda-ui/Tabs';
import Tab from 'yoda-ui/Tabs/Tab';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const ParticipantsList: FC<ParticipantsListProps> = (props) => {
  const { participants } = props;
  const { INVITED, REGISTERED } = ParticipantsTabs;

  const { itemsInvited, itemsRegistered, selectedTab, tabsConfig } = useParticipantsList({ participants });

  const tabs = useMemo(
    () => tabsConfig.map(({ label, onClick, value }) => (
      <Tab
        key={ value }
        label={ label }
        onClick={ onClick }
        value={ value }
        sx={ styles.Tab }
      />
    )),
    [tabsConfig],
  );

  return (
    <>
      <Box marginBottom={ YodaSpacing.large }>
        <Tabs value={ selectedTab } textColor={ 'inherit' }>
          { tabs }
        </Tabs>
      </Box>
      {
        !!itemsRegistered.length && (
          <Box hidden={ selectedTab !== REGISTERED }>
            <List disablePadding>
              { itemsRegistered.map((item) => <ParticipantsItem { ...item } key={ item.email } variant={ REGISTERED }/>) }
            </List>
          </Box>
        )
      }
      {
        !!itemsInvited.length && (
          <Box hidden={ selectedTab !== INVITED }>
            <List disablePadding>
              { itemsInvited.map((item) => <ParticipantsItem { ...item } key={ item.email } variant={ INVITED }/>) }
            </List>
          </Box>
        )
      }
    </>
  );
};

export default ParticipantsList;
