import { FC } from 'react';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Teaser from 'modules/events/components/Teaser';
import Testimonials from 'modules/events/components/Testimonials';
import useOptionalInformations from 'modules/events/groups/OptionalInformationsGroup/OptionalInformationsGroup.hook';
import Box from 'yoda-ui/Box';
import CheckBoxYoda from 'yoda-ui/Form/CheckBoxYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const OptionalInformationsGroup: FC = () => {
  const {
    optionalInformationsConfig,
    t,
  } = useOptionalInformations();

  return (
    <FormGroup title={ t('event_group_optional_information') } groupName={ CommonGroups.optionalInformations }>
      <Box marginBottom={ YodaSpacing.large } hidden={ true }>
        <Teaser
          { ...optionalInformationsConfig.teaser }
        />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <Testimonials { ...optionalInformationsConfig.testimonials } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...optionalInformationsConfig.recordingOptinLabel } />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...optionalInformationsConfig.disclaimerText } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...optionalInformationsConfig.productInformationButtonLabel } />
      </Box>

      <Box marginBottom={ YodaSpacing.large }>
        <TextYoda { ...optionalInformationsConfig.productInformationButtonLink } />
      </Box>

      <Box marginBottom={ YodaSpacing.large } width='50%'>
        <TextYoda { ...optionalInformationsConfig.participantsSupportPhoneNumber } />
      </Box>

      <Box marginBottom={ YodaSpacing.large } width='50%'>
        <CheckBoxYoda { ...optionalInformationsConfig.collectParticipantPhoneNumber } />
      </Box>

    </FormGroup>
  );
};

export default OptionalInformationsGroup;
