import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaBorderRadius } from 'yoda-ui/yodaTheme';

export const CepFormEstimationCostGroupStyle: SxProps<Theme> = {
  borderRadius: YodaBorderRadius.medium,

  '& .MuiTableRow-root': {
    background: YodaColors.white,
  },

  '& .MuiTable-root > .MuiTableRow-root .MuiTableCell-root': {
    '&:nth-last-of-type(1), &:nth-last-of-type(2)': {
      whiteSpace: 'nowrap',
    },
  },

  '& .MuiTable-root .MuiTableFooter-root, & .MuiTableRow-footer': {
    background: YodaColors.transparent,
  },

  '& .MuiTable-root .MuiTableFooter-root .MuiTableCell-root': {
    border: 'none',
  },
};

export const CepFormEstimationCostGroupBlueCellStyle: SxProps<Theme> = {
  background: YodaColors.primaryBlue,
};
