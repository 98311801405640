import { useNavigate } from 'react-router-dom';
import { mayaStrategicImperativeListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import { SiFormSubmitCallbackType } from 'modules/maya/components/StrategicImperativeForm/StrategicImperativeForm.types';
import { useCreateStrategicImperativeMutation } from 'modules/maya/graphql/mutations/createStrategicImperatives';

const useStrategicImperativeCreate = () => {
  const navigate = useNavigate();

  const [createStrategicImperative] = useCreateStrategicImperativeMutation();

  const handleCancelStrategicImperativeForm = () => {
    navigate(mayaStrategicImperativeListRoute);
  };

  const handleSaveStrategicImperativeForm: SiFormSubmitCallbackType = async (strategicImperative) => {
    const strategicImperativeData = await createStrategicImperative(strategicImperative);

    if (strategicImperativeData) {
      succesToast(`Strategic imperative ${strategicImperative.title} created`);
      navigate(mayaStrategicImperativeListRoute);
    } else {
      navigate(mayaStrategicImperativeListRoute);
      warningToast(`Cannot create strategic imperative ${strategicImperative.title}`);
    }
  };

  return {
    handleCancelStrategicImperativeForm,
    handleSaveStrategicImperativeForm,
  };
};

export default useStrategicImperativeCreate;
