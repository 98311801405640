import { FC } from 'react';

import useAddSpeakerProfileModal from './AddSpeakerProfileModal.hook';
import DialogForm from 'components/DialogForm';
import { AddSpeakerProfileModalProps } from 'modules/events/components/AddSpeakerProfile/AddSpeakerProfile.types';
import SpeakerProfile from 'modules/events/components/AddSpeakerProfile/AddSpeakerProfileModal/SpeakerProfile';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';

const AddSpeakerProfileModal: FC<AddSpeakerProfileModalProps> = ({ open, setOpen }) => {
  const {
    addSpeakerProfileConfig,
    cancelButtonConfig,
    providerFields,
    submitButtonConfig,
    t,
  } = useAddSpeakerProfileModal(setOpen);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('events_add_speakerprofile_form_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
      >
        <Box>
          <SpeakerProfile speakerProfileConfig={ addSpeakerProfileConfig } />
        </Box>
      </DialogForm>
    </YodaFormProvider>
  );
};

export default AddSpeakerProfileModal;
