import { useTranslation } from 'react-i18next';
import { RegisterParticipantGroupConfigType } from 'modules/events/groups/RegisterParticipantGroup/RegisterParticipantGroup.types';

const useRegisterParticipantGroupConfig = () => {
  const { t } = useTranslation();

  const registerParticipantGroup: RegisterParticipantGroupConfigType = {
    participantMdmid: {
      label: t('event_register_participant_mdmid_label'),
      name: 'participantMdmid',
      placeholder: t('event_register_participant_mdmid_placeholder'),
      required: true,
    },
  };

  return {
    registerParticipantGroup,
  };
};

export default useRegisterParticipantGroupConfig;
