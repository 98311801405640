export enum CepSteps {
  configurationStep = 'configurationStep',
  costEstimationStep = 'costEstimationStep',
  targetListStep = 'targetListStep',
}

export enum CepGroups {
  configuration = 'configuration',
}

