import ActionButton from 'components/ActionButton';
import ContentCard from 'components/ContentCard';
import { TargetListMode } from 'modules/maya/basics/enums/maya.enums';
import DynamicTargetListForm from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm';
import GridList from 'modules/maya/components/GridList/GridList';
import UploadTargetListForm from 'modules/maya/components/UploadTargetListForm/UploadTargetListForm';
import useCepFormTargetListGroup from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.hook';
import {
  cepApprovedDeleteTargetsButtonStyle,
  deleteTargetsButtonStyle,
  targetListSelectStyle,
} from 'modules/maya/groups/CepFormTargetListGroup/CepFormTargetListGroup.styles';
import Box from 'yoda-ui/Box';
import ConfirmationDialog from 'yoda-ui/Dialogs/ConfirmationDialog';
import RadioGroup from 'yoda-ui/Form/RadioGroup';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CepFormTargetListGroup = () => {
  const {
    cepFormTargetListGroupConfig,
    targetListMode,
    handleRemoveTargets,
    data,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    t,
    openModalButtonConfig,
    open,
    setOpen,
    isCepApproved,
    cepStateValue,
    targetListConfig,
    updateTargetList,
    setLoading,
    syncTargetsButtonConfig,
  } = useCepFormTargetListGroup();

  return (
    <>
      <Box>
        <ContentCard>
          <Box marginBottom={ YodaSpacing.medium } marginTop={ YodaSpacing.xxxSmall } >
            { t('maya_targetlist_select_title') }
          </Box>
          <Box marginBottom={ YodaSpacing.large }>
            <RadioGroup { ...cepFormTargetListGroupConfig.selectTargetList } sx={ targetListSelectStyle } />
          </Box>
        </ContentCard>
      </Box>
      <Box marginTop={ YodaSpacing.large }>
        {
          targetListMode === TargetListMode.dynamic
            ? <DynamicTargetListForm
              updateTargetList={ updateTargetList }
              loading={ loading }
              setLoading={ setLoading }
            />
            : <UploadTargetListForm
              updateTargetList={ updateTargetList }
              loading={ loading }
              setLoading={ setLoading }
            />
        }
        <ContentCard>
          <Box sx={ isCepApproved ? cepApprovedDeleteTargetsButtonStyle : deleteTargetsButtonStyle }>
            <ActionButton { ...syncTargetsButtonConfig } />
            <ActionButton { ...openModalButtonConfig } />
          </Box>
          <GridList
            getRowId={ (row) => row._id }
            loading={ loading }
            columnConfig={ targetListConfig }
            rows={ data }
            onPageChange={ onPageChange }
            onPageSizeChange={ onPageSizeChange }
            rowCountState={ rowCountState }
            disableColumnMenu={ true }
            hideFooterPagination={ rowCountState === 0 }
          />
          {
            open && (
              <ConfirmationDialog
                dialogTitle={ t('maya_targetlist_modal_remove') }
                onConfirm={
                  () => {
                    handleRemoveTargets(
                      cepStateValue.cep?._id,
                    );
                  }
                }
                onConfirmLabel={ t('actions_confirm_label') }
                onDismiss={ () => setOpen(false) }
                onDismissLabel={ t('actions_cancel_label') }
              >
              </ConfirmationDialog>
            )
          }
        </ContentCard>
      </Box>
    </>

  );
};

export default CepFormTargetListGroup;
