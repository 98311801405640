import { useTranslation } from 'react-i18next';
import useOptionalInformationsConfig from 'modules/events/groups/OptionalInformationsGroup/OptionalInformationsGroup.config';

const useOptionalInformations = () => {
  const { t } = useTranslation();
  const { optionalInformationsConfig } = useOptionalInformationsConfig();

  return {
    optionalInformationsConfig,
    t,
  };
};

export default useOptionalInformations;
