export const homeRoute = '/';
export const loginRoute = '/login';

export const eventsRoute = '/events';

export const createOnlineMeetingRoute = '/events/createOnlineMeeting';
export const createOnlineMeetingWithIdRoute = `${createOnlineMeetingRoute}/:id`;

export const createOnlineWebinarRoute = '/events/createOnlineWebinar';
export const createOnlineWebinarWithIdRoute = `${createOnlineWebinarRoute}/:id`;

export const editOnlineMeetingRoute = '/events/edit/onlineMeeting';
export const editOnlineMeetingWithIdRoute = `${editOnlineMeetingRoute}/:id`;

export const editOnlineWebinarRoute = '/events/edit/onlineWebinar';
export const editOnlineWebinarWithIdRoute = `${editOnlineWebinarRoute}/:id`;

export const mayaRoute = '/maya';

export const mayaStrategicImperativeListRoute = '/maya/strategicImperatives';
export const mayaCustomerInitiativeListRoute = '/maya/customerInitiatives';
export const mayaCiTemplateListRoute = '/maya/ciTemplate';
export const mayaCepListRoute = '/maya/customerEngagementPlannings';
export const mayaCepTemplateListRoute = '/maya/cepTemplate';

export const mayaStrategicImperativeCreateRoute = '/maya/strategicImperative/create';
export const mayaCustomerInitiativeCreateRoute = '/maya/customerInitiative/create';
export const mayaCiTemplateCreateRoute = '/maya/ciTemplate/create';
export const mayaCepCreateRoute = '/maya/cepCreate/create';
export const mayaCepTemplateCreateRoute = '/maya/cepTemplate/create';

export const mayaStrategicImperativeEditRoute = '/maya/strategicImperative/edit';
export const mayaCustomerInitiativeEditRoute = '/maya/customerInitiative/edit';
export const mayaCiTemplateEditRoute = '/maya/ciTemplate/edit';
export const mayaCepEditRoute = '/maya/customerEngagementPlanning/edit';
export const mayaCepTemplateEditRoute = '/maya/cepTemplate/edit';

export const mayaStrategicImperativeDuplicateRoute = '/maya/strategicImperative/duplicate';
export const mayaCustomerInitiativeDuplicateRoute = '/maya/customerInitiative/duplicate';
export const mayaCepDuplicateRoute = '/maya/customerEngagementPlanning/duplicate';

export const mayaCiViewRoute = '/maya/customerInitiative/view';
export const mayaCepViewRoute = '/maya/customerEngagementPlanning/view';

export const mayaImportExportRoute = '/maya/importAndExport';
export const mayaCepTrashedListRoute = '/maya/customerEngagementPlanning/trashed';
export const mayaTacticPrintRoute = '/maya/customerEngagementPlanning/tacticPrint';
