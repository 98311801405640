import { transformInputDateUTC } from 'basics/utils/dates/dates.utils';
import { CustomerInitiative, StrategicImperative } from 'generated/maya.types';
import { isStrategicImperative } from 'modules/maya/basics/types/strategicImperative.types';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';
import { DateRange } from 'yoda-ui/Form/DateRangePicker/DateRangePicker.types';

export const ciToBE = (data: YodaFieldValue) => {
  return {
    title: data.title,
    description: data.description,
    ...transformInputDateUTC(data.date),
    country: data.country,
    product: data.product,
    therapeuticArea: data.therapeuticArea,
    strategicImperative: data.strategicImperative,
    problemStatement: data.problemStatement,
    solutionCoreStory: data.solutionCoreStory,
    successMesure: data.successMesure,
    functionalOwner: data.functionalOwner,
    functionsInvolved: data.functionsInvolved,
    totalBudget: data.totalBudget,
    isTemplate: data.isTemplate,
  };
};

export const ciToForm = (
  ci: Partial<CustomerInitiative> | undefined | null,
  strategicImperative?: StrategicImperative | undefined,
) => {
  const strategicImperativeDefaultValue = isStrategicImperative(ci?.strategicImperative) && ci?.strategicImperative._id
    ? ci?.strategicImperative._id
    : (strategicImperative?._id || '');

  const therapeuticAreaDefaultValue = ci?.therapeuticArea || strategicImperative?.therapeuticArea || '';

  const dateStart = new Date(ci?.dateStart);
  const dateStartUTC = new Date(
    dateStart.getUTCFullYear(),
    dateStart.getUTCMonth(),
    dateStart.getUTCDate(),
    dateStart.getUTCHours(),
    dateStart.getUTCMinutes(),
    dateStart.getUTCSeconds(),
  );

  const dateEnd = new Date(ci?.dateEnd);
  const dateEndUTC = new Date(
    dateEnd.getUTCFullYear(),
    dateEnd.getUTCMonth(),
    dateEnd.getUTCDate(),
    dateEnd.getUTCHours(),
    dateEnd.getUTCMinutes(),
    dateEnd.getUTCSeconds(),
  );

  return {
    title: ci?.title || '',
    description: ci?.description || '',
    date: (ci?.dateStart && ci?.dateEnd) ? [dateStartUTC, dateEndUTC] as DateRange : undefined,
    country: ci?.country || '',
    product: ci?.product || '',
    therapeuticArea: therapeuticAreaDefaultValue,
    strategicImperative: strategicImperativeDefaultValue,
    problemStatement: ci?.problemStatement || '',
    solutionCoreStory: ci?.solutionCoreStory || '',
    successMesure: ci?.successMesure || '',
    functionalOwner: ci?.functionalOwner || '',
    functionsInvolved: ci?.functionsInvolved || [],
    totalBudget: ci?.totalBudget || '',
  };
};
