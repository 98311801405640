import { useTranslation } from 'react-i18next';
import { StepButtonsConfig } from 'components/ButtonsBar/ButtonsBar';
import { CommonSteps } from 'modules/events/basics/enums/commonForms.enums';
import { CreateOnlineWebinarStepsIndex } from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.types';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCreateOnlineWebinarButtons = (onlineWebinarActions: any, isDirty: boolean, readyToPublish = false) => {
  const { handleSaveData,
    stepIndexer,
    handlePublishEvent,
    publishLoading = false,
    saveAndNext,
    saveAndPrevious,
    updateLoading } = onlineWebinarActions;
  const { t } = useTranslation();

  const firstStepButtons: StepButtonsConfig = {
    secondButton: {
      buttonProps: {
        disabled: updateLoading || !isDirty,
        onClick: handleSaveData,
        buttonType: ButtonType.neutral,
      },
      label: t('toast_draft_saved'),
      hidden: false,
      loading: updateLoading,
    },
    thirdButton: {
      buttonProps: {
        disabled: updateLoading,
        onClick: saveAndNext,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
      hidden: false,
      loading: updateLoading,
    },
  };

  const middleStepsButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        disabled: updateLoading,
        onClick: saveAndPrevious,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
      hidden: false,
      loading: updateLoading,
    },
    secondButton: {
      buttonProps: {
        disabled: updateLoading || !isDirty,
        onClick: handleSaveData,
        buttonType: ButtonType.neutral,
      },
      label: t('toast_draft_saved'),
      hidden: false,
      loading: updateLoading,
    },
    thirdButton: {
      buttonProps: {
        disabled: updateLoading,
        onClick: saveAndNext,
        buttonType: ButtonType.primary,
      },
      label: t('actions_next_label'),
      hidden: false,
      loading: updateLoading,
    },
  };

  const lastStepButtons: StepButtonsConfig = {
    firstButton: {
      buttonProps: {
        disabled: updateLoading,
        onClick: saveAndPrevious,
        buttonType: ButtonType.secondary,
        startIconName: Icons.chevronLeft,
      },
      label: t('actions_previous_label'),
      hidden: false,
      loading: updateLoading,
    },
    secondButton: {
      buttonProps: {
        disabled: updateLoading || !isDirty,
        onClick: handleSaveData,
        buttonType: ButtonType.neutral,
      },
      label: t('toast_draft_saved'),
      hidden: false,
      loading: updateLoading,
    },
    thirdButton: {
      buttonProps: {
        disabled: !readyToPublish,
        onClick: handlePublishEvent,
        buttonType: ButtonType.primary,
      },
      label: t('actions_publish_label'),
      loading: publishLoading,
      hidden: false,
    },
  };

  switch (CreateOnlineWebinarStepsIndex[stepIndexer.stepIndex]) {
    case CommonSteps.detailsStep:
      return firstStepButtons;
    case CommonSteps.sessionStep:
      return lastStepButtons;
    case CommonSteps.communicationStep:
      return middleStepsButtons;
    default:
      return null;
  }
};

export default useCreateOnlineWebinarButtons;
