import { useMemo } from 'react';
import { InviteesGroupEnums } from './InviteesGroup.enums';
import useInviteesGroup from './InviteesGroup.hook';
import * as styles from './InviteesGroup.styles';
import CardSwitch from 'components/CardSwitch';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import UploadInvitees from 'modules/events/components/UploadInvitees';
import Box from 'yoda-ui/Box';
import Tabs from 'yoda-ui/Tabs';
import Tab from 'yoda-ui/Tabs/Tab';

const InviteesGroup = () => {
  const { UPLOAD_LIST } = InviteesGroupEnums;
  const {
    cardSwitchConfig,
    uploadInviteesConfig,
    openedPanel,
    selectedTab,
    setSelectedTab,
    t,
    tabsConfig,
  } = useInviteesGroup();

  const tabs = useMemo(
    () => (tabsConfig.map(({ label, value }) => (
      <Tab
        key={ value }
        label={ label }
        value={ value }
        sx={ styles.Tab }
      />
    ))),
    [tabsConfig],
  );

  const uploadInviteesPanel = useMemo(
    () => <UploadInvitees { ...uploadInviteesConfig } />,
    [uploadInviteesConfig],
  );

  return (
    <>
      <CardSwitch
        name={ cardSwitchConfig.name }
        title={ cardSwitchConfig.title }
        defaultValue={ cardSwitchConfig.defaultValue }
        disabled={ cardSwitchConfig.disabled }
      />
      {
        openedPanel && (
          <FormGroup title={ t('inviteesGroup_title') } groupName={ CommonGroups.invitees }>
            <Tabs value={ selectedTab } onChange={ (_, value) => setSelectedTab(value) }>
              { tabs }
            </Tabs>
            <Box hidden={ selectedTab !== UPLOAD_LIST }>
              { uploadInviteesPanel }
            </Box>
          </FormGroup>
        )
      }
    </>
  );
};

export default InviteesGroup;
