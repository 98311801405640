import { useTranslation } from 'react-i18next';
import { array, mixed, number, object, string } from 'yup';
import { AssetTarget, AssetType } from 'generated/event.types';

export const maxTestimonyLength = 200;
export const maxRecordingOptinLabel = 255;
export const maxDisclaimerText = 255;
const useOptionalInformationsSchema = () => {
  const { t } = useTranslation();

  const maxLengthTestimonyMessage = `${t('events_field_testimony_error_max_message', { count: maxTestimonyLength })}`;
  const maxLengthRecordingOptinLabelMessage = `${t('events_field_recording_optin_error_max_message', { count: maxRecordingOptinLabel })}`;
  const maxLengthDisclaimerTextMessage = `${t('events_field_disclaimer_text_error_max_message', { count: maxDisclaimerText })}`;

  return {
    teaser: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        size: number().required(),
        name: string().required(),
        url: string().required(),
        type: mixed<AssetType>().required(),
        target: mixed<AssetTarget>().required(),
      })),
    testimonials: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        author: string().required(),
        testimony: string()
          .required()
          .max(maxTestimonyLength, maxLengthTestimonyMessage),
      })),
    recordingOptinLabel: string()
      .nullable(true)
      .max(maxRecordingOptinLabel, maxLengthRecordingOptinLabelMessage),
    disclaimerText: string()
      .nullable(true)
      .max(maxDisclaimerText, maxLengthDisclaimerTextMessage),
    productInformationButtonLabel: string()
      .nullable(true),
    productInformationButtonLink: string()
      .nullable(true),
    participantsSupportPhoneNumber: string()
      .nullable(true),
  };
};

export default useOptionalInformationsSchema;
