import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'basics/options/options.types';
import { TargetFilter, TargetsFacets } from 'generated/maya.types';
import useTargetsFiltersOptions from 'modules/maya/basics/options/targetsFilters.options';
import useDynamicTargetListSchema from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.schema';
import { DynamicTargetListFormConfigType } from 'modules/maya/components/DynamicTargetListForm/DynamicTargetListForm.types';

const useDynamicTargetListConfig = (
  targetsFacets: TargetsFacets,
  filteredTargetsFacets: TargetsFacets,
  targetFilters: TargetFilter,
  setCountry: Dispatch<SetStateAction<string | null>>,
  setBusinessUnit: Dispatch<SetStateAction<string | null>>,
) => {
  const { t } = useTranslation();

  const dynamicTargetListSchema = useDynamicTargetListSchema();
  const targetFiltersOptions = useTargetsFiltersOptions(targetsFacets);
  const filteredTargetsFacetsOption = useTargetsFiltersOptions(filteredTargetsFacets);

  const dynamicTargetListConfig: DynamicTargetListFormConfigType = {
    country: {
      label: t('maya_dynamic_target_list_form_country_label'),
      name: 'country',
      placeholder: t('maya_dynamic_target_list_form_country_placeholder'),
      required: true,
      options: targetFiltersOptions.countries || [],
      defaultValue: targetFilters?.countries ? targetFilters?.countries[0] : null,
      validation: dynamicTargetListSchema.country,
      onChange: ((_: SyntheticEvent, value: SelectOption) => setCountry(value.id as string)),
    },
    type: {
      label: t('maya_dynamic_target_list_form_type_label'),
      name: 'type',
      placeholder: t('maya_dynamic_target_list_form_type_placeholder'),
      options: filteredTargetsFacetsOption.types || [],
      required: false,
      defaultValue: targetFilters?.types ? targetFilters?.types : [],
      disabled: !filteredTargetsFacetsOption.types?.length,
    },
    businessUnit: {
      label: t('maya_dynamic_target_list_form_businessUnit_label'),
      name: 'businessUnit',
      placeholder: t('maya_dynamic_target_list_form_businessUnit_placeholder'),
      options: targetFiltersOptions.businessUnits || [],
      required: true,
      defaultValue: targetFilters?.businessUnits ? targetFilters?.businessUnits[0] : null,
      validation: dynamicTargetListSchema.businessUnit,
      onChange: ((_: SyntheticEvent, value: SelectOption) => setBusinessUnit(value.id as string)),
    },
    specialty: {
      label: t('maya_dynamic_target_list_form_specialty_label'),
      name: 'specialty',
      placeholder: t('maya_dynamic_target_list_form_specialty_placeholder'),
      options: filteredTargetsFacetsOption.specialties || [],
      required: false,
      defaultValue: targetFilters?.specialties ? targetFilters?.specialties : [],
      disabled: !filteredTargetsFacetsOption.specialties?.length,
    },
    smaSegments: {
      label: t('maya_dynamic_target_list_form_smaSegments_label'),
      name: 'smaSegments',
      placeholder: t('maya_dynamic_target_list_form_smaSegments_placeholder'),
      options: filteredTargetsFacetsOption.smaSegments || [],
      required: false,
      defaultValue: targetFilters?.smaSegments || [],
      disabled: !filteredTargetsFacetsOption.smaSegments?.length,
    },
    biosSegments: {
      label: t('maya_dynamic_target_list_form_biosSegments_label'),
      name: 'biosSegments',
      placeholder: t('maya_dynamic_target_list_form_biosSegments_placeholder'),
      options: filteredTargetsFacetsOption.biosSegments || [],
      required: false,
      defaultValue: targetFilters?.biosSegments || [],
      disabled: !filteredTargetsFacetsOption.biosSegments?.length,
    },
    imsSegments: {
      label: t('maya_dynamic_target_list_form_imsSegments_label'),
      name: 'imsSegments',
      placeholder: t('maya_dynamic_target_list_form_imsSegments_placeholder'),
      options: filteredTargetsFacetsOption.imsSegments || [],
      required: false,
      defaultValue: targetFilters?.imsSegments || [],
      disabled: !filteredTargetsFacetsOption.imsSegments?.length,
    },
  };

  return {
    dynamicTargetListConfig,
  };
};

export default useDynamicTargetListConfig;
