import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useTargetListConfig from './UploadTargetListForm.config';
import { succesToast, errorToast } from 'basics/utils/toast';
import { HandleFilesExternal } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType, CustomerEngagementPlanning } from 'generated/maya.types';
import { CepStatus } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { useCreateTargetListFromCSVMutation } from 'modules/maya/graphql/mutations/createTargetList';
import useGetCepByIdLazyQuery from 'modules/maya/graphql/queries/getCepByIdLazy';
import cepState from 'modules/maya/states/cep.states';

const useUploadTargetList = (
  updateTargetList: () => Promise<void>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation();
  const [runCreateMutation, { loading: uploadLoading }] = useCreateTargetListFromCSVMutation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const { uploadTargetListConfig } = useTargetListConfig();

  const handleFilesExternal: HandleFilesExternal = async (files, setErrorMessage) => {
    const errorMessage = t('errors_standard');
    if (!cepStateValue.cep?._id || !cepStateValue.cep?.formattedCepId) {
      setErrorMessage(errorMessage);
      return;
    }
    const { size, name } = files[0];

    setLoading(true);

    try {
      const targetListMutation = await runCreateMutation(
        files[0],
        {
          size,
          name,
          assetType: AssetType.csv,
          assetTarget: AssetTarget.targetListCsv,
        },
        cepStateValue.cep?._id,
        cepStateValue.cep?.formattedCepId,
      );

      cepState.targetList = [
        ...cepStateValue.targetList,
        ...(targetListMutation?.targetList && targetListMutation.targetList.length ? targetListMutation.targetList : []),
      ];

      const { data: { getCustomerEngagementPlanningById: customerEngagementPlanning } } = await queryGetCepById(
        { variables: { id: cepStateValue.cep?._id } },
      );
      if (cepState.cep) {
        cepState.cep.globalCepSyncStatus = (
          customerEngagementPlanning as CustomerEngagementPlanning
        ).globalCepSyncStatus;
      }

      if (targetListMutation) {
        const importedData = targetListMutation?.targetList.length;
        succesToast(`${importedData} targets have been imported from your file`);
      } else {
        errorToast(t('No Target could be imported'));
      }

      updateTargetList();

      setLoading(false);
    } catch {
      errorToast(t('errors_standard'));
    }
  };

  return {
    uploadTargetListConfig,
    handleFilesExternal,
    data: Object.values(cepStateValue.targetList),
    uploadLoading,
    t,
    isCepApproved,
  };
};

export default useUploadTargetList;
