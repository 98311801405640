import { useState } from 'react';
import { useYodaForm } from 'services/yodaForm';

const useFieldNumberClick = (
  fieldName: string,
  defaultValue: string | undefined,
  onChange: (
    (value: number) => void
  ) | undefined,
) => {
  const yodaMethods = useYodaForm();
  const { setValue } = yodaMethods;

  const [fieldValue, setFieldValue] = useState(parseInt(defaultValue || '0', 10));

  const changeValue = (
    newValue: number,
  ) => {
    setFieldValue(newValue);
    setValue(fieldName, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return {
    fieldValue,
    changeValue,
  };
};

export default useFieldNumberClick;
