import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaJustifyContent } from 'yoda-ui/yodaTheme';

export const CustomerInitiativeListFilters: SxProps<Theme> = {
  background: YodaColors.white,
  marginBottom: '10px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: YodaColors.primaryBlue,
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: YodaColors.primaryBlueHover,
  },
};

export const deleteCiButtonStyle: SxProps<Theme> = {
  marginTop: '-20px',
  marginBottom: '-25px',
  display: 'flex',
  justifyContent: YodaJustifyContent.flexEnd,
};

export const GridStyle: SxProps<Theme> = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
    backgroundColor: YodaColors.blue2,
    boxShadow: `0 0 7px 1px ${YodaColors.gray2}`,
  },
};
