import { useTranslation } from 'react-i18next';
import { Market } from 'generated/event.types';

const useMarketOptions = () => {
  const { t } = useTranslation();
  const marketKeys = Object.keys(Market).filter((market) => market !== Market.uk);
  const marketValues = Object.values(Market).filter((market) => market !== Market.uk);
  return marketValues.map((value, index) => { return { id: marketKeys[index], label: t(`market_label_${value}`) }; });
};

export default useMarketOptions;
