import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cepFormStepIndexer } from './CepForm.states';
import { mayaCepListRoute, mayaCepTemplateListRoute } from 'basics/constants/routes.constants';
import { errorToast, succesToast, warningToast } from 'basics/utils/toast';
import useStepIndexer from 'hooks/useStepIndexer';
import { CepStatus } from 'modules/maya/basics/enums/maya.enums';
import { cepToBE } from 'modules/maya/basics/transformers/Cep.transformer';
import { CepFormStepsIndex, CepFormSubmitCallbackType } from 'modules/maya/components/CepForm/CepForm.types';
import { useSetCepStatusToApprovedMutation } from 'modules/maya/graphql/mutations/setCepStatusToApproved';
import { useSetCepStatusToFeedbackMutation } from 'modules/maya/graphql/mutations/setCepStatusToFeedback';
import cepState from 'modules/maya/states/cep.states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepFormActions = (yodaFormMethods: any, submitCallback: CepFormSubmitCallbackType, isTemplate: boolean) => {
  const { t } = useTranslation();
  const stepIndexer = useStepIndexer(cepFormStepIndexer);
  const { stepIndex, moveNextStep, movePreviousStep } = stepIndexer;
  const { getValues, formState, setStepShowError } = yodaFormMethods;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [setCepStatusToApproved] = useSetCepStatusToApprovedMutation();
  const [setCepStatusToFeedback] = useSetCepStatusToFeedbackMutation();

  const setShowError = useCallback(
    (stepIndexVal: number) => {
      const step = CepFormStepsIndex[stepIndexVal];
      setStepShowError(true, [step]);
    },
    [setStepShowError],
  );

  const handleSaveData = useCallback(async () => {
    if (formState.isDirty) {
      setIsSubmitting(true);

      const customerEngagementPlanningInput = getValues();
      if (customerEngagementPlanningInput) {
        const formattedCEP = cepToBE(customerEngagementPlanningInput);
        const customerEngagementPlanningData = await submitCallback(formattedCEP);
        if (!customerEngagementPlanningData) {
          navigate(mayaCepListRoute);
        }
        cepState.cep = customerEngagementPlanningData || {};

        setIsSubmitting(false);
        if (customerEngagementPlanningData) {
          succesToast(t('toast_draft_saved'));
          return customerEngagementPlanningData;
        }
        warningToast(t('errors_standard'));
      }
    } else {
      formState.showError = true;
      setShowError(stepIndex);
    }

    return null;
  }, [formState, getValues, submitCallback, t, navigate, setShowError, stepIndex]);

  const handlePublishEvent = useCallback(async () => {
    if (formState.isValid) {
      const customerEngagementPlanInput = getValues();
      if (customerEngagementPlanInput) {
        const cep = await handleSaveData();
        const cepStatus = cep?.status || cepState.cep?.status;
        const cepId = cep?._id || cepState.cep?._id;

        setIsSubmitting(true);
        if (cepStatus && cepId && cepStatus === CepStatus.DRAFT) {
          if (await setCepStatusToFeedback(cepId)) {
            succesToast(t('maya_action_feedback_cep_success'));
            navigate(mayaCepListRoute);
          } else {
            errorToast(t('maya_action_feedback_cep_error'));
          }
        }

        if (cepStatus && cepId && cepStatus === CepStatus.FEEDBACK) {
          if (await setCepStatusToApproved(cepId)) {
            succesToast(t('maya_action_approve_cep_success'));
            navigate(mayaCepListRoute);
          }
        }
        setIsSubmitting(false);
      }
    }
  }, [formState.isValid, getValues, handleSaveData, t, setCepStatusToFeedback, navigate, setCepStatusToApproved]);

  const handleChangeStep = useCallback(
    (stepIndexVal: number) => {
      const previousStep = stepIndexer.previousStep || 0;
      stepIndexer.moveToStep(stepIndexVal);
      setShowError(previousStep);
    },
    [setShowError, stepIndexer],
  );

  const enableDuplicateSave = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (stepIndex === CepFormStepsIndex.configurationStep && urlParams.get('sourceId')) {
      formState.isDirty = true;
    }
  }, [formState, stepIndex]);

  const saveAndNext = useCallback(async () => {
    enableDuplicateSave();
    await handleSaveData();
    setShowError(stepIndex);
    moveNextStep();
  }, [enableDuplicateSave, handleSaveData, moveNextStep, setShowError, stepIndex]);

  const saveAndPrevious = useCallback(() => {
    handleSaveData();
    setShowError(stepIndex);
    movePreviousStep();
  }, [handleSaveData, movePreviousStep, setShowError, stepIndex]);

  const saveAsDraft = useCallback(() => {
    handleSaveData();
    setShowError(stepIndex);
    if (isTemplate) {
      navigate(mayaCepTemplateListRoute);
    } else {
      navigate(mayaCepListRoute);
    }
  }, [handleSaveData, isTemplate, navigate, setShowError, stepIndex]);

  return {
    handleChangeStep,
    handlePublishEvent,
    handleSaveData,
    saveAsDraft,
    setShowError,
    stepIndexer,
    saveAndNext,
    saveAndPrevious,
    isSubmitting,
  };
};

export default useCepFormActions;
