import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import CardSwitch from 'components/CardSwitch';
import { TacticMode } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType, TacticStateType } from 'modules/maya/basics/types/maya.types';
import TacticKanban from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticKanban/TacticKanban';
import TacticsList from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticsList/TacticsList';
import useSelectTacticDesignConfig from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticsStep.config';
import { TacticSwitchStyle } from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticStep.styles';
import { TacticStepPropsType } from 'modules/maya/components/CepForm/Steps/TacticsStep/TacticStep.types';
import TacticForm from 'modules/maya/components/TacticForm/TacticForm';
import cepState from 'modules/maya/states/cep.states';
import tacticState from 'modules/maya/states/tactic.states';
import Box from 'yoda-ui/Box';

const TacticsStep: FC<TacticStepPropsType> = ({ isTemplate }) => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;
  const { cardSwitchConfig, newDesign } = useSelectTacticDesignConfig();

  useEffect(() => {
    tacticState.cepId = cepStateValue.cep?._id || '';
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  }, [cepStateValue.cep?._id]);

  return (
    <>
      {
        tacticStateValue.mode === TacticMode.form
          ? <TacticForm isTemplate={ isTemplate }/>
          : <Box>
            <Box sx={ TacticSwitchStyle }>
              <CardSwitch
                name={ cardSwitchConfig.name }
                title={ cardSwitchConfig.title }
                defaultValue={ cardSwitchConfig.defaultValue }
                onclick={ cardSwitchConfig.onclick }
              />
            </Box>
            {
              newDesign
                ? <TacticKanban />
                : <TacticsList />
            }
          </Box>
      }

    </>
  );
};

export default TacticsStep;
