import truncate from 'lodash/truncate';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { succesToast, errorToast } from 'basics/utils/toast';
import FieldCard from 'components/FieldCard';
import Logger from 'services/logger';
import { ButtonBackgroundType, ButtonSize, ButtonVariant, IconButton } from 'yoda-ui/BiogenLincDS/Buttons';
import Box from 'yoda-ui/Box';
import { Icons } from 'yoda-ui/Icons/Icon';
import { YodaFontSize } from 'yoda-ui/yodaTheme';

type CopyCardProps = {
  link: string;
};

const CopyCard: FC<CopyCardProps> = ({ link }) => {
  const writeToClipboardSupported = typeof navigator?.clipboard?.writeText === 'function';
  const { t } = useTranslation();

  const handleWriteToClipboard = useCallback(() => {
    try {
      navigator.clipboard.writeText(link);
      succesToast(t('copyCard_toast_success'));
    } catch (error) {
      if (error instanceof Error) {
        Logger.logError({
          error,
          customContext: { link },
          transactionName: 'CopyCard.tsx - handleWriteToClipboard',
        });
      }
      errorToast(t('copyCard_toast_failure'));
    }
  }, [link, t]);

  return (
    <FieldCard>
      <Box fontSize={ YodaFontSize.small } display="flex" alignItems="center" onDoubleClick={ handleWriteToClipboard }>
        { truncate(link, { length: 45 }) }
      </Box>
      {
        writeToClipboardSupported && (
          <Box display="flex">
            <IconButton
              onClick={ handleWriteToClipboard }
              disabled={ !link }
              icon={ Icons.contentCopy }
              variant={ ButtonVariant.primary }
              size={ ButtonSize.small }
              backgroundType={ ButtonBackgroundType.noBackground }
            />
          </Box>
        )
      }
    </FieldCard>
  );
};

export default CopyCard;
