import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import cepState from 'modules/maya/states/cep.states';
import { StepItem } from 'yoda-ui/Stepper/Stepper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepFormStepper = (useWatchStep: any) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const watchConfigurationStep = useWatchStep(CepSteps.configurationStep);
  const watchCostEstimationStep = useWatchStep(CepSteps.costEstimationStep);

  const customerEngagementPlanningStateValue = useSnapshot(cepState) as CepStateType;

  const disableStep = !customerEngagementPlanningStateValue.cep?._id;

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('maya_cep_step_configuration')}`,
      isValid: watchConfigurationStep?.isValid || false,
      disabled: disableStep,
    },
    {
      title: `2. ${t('maya_cep_step_target_list')}`,
      isValid: customerEngagementPlanningStateValue.targetList.length || false,
      disabled: disableStep,
    },
    {
      title: `3. ${t('maya_cep_step_tactics')}`,
      isValid: customerEngagementPlanningStateValue.tactics.length || false,
      disabled: disableStep,
    },
  ];
  if (flags.mayaFinanceBiogenlinc866) {
    stepsList.push(
      {
        title: `4. ${t('maya_cep_step_cost_estimation')}`,
        isValid: watchCostEstimationStep?.isValid || false,
        disabled: disableStep,
      },
    );
  }
  return stepsList;
};

export default useCepFormStepper;
