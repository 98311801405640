import { gql, MutationResult, useMutation } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { removetargetListFragment } from 'modules/maya/graphql/fragments/targetList.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

type UseRemoveTargetsMutationResponse = {
  removeTargets: boolean;
};

type UseRemoveTargetsMutation = [
  (cepId?: string) => Promise<boolean | null>,
  MutationResult<UseRemoveTargetsMutationResponse>,
];

export const removeTargetsMutationCall = gql`
  mutation removeTargetsByCepId($cepId: String!, $sendToVeeva: Boolean!) {
    removeTargetsByCepId(cepId: $cepId, sendToVeeva: $sendToVeeva) {
      ${removetargetListFragment} 
    }
  }
`;

export const useRemoveTargetsMutation = (): UseRemoveTargetsMutation => {
  const [removeTargets, state] = useMutation<UseRemoveTargetsMutationResponse>(removeTargetsMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  const flags = useFlags();

  const executeMutation = useCallback(async (cepId) => {
    try {
      const { data } = await removeTargets({
        variables: {
          cepId,
          sendToVeeva: flags.mayaEnableVeevaBiogenlinc1816,
        },
      });
      return !!data?.removeTargets;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error);
        Logger.logError({
          error,
          customContext,
          transactionName: 'removeTargets.ts - useRemoveTargetsMutation',
        });
      }
      return null;
    }
  }, [flags.mayaEnableVeevaBiogenlinc1816, removeTargets]);

  return [executeMutation, state];
};
