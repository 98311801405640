import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MuiDateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FC } from 'react';
import ClearButton from './ClearButton';
import useDateRangePicker from './DateRangePicker.hook';
import { dateRangePickerStyles } from './DateRangePicker.styles';
import { DateRange, DateRangePickerProps } from './DateRangePicker.types';
import Box from 'yoda-ui/Box';
import FormHelperText from 'yoda-ui/Form/FormHelperText';
import TextField from 'yoda-ui/Form/TextField';

const DateRangePicker: FC<DateRangePickerProps> = ({
  name,
  validation,
  onChange,
  startRequired,
  endRequired,
  separator = '',
  defaultValue,
  disabled,
  ...props
}) => {
  const {
    locale,
    handleChange,
    fieldValue,
    fieldShowError,
    calendarOpen,
    hideCalendar,
    showCalendar,
    fieldErrorMessage,
    handleClearButtonClick,
    clearButtonDisabled,
  } = useDateRangePicker({ name, validation, onChange, defaultValue });

  return (
    // Question: shouldn't we have one Localization provider as far up in the tree as possible (as suggested in the docs?)
    <LocalizationProvider dateAdapter={ AdapterDateFns } locale={ locale }>
      <MuiDateRangePicker
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        onClose={ () => hideCalendar() }
        onChange={ handleChange }
        value={ fieldValue || [null, null] as DateRange }
        open={ calendarOpen }
        disabled={ disabled }
        renderInput={
          (startProps, endProps) => (
            <>
              <TextField
                { ...startProps }
                sx={ dateRangePickerStyles.textField }
                helperText={ null }
                required={ startRequired }
                error={ fieldShowError }
                onClick={ showCalendar }
                disabled={ disabled }
              />
              <Box sx={ dateRangePickerStyles.box }>{ separator }</Box>
              <TextField
                { ...endProps }
                sx={ dateRangePickerStyles.textField }
                helperText={ null }
                required={ endRequired }
                error={ fieldShowError }
                onClick={ showCalendar }
                disabled={ disabled }
                InputProps={
                  {
                    endAdornment: disabled ? null : <ClearButton onClick={ handleClearButtonClick } disabled={ clearButtonDisabled } />,
                  }
                }
              />
            </>
          )
        }
        { ...props }
      />
      { fieldShowError && <FormHelperText error>{ fieldErrorMessage }</FormHelperText> }
    </LocalizationProvider>
  );
};

export default DateRangePicker;
