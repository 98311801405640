import { Dispatch, FC, SetStateAction } from 'react';
import { Participant } from 'modules/events/basics/types/events.types';
import ParticipantsGroup from 'modules/events/groups/ParticipantsGroup';
import RegisterParticipantGroup from 'modules/events/groups/RegisterParticipantGroup';
import Box from 'yoda-ui/Box';

type InviteesRegistrationPanelProps = {
  eventId: string;
  participants: Participant[] | null;
  setParticipants: Dispatch<SetStateAction<Participant[] | null>>;
};

const InviteesRegistrationPanel: FC<InviteesRegistrationPanelProps> = (props) => {
  if (!props.participants) {
    return null;
  }

  return (
    <>
      <RegisterParticipantGroup eventId={ props.eventId } participants={ props.participants } setParticipants={ props.setParticipants } />
      <Box flexDirection="column">
        <ParticipantsGroup participants={ props.participants }/>
      </Box>
    </>

  );
};

export default InviteesRegistrationPanel;
