import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mayaCiTemplateListRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import { CiFormSubmitCallbackType } from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.types';
import { useCreateCustomerInitiativeMutation } from 'modules/maya/graphql/mutations/createCustomerInitiative';
import useGetStrategicImperativeByIdQuery from 'modules/maya/graphql/queries/getStrategicImperativeById';

const useCiTemplateCreate = () => {
  const navigate = useNavigate();

  const [createCustomerInitiative] = useCreateCustomerInitiativeMutation();

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const parentId = urlParams.get('parentId');

  const [loading, setLoading] = useState(true);

  const parentStrategicImperative = useGetStrategicImperativeByIdQuery(parentId).data;

  useEffect(() => {
    if (parentStrategicImperative || !parentId) {
      setLoading(false);
    }
  }, [parentId, parentStrategicImperative, setLoading]);

  const handleCancelCustomerInitiativeForm = () => {
    navigate(mayaCiTemplateListRoute);
  };

  const handleSaveCustomerInitiativeForm: CiFormSubmitCallbackType = async (customerInitiative) => {
    const customerInitiativeData = await createCustomerInitiative({ ...customerInitiative, isTemplate: true }, false);

    if (customerInitiativeData) {
      succesToast(`Customer initiative ${customerInitiative.title} created`);
      navigate(mayaCiTemplateListRoute);
    } else {
      warningToast(`Cannot create customer initiative ${customerInitiative.title}`);
    }
  };

  return {
    loading,
    parentStrategicImperative,
    handleSaveCustomerInitiativeForm,
    handleCancelCustomerInitiativeForm,
  };
};

export default useCiTemplateCreate;
