import { useTranslation } from 'react-i18next';
import { array, object, string } from 'yup';

const maxProgramNameLength = 20;
const maxProgramLogoUrlLength = 200;

const useAddProgramSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');
  const maxProgramNameMessage = `${t('event_modal_program_max_error_message', { count: maxProgramNameLength })}`;
  const maxProgramLogoUrlMessage = `${t('event_modal_program_logo_url_max_error_message', { count: maxProgramLogoUrlLength })}`;

  return {
    programName: string()
      .required(requiredLabel)
      .max(maxProgramNameLength, maxProgramNameMessage),
    programLogo: array()
      .nullable(true)
      .of(object().shape({
        id: string().required(),
        url: string().required().max(maxProgramLogoUrlLength, maxProgramLogoUrlMessage),
      })),
  };
};

export default useAddProgramSchema;
