import { createOnlineMeetingRoute, createOnlineWebinarRoute, eventsRoute } from 'basics/constants/routes.constants';
import { Format, Presence } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';

const mappedRoutes: { [key: string]: { [key: string]: string } } = {
  [Presence.online]: {
    [Format.meeting]: createOnlineMeetingRoute,
    [Format.webinar]: createOnlineWebinarRoute,
    [Format.congress]: eventsRoute,
    [Format.onDemand]: eventsRoute,
    [Format.liveStreaming]: eventsRoute,
    [Format.standalone]: eventsRoute,
  },
  [Presence.physical]: {
    [Format.meeting]: eventsRoute,
    [Format.webinar]: eventsRoute,
    [Format.congress]: eventsRoute,
    [Format.onDemand]: eventsRoute,
    [Format.liveStreaming]: eventsRoute,
    [Format.standalone]: eventsRoute,
  },
  [Presence.hybrid]: {
    [Format.meeting]: eventsRoute,
    [Format.webinar]: eventsRoute,
    [Format.congress]: eventsRoute,
    [Format.onDemand]: eventsRoute,
    [Format.liveStreaming]: eventsRoute,
    [Format.standalone]: eventsRoute,
  },
};

export const getRouteByFormatAndPresence = (eventData: Partial<Event>) => {
  let route = eventsRoute;
  const { presence, format, _id: id } = eventData;
  const routesByPresence = presence ? mappedRoutes[presence] : null;
  if (eventData && presence && format && routesByPresence && routesByPresence[format]) {
    route = `${routesByPresence[format]}/${id}`;
  }
  return route;
};
