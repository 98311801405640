import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

export const strategicImperativeToBE = (data: YodaFieldValue) => {
  return {
    title: data.title,
    description: data.description,
    therapeuticArea: data.therapeuticArea,
    year: parseInt(data.year, 10),
  };
};

