import { CommonSteps } from 'modules/events/basics/enums/commonForms.enums';
import { useYodaForm } from 'services/yodaForm';

const useDetailsStep = () => {
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CommonSteps.detailsStep);

  return {
    stepIsValid: stepState ? stepState.isValid : false,
  };
};

export default useDetailsStep;
