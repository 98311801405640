import { FC } from 'react';
import { SecondaryNavItemVariants } from './SecondaryNav.enums';
import * as styles from './SecondaryNav.styles';
import { SecondaryNavProps } from './SecondaryNav.types';
import SecondaryNavItem from './SecondaryNavItem';
import { Icons } from 'yoda-ui/Icons/Icon';
import List from 'yoda-ui/List';

const SecondaryNav: FC<SecondaryNavProps> = (props) => {
  const { goBackText, goBackOnClick, items = [] } = props;

  return (
    <List sx={ styles.List }>
      {
        goBackOnClick && (
          <SecondaryNavItem
            icon={ Icons.chevronLeft }
            key="goback-item"
            text={ goBackText }
            onClick={ goBackOnClick }
            variant={ SecondaryNavItemVariants.HEAD }
          />
        )
      }
      { items.map((item) => <SecondaryNavItem { ...item } key={ item.key } />) }
    </List>
  );
};

export default SecondaryNav;
