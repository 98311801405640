import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaSpacing, YodaFontSize } from 'yoda-ui/yodaTheme';

export const MayaMenuEntryStyles: SxProps<Theme> = {
  border: 'none',
  textAlign: 'left',
  paddingTop: YodaSpacing.small,
  paddingBottom: YodaSpacing.small,
  paddingLeft: YodaSpacing.small,
  paddingRight: YodaSpacing.small,
  fontSize: YodaFontSize.medium,
  cursor: 'pointer',
  background: YodaColors.transparent,
  color: YodaColors.black,

  '&:hover': {
    color: YodaColors.primaryBlue,
  },

  '&[data-current="true"]': {
    background: YodaColors.blue2,
    color: YodaColors.primaryBlue,
  },
};
