import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { Market } from 'generated/event.types';
import InfoTag from 'modules/events/components/SideFormDetails/InfoTag';
import StatusBanner from 'modules/events/components/SideFormDetails/StatusBanner';
import eventState from 'modules/events/states/events.states';
import Box from 'yoda-ui/Box';
import { YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const sideContainerStyle = {
  root: {
    width: '272px',
  },
  firstBox: {
    backgroundColor: YodaColors.grayLight,
  },
};

const SideContainer: FC = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState);
  const { title, market, presence, format } = snap.createEvent;

  if (!(format && market && presence)) {
    return null;
  }

  return (
    <Box sx={ sideContainerStyle.root }>
      <Box
        sx={ sideContainerStyle.firstBox }
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={ YodaSpacing.medium }
      >
        <Box marginBottom={ YodaSpacing.medium }>
          <Box fontSize={ YodaFontSize.small } fontWeight={ YodaFontWeight.light }>
            { t('event_creation_modal_title_field_label') }
          </Box>
          <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium }>
            { title }
          </Box>
        </Box>
        <Box marginBottom={ YodaSpacing.xxxLarge }>
          <InfoTag
            title={ `${t('event_creation_modal_market_field_label')}*` }
            field={ (market && market === Market.gb ? Market.uk : market).toUpperCase() }
          />
          <InfoTag title={ `${t('event_creation_modal_presence_type_field_label')}*` } field={ presence } />
          <InfoTag title={ `${t('event_creation_modal_event_format_field_label')}*` } field={ format } />
        </Box>
      </Box>
      <StatusBanner />
    </Box>
  );
};

export default SideContainer;
