import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateOnlineWebinarActions from './CreateOnlineWebinar.actions.hook';
import { onlineWebinarOutputTransformer } from './CreateOnlineWebinar.transformers';
import useCreateOnlineWebinarButtons from './CreateOnlineWebinarButtons.hook';
import useCreateOnlineWebinarStepper from './CreateOnlineWebinarStepper.hook';
import { eventsRoute } from 'basics/constants/routes.constants';
import { warningToast } from 'basics/utils/toast';
import { EventStatus } from 'generated/event.types';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import eventState from 'modules/events/states/events.states';
import { useYodaCreateForm } from 'services/yodaForm';

const useCreateOnlineWebinar = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const yodaFormMethods = useYodaCreateForm({ setIsValidOptional: true });
  const onlineWebinarActions = useCreateOnlineWebinarActions(yodaFormMethods);

  const { isDirty } = yodaFormMethods.useWatchForm();
  const { queryGetFullPrivateEventById, data: event, error, loading } = useLazyGetFullPrivateEventById();
  const buttonsConfig = useCreateOnlineWebinarButtons(
    onlineWebinarActions,
    isDirty,
    yodaFormMethods.formState.isValid && event?.status === EventStatus.ready,
  );
  const stepsList = useCreateOnlineWebinarStepper(yodaFormMethods.useWatchStep);

  useEffect(() => {
    if (id) {
      queryGetFullPrivateEventById({ variables: { eventId: id } });
    }
  }, [id, queryGetFullPrivateEventById]);

  useEffect(() => {
    if (event) {
      eventState.createEvent = onlineWebinarOutputTransformer(event);
    }
    if (error) {
      warningToast(t('errors_standard'));
      navigate(eventsRoute);
    }
  }, [event]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickHome = async () => {
    navigate(eventsRoute);
  };

  const showLoader = loading || !event;

  return {
    t,
    handleClickHome,
    handleChangeStep: onlineWebinarActions.handleChangeStep,
    showLoader,
    yodaFormMethods,
    stepIndexer: onlineWebinarActions.stepIndexer,
    id,
    buttonsConfig,
    stepsList,
  };
};

export default useCreateOnlineWebinar;
