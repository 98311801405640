import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { HandleFilesExternal, HandleRemoveItemAsync } from 'components/QuickForm/QuickForm.types';
import { AssetTarget, AssetType } from 'generated/maya.types';
import { useImportAopFromCSVMutation } from 'modules/maya/graphql/mutations/importAop';
import useAopImportConfig from 'modules/maya/routes/ImportExport/AopImport/AopImport.config';

const useAopImport = () => {
  const { t } = useTranslation();
  const [runCreateMutation, { loading }] = useImportAopFromCSVMutation();
  const { uploadAopImportConfig } = useAopImportConfig();

  const handleFilesExternal: HandleFilesExternal = async (files) => {
    const { size, name } = files[0];

    const aopImportResult = await runCreateMutation(
      files[0],
      {
        size,
        name,
        assetType: AssetType.csv,
        assetTarget: AssetTarget.aopImportCsv,
      },
    );

    if (aopImportResult?.response) {
      toast.success(t('maya_aop_file_import_success', { exportedLineCount: aopImportResult?.response }));
    } else {
      toast.error(t('maya_aop_file_import_error'));
    }
  };
  const handleRemoveItemAsync: HandleRemoveItemAsync = async () => {};

  return {
    uploadAopImportConfig: {
      ...uploadAopImportConfig,
      handleFilesExternal,
      handleRemoveItemAsync,
    },
    t,
    loading,
  };
};

export default useAopImport;
