import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { TagColorsMap } from './ParticipantItem.types';
import { ParticipantStatus } from 'modules/events/basics/enums/events.enums';
import { RegistrationStatusHistory } from 'modules/events/basics/types/events.types';
import { findParticipantLaterStatus } from 'modules/events/basics/utils/participants.utils';
import { YodaColors } from 'yoda-ui/yodaTheme';

const tagColorsMap = (t: TFunction<'translation'>): TagColorsMap => ({
  [ParticipantStatus.accepted]: {
    label: t('participant_status_confirmed'),
    bgColor: YodaColors.greenSuccessLight,
    color: YodaColors.greenSuccessDark,
  },
  [ParticipantStatus.invited]: {
    label: t('participant_status_invited'),
    bgColor: YodaColors.gray2,
    color: YodaColors.gray8,
  },
  [ParticipantStatus.requested]: {
    label: t('participant_status_pending'),
    bgColor: YodaColors.orangeWarningLight,
    color: YodaColors.orangeWarningDark,
  },
  [ParticipantStatus.rejected]: {
    label: t('participant_status_rejected'),
    bgColor: YodaColors.redErrorLight,
    color: YodaColors.redErrorDark,
  },
});

const useParticipantsItem = (registrationStatusHistory: RegistrationStatusHistory) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const toggleOpened = () => {
    setOpened((prev) => !prev);
  };

  const tagConfig = useMemo(
    () => {
      const status = findParticipantLaterStatus(registrationStatusHistory);
      return status ? tagColorsMap(t)[status] : null;
    },
    [registrationStatusHistory, t],
  );

  return {
    opened,
    tagConfig,
    toggleOpened,
  };
};

export default useParticipantsItem;
