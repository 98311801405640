import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import { FC } from 'react';
import { MayaMenuEntryStyles } from './MayaMenuEntry.styles';
import useMayaMenuEntry from 'modules/maya/components/MayaMenuEntry/MayaMenuEntry.hook';
import { MayaMenuEntryPropsType } from 'modules/maya/components/MayaMenuEntry/MayaMenuEntry.types';
import Box from 'yoda-ui/Box';

const MayaMenuEntry: FC<MayaMenuEntryPropsType> = ({ title, currentLocation, activeRoute: active, onClick, childrens }) => {
  const {
    anchorRef,
    handleClose,
    handleListKeyDown,
    handleToggle,
    navigate,
    open,
    setOpen,
  } = useMayaMenuEntry();

  return (
    childrens
      ? <>
        <Button
          ref={ anchorRef }
          id="composition-button"
          aria-controls={ open ? 'composition-menu' : undefined }
          aria-expanded={ open ? 'true' : undefined }
          aria-haspopup="true"
          onClick={ handleToggle }
          sx={ MayaMenuEntryStyles }
          data-current={ active.includes(currentLocation) }
        >
          { title }
        </Button>
        <Popper
          open={ open }
          anchorEl={ anchorRef.current }
          role={ undefined }
          placement="bottom-start"
          transition
          disablePortal
        >
          {
            ({ TransitionProps, placement }) => (
              <Grow
                { ...TransitionProps }
                style={ { transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' } }
              >
                <Paper>
                  <ClickAwayListener onClickAway={ handleClose }>
                    <MenuList
                      autoFocusItem={ open }
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={ handleListKeyDown }
                    >
                      {
                        childrens.map((itemMenu) => {
                          return itemMenu.enable && (
                            <Box className='MayaMenu-item' key={ itemMenu.id } sx={ MayaMenuEntryStyles }>
                              <MayaMenuEntry
                                title={ itemMenu.title }
                                onClick={
                                  itemMenu.targetRoute ? () => {
                                    navigate(itemMenu.targetRoute as string);
                                    setOpen(false);
                                  } : undefined
                                }
                                currentLocation={ currentLocation }
                                activeRoute={ itemMenu.activeRoute }
                                childrens={ itemMenu.childrens }
                              />
                            </Box>
                          );
                        })
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )
          }
        </Popper>
      </>
      : <Button sx={ MayaMenuEntryStyles } onClick={ onClick } data-current={ active.includes(currentLocation) }>
        { title }
      </Button>
  );
};

export default MayaMenuEntry;
