import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import biogenLincConfigSchema from './BiogenLincConfigGroup.schema';
import { EventPromotionalityType, IsVisibleOnBiogenLincType, PrcValidationType } from './BiogenLincConfigGroup.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import { isDraft } from 'modules/events/basics/utils/events.utils';
import useLazyGetScreenshots from 'modules/events/graphql/queries/getScreenshotsLazy';
import eventState from 'modules/events/states/events.states';
import { useYodaForm } from 'services/yodaForm';

const useBiogenLincConfig = () => {
  const { t } = useTranslation();
  const { /* queryGetScreenshots, */loading } = useLazyGetScreenshots({
    eventId: eventState.createEvent._id,
    title: eventState.createEvent.title,
  });
  const snap = useSnapshot(eventState);
  const { setFieldValidation, triggerFieldValidation, useWatchField/* , formState */ } = useYodaForm();
  const isVisibleOnBiogenLincField = useWatchField(commonFields.isVisibleOnBiogenLinc);
  const prcCodeEventDetailsField = useWatchField(commonFields.prcCodeEventDetails);

  const isPublished = !isDraft(snap.createEvent.status);

  useEffect(() => {
    const updatedValidation = biogenLincConfigSchema.prcCodeEventDetails;
    setFieldValidation(commonFields.prcCodeEventDetails, updatedValidation);
    triggerFieldValidation(commonFields.prcCodeEventDetails, prcCodeEventDetailsField?.value);
  }, [isVisibleOnBiogenLincField?.value, prcCodeEventDetailsField?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const radioConfigs = useMemo(() => [
    {
      label: t('eventPromotionality_gatedRadio_label'),
      key: EventPromotionalityEnum.gated,
      value: EventPromotionalityEnum.gated,
      disabled: isPublished,
    },
    {
      label: t('eventPromotionality_publicRadio_label'),
      key: EventPromotionalityEnum.public,
      value: EventPromotionalityEnum.public,
      disabled: isPublished,
    },
  ], [isPublished, t]);

  let snapIsVisibleOnBiogenLinc = snap.createEvent.data?.isVisibleOnBiogenLinc;

  if (snapIsVisibleOnBiogenLinc === undefined) {
    snapIsVisibleOnBiogenLinc = true;
  }

  const isVisibleOnBiogenLincConfig: IsVisibleOnBiogenLincType = useMemo(() => ({
    name: commonFields.isVisibleOnBiogenLinc,
    title: t('isVisibleOnBiogenLinc_title'),
    text: t('isVisibleOnBiogenLinc_explanation_text'),
    defaultValue: snap.createEvent.data?.isVisibleOnBiogenLinc || snapIsVisibleOnBiogenLinc || false,
    disabled: isPublished,
  }), [isPublished, snap.createEvent.data?.isVisibleOnBiogenLinc, snapIsVisibleOnBiogenLinc, t]);

  const eventPromotionalityConfig: EventPromotionalityType = useMemo(() => ({
    formGroupConfig: {
      title: t('eventPromotionality_form_title'),
    },
    radioGroupConfig: {
      name: commonFields.isPublic,
      radioConfigs,
      row: true,
      defaultValue: snap.createEvent.data?.isPublic || EventPromotionalityEnum.gated,
      validation: biogenLincConfigSchema.isPublic,
    },
    enableGuestRegistration: {
      label: t('events_enable_guest_registration_label'),
      name: commonFields.enableGuestRegistration,
      checked: snap.createEvent.data?.enableGuestRegistration,
      disabled: isPublished,
    },
  }), [isPublished, radioConfigs, snap.createEvent.data?.enableGuestRegistration, snap.createEvent.data?.isPublic, t]);

  const prcValidationsConfig: PrcValidationType = {
    eventDetails: {
      headerConfig: {
        title: t('prcValidation_eventDetails_header_title'),
        buttonLabel: t('prcValidation_eventDetails_header_buttonLabel'),
        // handleDownload: queryGetScreenshots,
        loading,
        // disabled: !formState.isValid /* || loading */,
      },
      fieldConfig: {
        label: t('prcValidation_eventDetails_field_label'),
        name: commonFields.prcCodeEventDetails,
        placeholder: t('prcValidation_eventDetails_field_placeholder'),
        defaultValue: snap.createEvent.data?.prcCodeEventDetails,
        validation: biogenLincConfigSchema.prcCodeEventDetails.default,
      },
    },
  };

  return {
    isVisibleOnBiogenLincConfig,
    eventPromotionalityConfig,
    prcValidationsConfig,
    t,
  };
};

export default useBiogenLincConfig;
