import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { useYodaForm } from 'services/yodaForm';

const useEstimationCostStep = () => {
  const { useWatchStep } = useYodaForm();
  const stepState = useWatchStep(CepSteps.costEstimationStep);

  return {
    stepIsValid: stepState ? stepState.isValid : false,
  };
};

export default useEstimationCostStep;
