import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import useCepFormConfigurationGroupConfig
  from './CepFormConfigurationGroup.config';
import { therapeuticAreaRelativesProducts } from 'basics/constants/common.constants';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { SelectOption } from 'basics/options/options.types';
import { CustomerInitiative } from 'generated/maya.types';
import { CepSteps } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import useGetCustomerInitiativeByIdQuery from 'modules/maya/graphql/queries/getCustomerInitiativeById';
import cepState from 'modules/maya/states/cep.states';
import { useYodaForm } from 'services/yodaForm';

const useCepFormConfigurationGroup = (isTemplate: boolean) => {
  const [customerInitiativeId, setCustomerInitiativeId] = useState<string | null>(null);
  const [selectedTA, setSelectedTA] = useState<string | null>(null);

  const { setValue, setFieldShowError, setStepShowError } = useYodaForm();

  const cepStateValue = useSnapshot(cepState) as CepStateType;

  const urlParams = new URLSearchParams(window.location.search);
  const parentCustomerInitiativeId = urlParams.get('parentId');

  const parentCustomerInitiative = useGetCustomerInitiativeByIdQuery(parentCustomerInitiativeId).data;
  const newCustomerInitiative = useGetCustomerInitiativeByIdQuery(customerInitiativeId).data;
  const currentCustomerInitiative = newCustomerInitiative
  || cepStateValue.cep?.customerInitiative as CustomerInitiative
  || parentCustomerInitiative;

  const {
    cepFormConfigurationGroup,
  } = useCepFormConfigurationGroupConfig(
    currentCustomerInitiative,
    selectedTA,
    isTemplate,
  );

  if (Object.keys(cepStateValue.cep || {}).length && !parentCustomerInitiativeId) {
    const fieldsName = Object.keys(cepFormConfigurationGroup);
    setFieldShowError(true, fieldsName);
    setStepShowError(true, [CepSteps.configurationStep]);
  }

  useEffect(() => {
    if (newCustomerInitiative) {
      setValue(cepFormConfigurationGroup.therapeuticArea.name, newCustomerInitiative?.therapeuticArea);
      setValue(cepFormConfigurationGroup.product.name, newCustomerInitiative?.product[0] || null);
      setValue(cepFormConfigurationGroup.biogenFunction.name, newCustomerInitiative?.functionalOwner);
    }
  }, [
    cepFormConfigurationGroup.biogenFunction.name,
    cepFormConfigurationGroup.product.name,
    cepFormConfigurationGroup.therapeuticArea.name,
    newCustomerInitiative,
    setValue,
  ]);

  const handleCustomerInitiativeChange = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption) => {
    if (value?.id) {
      setCustomerInitiativeId(value.id as string);
    }
  }, [setCustomerInitiativeId]);

  const handleChangeTA = useCallback((event: SyntheticEvent<Element, Event>, TAValue: SelectOption | null) => {
    setSelectedTA(TAValue?.label || null);
    if (TAValue === null) {
      setValue(cepFormConfigurationGroup.product.name, []);
    }
  }, [setValue, cepFormConfigurationGroup]);

  const handleChangeProduct = useCallback((event: SyntheticEvent<Element, Event>, product: SelectOption) => {
    if (product) {
      const therapeuticAreaValue = Object.keys(therapeuticAreaRelativesProducts)
        .find((key: string) => therapeuticAreaRelativesProducts[key as keyof typeof therapeuticAreaRelativesProducts]
          ?.includes(product.id as BiogenProduct));
      setValue(cepFormConfigurationGroup.therapeuticArea.name, therapeuticAreaValue);
    }
  }, [setValue, cepFormConfigurationGroup]);

  return {
    cepFormConfigurationGroup,
    handleCustomerInitiativeChange,
    handleChangeTA,
    handleChangeProduct,
  };
};

export default useCepFormConfigurationGroup;
