import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import { Event } from 'modules/events/basics/types/events.types';
import EventSummary from 'modules/events/components/EventsList/EventSummary';
import LinkItem, { LinkConfig } from 'modules/events/components/EventsList/LinkDialog/LinkItem/LinkItem';
import Box from 'yoda-ui/Box';
import Dialog, { DialogProps } from 'yoda-ui/Dialogs/Dialog/Dialog';
import DialogContent from 'yoda-ui/Dialogs/Dialog/DialogContent';
import { Icons } from 'yoda-ui/Icons/Icon';
import { YodaBorderRadius, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export type LinkDialogProps = DialogProps & {
  closeDialog: () => void;
  event: Event;
};

const dialogStyle = {
  '& .MuiPaper-rounded': {
    maxWidth: 'unset',
    overflow: 'visible',
  },
};

const LinkDialog: FC<LinkDialogProps> = ({ open, closeDialog, event, ...props }) => {
  const { t } = useTranslation();
  const { licenseId, organizerUrl, participantUrl } = event.data.provider || { organizerUrl: '', participantUrl: '' };

  const organizerLinkConfig: LinkConfig = {
    title: t('linkDialog_adminLink_title'),
    link: organizerUrl || '',
    button: {
      icon: Icons.playCircleOutline,
      label: t('linkDialog_launchEventButton_label'),
    },
  };
  const organizerProfileConfig: LinkConfig = {
    title: t('linkDialog_adminAccount_title'),
    link: licenseId || '',
  };
  const userLinkConfig: LinkConfig = {
    title: t('linkDialog_userLink_title'),
    link: participantUrl || '',
  };

  const eventIdConfig: LinkConfig = {
    title: t('linkDialog_event_id'),
    link: event._id || '',
  };

  const veevaExternalIdConfig: LinkConfig = {
    title: t('linkDialog_external_id'),
    link: event.externalId || '',
  };

  const zoomSessionIdConfig: LinkConfig = {
    title: t('linkDialog_provided_id'),
    link: event.data.provider?.providedId || '',
  };

  const webAppLinkLConfig: LinkConfig = {
    title: t('linkDialog_web_app_link'),
    link: event.data.biogenLincConfig?.slug || '',
  };

  const linkConfigs = [
    organizerProfileConfig,
    organizerLinkConfig,
    userLinkConfig,
    eventIdConfig,
    veevaExternalIdConfig,
    zoomSessionIdConfig,
  ];

  if (event.data.biogenLincConfig?.isVisibleOnBiogenLinc) {
    linkConfigs.push(webAppLinkLConfig);
  }

  return (
    <Dialog open={ open } { ...props } borderRadius={ YodaBorderRadius.medium } onClose={ closeDialog } sx={ dialogStyle }>
      <Box padding={ YodaSpacing.large }>
        <DefaultDialogTitle cancelDialog={ closeDialog }>
          <EventSummary event={ event } titleSize={ YodaFontSize.xLarge }/>
        </DefaultDialogTitle>
        <DialogContent>
          { linkConfigs.map((linkConfig) => (<LinkItem key={ linkConfig.title } linkConfig={ linkConfig } />)) }
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LinkDialog;
