import { FC } from 'react';
import useSessionsStep from './SessionStep.hook';
import TabTitle from 'components/TabTitle';
import Box from 'yoda-ui/Box';

const SessionStep: FC = () => {
  const { t } = useSessionsStep();
  return (
    <Box>
      <TabTitle title={ t('event_step_session') } />
    </Box>
  );
};

export default SessionStep;
