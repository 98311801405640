import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import DetailsStep from './Steps/DetailsStep/DetailsStep';
import SessionStep from './Steps/SessionStep/SessionStep';
import { eventsRoute } from 'basics/constants/routes.constants';
import ButtonsBar from 'components/ButtonsBar';
import {
  FormLayoutContainer,
  FormLayoutMidPanelHeader,
  FormLayoutRightPanel,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
} from 'components/FormLayout';
import LargeContainer from 'components/Layout/LargeContainer';
import SideFormDetails from 'modules/events/components/SideFormDetails';
import useCreateOnlineWebinar from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.hook';
import { CreateOnlineWebinarStepsIndex } from 'modules/events/routes/CreateOnlineWebinar/CreateOnlineWebinar.types';
import CommunicationStep from 'modules/events/routes/CreateOnlineWebinar/Steps/CommunicationStep/CommunicationStep';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import Icon from 'yoda-ui/Icons/Icon';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';
import LinkButton from 'yoda-ui/Links/LinkButton/LinkButton';
import Loader from 'yoda-ui/Loader';
import Stepper from 'yoda-ui/Stepper/Stepper';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const CreateOnlineWebinar: FC = () => {
  const {
    t,
    handleClickHome,
    handleChangeStep,
    showLoader,
    yodaFormMethods,
    stepIndexer,
    id,
    buttonsConfig,
    stepsList,
  } = useCreateOnlineWebinar();

  if (!id) {
    return <Navigate to={ eventsRoute } replace />;
  }

  if (showLoader) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return (
    <LargeContainer
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <YodaFormProvider { ...yodaFormMethods.providerFields }>
        <FormLayoutContainer>
          <FormLayoutMidPanel >
            <FormLayoutMidPanelHeader>
              <Box marginBottom={ YodaSpacing.medium }>
                <LinkButton startIcon={ <Icon name={ Icons.chevronLeft } /> } onClick={ handleClickHome }>
                  { `${t('home_link')}` }
                </LinkButton>
              </Box>
              <Stepper
                handleChangeStep={ handleChangeStep }
                stepsList={ stepsList }
                stepIndex={ stepIndexer.stepIndex }
              />
            </FormLayoutMidPanelHeader>
            <FormLayoutMidPanelContent>
              <Box hidden={ stepIndexer.stepIndex !== CreateOnlineWebinarStepsIndex.detailsStep }>
                <DetailsStep />
              </Box>
              <Box hidden={ stepIndexer.stepIndex !== CreateOnlineWebinarStepsIndex.sessionStep }>
                <SessionStep />
              </Box>
              <Box hidden={ stepIndexer.stepIndex !== CreateOnlineWebinarStepsIndex.communicationStep }>
                <CommunicationStep />
              </Box>
              { buttonsConfig && <ButtonsBar { ...buttonsConfig } /> }
            </FormLayoutMidPanelContent>
          </FormLayoutMidPanel>
          <FormLayoutRightPanel >
            <SideFormDetails />
          </FormLayoutRightPanel>
        </FormLayoutContainer>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default CreateOnlineWebinar;
