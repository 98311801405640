import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  createOnlineMeetingWithIdRoute,
  createOnlineWebinarWithIdRoute,
  editOnlineMeetingWithIdRoute, editOnlineWebinarWithIdRoute,
  homeRoute,
  loginRoute,
  mayaCepCreateRoute,
  mayaCepListRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeEditRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCustomerInitiativeListRoute,
  mayaRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeListRoute,
  mayaImportExportRoute,
  mayaCiTemplateCreateRoute,
  mayaCiTemplateListRoute,
  mayaCiTemplateEditRoute,
  mayaCepEditRoute,
  mayaCepDuplicateRoute,
  mayaCepTemplateListRoute,
  mayaCepTemplateCreateRoute,
  mayaCepTemplateEditRoute,
  eventsRoute,
  mayaCepTrashedListRoute,
  mayaCepViewRoute,
  mayaCiViewRoute,
  mayaStrategicImperativeEditRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaTacticPrintRoute,
} from 'basics/constants/routes.constants';
import HomePage from 'components/HomePage/HomePage';
import IdleTimerDialog from 'components/IdleTimerDialog';
import CreateOnlineMeeting from 'modules/events/routes/CreateOnlineMeeting';
import CreateOnlineWebinar from 'modules/events/routes/CreateOnlineWebinar';
import EditOnlineMeeting from 'modules/events/routes/EditOnlineMeeting/EditOnlineMeeting';
import EditOnlineWebinar from 'modules/events/routes/EditOnlineWebinar/EditOnlineWebinar';
import Events from 'modules/events/routes/Events';
import CepCreate from 'modules/maya/routes/CepCreate/CepCreate';
import CepDuplicate from 'modules/maya/routes/CepDuplicate/CepDuplicate';
import CepEdit from 'modules/maya/routes/CepEdit/CepEdit';
import CepList from 'modules/maya/routes/CepList/CepList';
import CepTemplateCreate from 'modules/maya/routes/CepTemplateCreate/CepTemplateCreate';
import CepTemplateEdit from 'modules/maya/routes/CepTemplateEdit/CepTemplateEdit';
import CepTemplateList from 'modules/maya/routes/CepTemplateList/CepTemplateList';
import CepTrashedList from 'modules/maya/routes/CepTrashedList/CepTrashedList';
import CepView from 'modules/maya/routes/CepView/CepView';
import CiTemplateCreate from 'modules/maya/routes/CiTemplateCreate/CiTemplateCreate';
import CiTemplateEdit from 'modules/maya/routes/CiTemplateEdit/CiTemplateEdit';
import CiTemplateList from 'modules/maya/routes/CiTemplatesList/CiTemplateList';
import CustomerInitiativeCreate from 'modules/maya/routes/CustomerInitiativeCreate/CustomerInitiativeCreate';
import CustomerInitiativeDuplicate from 'modules/maya/routes/CustomerInitiativeDuplicate/CustomerInitiativeDuplicate';
import CustomerInitiativeEdit from 'modules/maya/routes/CustomerInitiativeEdit/CustomerInitiativeEdit';
import CustomerInitiativeList from 'modules/maya/routes/CustomerInitiativeList/CustomerInitiativeList';
import CustomerInitiativeView from 'modules/maya/routes/CustomerInitiativeView/CustomerInitiativeView';
import AopImportExport from 'modules/maya/routes/ImportExport/AopImportExport';
import Maya from 'modules/maya/routes/Maya/Maya';
import StrategicImperativeCreate from 'modules/maya/routes/StrategicImperativeCreate/StrategicImperativeCreate';
import StrategicImperativeDuplicate from 'modules/maya/routes/StrategicImperativeDuplicate/StrategicImperativeDuplicate';
import StrategicImperativeEdit from 'modules/maya/routes/StrategicImperativeEdit/StrategicImperativeEdit';
import StrategicImperativeList from 'modules/maya/routes/StrategicImperativeList/StrategicImperativeList';
import TacticPrintView from 'modules/maya/routes/TacticPrintView/TacticPrintView';
import NotFound from 'routes/AuthenticatedApp/NotFound';

const AuthenticatedApp: FC = () => {
  const flags = useFlags();

  return (
    <>
      <IdleTimerDialog />
      <Routes>
        <Route path="*" element={ <NotFound /> } />
        <Route path={ loginRoute } element={ <Navigate to="/" replace /> } />
        {
          flags.enableEvents && (
            <>
              <Route path={ homeRoute } element={ <HomePage /> } />

              <Route path={ eventsRoute } element={ <Events /> } />
              <Route path={ createOnlineMeetingWithIdRoute } element={ <CreateOnlineMeeting /> } />
              <Route path={ createOnlineWebinarWithIdRoute } element={ <CreateOnlineWebinar /> } />
              <Route path={ editOnlineMeetingWithIdRoute } element={ <EditOnlineMeeting /> } />
              <Route path={ editOnlineWebinarWithIdRoute } element={ <EditOnlineWebinar /> } />
            </>
          )
        }
        {
          flags.enableMaya && (
            <>
              <Route path={ mayaRoute } element={ <Maya /> } />
              <Route path={ mayaStrategicImperativeListRoute } element={ <StrategicImperativeList /> } />
              <Route path={ mayaStrategicImperativeCreateRoute } element={ <StrategicImperativeCreate /> } />
              <Route path={ mayaStrategicImperativeEditRoute } element={ <StrategicImperativeEdit/> } />
              <Route path={ mayaStrategicImperativeDuplicateRoute } element={ <StrategicImperativeDuplicate /> } />
              <Route path={ mayaCustomerInitiativeListRoute } element={ <CustomerInitiativeList /> } />
              <Route path={ mayaCustomerInitiativeCreateRoute } element={ <CustomerInitiativeCreate /> } />
              <Route path={ mayaCiTemplateCreateRoute } element={ <CiTemplateCreate /> } />
              <Route path={ mayaCiTemplateEditRoute } element={ <CiTemplateEdit /> } />
              <Route path={ mayaCustomerInitiativeDuplicateRoute } element={ <CustomerInitiativeDuplicate /> } />
              <Route path={ mayaCustomerInitiativeEditRoute } element={ <CustomerInitiativeEdit /> } />
              <Route path={ mayaCiTemplateListRoute } element={ <CiTemplateList /> } />
              <Route path={ mayaCiViewRoute } element={ <CustomerInitiativeView /> } />
              <Route path={ mayaCepListRoute } element={ <CepList /> } />
              <Route path={ mayaCepTemplateListRoute } element={ <CepTemplateList /> } />
              <Route path={ mayaCepCreateRoute } element={ <CepCreate /> } />
              <Route path={ mayaCepTemplateCreateRoute } element={ <CepTemplateCreate /> } />
              <Route path={ mayaCepTemplateEditRoute } element={ <CepTemplateEdit /> } />
              <Route path={ mayaCepEditRoute } element={ <CepEdit /> } />
              <Route path={ mayaCepDuplicateRoute } element={ <CepDuplicate /> } />
              <Route path={ mayaCepTrashedListRoute } element={ <CepTrashedList /> } />
              <Route path={ mayaCepViewRoute } element={ <CepView /> } />
              <Route path={ mayaTacticPrintRoute } element={ <TacticPrintView /> } />
              {
                flags.mayaEnableAopImportBiogenlinc32 && (
                  <Route path={ mayaImportExportRoute } element={ <AopImportExport /> } />
                )
              }
            </>
          )
        }
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
