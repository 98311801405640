import { FC } from 'react';
import { formLayoutPaddingTop, formLayoutStickyStyles } from './FormLayout.styles';
import Box from 'yoda-ui/Box';

const FormLayoutMidPanelHeader: FC = ({ children }) => {
  return (
    <Box
      paddingTop={ formLayoutPaddingTop }
      width="20%"
      sx={ formLayoutStickyStyles }
    >
      { children }
    </Box>
  );
};

export default FormLayoutMidPanelHeader;
