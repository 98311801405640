import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import { CepFormSubmitCallbackType } from 'modules/maya/components/CepForm/CepForm.types';
import { useCreateCepMutation } from 'modules/maya/graphql/mutations/createCep';
import { useUpdateCepMutation } from 'modules/maya/graphql/mutations/updateCep';
import cepState from 'modules/maya/states/cep.states';

const useCepCreate = () => {
  const [createCustomerEngagementPlanning] = useCreateCepMutation();
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();

  const customerEngagementPlanningStateValue = useSnapshot(cepState) as CepStateType;

  useEffect(() => {
    cepState.cep = {};
  }, []);

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    if (customerEngagementPlanningStateValue.cep?._id) {
      const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
        customerEngagementPlanningStateValue.cep._id,
        cep,
        true,
      );

      return customerEngagementPlanningData;
    }

    const customerEngagementPlanningData = await createCustomerEngagementPlanning(
      cep,
      true,
    );
    return customerEngagementPlanningData;
  };

  return {
    submitCallback,
  };
};

export default useCepCreate;
