import { EventStatus } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventPromotionalityEnum } from 'modules/events/basics/enums/events.enums';
import { Event, EventStateType } from 'modules/events/basics/types/events.types';
import { OnlineMeetingInput } from 'modules/events/basics/types/onlineMeeting.types';
import {
  transformAgenda,
  transformInputAssets,
  transformInputBiogenLincConfig,
  transformInputProvider,
  transformSpeakers,
} from 'modules/events/basics/utils/eventsInput.transformers';
import { transformOutputProvider, transformOutputSession } from 'modules/events/basics/utils/eventsOutput.transformers';
import eventState from 'modules/events/states/events.states';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

// FROM BO TO BE
export const onlineMeetingInputTransformer = (data: YodaFieldValue, formValues: YodaFieldValue) => {
  if (eventState.createEvent._id && eventState.createEvent.status) {
    const selectedProgram = eventState.programs?.data?.find((program) => program.programName === data[commonFields.program]);
    const isPublishedEvent = eventState.createEvent.status === EventStatus.published;
    const assets = transformInputAssets(data);
    const provider = transformInputProvider(formValues);
    const biogenLincConfig = transformInputBiogenLincConfig(data, formValues);
    const speakers = transformSpeakers(data);
    const agenda = transformAgenda(data);

    const onlineMeetingInput: OnlineMeetingInput = {
      id: eventState.createEvent._id,
      ...(data[commonFields.title] !== undefined && data[commonFields.title] ? { title: data[commonFields.title] } : {}),
      ...(data[commonFields.emailContentfulUrl] !== undefined ? { contentfulUrl: data[commonFields.emailContentfulUrl] } : {}),
      ...(data[commonFields.locale] !== undefined && data[commonFields.locale] ? { locale: data[commonFields.locale] } : {}),
      ...(data[commonFields.disclaimerText] !== undefined ? { disclaimerText: data[commonFields.disclaimerText] } : {}),
      ...(formValues[commonFields.productInformationButtonLabel] !== undefined ? {
        productInformationButtonLabel: formValues[commonFields.productInformationButtonLabel],
      } : {}),
      ...(data[commonFields.productInformationButtonLink] !== undefined ? {
        productInformationButtonLink: data[commonFields.productInformationButtonLink],
      } : {}),
      ...(data[commonFields.speakerSupportPhoneNumber] !== undefined ? {
        speakerSupportPhoneNumber: data[commonFields.speakerSupportPhoneNumber],
      } : {}),
      ...(data[commonFields.collectParticipantPhoneNumber] !== undefined ? {
        collectParticipantPhoneNumber: data[commonFields.collectParticipantPhoneNumber],
      } : {}),
      ...(data[commonFields.enableZoomStreaming] !== undefined ? {
        enableZoomStreaming: data[commonFields.enableZoomStreaming],
      } : {}),
      ...(data[commonFields.recordingOptinLabel] !== undefined ? { recordingOptinLabel: data[commonFields.recordingOptinLabel] } : {}),
      ...(data[commonFields.emailHeaderNote] !== undefined ? { emailHeaderNote: data[commonFields.emailHeaderNote] } : {}),
      ...(data[commonFields.emailFooterNote] !== undefined ? { emailFooterNote: data[commonFields.emailFooterNote] } : {}),
      ...(data[commonFields.biogenLogo] !== undefined ? { biogenLogo: data[commonFields.biogenLogo] } : {}),
      ...(data[commonFields.buttonColor] !== undefined ? { buttonColor: data[commonFields.buttonColor] } : {}),
      ...(data[commonFields.participantsSupportPhoneNumber] !== undefined
        ? { participantsSupportPhoneNumber: data[commonFields.participantsSupportPhoneNumber] } : {}),
      ...(!isPublishedEvent && data[commonFields.coorganizers] ? { coOrganizers: data[commonFields.coorganizers] } : {}),
      ...(data[commonFields.description] !== undefined ? { description: data[commonFields.description] } : {}),
      ...(data[commonFields.product] ? { product: data[commonFields.product] } : {}),
      ...(data[commonFields.program] ? { program: data[commonFields.program] } : {}),
      ...(selectedProgram ? { programLogoUrl: selectedProgram.programLogoUrl } : {}),
      ...(!isPublishedEvent ? provider : null),
      ...(!isPublishedEvent ? speakers : null),
      ...(!isPublishedEvent && data[commonFields.testimonials] ? { testimonials: data[commonFields.testimonials] } : null),
      ...(data[commonFields.agenda] ? { agenda } : null),
      ...(assets),
      ...(biogenLincConfig),
    };

    return onlineMeetingInput;
  }
  return undefined;
};

// FROM BE TO BO
export const onlineMeetingTransformer = (event: Event) => {
  const createEventState: EventStateType['createEvent'] = {
    ...event,
    data: {
      ...event.data,
      ...transformOutputProvider(event.data?.provider),
      session: transformOutputSession(event.data?.session),
      cardImage: event.data?.assets?.cardImage,
      teaser: event.data?.assets?.teaser,
      isVisibleOnBiogenLinc: event.data?.biogenLincConfig?.isVisibleOnBiogenLinc,
      enableGuestRegistration: event.data?.biogenLincConfig?.enableGuestRegistration,
      isPublic: event.data?.biogenLincConfig?.isPublic ? EventPromotionalityEnum.public : EventPromotionalityEnum.gated,
      prcCodeEventDetails: event.data?.biogenLincConfig?.prcCodeEventDetails,
      participants: event.data?.participants,
      showSendInvitations: event.data?.biogenLincConfig?.showSendInvitations,
    },
  };
  return createEventState;
};
