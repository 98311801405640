import ParticipantsGroup from 'modules/events/groups/ParticipantsGroup';
import useEditOnlineWebinar from 'modules/events/routes/EditOnlineWebinar/EditOnlineWebinar.hook';
import Box from 'yoda-ui/Box';

const InviteesRegistrationPanel = () => {
  const { event } = useEditOnlineWebinar();

  if (!event?.data?.participants) {
    return null;
  }

  return (
    <Box flexDirection="column">
      <ParticipantsGroup participants={ event.data.participants }/>
    </Box>
  );
};

export default InviteesRegistrationPanel;
