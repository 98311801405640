export const userFragment = `
  id
  name
  lastName
  email
`;

export const eventFragment = `
  __typename  
  _id
  owner {
    ${userFragment}
  }
  externalId
  title
  description
  contentfulUrl
  locale
  format
  presence
  market
  status
  createdAt
  updatedAt
`;
