import { useTranslation } from 'react-i18next';
import { Channel, EngagementMetric } from 'modules/maya/basics/enums/maya.enums';

const useEngagementMetricOptions = (tacticChannelType: string) => {
  const { t } = useTranslation();
  const engagementMetricKeys = Object.keys(EngagementMetric);
  const engagementMetricValues = Object.values(EngagementMetric);
  switch (tacticChannelType) {
    case Channel.approvedEmail:
    case Channel.broadcastEmail:
    case Channel.oneToOne:
      return [
        { id: EngagementMetric.ctaClickRate, label: t('maya_engagementmetric_ctaclickrate') },
        { id: EngagementMetric.openRate, label: t('maya_engagementmetric_openrate') },
      ];
    case Channel.virtualEvent:
    case Channel.f2fEvent:
    case Channel.hybridEvent:
      return [
        { id: EngagementMetric.attendanceRate, label: t('maya_engagementmetric_attendancerate') },
      ];
    case Channel.webLogIn:
    case Channel.webNoLogIn:
      return [
        { id: EngagementMetric.actionCompleted, label: t('maya_engagementmetric_actioncompleted') },
      ];
    default:
      return engagementMetricValues.map((value, index) => { return { id: engagementMetricKeys[index], label: value }; });
  }
};

export default useEngagementMetricOptions;
