import { FormAgendaConfig, ListConfig } from 'components/QuickForm/QuickForm.types';
import { Event } from 'modules/events/basics/types/events.types';
import { YodaFieldValidation } from 'services/yodaForm/yodaForm.types';

export type AgendaConfigType = {
  defaultValue: Event[];
  formConfig: FormAgendaConfig;
  listConfig: ListConfig;
  name: string;
  validation: YodaFieldValidation;
  disabled: boolean;
};

export enum AgendaConfigField {
  dateRange = 'dateRange',
  description = 'description',
  endTime = 'endTime',
  startTime = 'startTime',
  title = 'title',
}
