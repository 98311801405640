import { FC } from 'react';
import ContentCard from 'components/ContentCard';
import GridList from 'modules/maya/components/GridList/GridList';
import useTargetListView from 'modules/maya/routes/CepView/Steps/TargetListView/TargetListView.hook';

const TargetListView: FC = () => {
  const {
    data,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    targetListConfig,
  } = useTargetListView();

  return (
    <ContentCard>
      <GridList
        getRowId={ (row) => row._id }
        loading={ loading }
        columnConfig={ targetListConfig }
        rows={ data }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        disableColumnMenu={ true }
        hideFooterPagination={ rowCountState === 0 }
      />
    </ContentCard>
  );
};

export default TargetListView;
