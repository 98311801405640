import { Dispatch, FC, SetStateAction } from 'react';
import useCreateEventModal from './CreateEventModal.hook';
import DialogForm from 'components/DialogForm';
import CreateEventDialogContent from 'modules/events/components/CreateEvent/CreateEventModal/CreateEventDialogContent';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import { YodaFontSize, YodaJustifyContent } from 'yoda-ui/yodaTheme';

type CreateEventModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const CreateEventModal: FC<CreateEventModalProps> = ({ open, setOpen }) => {
  const {
    cancelButtonConfig,
    createEventConfig,
    providerFields,
    submitButtonConfig,
    t,
  } = useCreateEventModal(setOpen);

  return (
    <YodaFormProvider { ...providerFields }>
      <DialogForm
        dialogTitle={ t('event_creation_modal_title') }
        open={ open }
        setOpen={ setOpen }
        cancelButtonConfig={ cancelButtonConfig }
        submitButtonConfig={ submitButtonConfig }
        fullWidth
        justifyActions={ YodaJustifyContent.center }
      >
        <Box fontSize={ YodaFontSize.xLarge }>
          <CreateEventDialogContent createEventConfig={ createEventConfig } />
        </Box>
      </DialogForm>
    </YodaFormProvider>
  );
};

export default CreateEventModal;
