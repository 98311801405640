import { useNavigate } from 'react-router-dom';
import { eventsRoute, mayaRoute } from 'basics/constants/routes.constants';
import { useCurrentUserState } from 'contexts/user';

const useHomePage = () => {
  const { user } = useCurrentUserState();
  const navigate = useNavigate();

  const handleNavigateToMaya = () => {
    navigate(mayaRoute);
  };

  const handleNavigateToEvents = () => {
    navigate(eventsRoute);
  };

  return {
    handleNavigateToMaya,
    handleNavigateToEvents,
    user,
  };
};

export default useHomePage;
