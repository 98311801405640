import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { Tactic } from 'generated/maya.types';
import { TacticMode } from 'modules/maya/basics/enums/maya.enums';
import { CepStateType } from 'modules/maya/basics/types/maya.types';
import TacticCard from 'modules/maya/components/TacticCard/TacticCard';
import useTacticKanbanView from 'modules/maya/routes/CepView/Steps/TacticsView/TacticsView.hook';
import {
  TacticCardListStyle,
  TacticCardWrapperStyle,
  TacticKanbanStyle,
} from 'modules/maya/routes/CepView/Steps/TacticsView/TacticsView.styles';
import cepState from 'modules/maya/states/cep.states';
import tacticState from 'modules/maya/states/tactic.states';
import Box from 'yoda-ui/Box';
import Card from 'yoda-ui/Card/Card';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticsView: FC = () => {
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const { t } = useTranslation();
  const {
    groupedTactics,
  } = useTacticKanbanView();

  useEffect(() => {
    tacticState.cepId = cepStateValue.cep?._id || '';
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  }, [cepStateValue.cep?._id]);

  return (
    <Box>
      <Box>
        <Box sx={ TacticKanbanStyle }>
          {
            Object.keys(groupedTactics).map((actionOrder: string) => {
              const tactics = groupedTactics[actionOrder];
              return (
                tactics.length > 0 && (
                  <Card key={ actionOrder } variant="outlined" sx={ TacticCardWrapperStyle }>
                    <Box marginBottom={ YodaSpacing.small }>
                      { `${t('maya_tactic_action_order')} ${actionOrder}` }
                    </Box>
                    <Box sx={ TacticCardListStyle }>
                      {
                        tactics.map((tactic: Tactic) => (
                          <TacticCard key={ tactic._id } tactic={ tactic } readOnly={ true } />
                        ))
                      }
                    </Box>
                  </Card>
                )
              );
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

export default TacticsView;
