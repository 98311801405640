import { useEffect, useMemo, useState } from 'react';
import useGetCustomerInitiativeByIdQuery from 'modules/maya/graphql/queries/getCustomerInitiativeById';

const useCustomerInitiativeView = () => {
  const [loading, setLoading] = useState(true);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const customerInitiative = useGetCustomerInitiativeByIdQuery(urlParams.get('id')).data;

  useEffect(() => {
    if (customerInitiative) {
      setLoading(false);
    }
  }, [customerInitiative, setLoading]);

  let date;
  if (customerInitiative?.dateStart && customerInitiative?.dateEnd) {
    date = `
      ${new Date(customerInitiative.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(customerInitiative.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    customerInitiative,
    date,
    loading,
  };
};

export default useCustomerInitiativeView;
