export enum EventsListItemAction {
  deleteEvent = 'deleteEvent',
  duplicateEvent = 'duplicateEvent',
  editEvent = 'editEvent',
  editPublishedEvent = 'editPublishedEvent',
  manageEvent = 'manageEvent',
  showEventLinks = 'showEventLinks',
  exportParticipants = 'exportParticipants',
  sendInvitation = 'sendInvitation',
}
