import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCard from 'components/ContentCard';
import { FormLayoutContainer } from 'components/FormLayout';
import useCepFormConfigurationGroup from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.hook';
import { CepFormConfigurationGroupPropsType } from 'modules/maya/groups/CepFormConfigurationGroup/CepFormConfigurationGroup.types';
import Box from 'yoda-ui/Box';
import DateRangePicker from 'yoda-ui/Form/DateRangePicker';
import RadioGroup from 'yoda-ui/Form/RadioGroup';
import SelectYoda from 'yoda-ui/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const CepFormConfigurationGroup: FC<CepFormConfigurationGroupPropsType> = ({ isTemplate }) => {
  const {
    cepFormConfigurationGroup,
    handleCustomerInitiativeChange,
    handleChangeTA,
    handleChangeProduct,
  } = useCepFormConfigurationGroup(isTemplate);
  const { t } = useTranslation();
  const width50Style = 'calc(50% - 20px)';
  const flags = useFlags();

  return (
    <>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <Box marginBottom={ YodaSpacing.large }>
          <TextYoda { ...cepFormConfigurationGroup.title } />
        </Box>
        <Box width={ width50Style } marginBottom={ YodaSpacing.large }>
          <SelectYoda
            { ...cepFormConfigurationGroup.customerInitiative }
            onChange={ handleCustomerInitiativeChange }
          />
          <TextYoda { ...cepFormConfigurationGroup.ciDateStart } display={ false }/>
          <TextYoda { ...cepFormConfigurationGroup.ciDateEnd } display={ false } />
        </Box>
        <Box>
          <DateRangePicker { ...cepFormConfigurationGroup.date } />
        </Box>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween }>
          {
            flags.mayaFinanceBiogenlinc866 && (
              <Box width={ width50Style }>
                { t('maya_cep_configurationStep_multiYear_label') }
                <RadioGroup { ...cepFormConfigurationGroup.multiyearPlan } />
              </Box>
            )
          }

        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ YodaSpacing.large }>
          <Box width={ width50Style }>
            <SelectYoda { ...cepFormConfigurationGroup.therapeuticArea } onChange={ handleChangeTA } />
          </Box>
          <Box width={ width50Style }>
            <SelectYoda { ...cepFormConfigurationGroup.product } onChange={ handleChangeProduct } />
          </Box>
        </FormLayoutContainer>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween }>
          <Box width={ width50Style }>
            <SelectYoda { ...cepFormConfigurationGroup.biogenFunction } />
          </Box>
          <Box width={ width50Style } >
            <TextYoda { ...cepFormConfigurationGroup.kpi } />
          </Box>
        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ YodaSpacing.xxxSmall }>
          { <TextYoda { ...cepFormConfigurationGroup.description } /> }
        </FormLayoutContainer>
      </ContentCard>
      <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween }>
          <Box width={ width50Style }>
            <MultiSelectYoda { ...cepFormConfigurationGroup.customerType } />
          </Box>
          <Box width={ width50Style }>
            <MultiSelectYoda { ...cepFormConfigurationGroup.specialty } />
          </Box>
          {
            flags.mayaFinanceBiogenlinc866 && (
              <Box width={ width50Style } >
                { t('maya_cep_configurationStep_digital_label') }
                <RadioGroup { ...cepFormConfigurationGroup.digital } />
              </Box>
            )
          }
        </FormLayoutContainer>
      </ContentCard>
      {
        flags.mayaFinanceBiogenlinc866 && (
          <ContentCard sx={ { marginBottom: fromUnitsToRem(YodaSpacing.large) } }>
            <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } marginBottom={ YodaSpacing.large }>
              <Box width={ width50Style }>
                <MultiSelectYoda { ...cepFormConfigurationGroup.spendCategory } />
              </Box>
              <Box width={ width50Style }>
                <SelectYoda { ...cepFormConfigurationGroup.localCurrency } />
              </Box>
            </FormLayoutContainer>
            <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween }>
              <Box width={ width50Style }>
                { t('maya_cep_configurationStep_AOP_label') }
                <RadioGroup { ...cepFormConfigurationGroup.aop } />
              </Box>
              <Box width={ width50Style }>
                <TextYoda { ...cepFormConfigurationGroup.budgetPlanned } />
              </Box>
            </FormLayoutContainer>
          </ContentCard>
        )
      }
    </>
  );
};

export default CepFormConfigurationGroup;
