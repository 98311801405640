import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateEventModal from 'modules/events/components/CreateEvent/CreateEventModal';
import Box from 'yoda-ui/Box';
import Button from 'yoda-ui/Button';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import Icon from 'yoda-ui/Icons/Icon';
import { Icons } from 'yoda-ui/Icons/Icon/Icon';

const CreateEvent: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Button
        startIcon={ <Icon name={ Icons.addCircle } /> }
        buttonType={ ButtonType.secondary }
        onClick={ () => setOpen(true) }
      >
        { t('event_creation_modal_button_label') }
      </Button>
      <CreateEventModal open={ open } setOpen={ setOpen } />
    </Box>
  );
};

export default CreateEvent;
