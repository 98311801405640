import { useTranslation } from 'react-i18next';
import { mixed, string } from 'yup';
import { BiogenTherapeuticArea } from 'basics/enums/biogen.enums';

const useStrategicImperativeFormSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('form_errors_required');

  const currentYear: number = new Date().getFullYear();
  const currentYearError = t('maya_strategic_imperative_form_year_error');
  const nextDecade: number = new Date().getFullYear() + 10;
  const nextDecadeError = t('maya_strategic_imperative_form_decade_error');

  return {
    title: string()
      .required(requiredLabel),
    description: string(),
    therapeuticArea: mixed<BiogenTherapeuticArea>()
      .oneOf(Object.values(BiogenTherapeuticArea), requiredLabel)
      .required(),
    year: string()
      .test({
        name: 'is_year_correct',
        test: (year, context) => {
          if (!year) {
            return context.createError(
              {
                message: requiredLabel,
              },
            );
          }

          const yearNumber = parseInt(year || '0', 10);

          if (yearNumber < currentYear) {
            return context.createError(
              {
                message: currentYearError,
              },
            );
          }
          if (yearNumber > nextDecade) {
            return context.createError(
              {
                message: nextDecadeError,
              },
            );
          }

          return true;
        },
      }),
  };
};

export default useStrategicImperativeFormSchema;
