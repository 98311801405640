export enum CreateOnlineMeetingStepsIndex {
  detailsStep = 0,
  communicationStep = 1,
  sessionStep = 2,
}

// TODO: rename this one to CreateOnlineMeetingStepsIndex when epic BIOGENLINC-3524 is finished
export enum CreateOnlineMeetingWithEmailConfigurationStepsIndex {
  detailsStep = 0,
  communicationStep = 1,
  emailConfigurationStep = 2,
  sessionStep = 3,
}
