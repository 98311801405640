import { CustomerInitiative } from 'generated/maya.types';

export const isCustomerInitiative = (value: unknown): value is CustomerInitiative => {
  const customerInitiativeRequiredKey = [
    '_id',
    'title',
    'description',
    'dateStart',
    'dateEnd',
    'country',
    'product',
    'therapeuticArea',
    'strategicImperative',
    'problemStatement',
    'solutionCoreStory',
    'successMesure',
    'functionalOwner',
    'functionsInvolved',
    'totalBudget',
    'status',
    'updated',
    'isTemplate',
  ];

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const objectKeys = Object.keys(value);
  const mixedSet = new Set([...objectKeys, ...customerInitiativeRequiredKey]);

  return mixedSet.size === objectKeys.length;
};
