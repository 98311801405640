import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { useSnapshot } from 'valtio';
import useAgendaGroupConfig from './AgendaGroup.config';
import { EventStateType } from 'modules/events/basics/types/events.types';
import useAgendaConfig from 'modules/events/components/Agenda/Agenda.config';
import eventState from 'modules/events/states/events.states';

const useAgenda = () => {
  const { createEvent: { data: eventSnapData } } = useSnapshot(eventState) as EventStateType;

  const [startDate, endDate] = eventSnapData?.session?.dateRange || [null, null];
  const dateRange: string[] = startDate && endDate ? [
    format(startDate, 'yyyy-MM-dd'),
    format(endDate, 'yyyy-MM-dd'),
  ] : [];
  const minParentTime = startDate && isToday(startDate) ? format(startDate.getTime(), 'HH:mm') : '00:00';
  const { formConfig: agendaFormConfig } = useAgendaConfig({ dateRange, minParentTime });
  const { formConfig } = useAgendaGroupConfig({ agendaFormConfig });

  return {
    formConfig,
  };
};

export default useAgenda;
