import { useTranslation } from 'react-i18next';
import { validateImagesDimensions } from 'basics/utils/files/files.validations';
import { HandleFiles } from 'components/QuickForm/QuickForm.types';
import useAddSpeakerProfileConfig from 'modules/events/components/AddSpeakerProfile/AddSpeakerProfile.config';

const useAddSpeakerProfile = () => {
  const { t } = useTranslation();
  const { addSpeakerProfileConfig } = useAddSpeakerProfileConfig();

  const handleDropImage: HandleFiles = async (files) => {
    const { minWidth, minHeight } = addSpeakerProfileConfig.image.dimensions;
    const dimensionsAreValid = await validateImagesDimensions({
      files,
      dimensions: addSpeakerProfileConfig.image.dimensions,
    });

    return dimensionsAreValid ? { ok: true } : {
      ok: false,
      errorMessage: `${t('cardImage_dimensions_validation_error_message', { imageWidth: minWidth, imageHeight: minHeight })}`,
    };
  };
  return {
    handleDropImage,
    addSpeakerProfileConfig,
  };
};

export default useAddSpeakerProfile;
