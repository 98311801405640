import { t } from 'i18next';
import { cloneDeep, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  mayaCepTemplateCreateRoute,
  mayaCiTemplateCreateRoute,
  mayaCiTemplateEditRoute,
  mayaCustomerInitiativeDuplicateRoute,
} from 'basics/constants/routes.constants';
import { errorToast, succesToast } from 'basics/utils/toast';
import { CustomerInitiative } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CiStatus } from 'modules/maya/basics/enums/maya.enums';
import { useSetCiStatusToDeletedMutation } from 'modules/maya/graphql/mutations/setCiStatusToDeleted';
import useGetAllCustomerInitiativeQuery from 'modules/maya/graphql/queries/getAllCustomerInitiatives';
import useCiTemplateListConfig from 'modules/maya/routes/CiTemplatesList/CiTemplateList.config';

const useCiTemplateList = () => {
  const navigate = useNavigate();
  const [ciTitleFilter, setCiTitleFilter] = useState<(string)>();
  const [setCiStatusToDeleted, { loading: removeCiLoading }] = useSetCiStatusToDeletedMutation();
  const [cis, setCis] = useState<CustomerInitiative[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      title: ciTitleFilter,
      isTemplate: true,
      status: [CiStatus.ACTIVE, CiStatus.DRAFT],
    }),
    [ciTitleFilter],
  );

  const { data, loading, count } = useGetAllCustomerInitiativeQuery(queryOptions, queryFilters);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setCis(data);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const debouncedSearch = useCallback(() => {
    return debounce((searchValue) => setCiTitleFilter(searchValue), 750);
  },
  [setCiTitleFilter]);

  const handleChangeCiTitle = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    debouncedSearch()(event.target.value);
  }, [debouncedSearch]);

  const editCiTemplateCallback = async (cepId: string) => {
    navigate(`${mayaCiTemplateEditRoute}?id=${cepId}`);
  };

  const duplicateCiTemplateCallback = async (cepId: string) => {
    navigate(`${mayaCustomerInitiativeDuplicateRoute}?sourceId=${cepId}`);
  };

  const addChildCallback = async (cepId: string) => {
    navigate(`${mayaCepTemplateCreateRoute}?sourceId=${cepId}`);
  };

  const deleteCiCallback = async (ciId: string) => {
    const isCiDeleted = await setCiStatusToDeleted(ciId, false);

    if (isCiDeleted.data) {
      succesToast(t('maya_action_deleting_ci_success'));
      const clonedCis = cloneDeep(cis);
      const targetCiIndex = clonedCis.findIndex((ci) => { return ci._id === ciId; });
      if (targetCiIndex !== -1) {
        clonedCis.splice(targetCiIndex, 1);
        setCis(clonedCis);
      }
    } else if (isCiDeleted.error) {
      errorToast(isCiDeleted.error);
    }
    setOpen(false);
  };

  const openModalCallback = (ciId: string) => {
    setOpen(true);
    setRowId(ciId);
  };

  const { customerInitiativeListColumnConfig, ciTitleFieldConfig } = useCiTemplateListConfig(
    editCiTemplateCallback,
    duplicateCiTemplateCallback,
    addChildCallback,
    openModalCallback,
  );

  const newCiTemplateClick = () => {
    navigate(mayaCiTemplateCreateRoute);
  };

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: 'Template' }),
    newElementClick: newCiTemplateClick,
  };

  return {
    customerInitiativeListColumnConfig,
    ciTitleFieldConfig,
    handleChangeCiTitle,
    newElementButton,
    cis,
    open,
    setOpen,
    deleteCiCallback,
    rowId,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    removeCiLoading,
  };
};

export default useCiTemplateList;
