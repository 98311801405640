import { Buffer } from 'buffer';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EventStatus, OnlineEvent } from 'generated/event.types';
import { Event } from 'modules/events/basics/types/events.types';
import { getEditRouteFromEvent, getEditRouteFromPublishedEvent, isDraft } from 'modules/events/basics/utils/events.utils';
import { EventsListItemAction } from 'modules/events/components/EventsList/EventsListItem/EventsListItem.types';
import { useSendOnlineEventInvitationtMutation } from 'modules/events/graphql/mutations/sendOnlineEventInvitation';
import useExportEventParticipantDataQuery, { ResultType } from 'modules/events/graphql/queries/exportEventParticipantData';
import { YodaColors } from 'yoda-ui/yodaTheme';

type UseEventsListItem = {
  event: Event;
};

const useEventsListItem = ({ event }: UseEventsListItem) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [linkDialogIsOpen, setLinkDialogIsOpen] = useState(false);

  const closeLinkDialog = useCallback(() => {
    setLinkDialogIsOpen(false);
  }, []);

  const handleEditClick = useCallback(() => {
    navigate(getEditRouteFromEvent(event));
  }, [event, navigate]);

  const [executeMutation] = useSendOnlineEventInvitationtMutation();

  const handleEditPublishedClick = useCallback(() => {
    if (!isDraft(event.status)) {
      navigate(getEditRouteFromPublishedEvent(event));
    }
  }, [event, navigate]);

  const triggerDownload = (url: string) => {
    const eventClick = new MouseEvent('click');
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('download', `export-event-${new Date().getTime()}`);
    el.dispatchEvent(eventClick);
    URL.revokeObjectURL(url);
  };

  const onCompleteCallback = (data: ResultType) => {
    const url = URL.createObjectURL(new Blob(
      [(
        data?.exportEventParticipantData?.buffer
          ? Buffer.from(data.exportEventParticipantData.buffer, 'base64')
          : null
      ) as unknown as Buffer],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    ));
    triggerDownload(url);
  };

  const { error, exportData } = useExportEventParticipantDataQuery(onCompleteCallback);

  const useExportParticipantsClick = useCallback(async () => {
    await exportData(
      {
        variables: {
          eventId: event._id,
        },
      },
    );

    if (error) {
      toast.error(t('event_export_error'));
    } else {
      toast.success(t('event_export_success'));
    }
  }, [error, event._id, exportData, t]);

  const menuItemConfigs = useMemo(() => {
    const isPublished = event.status === EventStatus.published;
    const showSendInvitations = !!(event.data as OnlineEvent)?.biogenLincConfig?.showSendInvitations;
    const invitationSent = !!(event.data as OnlineEvent)?.invitationSent;

    return [
      {
        key: EventsListItemAction.showEventLinks,
        label: t('eventList_menuItemConfigs_eventLinks_label'),
        onClick: () => setLinkDialogIsOpen(true),
        hideItem: isDraft(event.status),
      },
      {
        key: EventsListItemAction.editPublishedEvent,
        label: t('eventList_menuItemConfigs_editPublishedEvent_label'),
        onClick: handleEditPublishedClick,
        hideItem: isDraft(event.status),
      },
      {
        key: EventsListItemAction.editEvent,
        label: t('eventList_menuItemConfigs_editEvent_label'),
        onClick: handleEditClick,
      },
      {
        key: EventsListItemAction.exportParticipants,
        label: t('eventList_menuItemConfigs_exportParticipants_label'),
        onClick: useExportParticipantsClick,
      },
      {
        key: EventsListItemAction.sendInvitation,
        label: t('eventList_menuItemConfigs_sendInvitation_label'),
        onClick: () => executeMutation(event?._id ? event?._id.toString() : ''),
        hideItem: isDraft(event.status) || !(isPublished && showSendInvitations && !invitationSent),
      },
    ];
  }, [t, event.status, event.data, event._id, handleEditPublishedClick, handleEditClick, useExportParticipantsClick, executeMutation]);

  const eventStatusBadgeMap: Record<EventStatus, { label: string; bgColor: string; color: string }> = {
    [EventStatus.draft]: {
      label: t('event_status_draft'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
    [EventStatus.ready]: {
      label: t('event_status_draft'),
      bgColor: YodaColors.warningLight,
      color: YodaColors.warning,
    },
    [EventStatus.published]: {
      label: t('event_status_published'),
      bgColor: YodaColors.greenSuccessLight,
      color: YodaColors.greenSuccessDark,
    },
  };

  return {
    badge: eventStatusBadgeMap[event.status],
    handleClickEvent: isDraft(event.status) ? handleEditClick : handleEditPublishedClick,
    menuItemConfigs,
    linkDialogIsOpen,
    closeLinkDialog,
  };
};

export default useEventsListItem;
