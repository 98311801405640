import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import { BuildDateRangeTimeOptionsProps, SelectOption } from './options.types';
import { getStartTimeFromDate, getUTCDate, timeToObject } from 'basics/utils/dates/dates.utils';

const numberToTime = (hour: number, minute: number): string => `${String(hour).padStart(2, '0')}:${minute === 0 ? '00' : 15 * minute}`;

export const rawToTime = (strTime: string) => {
  const { hour, minutes } = timeToObject(strTime);
  let hourToTime = hour;
  let minuteToTime = Math.ceil(minutes / 15);
  if (minuteToTime > 3) {
    minuteToTime = 0;
    hourToTime += 1;
  }
  return hourToTime < 24 ? numberToTime(hourToTime, minuteToTime) : '00:00';
};

export const buildTimeOption = (hour: number, minute: number) => {
  const time = numberToTime(hour, minute);
  return { id: time, label: time };
};

export const buildTimeOptions = (startTime?: string, endTime?: string, shouldSkipFirstOption = false): SelectOption[] => {
  let timeOptions: SelectOption[] = [];
  let hourIteration = 0;
  let minuteIteration = 0;

  for (hourIteration; hourIteration < 24; hourIteration += 1) {
    for (minuteIteration; minuteIteration < 4; minuteIteration += 1) {
      timeOptions.push(buildTimeOption(hourIteration, minuteIteration));
    }
    minuteIteration = 0;
  }

  // If startTime, remove all options before startTime
  if (startTime) {
    const formattedStartTime = rawToTime(startTime);
    let startTimeIndex = timeOptions.findIndex((value) => value.id === formattedStartTime);
    if (startTimeIndex >= 0) {
      if (shouldSkipFirstOption) {
        startTimeIndex += 1;
      }
      timeOptions = timeOptions.slice(startTimeIndex);
    }
  }

  // If endTime, remove all optiones after endtime
  if (endTime) {
    const formattedEndTime = rawToTime(endTime);
    const endTimeIndex = timeOptions.findIndex((value) => value.id === formattedEndTime);
    if (endTimeIndex >= 0) {
      timeOptions = timeOptions.slice(0, endTimeIndex);
    }
  }

  return timeOptions;
};

export const buildDateRangePickerStartTimeOptions = ({ dateRange, startTime, setValue, fieldsState }: BuildDateRangeTimeOptionsProps) => {
  const [startDate, endDate] = dateRange || [null, null];
  if (startDate && isToday(startDate)) {
    const now = new Date();
    const startDateUtc = getUTCDate(startDate, startTime);
    if (startDateUtc && startDateUtc < now) {
      if (startDate === endDate) {
        const endDateUtc = getUTCDate(endDate, fieldsState.endTime?.value);
        if (endDateUtc && endDateUtc < now) {
          setValue('endTime', undefined);
        }
      }
      return buildTimeOptions(startTime);
    }
    return buildTimeOptions(getStartTimeFromDate(now));
  }
  return buildTimeOptions();
};

export const buildDateRangePickerEndTimeOptions = ({ dateRange, startTime, setValue, fieldsState }: BuildDateRangeTimeOptionsProps) => {
  const [startDate, endDate] = dateRange || [null, null];
  const realEndDate = endDate || startDate;
  if (startDate && realEndDate && isSameDay(startDate, realEndDate)) {
    const endDateUtc = getUTCDate(realEndDate, fieldsState.endTime?.value);
    const startDateUtc = getUTCDate(startDate, startTime);
    if (startDateUtc && endDateUtc && endDateUtc < startDateUtc) {
      setValue('endTime', undefined);
    }
    let now;
    if (isToday(realEndDate)) {
      now = getStartTimeFromDate(new Date());
    }
    return buildTimeOptions(startTime || now, undefined, true);
  }
  return buildTimeOptions();
};
