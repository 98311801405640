import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import { Participant } from 'modules/events/basics/types/events.types';
import ParticipantsList from 'modules/events/components/ParticipantsList';

type ParticipantsGroupProps = {
  participants: Participant[];
};

const ParticipantsGroup: FC<ParticipantsGroupProps> = (props) => {
  const { t } = useTranslation();
  const { participants } = props;

  if (!participants) {
    return null;
  }

  return (
    <FormGroup
      title={ t('participantsGroup_formGroupTitle') }
      groupName={ CommonGroups.invitees }
      collapsible={ false }
    >
      <ParticipantsList participants={ participants }/>
    </FormGroup>
  );
};

export default ParticipantsGroup;
