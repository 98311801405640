import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors } from 'yoda-ui/yodaTheme';

export const widgetItem: SxProps<Theme> = {
  display: 'flex',
  width: '20%',
  justifyContent: 'space-between',
  backgroundColor: YodaColors.primaryBlueLight,
  padding: '20px 15px',
  fontWeight: '400',
  fontSize: '25px',
  borderRadius: '10px',
};
