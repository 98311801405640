import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import { CommonGroups } from 'modules/events/basics/enums/commonForms.enums';
import Coorganizers from 'modules/events/components/Coorganizers';
import useCoorganizersConfig from 'modules/events/groups/CoorganizersGroup/CoorganizersGroup.config';

const CoorganizersGroup: FC = () => {
  const { t } = useTranslation();
  const { coorganizersConfig, isPublished } = useCoorganizersConfig();
  return (
    <FormGroup title={ t('coorganizers_form_list_title') } groupName={ CommonGroups.coorganizers }>
      <Coorganizers { ...coorganizersConfig.coorganizers } disabled={ isPublished } />
    </FormGroup>
  );
};

export default CoorganizersGroup;
