import { CustomerEngagementPlanning } from 'generated/maya.types';

export type CepFormSubmitCallbackType = (
  cep: Partial<CustomerEngagementPlanning>
) => Promise<CustomerEngagementPlanning | null | undefined>;

export type CepFormPropsType = {
  submitCallback: CepFormSubmitCallbackType;
  isTemplate: boolean;
};

export enum CepFormStepsIndex {
  configurationStep = 0,
  targetListStep = 1,
  tacticsStep = 2,
  costEstimationStep = 3,
}
