import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MayaMenuEntryConfig } from './MayaMenu.types';
import {
  mayaCepCreateRoute,
  mayaCepDuplicateRoute,
  mayaCepEditRoute,
  mayaCepListRoute,
  mayaCustomerInitiativeCreateRoute,
  mayaCustomerInitiativeListRoute,
  mayaImportExportRoute,
  mayaCiTemplateListRoute,
  mayaStrategicImperativeCreateRoute,
  mayaStrategicImperativeListRoute,
  mayaCiTemplateCreateRoute,
  mayaCustomerInitiativeEditRoute,
  mayaCustomerInitiativeDuplicateRoute,
  mayaCiTemplateEditRoute,
  mayaCepTemplateListRoute,
  mayaCepTemplateCreateRoute,
  mayaCepTemplateEditRoute,
  mayaCepTrashedListRoute,
  mayaStrategicImperativeDuplicateRoute,
  mayaStrategicImperativeEditRoute,
} from 'basics/constants/routes.constants';
import useGetFacetStrategicImperativeYearsQuery from 'modules/maya/graphql/queries/getFacetStrategicImperativesYears';

const useMayaMenu = () => {
  const location = useLocation();
  const currentLocation = location.pathname;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const flags = useFlags();

  const { data: siYear, loading } = useGetFacetStrategicImperativeYearsQuery();

  const strategicImperativePath = () => {
    const siChildren: MayaMenuEntryConfig[] = [];

    if (!loading) {
      siYear.forEach(
        (year) => siChildren.push({
          id: `strategicImperativeList${year}`,
          title: t('maya_main_menu_si_by_year_label', { year }),
          targetRoute: `${mayaStrategicImperativeListRoute}?year=${year}`,
          enable: true,
          activeRoute: [
            `${mayaStrategicImperativeListRoute}?year=${year}`,
            `${mayaStrategicImperativeCreateRoute}?year=${year}`,
            `${mayaStrategicImperativeDuplicateRoute}?year=${year}`,
            `${mayaStrategicImperativeEditRoute}?year=${year}`,
          ],
        }),
      );
    }

    return siChildren;
  };

  const configMenu: MayaMenuEntryConfig[] = [
    {
      id: 'strategicImperative',
      title: t('maya_main_menu_strategic_imperative_label'),
      targetRoute: mayaStrategicImperativeListRoute,
      enable: flags.mayaBiogenlinc3715ShowStrategicImperatives,
      activeRoute: [
        mayaStrategicImperativeListRoute,
        mayaStrategicImperativeCreateRoute,
        mayaStrategicImperativeDuplicateRoute,
        mayaStrategicImperativeEditRoute,
      ],
      childrens: strategicImperativePath(),
    },
    {
      id: 'customerInitiative',
      title: t('maya_main_menu_ci'),
      enable: true,
      activeRoute: [
        mayaCustomerInitiativeListRoute,
        mayaCustomerInitiativeCreateRoute,
        mayaCustomerInitiativeEditRoute,
        mayaCustomerInitiativeDuplicateRoute,
        mayaCiTemplateListRoute,
        mayaCiTemplateCreateRoute,
        mayaCiTemplateEditRoute,
      ],
      childrens: [
        {
          id: 'customerInitiativelist',
          title: t('maya_main_menu_ci_list_label'),
          targetRoute: mayaCustomerInitiativeListRoute,
          enable: true,
          activeRoute: [
            mayaCustomerInitiativeListRoute,
            mayaCustomerInitiativeCreateRoute,
            mayaCustomerInitiativeEditRoute,
            mayaCustomerInitiativeDuplicateRoute,
          ],
        },
        {
          id: 'customerInitiativeTemplates',
          title: t('maya_menu_ci_tpl_label'),
          targetRoute: mayaCiTemplateListRoute,
          enable: flags.mayaBiogenlinc3129EnableCiTemplates,
          activeRoute: [
            mayaCiTemplateListRoute,
            mayaCiTemplateCreateRoute,
            mayaCiTemplateEditRoute,
          ],
        },
      ],
    },
    {
      id: 'customerEngagementPlanning',
      title: t('maya_main_menu_cep'),
      enable: true,
      activeRoute: [
        mayaCepListRoute,
        mayaCepCreateRoute,
        mayaCepEditRoute,
        mayaCepDuplicateRoute,
        mayaCepTemplateListRoute,
        mayaCepTemplateCreateRoute,
        mayaCepTemplateEditRoute,
        mayaCepTrashedListRoute,
      ],
      childrens: [
        {
          id: 'customerEngagementPlanningList',
          title: t('maya_main_menu_cep_list_label'),
          targetRoute: mayaCepListRoute,
          enable: true,
          activeRoute: [
            mayaCepListRoute,
            mayaCepCreateRoute,
            mayaCepEditRoute,
            mayaCepDuplicateRoute,
          ],
        },
        {
          id: 'cepTemplates',
          title: t('maya_menu_cep_tpl_label'),
          targetRoute: mayaCepTemplateListRoute,
          enable: flags.mayaBiogenlinc3129EnableCepTemplates,
          activeRoute: [
            mayaCepTemplateListRoute,
            mayaCepTemplateCreateRoute,
            mayaCepTemplateEditRoute,
          ],
        },
        {
          id: 'customerEngagementPlanningTrashed',
          title: t('maya_menu_cep_trashed_label'),
          targetRoute: mayaCepTrashedListRoute,
          enable: true,
          activeRoute: [
            mayaCepTrashedListRoute,
          ],
        },
      ],
    },
    {
      id: 'aopImportExport',
      title: t('maya_main_menu_import_export_label'),
      targetRoute: mayaImportExportRoute,
      enable: flags.mayaEnableAopImportBiogenlinc32,
      activeRoute: [mayaImportExportRoute],
    },
  ];

  return {
    configMenu,
    currentLocation,
    navigate,
  };
};

export default useMayaMenu;
