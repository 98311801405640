import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BACKOFFICE_TITLE } from 'basics/constants/common.constants';
import LargeContainer from 'components/Layout/LargeContainer';
import { useCurrentUserState } from 'contexts/user';
import CreateEvent from 'modules/events/components/CreateEvent';
import EventsList from 'modules/events/components/EventsList';
import useEvents from 'modules/events/routes/Events/Events.hook';
import usePastEvents from 'modules/events/routes/Events/PastEvents.hook';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';
import Paper from 'yoda-ui/Paper';
import { YodaBorderRadius, YodaColors, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const Events: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useEvents(false);
  const { pastEventsData, pastEventsLoading } = usePastEvents(true);
  const { user } = useCurrentUserState();
  const flags = useFlags();
  const expandPastEvents = false;
  const [expand, setExpand] = useState(expandPastEvents);

  const onChangeHandler = () => {
    setExpand((prevState) => !prevState);
  };

  return (
    <LargeContainer
      flexDirection="column"
      marginBottom={ YodaSpacing.xLarge }
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box maxWidth="70%">
        <Box fontSize={ YodaFontSize.xxxxLarge } color={ YodaColors.gray5 }>
          { t('titles_home', { appName: BACKOFFICE_TITLE, userName: user?.name || '' }) }
        </Box>
        <Box
          marginTop={ YodaSpacing.small }
          marginBottom={ YodaSpacing.medium }
          fontSize={ YodaFontSize.xLarge }
          color={ YodaColors.gray4 }
        >
          { t('home_description') }
        </Box>
      </Box>
      <Box marginY={ YodaSpacing.xxLarge } width="100%">
        <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between">
          <Box fontSize={ YodaFontSize.xLarge }>
            { t('event_list_title') }
          </Box>
          {
            flags.eventsVeevaBiogenlinc400
            && (
              <Box>
                <CreateEvent />
              </Box>
            )
          }
        </Box>
      </Box>
      {
        loading && (
          <Box display="flex" flexDirection="column" justifyContent="center" width="100vw" marginBottom="20px">
            <Loader center />
          </Box>
        )
      }
      {
        data && data.length > 0
          ? <EventsList events={ data } />
          : !loading && (<Box display="flex" justifyContent="center">{ t('events_no_events_result_label') }</Box>)
      }
      {
        flags.eventsShowPastEvents3525 && (
          <Box marginY={ YodaSpacing.xxLarge } width="100%">
            <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between">
              <Box fontSize={ YodaFontSize.xLarge }>
                { t('past_event_list_title') }
              </Box>
            </Box>
          </Box>
        )
      }
      {
        !expand && !pastEventsLoading && pastEventsData.length > 0 && flags.eventsShowPastEvents3525 && (
          <Box display="flex" justifyContent="center" width="100%" height="100%" marginBottom="20px" maxHeight="72px" bgcolor="white">
            <Paper margin={ YodaSpacing.none } borderRadius={ YodaBorderRadius.medium }>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                padding={ YodaSpacing.medium }
              >
                <Box
                  onClick={ onChangeHandler }
                  style={ { cursor: 'pointer' } }
                  fontWeight={ YodaFontWeight.medium }
                >
                  { t('past_event_load_more', { count: pastEventsData.length }) }
                </Box>
              </Box>
            </Paper>
          </Box>
        )
      }
      {
        pastEventsLoading && flags.eventsShowPastEvents3525 && (
          <Box display="flex" flexDirection="column" justifyContent="center" width="100vw" marginBottom="20px">
            <Loader center />
          </Box>
        )
      }
      {
        pastEventsData && pastEventsData.length > 0 && !pastEventsLoading && expand && flags.eventsShowPastEvents3525 && (
          <EventsList events={ pastEventsData } />
        )
      }
      {
        pastEventsData.length === 0 && flags.eventsShowPastEvents3525 && (
          <Box display="flex" justifyContent="center">{ t('events_no_past_events_result_label') }</Box>
        )
      }
    </LargeContainer>
  );
};
export default Events;
