import { FC } from 'react';
import useFormColumn from '../Form2Fields.hook';
import FormFieldTitle from 'components/FormFieldTitle';
import { FieldState, SetFieldState, TextFieldBasis } from 'components/QuickForm/QuickForm.types';
import Box from 'yoda-ui/Box';
import Button from 'yoda-ui/Button';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import RichtextYoda from 'yoda-ui/Form/RichtextYoda';
import TextField from 'yoda-ui/Form/TextField';
import { textYodaStyle } from 'yoda-ui/Form/TextYoda/TextYoda.styles';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

type FormColumnProps = {
  addButtonLabel?: string;
  fieldState: FieldState;
  title: string;
  firstFieldConfig: TextFieldBasis;
  secondFieldConfig: TextFieldBasis;
  setFieldState: SetFieldState;
  transformToState: Function;
};

const FormColumn: FC<FormColumnProps> = ({
  addButtonLabel,
  fieldState,
  title,
  firstFieldConfig,
  secondFieldConfig,
  setFieldState,
  transformToState,
}) => {
  const {
    canAddItem,
    firstField,
    firstTextFieldConfig,
    secondField,
    secondTextFieldConfig,
    handleAddItem,
    setFirstField,
    setSecondField,
    t,
  } = useFormColumn({ firstFieldConfig, secondFieldConfig, setFieldState, fieldState, transformToState });

  return (
    <>
      <FormFieldTitle title={ title } />
      <Box marginBottom={ YodaSpacing.medium }>
        <TextField
          { ...firstTextFieldConfig }
          value={ firstField }
          fullWidth
          sx={ textYodaStyle }
          onChange={ (event) => setFirstField(event.target.value) }
        />
      </Box>
      <Box marginBottom={ YodaSpacing.large }>
        {
          secondFieldConfig.richtext
            ? <RichtextYoda
              { ...secondTextFieldConfig }
              defaultValue={ secondField.length === 0 ? '' : undefined }
              name={ secondFieldConfig.formFieldName }
              onChange={ (value) => setSecondField(value) }
            />
            : <TextField
              { ...secondTextFieldConfig }
              value={ secondField }
              fullWidth
              sx={ textYodaStyle }
              onChange={ (event) => setSecondField(event.target.value) }
            />
        }
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          buttonType={ ButtonType.neutral }
          onClick={ handleAddItem }
          disabled={ !canAddItem }
        >
          { addButtonLabel || t('actions_add_label') }
        </Button>
      </Box>
    </>
  );
};

export default FormColumn;
