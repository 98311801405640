import { fromUnitsToRem, YodaSpacing } from 'yoda-ui/yodaTheme';

export const fieldNumberClickerStyle = {
  '& .FieldNumberClicker-btn': {
    display: 'flex',
    position: 'absolute',
    zIndex: 5,
    top: '50%',
    transform: 'translateY(-50%)',
  },

  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
    paddingLeft: fromUnitsToRem(YodaSpacing.xLarge),
    paddingRight: fromUnitsToRem(YodaSpacing.xLarge),
  },
};
