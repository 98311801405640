import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GridList from 'modules/maya/components/GridList/GridList';
import MayaLayout from 'modules/maya/components/MayaLayout';
import { CepListFilters } from 'modules/maya/routes/CepList/CepList.style';
import useCepTemplateList from 'modules/maya/routes/CepTemplateList/CepTemplateList.hook';
import { useYodaCreateForm, YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';
import ConfirmationDialog from 'yoda-ui/Dialogs/ConfirmationDialog';
import TextYoda from 'yoda-ui/Form/TextYoda';
import Loader from 'yoda-ui/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

const CepTemplateList: FC = () => {
  const { providerFields } = useYodaCreateForm();
  const { t } = useTranslation();

  const {
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    debouncedHandleChangeCepTitle,
    deleteCepCallback,
    getFilteredRows,
    menuCustomerEngagementPlanningLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCepLoading,
    rowCountState,
    rowId,
    setOpen,
  } = useCepTemplateList();

  return (
    <MayaLayout>
      <GridList
        loading={ cepLoading }
        getRowId={ (row) => row._id }
        title={ menuCustomerEngagementPlanningLabel }
        columnConfig={ cepListColumnConfig }
        rows={ getFilteredRows() }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        newElementButton={ newElementButton }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box sx={ CepListFilters }>
                <TextYoda
                  onChange={ debouncedHandleChangeCepTitle }
                  { ...cepTitleFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
        disableColumnMenu={ true }
      />
      {
        open && (
          <ConfirmationDialog
            dialogTitle={ t('maya_cep_modal_remove') }
            onConfirm={
              () => {
                deleteCepCallback(rowId);
              }
            }
            onConfirmLabel={ removeCepLoading ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label') }
            onDismiss={ () => setOpen(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </MayaLayout>
  );
};

export default CepTemplateList;
