import { GridCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { cloneDeep, debounce } from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCepListConfig from './CepList.config';
import { mayaCepCreateRoute, mayaCepDuplicateRoute, mayaCepEditRoute, mayaCepViewRoute } from 'basics/constants/routes.constants';
import { SelectOption } from 'basics/options/options.types';
import { errorToast, succesToast } from 'basics/utils/toast';
import { useCurrentUserState } from 'contexts/user';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { PAGINATION_PAGE_SIZE } from 'modules/maya/basics/constants/common.constant';
import { CepStatus, VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
import { useSetCepStatusToApprovedMutation } from 'modules/maya/graphql/mutations/setCepStatusToApproved';
import { useSetCepStatusToDeletedMutation } from 'modules/maya/graphql/mutations/setCepStatusToDeleted';
import { useSetCepStatusToFeedbackMutation } from 'modules/maya/graphql/mutations/setCepStatusToFeedback';
import { useSyncCepToVeevaMutation } from 'modules/maya/graphql/mutations/syncCepToVeeva';
import useGetAllCepQuery from 'modules/maya/graphql/queries/getAllCeps';

const useCepList = () => {
  const { user } = useCurrentUserState();

  const [cepTitleFilter, setCepTitleFilter] = useState<(string)>();
  const [customerInitiativeFilter, setCustomerInitiativeFilter] = useState<(string | number)[]>([]);
  const [statusFilter, setStatusFilter] = useState<(string | number)[]>([]);
  const [ceps, setCeps] = useState<CustomerEngagementPlanning[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);

  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  const navigate = useNavigate();

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      title: cepTitleFilter,
      status: statusFilter.length ? statusFilter : [CepStatus.DRAFT, CepStatus.APPROVED, CepStatus.FEEDBACK],
      customerInitiative: customerInitiativeFilter.length ? customerInitiativeFilter : undefined,
      isTemplate: false,
      countries: user?.country ? [user?.country] : undefined,
    }),
    [cepTitleFilter, customerInitiativeFilter, statusFilter, user?.country],
  );

  const { data: cepData, loading: cepLoading, count } = useGetAllCepQuery(queryFilters, queryOptions);
  const [setCepStatusToFeedback] = useSetCepStatusToFeedbackMutation();
  const [setCepStatusToApproved] = useSetCepStatusToApprovedMutation();
  const [setCepStatusToDeleted, { loading: removeCepLoading }] = useSetCepStatusToDeletedMutation();
  const [syncCepToVeeva] = useSyncCepToVeevaMutation();

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!cepLoading) {
      setCeps(cepData);
    }
  }, [cepData, cepLoading]);

  const refreshCepStatus = (newStatus: CepStatus, cepId: string) => {
    const clonedCeps = cloneDeep(ceps);
    const targetCepIndex = ceps.findIndex((cep) => { return cep._id === cepId; });
    if (targetCepIndex >= 0) {
      const updatedTargetCep = cloneDeep(ceps[targetCepIndex]);
      updatedTargetCep.status = newStatus;
      clonedCeps.splice(targetCepIndex, 1, updatedTargetCep);
      setCeps(clonedCeps);
    }
  };

  const editCepCallback = async (cepId: string) => {
    navigate(`${mayaCepEditRoute}?id=${cepId}`);
  };

  const duplicateCepCallback = async (cepId: string) => {
    navigate(`${mayaCepDuplicateRoute}?sourceId=${cepId}`);
  };

  const setCepToApprovedCallback = async (cepId: string) => {
    if (await setCepStatusToApproved(cepId)) {
      succesToast(t('maya_action_approve_cep_success'));
      refreshCepStatus(CepStatus.APPROVED, cepId);
    }
  };

  const setCepToFeedbackCallback = async (cepId: string) => {
    if (await setCepStatusToFeedback(cepId)) {
      succesToast(t('maya_action_feedback_cep_success'));
      refreshCepStatus(CepStatus.FEEDBACK, cepId);
    } else {
      errorToast(t('maya_action_feedback_cep_error'));
    }
  };

  const syncCepCallback = async (cepId: string) => {
    const clonedCeps = cloneDeep(ceps);
    const targetCepIndex = clonedCeps.findIndex((cep) => { return cep._id === cepId; });
    const updatedTargetCep = cloneDeep(clonedCeps[targetCepIndex]);

    if (await syncCepToVeeva(cepId)) {
      succesToast(t('maya_action_sync_cep_success'));
      updatedTargetCep.globalCepSyncStatus = { syncStatus: VeevaSyncStatus.SYNC, errors: [] };
    } else {
      errorToast(t('maya_action_sync_cep_error'));
      updatedTargetCep.globalCepSyncStatus = { syncStatus: VeevaSyncStatus.ERROR, errors: [] };
    }

    clonedCeps.splice(targetCepIndex, 1, updatedTargetCep);
    setCeps(clonedCeps);
  };

  const deleteCepCallback = async (cepId: string) => {
    const isCepDeleted = await setCepStatusToDeleted(cepId, true);

    if (isCepDeleted.data) {
      succesToast(t('maya_action_deleting_cep_success'));
      const clonedCeps = cloneDeep(ceps);
      const targetCepIndex = clonedCeps.findIndex((cep) => { return cep._id === cepId; });
      if (targetCepIndex !== -1) {
        clonedCeps.splice(targetCepIndex, 1);
        setCeps(clonedCeps);
      }
    } else if (isCepDeleted.error) {
      errorToast(isCepDeleted.error);
    }
    setOpen(false);
  };

  const openModalCallback = (cepId: string) => {
    setOpen(true);
    setRowId(cepId);
  };

  const {
    cepListColumnConfig,
    cepTitleFieldConfig,
    statusFieldConfig,
    customerInitiativeFieldConfig,
  } = useCepListConfig(
    setCepToFeedbackCallback,
    setCepToApprovedCallback,
    syncCepCallback,
    editCepCallback,
    duplicateCepCallback,
    openModalCallback,
  );

  const newCepClick = () => {
    navigate(mayaCepCreateRoute);
  };

  const handleChangeCepTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCepTitleFilter(event.target.value);
  };
  const debouncedHandleChangeCepTitle = useMemo(
    () => debounce(handleChangeCepTitle, 750),
    [],
  );

  const handleChangeCI = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption[]) => {
    const values = value.map((element) => element.id);
    setCustomerInitiativeFilter(values);
  }, [setCustomerInitiativeFilter]);

  const handleChangeStatus = useCallback((event: SyntheticEvent<Element, Event>, value: SelectOption[]) => {
    const values = value.map((element) => element.id);
    setStatusFilter(values);
  }, [setStatusFilter]);

  const getFilteredRows = useCallback(() => {
    let filteredRows = ceps;

    if (customerInitiativeFilter.length > 0) {
      filteredRows = cepData.filter((value) => {
        if (typeof value.customerInitiative === 'string') {
          return value.customerInitiative;
        }

        return customerInitiativeFilter.indexOf(value.customerInitiative._id) !== -1;
      });
    }

    if (statusFilter.length > 0) {
      filteredRows = filteredRows.filter((value) => {
        return statusFilter.indexOf(value.status) !== -1;
      });
    }

    return filteredRows;
  }, [ceps, customerInitiativeFilter, statusFilter, cepData]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleClick = (params: GridCellParams) => {
    if (params.field !== 'action') {
      navigate(`${mayaCepViewRoute}?id=${params.row._id}`);
    }
  };

  const menuCustomerEngagementPlanningLabel = t('maya_main_menu_cep_label');

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: menuCustomerEngagementPlanningLabel }),
    newElementClick: newCepClick,
  };

  return {
    cepData,
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    customerInitiativeFieldConfig,
    customerInitiativeFilter,
    debouncedHandleChangeCepTitle,
    deleteCepCallback,
    getFilteredRows,
    handleChangeCI,
    handleChangeStatus,
    handleClick,
    menuCustomerEngagementPlanningLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCepLoading,
    rowCountState,
    rowId,
    setOpen,
    statusFieldConfig,
    statusFilter,
  };
};

export default useCepList;
