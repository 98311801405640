import { useEffect, useState } from 'react';
import { CustomerEngagementPlanning } from 'generated/maya.types';

const useCepConfigView = (cep?: CustomerEngagementPlanning) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cep) {
      setLoading(false);
    }
  }, [cep, setLoading]);

  let date;
  if (cep?.dateStart && cep?.dateEnd) {
    date = `
      ${new Date(cep.dateStart).toLocaleDateString('fr-FR')} 
      - 
      ${new Date(cep.dateEnd).toLocaleDateString('fr-FR')}`;
  }

  return {
    cep,
    date,
    loading,
  };
};

export default useCepConfigView;
