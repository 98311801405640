import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCustomerInitiativeFormSchema from './CustomerInitiativeForm.schema';
import { CustomerInitiativeFormConfigType } from './CustomerInitiativeForm.types';
import { mayaCiTemplateCreateRoute, mayaCiTemplateEditRoute } from 'basics/constants/routes.constants';
import initiativeCountriesAndRegionOptions from 'basics/options/countriesAndRegion.options';
import initiativeCountryOptions from 'basics/options/country.options';
import useProductOptions from 'basics/options/product.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { CustomerInitiative, StrategicImperative } from 'generated/maya.types';
import { CiStatus } from 'modules/maya/basics/enums/maya.enums';
import biogenFunctionOptions from 'modules/maya/basics/options/biogenFunction.options';
import useStrategicImperativeOptions from 'modules/maya/basics/options/strategicImperative.options';
import { ciToForm } from 'modules/maya/basics/transformers/CustomerInitiative.transformer';

const useCustomerInitiativeFormConfig = (
  customerInitiative: CustomerInitiative | undefined,
  strategicImperative: StrategicImperative | undefined,
  therapeuticArea: string | null,
) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentURL = location.pathname;
  const templateRoute = [mayaCiTemplateCreateRoute, mayaCiTemplateEditRoute];
  const countryOptions = templateRoute.includes(currentURL) ? initiativeCountriesAndRegionOptions : initiativeCountryOptions;
  const isTemplate = templateRoute.includes(currentURL);
  const customerInitiativeFormSchema = useCustomerInitiativeFormSchema(isTemplate);

  const defaultFormValue = useMemo(
    () => ciToForm(customerInitiative, strategicImperative),
    [customerInitiative, strategicImperative],
  );

  const therapeuticAreaDefaultValue = customerInitiative?.therapeuticArea || strategicImperative?.therapeuticArea || '';
  const currentTA = therapeuticArea ?? customerInitiative?.therapeuticArea ?? '';

  const customerInitiativeFormConfig: CustomerInitiativeFormConfigType = {
    title: {
      label: t('maya_customer_initiative_form_title_label'),
      name: 'title',
      placeholder: t('maya_customer_initiative_form_title_placeholder'),
      required: true,
      defaultValue: defaultFormValue.title,
      validation: customerInitiativeFormSchema.title.trim(),
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    description: {
      label: t('maya_customer_initiative_form_description_label'),
      name: 'description',
      placeholder: t('maya_customer_initiative_form_description_placeholder'),
      required: true,
      defaultValue: defaultFormValue.description,
      multiline: true,
      validation: customerInitiativeFormSchema.description.trim(),
      rows: 4,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    date: {
      startText: t('maya_customer_initiative_date_start_label'),
      endText: t('maya_customer_initiative_date_end_label'),
      name: 'date',
      disablePast: false,
      validation: customerInitiativeFormSchema.date,
      startRequired: true,
      endRequired: true,
      defaultValue: defaultFormValue.date,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    country: {
      label: t('maya_customer_initiative_form_country_label'),
      name: 'country',
      placeholder: t('maya_customer_initiative_form_country_placeholder'),
      defaultValue: defaultFormValue.country,
      options: countryOptions,
      required: true,
      validation: customerInitiativeFormSchema.country,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    therapeuticArea: {
      label: t('maya_customer_initiative_form_therapeutic_area_label'),
      name: 'therapeuticArea',
      placeholder: t('maya_customer_initiative_form_therapeutic_area_placeholder'),
      defaultValue: therapeuticAreaDefaultValue,
      options: useTherapeuticAreaOptions(),
      required: true,
      validation: customerInitiativeFormSchema.therapeuticArea,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    strategicImperative: {
      label: t('maya_customer_initiative_form_strategic_imperative_label'),
      name: 'strategicImperative',
      placeholder: t('actions_select_placeholder'),
      options: useStrategicImperativeOptions({ _id: strategicImperative?._id }),
      defaultValue: defaultFormValue.strategicImperative,
      required: true,
      validation: customerInitiativeFormSchema.strategicImperative,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    product: {
      label: t('maya_customer_initiative_form_product_label'),
      name: 'product',
      placeholder: t('maya_customer_initiative_form_product_placeholder'),
      options: useProductOptions(currentTA),
      defaultValue: defaultFormValue.product,
      required: true,
      validation: customerInitiativeFormSchema.product,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    problemStatement: {
      label: t('maya_customer_initiative_form_problem_statement_label'),
      name: 'problemStatement',
      placeholder: t('maya_customer_initiative_form_problem_statement_placeholder'),
      required: false,
      defaultValue: defaultFormValue.problemStatement,
      multiline: true,
      rows: 4,
      validation: customerInitiativeFormSchema.problemStatement,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    solutionCoreStory: {
      label: t('maya_customer_initiative_form_solution_core_story_label'),
      name: 'solutionCoreStory',
      placeholder: t('maya_customer_initiative_form_solution_core_story_placeholder'),
      required: true,
      defaultValue: defaultFormValue.solutionCoreStory,
      multiline: true,
      rows: 4,
      validation: customerInitiativeFormSchema.solutionCoreStory.trim(),
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    successMesure: {
      label: t('maya_customer_initiative_form_success_mesure_label'),
      name: 'successMesure',
      placeholder: t('maya_customer_initiative_form_success_mesure_placeholder'),
      required: false,
      defaultValue: defaultFormValue.successMesure,
      multiline: true,
      rows: 4,
      validation: customerInitiativeFormSchema.successMesure,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    functionalOwner: {
      label: t('maya_customer_initiative_form_functional_owner_label'),
      name: 'functionalOwner',
      placeholder: t('maya_customer_initiative_form_functional_owner_placeholder'),
      options: biogenFunctionOptions,
      defaultValue: defaultFormValue.functionalOwner,
      required: true,
      validation: customerInitiativeFormSchema.functionalOwner,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    functionsInvolved: {
      label: t('maya_customer_initiative_form_functions_involved_label'),
      name: 'functionsInvolved',
      placeholder: t('actions_select_placeholder'),
      options: biogenFunctionOptions,
      required: true,
      defaultValue: defaultFormValue.functionsInvolved,
      validation: customerInitiativeFormSchema.functionsInvolved,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
    totalBudget: {
      label: t('maya_customer_initiative_form_total_budget_label'),
      name: 'totalBudget',
      placeholder: t('maya_customer_initiative_form_total_budget_placeholder'),
      defaultValue: defaultFormValue.totalBudget,
      required: false,
      validation: customerInitiativeFormSchema.totalBudget,
      disabled: customerInitiative?.status === CiStatus.ACTIVE,
    },
  };

  return { customerInitiativeFormConfig };
};

export default useCustomerInitiativeFormConfig;
